import React from 'react'
import _ from 'lodash/fp'
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import {Button} from '@startlibs/components'

export const useRemoveQuestion = (form,path,i,check = ({id,...value}) => Object.values(value).filter(_.identity).length>0, dialogType = "generic") => {

  const confirmRemove = () => form.setValue(path,_.pullAt([i]))

  const TITLES = {
    locations: "Delete location",
    generic: "Confirm field deletion"
  }

  const ENTITY = {
    locations: "location",
    generic: "field"
  }

  const confirmDelete = useConfirmDialog(
    <ConfirmDialog
      title={TITLES[dialogType]}
      confirm={<Button alert>Delete</Button>}
      action={confirmRemove}
    >
      <p><b>Are you sure you want to delete this {ENTITY[dialogType]}?</b></p>
      <p>This cannot be undone.</p>
    </ConfirmDialog>
  )

  return () => {
    const value = form.getValue([...path, i])
    if (check(value)) {
      confirmDelete()
    } else {
      confirmRemove()
    }
  }
}
