import { Button, Icon, Loading } from '@startlibs/components';
import { getColor } from '@startlibs/utils';
import {useToggle} from '@startlibs/core'
import React, {useState} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';
import { EditorPageDivider } from './ReportFrontCover';
import {ImageInputContainer, ThumbnailContainer} from '../../../experts/components/ExpertProfileLayout'
import {NotProvided} from '../../ExpertAssignmentStep'
import { ReportTextButton } from './EditLayoutButton';
import {getAvatarBackgroundImage} from '../../../experts/utils/avatar'
import {getJwt} from '../../../../hooks/useJwt'
import { jwtPostFetcher } from '../../../../utils/authFetch';
import {lazyExperts} from './lazyExperts'
import {getReportResource, isRichText, safeMultipleNewLineToBr} from '../../../../utils/utils'
import {IfLocale} from '../../../../hocs/IfLocale'
import { RichTextDisplay } from '../../../../components/RichTextDisplay';

const ExpertProfileBox = styled.div`
  border: 1px solid ${getColor('gray210')};
  border-radius: 5px;
  background: white;
  & ~ & {
    margin-top: 1rem;
  }
  .expertbox-heading {
    position: relative;
    display: flex;
    padding: 1.5rem 1rem;
    align-items: center;
    ${ImageInputContainer} {
      flex-basis: 4.5rem;
      flex-shrink: 0;
      width: 4.5rem;
      height: 4.5rem;
      margin: 0 1rem 0 0;
      border: 1px solid ${getColor('gray210')};
      ${ThumbnailContainer} {
        box-shadow: none;
      }
      ${Icon} {
        font-size: 24px;
      }
    }
    .expert-name {
      font-size: 14px;
      font-weight: 600;
      flex-basis: 140px;
      flex-shrink: 0;
      margin-right: 1rem;
      flex-grow: 1;
    }
    .specializations {
      flex-grow: 1;
      padding-top: .5rem;
      flex-basis: 290px;
      .specialization-wrapper {
        font-size: 12px;
        > span ~ span {
          :before {
              content: ", ";
          }
        }
      }
    }
    .expertbox-actions {
      position: absolute;
      top: .5rem;
      right: .5rem;
      display: flex;
      align-items: center;
      ${Button} {
        margin-left: .5rem;
      }
      ${Loading} {
        margin-right: .5rem;
      }
    }
  }
  .expertbox-resume {
    padding: 1.5rem;
    display: flex;
    border-top: 1px solid ${getColor('gray210')};
    label {
      font-weight: 600;
      flex-basis: 5rem;
      flex-shrink: 0;
      font-size: 12px;
      padding: 0;
      margin: 0;
      border: 0;
    }
    .resume-content {
      flex-grow: 1;
      flex-basis: 0;
      min-width: 200px;
    }
  }
`

export const ReportExpertsProfiles = ({caseRequest,setCaseRequest, form}) => {

  const [experts] = useState(() => lazyExperts.use(caseRequest.reportExpertReviews).read())



  return <div>

    {experts.map(expert =>  <ExpertProfileItem
      isHidden={caseRequest.reportExpertReviews.find(({expertId})=> expertId === expert.id)?.hide}
      form={form} expert={expert} caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>)}

    <EditorPageDivider>
      <span>Expert profile end</span>
    </EditorPageDivider>

  </div>

}

export const ExpertProfileItem = ({expert,caseRequest,showEdit,setCaseRequest, resourceOrigin,form,isHidden}) => {

  //
  const loading = useToggle()

  const toggleHide = () => {
    const toggled = caseRequest.reportExpertReviews.map(({expertId,hide}) => expertId === expert.id ? {expertId,hide:!hide} : {expertId,hide})
    loading.wait(jwtPostFetcher(getJwt())('/api/admin/reportExpertReviews?requestId='+caseRequest.requestId,toggled,{method:"PUT"})
      .then(() => setCaseRequest(_.set('reportExpertReviews',toggled))))
  }

  return <ExpertProfileBox css={isHidden && "opacity: 0.5"} className="NODE-TO-BREAK">
    <div className="expertbox-heading">
      <ImageInputContainer>
        <ThumbnailContainer viewOnly css={expert.picture && `background-image:url('${getReportResource(expert.picture,resourceOrigin)}');`}>
          {!expert.picture && <Icon icon="physician"/>}
        </ThumbnailContainer>
      </ImageInputContainer>
      <div className="expert-name">
        {expert.firstName} {expert.lastName}
      </div>
      <IfLocale not contains="LEGAL"><div className="specializations">
        <div><strong>Specialization:</strong></div>
        {
          expert.info?.specialization?.length
            ? <div className="specialization-wrapper">{expert.info.specialization.map(spec => <span key={spec}>{spec}</span>)}</div>
            : <NotProvided/>
        }
      </div></IfLocale>
      {setCaseRequest && <div className="expertbox-actions">
        {loading.isOpen &&  <Loading size={16} borderWidth={3} />}
        <ReportTextButton onClick={toggleHide}>
          {isHidden ?
            <><Icon icon="view" /> Show in report</>
            : <><Icon icon="hide" /> Hide from report</>
          }
        </ReportTextButton>
        <Button.Link to={"/admin/experts/edit/"+expert.id} state={{prevLocation:window.location.pathname,nextLocation:window.location.pathname}} small>Edit expert profile</Button.Link>
      </div>}
      {showEdit && <div className="expertbox-actions">
        <Button.Link small to="/expert/profile">Edit profile</Button.Link>
      </div>}
    </div>
    {expert.info.resume &&
      <div className="expertbox-resume field-content-block">
        <label>Resume:</label>
        {/* <div className="resume-content"> */}
          {/* <div dangerouslySetInnerHTML={{__html: safeMultipleNewLineToBr(expert.info.resume)}}/> */}
          <RichTextDisplay className="resume-content" content={expert.info.resume} useSafeNewLine={!isRichText(expert.info.resume)} />
        {/* </div> */}
    </div>
    }
  </ExpertProfileBox>

}
