import React from 'react'
import _ from 'lodash/fp'
import {callIfFunction, getFetcher} from '@startlibs/utils'
import {useEffect, useMemo} from 'react'
import {clearSession, useClearSession} from './useClearSession'

export const SuspenseError = function(el,invalidateCache,cache) { this.get = () => el; this.invalidateCache = invalidateCache, this.cache=cache}

export const willUseSuspense = (loadResource, onUnauthorized) => {

  const cache = new Map()
  const suspenderCache = new Map()

  function read(key ) {
    if (cache.has(key)) {
      const cached = cache.get(key)
      if (cached instanceof SuspenseError) {
        throw cached
      } else {
        return cached
      }
    }
    if (suspenderCache.get(key)) {
      throw suspenderCache.get(key)
    }
    const suspender = loadResource(key)
    suspenderCache.set(key,suspender)
    suspender.then(thing => {
      cache.set(key,thing)
      suspenderCache.delete(key)
      return thing
    }).catch(e => {
      if (e && e[1] && e[1].status === 401) {
        clearSession()
        if (onUnauthorized) {
          callIfFunction(onUnauthorized,e)
          cache.set(key, new SuspenseError(e,() => remove(key),cache))
        } else {
          if (window.location.pathname.startsWith("/admin")) {
            window.location.href = "/api/adminAuthUrl"
          } else  if (window.location.pathname.startsWith("/expert")) {
            window.location.href = "/api/expertAuthUrl"
          } else {
            window.location.href = "/access"
          }
          return new Promise(() => {})
          // cache.set(key, new SuspenseError({ignoreError:true},() => remove(key),cache))
        }
      } else {
        cache.set(key, new SuspenseError(e,() => remove(key),cache))
        suspenderCache.delete(key)
      }
    })
    throw suspender
  }

  const remove = (key) => cache.delete(key)

  return (key) => {
    useEffect(() => () => remove(key),[key])
    return read(key)
  }
}

export const useSuspense = willUseSuspense((url) => getFetcher(url))



// Suspense integrations like Relay implement
// a contract like this to integrate with React.
// Real implementations can be significantly more complex.
// Don't copy-paste this into your project!
export const wrapPromise = (promise,...rootArgs) => {
  let status;
  let lazyResolver;
  let lazyPromise;
  let result;

  const suspend = (promise) => {
    status = "pending"
    lazyResolver = _.identity()
    lazyPromise = _.isFunction(promise) ? new Promise(res => lazyResolver = res) : Promise.resolve()
    return lazyPromise.then((...args) => callIfFunction(promise,...rootArgs,...args).then(
      r => {
        status = "success";
        result = r;
        return r
      },
      e => {
        status = "error";
        result = e;
        if (e && e[1] && e[1].status === 401) {
          if (window.location.pathname.startsWith("/admin")) {
            window.location.href = "/api/adminAuthUrl"
          } else  if (window.location.pathname.startsWith("/expert")) {
            window.location.href = "/api/expertAuthUrl"
          } else {
            window.location.href = "/access"
          }
          return new Promise(() => {})
        }
      }
    ));
  }

  let suspender = suspend(promise)

  return {
    read(...args) {
      lazyResolver(...args)
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    },
    promise(...args) {
      lazyResolver(...args)
      return suspender
    },
    refresh() {
      if (!_.isFunction(promise)) {
        throw "Refresh is only available on '() => Promise'"
      }
      suspender = suspend(promise)
      return suspender
    }
  }
}

export const WithLazyResource = ({value,fallback = null,children}) => {
  return <React.Suspense fallback={fallback}><Load value={value} children={children}/></React.Suspense>
}
export const Load = ({value,children}) => {
  const loaded = value.read()
  return children(loaded)
}