import {FormValue, RadioboxGroup, SimpleRadiobox, TextInput} from '@startlibs/form'
import React, {useEffect, useRef} from 'react'
import {Card, SectionHeading} from '../../components/PageLayout';
import {smoothScroll} from '@startlibs/utils'
import {FormattedMessage, useIntl} from 'react-intl'
import {IfLocale} from '../../hocs/IfLocale'


export const PrimaryConcern = ({readOnly, scrollTo}) => {

  const headerRef = useRef()
  const intl = useIntl()

  useEffect(() => {
    if (scrollTo) {
      smoothScroll({target:headerRef.current,offset:-90})
    }
  },[])

  return <>
    <SectionHeading ref={headerRef}><h3><FormattedMessage defaultMessage="Primary concern" description="Patient CaseInfo primary concern title"/></h3></SectionHeading>
    <Card css="padding-bottom:1.5rem;">

      {/* //Diagnosis Field  */}
      <IfLocale not contains="LEGAL">
        <FormValue path="caseInfo.intakeDiagnosis">{intakeDiagnosis =>
          intakeDiagnosis !== null && <>
           <TextInput
              disabled={readOnly}
              path="caseInfo.intakeDiagnosis"
              label="Please provide the primary diagnosis"
              helpText="(If unknown, please list unknown)"
              placeholder="Ex. Rheumatoid Arthritis"
              mandatory
            />
          </> 
          }
        </FormValue>

      </IfLocale>
     
      <IfLocale not contains="LEGAL">
        <RadioboxGroup
          disabled={readOnly}
          mandatory
          label="Has the patient been treated or had surgery for this condition before?"
          horizontal
          path="caseInfo.hasBeenTreatedOrSurgeryBefore"
          framedBoxes
          wide
        >
          <SimpleRadiobox fieldValue={true} label="Yes" className='fs-exclude'/>
          <SimpleRadiobox fieldValue={false} label="No" className='fs-exclude'/>
        </RadioboxGroup>

        <FormValue path="caseInfo.hasBeenTreatedOrSurgeryBefore">{hasBeenTreatedOrSurgeryBefore =>
          hasBeenTreatedOrSurgeryBefore && <>
          <TextInput
            disabled={readOnly}
            textarea
            autoResize
            path="caseInfo.treatedOrSurgeryBeforeDescription"
            label="If so, please describe"
            placeholder="Provide any relevant details here"
            className='fs-exclude'
            // mandatory
          />
          </>}
        </FormValue>
        
        <FormValue path="caseInfo.hasNewDiagnosisChangedTreatmentOrRecommendation">{hasNewDiagnosisChangedTreatmentOrRecommendation =>
          hasNewDiagnosisChangedTreatmentOrRecommendation !== null && <>
            <RadioboxGroup
              disabled={readOnly}
              mandatory
              label="Has there been a new diagnosis, changed treatment or other recommendation?"
              horizontal
              path="caseInfo.hasNewDiagnosisChangedTreatmentOrRecommendation"
              framedBoxes
              wide
            >
              <SimpleRadiobox fieldValue={true} label="Yes" className='fs-exclude'/>
              <SimpleRadiobox fieldValue={false} label="No" className='fs-exclude'/>
            </RadioboxGroup>

            <FormValue path="caseInfo.hasNewDiagnosisChangedTreatmentOrRecommendation">{hasNewDiagnosisChangedTreatmentOrRecommendation =>
              hasNewDiagnosisChangedTreatmentOrRecommendation && <>
              <TextInput
                disabled={readOnly}
                textarea
                autoResize
                path="caseInfo.newDiagnosisChangedTreatmentOrRecommendationDescription"
                label="Please describe"
                placeholder="Provide any relevant details here"
                className='fs-exclude'
                // mandatory
              />
              </>}
            </FormValue>  
          </>}
        </FormValue>
      </IfLocale>

      <TextInput
        className='fs-exclude'
        disabled={readOnly}
        textarea
        autoResize
        path="caseInfo.clinicalSummary"
        label={intl.formatMessage({
          defaultMessage:"Please add a description of your request including a brief medical history, current treatment plan, specific questions you may have, and any other pertinent information.",
          description:"Patient CaseInfo clinical summary details label"
        })}
        placeholder={intl.formatMessage({defaultMessage:"Describe your request and provide any additional information here",description:"Patient CaseInfo clinical summary details placeholder"})}
        mandatory
      />

    </Card>
  </>
}
