import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {ProgressBar, ProgressDetails, UploadingRecords, UploadingStatus} from "../AttachmentBoxStyles";
import {
  EmptyFile,
  getFileStateLabel,
  InvalidFilename,
  IS_RETRY_POSSIBLE,
  Quarantined,
  Uploading
} from "../../enums/FileState";
import {FailedLabel} from "../styled/FailedLabel";
import {Icon, Loading} from "@startlibs/components";
import {Failed, Queued} from "../../service/enums/RecordStatus";
import {useUploadQueueSelector} from "../../service/hooks/useUploadQueueSelector";
import {useDoAction} from "../../service/hooks/useDoAction";
import {UploaderAction} from "../../service/UploaderAction";
import {useUIDataSelector} from "../../service/hooks/useUIDataSelector";

styled

export const RecordRowProgressStatus = ({record,isMinified, cancelUpload, openDetails, isMultiFile}) => {
  const doAction = useDoAction()

  const cancel = () => cancelUpload ? cancelUpload(record) : doAction(UploaderAction.Delete,record)
  const retry = () => doAction(UploaderAction.Retry,record)

  const queuedRecord = useUploadQueueSelector(_.find(_.matchesProperty('key',record.key)))
  const isProcessing = useUIDataSelector('isProcessing')

  const uploadingFilesLength = (queuedRecord?.uploading?.length || 0)
  const uploadedFilesLength = (queuedRecord?.uploaded?.length || 0)
  const nonFailedFilesLength = (queuedRecord?.files?.length || 0) + uploadedFilesLength + uploadingFilesLength
  const failedFilesLength = (queuedRecord?.failed?.length || 0)
  const totalFiles = nonFailedFilesLength + failedFilesLength
  const hasOnlyFailedFiles = failedFilesLength === totalFiles && failedFilesLength > 0

  const showCancel = (!isProcessing || !isMultiFile)

  if (!record.files && record.quarantined) {
    return null
  }

  if (hasOnlyFailedFiles) {
    if (isMultiFile) {
      return <UploadingStatus>
        <ProgressDetails failed>
          <a className="link error" onClick={openDetails}>{failedFilesLength} file upload{failedFilesLength > 1 && 's'} failed</a>
          {
            queuedRecord.failed.find(IS_RETRY_POSSIBLE) &&
            <a className="link" onClick={retry}>Retry{failedFilesLength > 1 && ' all'}</a>
          }
        </ProgressDetails>
      </UploadingStatus>
    }
    return <UploadingStatus>
      <ProgressDetails failed>
        <span>File upload failed </span>
        {
          record.failed === InvalidFilename || record.failed === Quarantined || record.failed === EmptyFile
            ? <a className="link" onClick={cancel}>Cancel</a>
            : <>
              <a className="link" css="margin-right: .25rem;" onClick={retry}>Retry</a>
              <a className="link" onClick={cancel}>Cancel</a>
            </>
        }
        <FailedLabel>
           <span className="label-badge">
             <Icon icon="warning"/>
             {getFileStateLabel(record.failed)}
           </span>
        </FailedLabel>
      </ProgressDetails>
    </UploadingStatus>
  }

  if (uploadingFilesLength) {
    return <UploadingStatus>
      {!isMinified && <ProgressDetails>
        <span>Uploading file{totalFiles > 0 && "s"}...</span>
        {showCancel && <a className="link" onClick={cancel}>Cancel</a>}
      </ProgressDetails>}
      {!isMinified && <ProgressBar progress={record.progress} completed={record.progress === 100}/>}
      {
        isMinified &&
        <div css="display: flex; align-items: center;">
          <UploadingRecords><Loading size={16} borderWidth={3}/>Uploading</UploadingRecords>
          {showCancel && <a className="link" onClick={cancel}>Cancel</a>}
        </div>
      }
      {
        !isMinified && (
          failedFilesLength > 0
          ? <div className="files-count">
              <a className="link error" onClick={openDetails}>{failedFilesLength} file upload{failedFilesLength > 1 && 's'} failed</a>
              <a className="link" onClick={retry}>Retry{failedFilesLength > 1 && ' all'}</a>
          </div>
          : <div className="files-count"><a className="link" onClick={openDetails}>{uploadedFilesLength}/{totalFiles} files uploaded</a></div>
        )
      }
    </UploadingStatus>
  }

  if (record.status === Queued) {
    return <UploadingStatus>
      <ProgressDetails>
        <span>Waiting...</span>
        {showCancel && <a className="link" onClick={cancel}>Cancel</a>}
      </ProgressDetails>
      {!isMinified && <ProgressBar progress={record.progress} completed={record.progress === 100}/>}
    </UploadingStatus>
  }

  return null
}