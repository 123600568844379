import React from 'react'


export const ACTIVE = 'ACTIVE'
export const PENDING = 'PENDING'
export const SUSPENDED = 'SUSPENDED'

export const USER_STATUS_LABELS = {
  [ACTIVE]: 'Active',
  [PENDING]: 'Pending activation',
  [SUSPENDED]: 'Suspended'
}