export const RecordClass = {
  Pathology: 'Pathology',
  Radiology: 'Radiology',
  Clinical: 'Clinical',
  Other: 'Other',
}

export const RecordFormat = {
  DicomStudy: 'DicomStudy',
  NonCompliantDicom: 'NonCompliantDicom',
  Mirax: 'Mirax',
  AperioSVS: 'AperioSVS',
  HistechMRXS3D: 'HistechMRXS3D',
  PhilipsiSyntax: 'PhilipsiSyntax',
  HamamatsuNDPI: 'HamamatsuNDPI',
  TIFF: 'TIFF',
  TIF: 'TIF',
  PathologyDicom: 'PathologyDicom',
  CCDA: 'CCDA',
  PDF: 'PDF',
  Other: 'Other',
  VentanaBIF: 'VentanaBIF',
  Leica: 'Leica',
  HamamatsuVMS: 'HamamatsuVMS',
  HamamatsuVMU: 'HamamatsuVMU',
}

export const FormatToLabel = {
  PhilipsiSyntax: 'Philips iSyntax',
  HamamatsuNDPI: 'Hamamatsu NDPI',
  HamamatsuVMU: 'Hamamatsu VMU',
  HamamatsuVMS: 'Hamamatsu VMS',
  VentanaBIF: 'Ventana BIF',
  AperioSVS: 'Aperio SVS',
}
