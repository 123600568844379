import React from 'react'
import {ConfirmDialog, useConfirmDialog, useConfirmDialogWithProps} from '../../hooks/useConfirmDialog'
import {hasAnsweredAll} from '../../request/utils'
import {Button} from '@startlibs/components'
import {using} from '@startlibs/utils'
import {useNavigate} from 'react-router'
import _ from 'lodash/fp'
import {isStrEmpty} from '../../utils'

export const useConfirmAnswers = (caseRequest) => {
  const navigate = useNavigate()
  const onlyAnswers = !caseRequest.requestCode

  const confirmNoneVisibleDialog = useConfirmDialog(() =>
    using(!caseRequest.caseInfo.additionalFields.concat(caseRequest.caseInfo.questionsConsultant).length)( hasFields =>
      <ConfirmDialog
        css="max-width:48rem;"
        title='Enable fields to proceed'
        onSuccess={hasFields ? (() => navigate(`/admin/request/${caseRequest.requestCode}/review`)) : undefined}
        confirm={<Button highlight>{hasFields ? "Edit expert review": "Edit report"}</Button>}
      >
        <p css="margin-top:2rem">
          This report has no available "Questions & answers" and "Additional information".
        </p>
        <p>Please review and enable at least one before proceeding.</p>
      </ConfirmDialog>
    ))

  const confirmSomeUnfilledDialog = useConfirmDialog(
    <ConfirmDialog
      css="max-width:48rem;"
      title={onlyAnswers ? 'Please fill in the report answers' : 'Please fill in the report fields'}
      onSuccess={() => navigate(onlyAnswers ? `/expert/case/${caseRequest.requestId}/questions` : `/admin/request/${caseRequest.requestCode}/review`)}
      confirm={<Button highlight>Edit expert review</Button>}
    >
      <p css="margin-top:2rem">Some of your report {onlyAnswers ? "answers" : "fields"} are left empty. Please complete {onlyAnswers ? "those answers" : "or hide these fields"} before proceeding.</p>
    </ConfirmDialog>)


  return () => {

    const additionalFields = caseRequest?.reportData?.reportLayout?.additionalFields
      ? _.filter(({hide}) => !hide,caseRequest.caseInfo.additionalFields).map(({title,content})=> ({label:title,value:content}))
      : []

    const questionsAndAnswers = caseRequest?.reportData?.reportLayout?.questionsConsultant
      ? _.filter(({hide})=>!hide,caseRequest.caseInfo.questionsConsultant).map(({question,answer})=> ({label:question,value:answer}))
      : []

    const visibleAnswers = onlyAnswers
      ? caseRequest.caseInfo.questionsConsultant.map(({question,answer})=> ({label:question,value:answer}))
      : additionalFields.concat(questionsAndAnswers)

    if (!visibleAnswers.length) {
      confirmNoneVisibleDialog()
      return new Promise(() => {})
    }

    const hasEmptyAnswers = visibleAnswers.find(({label,value})=> isStrEmpty(label) || isStrEmpty(value))
    if (hasEmptyAnswers) {
      confirmSomeUnfilledDialog()
      return new Promise(() => {})
    }
    return Promise.resolve()
  }

}
