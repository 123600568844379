import {Button, SplitColumnsContainer} from '@startlibs/components'
import {Errors, Field, TextInput, WithForm} from '@startlibs/form'
import {useNavigate, useParams} from 'react-router'
import {useRefState} from '@startlibs/core'
import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import _ from 'lodash/fp'
import load from 'little-loader'
import {Card, PageContainer, PageFooter, SectionHeading} from '../components/PageLayout';
import {CreditCardIcon, StripeInput} from '../components/CheckoutComponents'
import {Header} from '../components/Header';
import {PurviewFooter} from '../components/PurviewFooter';
import {buildValidation, required} from '../utils/validation'
import {formatCurrency} from '../utils';
import {getJwt} from '../hooks/useJwt'
import {jwtFormFetcher} from '../utils/authFetch'
import {getCoveredAndCharge} from '../request/forms/utils'
import {CostsAndCoverageDetails} from '../request/CostsAndCoverageDetails'
import {useAlreadyRevoked} from './utils/useAlreadyRevoked'
import {setNotification} from '../components/Notifications'

const preValidation = buildValidation({
    cardholder: required
  }
)

export const PatientCheckout = forwardRef(({caseRequest, providerInfo,setCaseRequest}, ref) => {
  const navigate = useNavigate()
  const {position} = useParams()
  const alreaydRevoked = useAlreadyRevoked(() => setCaseRequest(_.set(`payments[${position}].revoked`,true)) )
  const [cardBrand, setCardBrand] = useState('unknown')
  const [stripe, setStripe] = useState()

  const cardRef = useRefState()
  const cardErrorsRef = useRefState({
    cardCvc: {message: 'Your card\'s security code is incomplete.'},
    cardExpiry: {message: 'Your card\'s expiration date is incomplete.'},
    card: {message: 'Your card number is incomplete.'}
  })
  const cardModifiedRef = useRefState()

  const loadStripe = () =>
    window.Stripe ? Promise.resolve(window.Stripe) : new Promise((res, rej) =>
      load('https://js.stripe.com/v3/', (err) => {
        if (err) {
          rej()
        }
        res(window.Stripe)
      })
    )


  const initStripe = (stripe) => {
    const elements = stripe.elements()
    const style = {
      base: {
        color: '#3c3c3c',
        lineHeight: '18px',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        '::placeholder': {
          color: 'rgba(0,0,0,0.35)'
        }
      },
      invalid: {
        color: '#C3282D',
        iconColor: '#fa755a'
      }
    }

    const cardExpiry = elements.create('cardExpiry', {style})
    cardExpiry.mount('#card-expiry-element')
    cardExpiry.on('change', ({error}) => {
      cardModifiedRef.set(true)
      cardErrorsRef.set((cardErrors) => ({...cardErrors, cardExpiry: error}))
    })

    const cardCvc = elements.create('cardCvc', {style})
    cardCvc.mount('#card-cvc-element')
    cardCvc.on('change', ({error}) => {
      cardModifiedRef.set(true)
      cardErrorsRef.set((cardErrors) => ({...cardErrors, cardCvc: error}))
    })

    cardRef.set(elements.create('cardNumber', {style}))
    cardRef.get().on('change', (event) => {
      setCardBrand(event.brand)
      cardModifiedRef.set(true)
      cardErrorsRef.set((cardErrors) => ({...cardErrors, card: event.error}))
    }).on('ready', (() => {
      setStripe(stripe)
    }))
    cardRef.get().mount('#card-element')
  }

  useEffect(() => {
    loadStripe().then(Stripe => {
        initStripe(Stripe(providerInfo.stripePublicKey))
      }
    )
  }, [])


  const submit = (values) => {
    /*const {loading, nextStep, , orderDetails} = this.props
    const [[coupon,couponError]] = orderDetails*/
    const validated = preValidation(values, {})
    const foundCardErrors = _.fromPairs(_.toPairs(cardErrorsRef.get()).filter(([k,v]) => v).map(([k,v]) => [k,v.message]))
    const stripeValidation = Object.keys(foundCardErrors).length ?
      stripe.createToken(cardRef.get()).then((result) => console.log(result) || ({card: [result.error.message]})) :
      Promise.resolve({})

    if (validated || Object.keys(foundCardErrors).length) {
      return stripeValidation.then((stripeErrors) => Promise.reject([{errors:{...validated, ...foundCardErrors}}]))
    }

    return stripe.createToken(cardRef.get(), {
        name: values.cardholder
      })
      .then((stripeResult) => {
        if (stripeResult.error) {
          // Inform the stomer that there was an error.
          if (stripeResult.error) {
            throw [{errors: {card: [stripeResult.error.message]}}]
          } else {
            throw [{errors: {}}]
          }
        } else {
          const cardInfo = stripeResult.token.card ? {
            lastDigits: stripeResult.token.card.last4,
            brand: stripeResult.token.card.brand
          } : {}
          return {stripeToken: stripeResult.token.id, cardInfo}
        }
      })
      .then(jwtFormFetcher(getJwt())('/api/payments/pay/'+position))
      .then(() => setCaseRequest(_.update(`payments[${position}]`,_.assign(_,{paid:true,chargeDate:new Date()}))))
      .then(() => setNotification("Paid successfully."))
      // .then(() => navigate("/patient/payments"))
      .catch((response) => {
        if(response[1] && response[1].status === 561){
          return response[1] && response[1].status === 561 && alreaydRevoked()
        }
        if(response[1] && response[1].status === 560){
          throw [{errors: {card: [response[0]]}}]
        }
      })
  }

  useImperativeHandle(ref, () => ({
    submit
  }), [stripe])

  const payment = caseRequest.payments[position]
  const [coverage,charge] = getCoveredAndCharge(payment)


  if (!charge || payment?.paid || payment?.revoked) {
    navigate("/patient/payments")
    return null
  }

  return <WithForm
    action={submit}
    alwaysSave
    // onSuccess={() => navigate("/patient")}
  >{form => <>
  <PageContainer>
    <Header
      title="Expert View request"
      caseRequest={caseRequest}
    />
    <SectionHeading>
      <h3>Payment details</h3>
      <p>You will be charged <strong>{providerInfo.currency.label}{formatCurrency(Number(charge))} {providerInfo.currency.key}</strong>. Please fill in your credit card information below to proceed:</p>
      <React.Suspense fallback={null}><CostsAndCoverageDetails payment={payment} white/></React.Suspense>
    </SectionHeading>
    <Card css="margin-bottom:1rem;">
      <SplitColumnsContainer viewportMinWidth={500} margin="big">
        <Field label="Card number" mandatory>
          <StripeInput id="card-element"/>
          <CreditCardIcon src={`/public/icon/${cardBrand}.svg`}/>
        </Field>
        <TextInput
          type="text"
          path="cardholder"
          label="Name on card"
          placeholder="Ex: John Doe"
          tabIndex={0}
          mandatory
        />
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={500} margin="big">
        <Field label="Expiration" mandatory>
          <StripeInput id="card-expiry-element"/>
        </Field>
        <Field label="CVC" mandatory>
          <StripeInput id="card-cvc-element"/>
        </Field>
      </SplitColumnsContainer>
    </Card>
    <Errors/>
    <PageFooter>
      <Button.Link to="/patient/payments">Cancel</Button.Link>
      <Button highlight isLoading={form.isLoading} type="submit">Submit payment</Button>
    </PageFooter>
  </PageContainer>
  <PurviewFooter />
</>}</WithForm>
})
