import { desaturate, lighten } from 'polished';
import React, {useContext, useEffect, useState} from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash/fp';
import styled, {css} from 'styled-components';

import { Icon, Dialog, DialogContent, DialogTitle, DialogFooter, Button, Loading } from '@startlibs/components';
import { SimpleCheckbox, TextInput} from '@startlibs/form'
import { callIfFunction, formatDate, getColor } from '@startlibs/utils';
import { useConstant, useToggle } from '@startlibs/core'

import { AdvancedPopup } from './AdvancedPopup';
import { SubmitDialog } from './SubmitDialog';
import { jwtGetFetcher } from '../../utils/authFetch';
import {UIAction} from "../../service/UIAction";
import {useDoAction} from "../../service/hooks/useDoAction";
import {UploaderConfigContext} from "../../service/UploaderConfigContext";
import {useUIDataSelector} from "../../service/hooks/useUIDataSelector";

const PdfSplitDialog = styled(Dialog)`
  max-width: 100rem;
  height: calc(100vh - 6rem);
  max-height: 70rem;
  ${DialogContent} {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`
const ActiveFilters = styled.div`
  background: ${props => desaturate(0.4, lighten(0.50, getColor("main")(props)))};; 
  margin-top: -0.25rem;
  margin-bottom: 0.75rem;
  padding: 0.75rem;
  border-radius: 6px;
  color: grey;
  display: flex;
  justify-content: space-between;
}
`

const SearchInputContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
  position: relative;
`

const ClearSearchButton = styled(Icon)`
  color: ${getColor('gray90')};
  font-size: 12px;
  position: absolute;
  right: 0.7rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  border-radius: 50%;
  background: rgba(0,0,0,0.1);
  :hover {
    background: #f7d3d4;
    color: ${getColor('alert')};
  }
`

const SelectionPanelWrapper = styled.div`
  background: ${getColor('gray240')};
  border-radius: 6px;
  border: 1px solid ${getColor('gray210')};
  overflow: hidden;
  position: relative;
  flex-grow: 1;
`
const SelectionPanel = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
`

const ListItem = styled.div`
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid ${getColor('gray210')};
  ${props => props.selected && css`
    background: ${props => desaturate(0.5, lighten(0.45, getColor("main")(props)))};
  `} 
`
const SectionTitle = styled.div `
  padding: 1rem 1.5rem 1rem 0.5rem;
  transition: 0.25s ease;
  flex-grow: 1;
  :hover {
    background: rgba(0,0,0,0.05);
    padding: 1rem 1rem 1rem 1rem;
  }
`
const CheckboxContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0.5rem 1rem 1.25rem;
  :hover {
    background: rgba(0,0,0,0.05);
  }
  ${SimpleCheckbox} {
    height: 3.5rem
    width: 3.5rem;
    margin-bottom: 0;
  }
`
const PdfIframe = styled.iframe`
  border: none; 
  height: 100%;
  width: 100%;
  z-index: 2;
  position: relative;
`
const PdfSplitDialogContent = styled.div`
  display: flex;
  flex-grow: 1;
  min-height: 0;
  min-width: 0;
  max-height: 68vh;
`
const LeftContainer = styled.div`
  flex-basis: 30%;
  margin-right: 1rem;
  min-width: 0;
  display: flex;
  flex-direction: column; 
  position: relative;
`
const RightContainer = styled.div`
  flex-grow: 1;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid ${getColor('gray210')};
  position: relative;
`

const EmptyList = styled.div `
  color: ${getColor('gray150')};
  padding: 3rem 1rem;
  font-size: 14px;
  text-align: center;
  max-width: 18rem;
  margin: auto;
`
const SelectedSections = styled.div `
  font-weight: 600;
  position: absolute;
  bottom: -3rem;
  display: flex;
  font-size: 14px;
  .link {
    margin-left: 1rem;
  }
`

export const PdfSplitPopup = () => {
  const record = useUIDataSelector('dialogs.splitPDF')
  const recordId = record.recordUID

  const {
    requestId,
    refreshUrl,
    jwt,
    worklistViewerJwt: expertViewJwt,
    apiEndpoints:{pdfIframeSrc},

  } = useContext(UploaderConfigContext)
  const doAction = useDoAction()
  const close = () => doAction(UIAction.ToggleSplitPDFDialog)

  const [params, rawSetParams] = useState({ page: 1, term: '' });
  const [indexesInfo, setIndexesInfo] = useState([])
  const [filteredInfo, setFilteredInfo] = useState([])
  const [selCount, setSelCount] = useState(0);
  const [page, setPage] = useState(1);
  const [hasMetadata, setHasMetadata] = useState("");
  const loadingBookmarks = useToggle(false)
  const hasError = useToggle(false)

  const pdfSrc = pdfIframeSrc(recordId)
  
  const debounceSetParams = useConstant(_.debounce(500, rawSetParams))

  const setParams = (updater, debounce) => {
    const verifyPage = (updater) => (state) => {
      const updatedState = callIfFunction(updater, state)
      return updatedState
    }
    if (debounce) {
      debounceSetParams(verifyPage(updater))
    } else {
      setTimeout(() => rawSetParams(verifyPage(updater)), 0)
    }
  }

  const handleClick = (value, index) => {
    
    // Set changes on filtered lsit (displayed on screen)
    var localFilteredInfo = []
    localFilteredInfo = filteredInfo
    localFilteredInfo[index].selected = value
    setFilteredInfo(localFilteredInfo)

    // Set selection on all indexes list (loaded from server)
    var localIndexInfo = []
    localIndexInfo = indexesInfo
    var indexOnList = indexesInfo.findIndex((item) => (
      (item.title == localFilteredInfo[index].title)
      && (item.date == localFilteredInfo[index].date)
      && (item.startIndex == localFilteredInfo[index].startIndex)
      && (item.endIndex == localFilteredInfo[index].endIndex)
      ))
    localIndexInfo[indexOnList].selected = value
    setIndexesInfo(localIndexInfo)
    
    // Increment/decrement selection counter
    value ? setSelCount(selCount + 1) : setSelCount(selCount - 1)
    
  }

  const clearSelection = () => {

    var localFilteredInfo = []
    filteredInfo.map((item,index) => {
      localFilteredInfo.push(item)
      localFilteredInfo[index].selected = false
    })
    setFilteredInfo(localFilteredInfo)

    var localIndexInfo = []
    indexesInfo.map((item,index) =>{
      localIndexInfo.push(item)
      localIndexInfo[index].selected = false
    })
    setIndexesInfo(localIndexInfo)

    setSelCount(0)

  }

  const queryTerm = useToggle(params.term || '');
  const advancedPopup = useToggle()
  const submitDialog = useToggle()

  const updateSearchTerm = (v) => {
    queryTerm.openWith(v);
    setParams(_.set('term', v), true);
  }

  useEffect(() => {
    setFilteredInfo(indexesInfo)
    var localFilteredInfo = []
    localFilteredInfo = indexesInfo
    if(params.dateStart){
      localFilteredInfo = localFilteredInfo.filter((info) => new Date(info.date) >= new Date(params.dateStart))
    }
    if(params.dateEnd){
      localFilteredInfo = localFilteredInfo.filter((info) => new Date(info.date) <= new Date(params.dateEnd))
    }
    if(params.term.length > 0){
      localFilteredInfo = localFilteredInfo.filter((info) => info?.title?.toLowerCase().indexOf(params.term.toLowerCase())>=0)
    }
    setFilteredInfo(localFilteredInfo)
  }, [params])

  useEffect(() => {

    loadingBookmarks.open()

    jwtGetFetcher(expertViewJwt)(`/api/pdf/bookmarks/${recordId}/${jwt()}`)
      .then((response) => {
        setIndexesInfo(response.bookmarks)
        setFilteredInfo(response.bookmarks)
        setHasMetadata(response.hasMetadata)
        loadingBookmarks.close()
      })
      .catch((error) => {
        setIndexesInfo([])
        setFilteredInfo([])
        loadingBookmarks.close()
        hasError.open()
      })
    
  }, [])

  
  return <PdfSplitDialog
    closeDialog={close}
  >
    <DialogTitle>Split document</DialogTitle>
    {/* <p>Select sections below to add them as separated documents on medical records list</p> */}
    <p>Select sections below to extract as new documents in the medical record list. This will not impact the original document.</p>
    <PdfSplitDialogContent>
        <LeftContainer>
          <SearchInputContainer>
            <div css="position:relative;width:100%;"><TextInput raw value={queryTerm.isOpen} setValue={updateSearchTerm} placeholder="Search index"/>
            {queryTerm.isOpen && <ClearSearchButton icon="x" onClick={() => updateSearchTerm("")}/>}</div>
            {(indexesInfo.length > 0 && indexesInfo?.[0].date) && <Button 
              withDropdown css="margin-left:1rem;min-width:6rem;"
              onClick={() => advancedPopup.open()}
              disabled={hasError.isOpen || indexesInfo.length === 0 || (filteredInfo.length > 0 && !filteredInfo?.[0].date)}
              >Filter</Button>}
            {advancedPopup.isOpen && 
              <AdvancedPopup 
                closePopup={advancedPopup.close}
                params={params}
                setParams={setParams}
                >
              </AdvancedPopup>
            }
          </SearchInputContainer>  
          {(params.dateStart || params.dateEnd) && <ActiveFilters>
            <div>
              <b>Filtering:</b> 
              {(params.dateStart && params.dateEnd) ? 
                <> Between {formatDate(new Date(params.dateStart),"MM/dd/yyyy","",false)} and {formatDate(new Date(params.dateEnd),"MM/dd/yyyy","",false)}</>
                : (params.dateStart) 
                ? <> From {formatDate(new Date(params.dateStart),"MM/dd/yyyy","",false)}</>
                : <> Up to {formatDate(new Date(params.dateEnd),"MM/dd/yyyy","",false)}</>
              }
            </div>
            <div>
              <a className="link" onClick={() => { setParams(
                  _.flow(
                    _.unset('dateStart'),
                    _.unset('dateEnd')
                  )
              );}}>Clear</a>
            </div>
          </ActiveFilters>}
          {hasMetadata === false && <ActiveFilters>
            Could not find metadata on this PDF file.
            Displaying pages instead.
          </ActiveFilters>}
          <SelectionPanelWrapper>
          <SelectionPanel>
            {loadingBookmarks.isOpen && <Loading absolute/>}
            {hasError.isOpen && <EmptyList>It was not possible to load sections from this file.</EmptyList>}
            {!loadingBookmarks.isOpen && !hasError.isOpen && indexesInfo.length === 0 && <EmptyList>This file has no sections</EmptyList>}
            {!loadingBookmarks.isOpen && !hasError.isOpen && indexesInfo.length > 0 && filteredInfo.length === 0 && <EmptyList>No sections found matching current filters</EmptyList>}
            {filteredInfo.map(
              (info, index) =>
                <ListItem selected={info.selected} key={index}> 
                  <CheckboxContainer onClick={() => handleClick(!info.selected, index)}>
                    <SimpleCheckbox
                      raw
                      value={info.selected}
                      setValue={() => handleClick(info.selected, index)}
                    />
                  </CheckboxContainer>
                  <SectionTitle css="cursor: pointer;" className="fs-exclude" onClick={() => {
                      var pdfContainer = document.getElementById('pdf');
                      ReactDOM.unmountComponentAtNode(pdfContainer);
                      setPage(info.startIndex);
                      const element = <PdfIframe id="pdfiframe" css="margin-top: -42px; height: calc((100%) + 42px);"
                        src={pdfSrc+"#toolbar=1&navpanes=0&scrollbar=0"+"&page="+info.startIndex}>
                      </PdfIframe>;
                      ReactDOM.render(element, document.getElementById('pdf'));
                      }}>
                    {info.date ? info.date + " - " + info.title : info.title}
                  </SectionTitle>
                </ListItem>
            )}
          </SelectionPanel>
        </SelectionPanelWrapper>
          {selCount > 0 && <SelectedSections>
            <div>{selCount} selected {selCount > 1 ? 'sections' : 'section'}</div>
            <a className="link" onClick={() => clearSelection()}>Clear</a>  
          </SelectedSections>}
        </LeftContainer>
        <RightContainer id="pdf">
          <Loading absolute/>
          <PdfIframe 
            css="margin-top: -42px; height: calc((100%) + 42px);"
            src={pdfSrc+"#toolbar=1&navpanes=0&scrollbar=0"+"&page="+page}>
          </PdfIframe>
      </RightContainer>
    </PdfSplitDialogContent>
    <DialogFooter>
      <Button onClick={close}>Cancel</Button>
      <Button 
        highlight 
        disabled={selCount === 0}
        onClick={() => {
          submitDialog.open()
        }}>
        Split
      </Button>
    </DialogFooter>
    {submitDialog.isOpen && 
      <SubmitDialog 
        closePopup={submitDialog.close}
        selectedSections={indexesInfo.filter((item) => item.selected === true)}
        jwt={jwt}
        expertViewJwt={expertViewJwt}
        recordId={recordId}
        requestId={requestId}
        closeParent={close} 
        refreshUrl={refreshUrl}
        >
      </SubmitDialog>
    }
  </PdfSplitDialog>
}
