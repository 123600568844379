import {Button} from '@startlibs/components'
import React from 'react'
import {CardWithIcon, PageContainer, PageFooter, RequestStatusIcon} from '../../components/PageLayout';
import {Header} from '../../components/Header';
import {PurviewFooter} from '../../components/PurviewFooter';

export const PatientApprovalSuccess = ({caseRequest}) => {

  return <>
    <PageContainer>
      <Header
        title="Expert View request"
        caseRequest={caseRequest}
      />
      <CardWithIcon>
        <RequestStatusIcon success icon="check"/>
        <h3>Case ready for expert review.</h3>
        <p>You have accepted your case as compiled by the institution. An expert will now be assigned to it and review your request.</p>
        <p>You will be notified as soon as the expert review is available.</p>
      </CardWithIcon>
      <PageFooter>
        <Button.Link to="/patient/details">View contact details</Button.Link>
        <Button.Link to="/patient/records">View medical records details</Button.Link>
      </PageFooter>
    </PageContainer>
    <PurviewFooter />
  </>
}
