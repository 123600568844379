import {Button, Icon, Tooltip} from '@startlibs/components';
import React from 'react'
import {
  CASE_CLOSED,
  CASE_DRAFT,
  CASE_REVIEWED,
  PENDING_ASSIGNMENT,
  PENDING_REQUEST,
  UNDER_REVIEW,
  WAITING_ACCEPTANCE,
  WAITING_APPROVAL,
  WAITING_MEDICAL_RECORDS,
  WAITING_MORE_INFORMATION
} from '../enums/CaseState'
import {ScrollToTop} from '../components/ScrollToTop'
import {
  CaseId,
  Sidebar,
  SidebarCard,
  SidebarContainer,
  SidebarDesc,
  SidebarInformation,
  SidebarSeparator,
  SidebarTitle,
  StepLink,
} from '../admin/steps/RequestSidebar';
import { IfLocale } from '../hocs/IfLocale';
import {Match} from "../components/Match";
import styled from 'styled-components';
import { getColor } from '@startlibs/utils';
import { useToggle } from '@startlibs/core';
import { ListExpertsDialog } from './dialogs/ListExpertsDialog';
import { lazyUserInfo } from '../components/WithProvider';


const ReadOnlyCard = styled.div`  
  background-color: #F8F2DF;
  color: rgba(0,0,0,0.6);
  font-weight: 600;
  border-radius: 6px;
  font-size: 14px;
  margin: -0.5rem 0.5rem 1rem;
  padding: 1.25rem;
  p {
    font-size: 11px;
    font-weight: 400;
    margin: 0.5rem 0 0;
  }
  ${Icon} {
    font-size: 22px;
    line-height: 0;
    vertical-align: -4px;
    margin-right: 0.25rem;
  }
`

const AssignedExpertInfo = styled(SidebarInformation)`
  padding-bottom: 0.25rem;
  &.expertName {
    font-size: 14px;
  }
  a {
    color: ${getColor('gray60')};
    
    :hover {
      color: ${getColor('gray30')};
    }
  }
`
const CoReviewersButton = styled(Button)`
  color: ${getColor('main')};
  font-weight: 600;
  box-shadow: none;
`

const STATE_LIST = [PENDING_REQUEST,CASE_DRAFT,WAITING_MEDICAL_RECORDS,WAITING_APPROVAL,PENDING_ASSIGNMENT,WAITING_ACCEPTANCE,UNDER_REVIEW,WAITING_MORE_INFORMATION,CASE_REVIEWED, CASE_CLOSED]

const isBefore = (state) => of => STATE_LIST.indexOf(state) < STATE_LIST.indexOf(of)



export const ExpertRequestSidebar = ({caseRequest = {}}) => {
  const isStateBefore = isBefore(caseRequest && caseRequest.state)
  const listExpertsPopup = useToggle()
  const userInfo = lazyUserInfo.read()

  return <Match path="/expert/case/:requestId/:step">{({match}) => {
    const step = match?.params && match.params.step
    return  <SidebarContainer>
      {
        listExpertsPopup.isOpen && <ListExpertsDialog listExpertsPopup={listExpertsPopup} coReviewersList={caseRequest.coReviewers} userInfo={userInfo} />
      }
      <ScrollToTop when={step}/>
      <Sidebar>
        <SidebarCard isPriority={caseRequest.markedAsPriorityEpochMilli} isExpert>
          {caseRequest?.isCoReview && 
            <ReadOnlyCard>
              <Icon icon="view"/> Read-only access
              <p>This case has been shared with you for informational purposes only</p>
            </ReadOnlyCard>
          }
          <div className="sidebar-heading">
            <SidebarDesc>CASE DETAILS</SidebarDesc>
            <SidebarTitle className='fs-exclude'>{caseRequest.patientInfo.firstName} {caseRequest.patientInfo?.middleName} {caseRequest.patientInfo.lastName}</SidebarTitle>
            <IfLocale contains="LEGAL">
              {caseRequest?.legalInfo?.caseNumber?.length > 0 
                ? <CaseId><b>Case Number:</b> {caseRequest.legalInfo.caseNumber}</CaseId>
                : null
              }
            </IfLocale>
            {caseRequest.isCoReview && caseRequest.leadExpert && <>
              <SidebarDesc>ASSIGNED TO</SidebarDesc>
              <AssignedExpertInfo className="expertName"><b>{caseRequest.leadExpert?.firstName} {caseRequest.leadExpert?.lastName}</b></AssignedExpertInfo>
              <AssignedExpertInfo><a href={"mailto:"+caseRequest.leadExpert?.email} target="_blank">{caseRequest.leadExpert?.email}</a></AssignedExpertInfo>
            </>}
            {
              <div className="sidebar-icons">
                {caseRequest.coReviewers && caseRequest.coReviewers.length > 0 && <>
                  <CoReviewersButton small color="#e7f0f5" onClick={()=> listExpertsPopup.open()} icon="users-line">{caseRequest.coReviewers.length}</CoReviewersButton>
                </>}
                {caseRequest.markedAsPriorityEpochMilli && <div className="priority-icon" onClick={e=> {e.preventDefault();e.stopPropagation()}}>
                  <Tooltip content="This case was marked as priority." instant>
                    <Icon icon="star"/>
                  </Tooltip>
                </div>}
              </div>
            }
          </div>
          <SidebarSeparator/>
          <StepLink
            to="remote-consultation"
            selected={step==="remote-consultation"}
          ><Icon icon="video"/> Video consultation</StepLink>
          <SidebarSeparator/>
          <StepLink
            to="overview"
            selected={step==="overview"}
          ><Icon icon="clinical-sheet"/> Case overview</StepLink>
          {!caseRequest.isCoReview && <StepLink
            to="questions"
            disabled={isStateBefore(UNDER_REVIEW) && !caseRequest.readOnly}
            selected={step==="questions"}
          ><Icon icon="question-line"/>{caseRequest.isCoReview ? " Questions": " Expert review"}</StepLink>}
          
          {!caseRequest.isCoReview && <StepLink
            to="report"
            disabled={isStateBefore(UNDER_REVIEW)}
            selected={step === "report" || step === "upload-report"}
          >
            {caseRequest.state === CASE_CLOSED ?
              <><Icon icon="checked-report"/> Case report</>
              : <><Icon icon="report"/> Report preview</>
            }
          </StepLink>}
        </SidebarCard>
      </Sidebar>
    </SidebarContainer>
    }
    }</Match>
}
