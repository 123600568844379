import {Button, DraggableItem, Icon, Loading} from '@startlibs/components';
import {_s} from '@startlibs/utils'
import {useToggle} from '@startlibs/core'
import React from 'react'
import _ from 'lodash/fp'
import {NotProvided} from '../../ExpertAssignmentStep';
import {ReportField} from './ReportFrontCover';
import {ReportTextButton} from './EditLayoutButton';
import {SectionContent} from './ReportAdditionalFields';
import {isRichText, multiWait, safeNewLineToDoubleBr} from '../../../../utils/utils'
import { RichTextDisplay } from '../../../../components/RichTextDisplay';

export const ReportQuestionAndAnswers = ({caseRequest, caseInfoLoading, updateCaseInfo, form}) => {

  const questionSectionLoading = useToggle()
  const questionsLoading = useToggle([])

  const setItemOrder = (from, to) => {
    questionSectionLoading.wait(updateCaseInfo(_.update('questionsConsultant',_s.moveElement(from,to))))
  }

  const toggleHide = (question) => {
    multiWait(questionsLoading,updateCaseInfo(_.update('questionsConsultant',_.map(q => q.id === question.id ? {...q,hide:!q.hide} : q))),question.id)
  }

  const toggleSection = () => {
    form.setValue('reportLayout.questionsConsultant',v => !v)
    questionSectionLoading.wait(form.submitForm())
  }
  const isHidden = !caseRequest.reportData.reportLayout.questionsConsultant

  return <><ReportField css={isHidden && "opacity: 0.5"}>
      <div className="field-heading">
        <h4>Questions & answers</h4>
        <div className="field-actions">
          {questionSectionLoading.isOpen && <Loading size={16} borderWidth={3} />}
          <ReportTextButton small onClick={toggleSection}>
            {isHidden ?
              <><Icon icon="view" /> Show in report</>
            : <><Icon icon="hide" /> Hide from report</>
            }
          </ReportTextButton>
        </div>
      </div>
    </ReportField>
    <SectionContent
      setItemOrder={setItemOrder}
      disabled={caseRequest.caseInfo.questionsConsultant?.length === 1}
    >
      {caseRequest.caseInfo.questionsConsultant.map(question =>
        <DraggableItem
          key={question.id}
          DragTag={QuestionAndAnswerItem}
          question={question}
          caseRequest={caseRequest}
          toggleHide={toggleHide}
          isHidden={isHidden}
          isLoading={questionsLoading.isOpen.indexOf(question.id)>=0}
          isDraggable={caseRequest.caseInfo.questionsConsultant.length > 1}
        />)}
    </SectionContent>
  </>


}

const QuestionAndAnswerItem = ({question, caseRequest, toggleHide, onTouchStart, onMouseDown, children, className, isHidden, isLoading, isDraggable}) =>
  <ReportField
    css={(question.hide || isHidden) && "opacity:0.5;"}
    onTouchStart={onTouchStart}
    onMouseDown={onMouseDown}
    className={className}
    subItem
    isDraggable={isDraggable}
  >
    <div className="field-heading">
      {/* <h4 className="fs-exclude">{question.question}</h4> */}
      <h4 className="fs-exclude"><RichTextDisplay content={question.question} /></h4>
      <div className="field-actions">
        {isLoading && <Loading size={16} borderWidth={3} />}
        {!isHidden &&
            <ReportTextButton onClick={() => toggleHide(question)}>
            {question.hide ?
              <><Icon icon="view" /> Show in report</>
              : <><Icon icon="hide" /> Hide from report</>
            }
          </ReportTextButton>
        }
        <Button.Link small to={`/admin/request/${caseRequest.requestCode}/review`} state={{question:question.id}}>Edit</Button.Link>
        {isDraggable && <Icon icon="move" className="move-icon"/>}
      </div>
    </div>
    <div className="field-content fs-exclude">
      {question.answer ?
        // <div dangerouslySetInnerHTML={{__html: safeNewLineToDoubleBr(question.answer)}}/>
        <RichTextDisplay className="field-content fs-exclude" content={question.answer} useSafeNewLine={!isRichText(question.answer)} />
        : <NotProvided />
      }
      {children}
    </div>
  </ReportField>
