
export const downloadFile =  (launchURL) => {
  if (window.navigator && window.navigator.msSaveBlob) {
    window.location.href = launchURL
  } else {
    const link = document.createElement('a')
    link.href = launchURL
    link.style.visibility = "hidden"
    link.setAttribute("download","")
    document.body.appendChild(link);
    link.click()
    link.remove()
  }
}