const abstractReadFile = readType => file =>
  new Promise((res, rej) => {
    const fileReader = new FileReader()
    fileReader.onload = evt => {
      res(evt.target.result)
    }
    fileReader.onerror = rej
    readType(fileReader, file)
  })

export const readFile = abstractReadFile((reader, file) => reader.readAsArrayBuffer(file))

export const readFileAsText = abstractReadFile((reader, file) => reader.readAsText(file))

export const tryReadingFile = async file =>
  new Promise((resolve, reject) => {
    if (!(file instanceof File)) {
      reject(new Error('Not a File'))
      return
    }
    const r = new FileReader()
    r.onload = e => {
      resolve()
    }
    r.onerror = e => {
      reject(r.error)
    }
    r.readAsArrayBuffer(file.size > 0 ? file.slice(-1) : file)
  })
