import React from 'react'
import {callIfFunction, getColor} from '@startlibs/utils'
import {Navbar} from '../../components/Navbar'
import {Card} from '../../components/PageLayout'
import {Icon} from '@startlibs/components'
import {InfoBox} from '../../components/InfoBox'
import {lazyProviderInfo} from '../../components/WithProvider'
import styled from 'styled-components'
import {WithLazyResource} from '../../hooks/useSuspense'

const ExpiredLinkWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  max-width: 400px;
  ${Card} {
    margin-bottom: 1rem;
    padding-bottom: 4rem;
  }
  ${Icon} {
    font-size: 5rem;
    color: ${getColor('gray180')};
  }
  h1 {
    font-size: 18px;
    margin: 1rem auto;
    color: ${getColor('main')};
  }
  p {
    margin: 0 .25rem;
  }
`

export class ProviderReleaseError extends React.Component {

  constructor(props) {
    super(props)
    this.state = {hasError: false}
  }

  static getDerivedStateFromError(error) {
    // let errorString = ''+error
    // if(errorString.includes('Cannot read property \'jwt\'')){
    //   return {hasError: true}
    // }
    const errorData = error && callIfFunction(error.get)
    if (errorData && errorData.ignoreError) {
      return {withoutUX: true}
    }
    const status =  errorData && errorData[1] && errorData[1].status
    if (status === 404) {
    // Update state so the next render will show the fallback UI.
      return {hasError: true}
    }
  }

  closeDialog = () => {
    this.setState({closingDialog: true})
    setTimeout(() => window.parent.postMessage(JSON.stringify({type: 'removeIframe'}), '*'), 300)
  }

  render() {
    if (this.state.withoutUX) {
      return null
    }
    if (this.state.hasError) {
      return <>
        <Navbar/>
        <ExpiredLinkWrapper>
          <Card>
            <Icon icon="warning"/>
            <h1>This link has expired or is not valid.</h1>
            <p>The link you are trying to access is not valid or it has reached its expiration date.</p>
          </Card>
          <InfoBox lightBlue>
            <p>Need help providing patient medical records?</p>
            <p><strong>Please contact <WithLazyResource value={lazyProviderInfo}>{providerInfo =>
              providerInfo.address.phoneNumber
            }</WithLazyResource>.</strong></p>
          </InfoBox>
        </ExpiredLinkWrapper>
      </>
    }

    return this.props.children
  }
}