import React, {useEffect, useState, useMemo} from 'react'
import qq from 'fine-uploader'
import {useLazyConstant, usePrevious, useRefState} from '@startlibs/core'
import {callIfFunction} from '@startlibs/utils'
import {getFileNameAndExtension} from '../utils/AttachmentsUtils'
import {isDev} from '../utils/isDev'
import {
  ConnectionError,
  FileNotFound,
  InvalidFilename,
  ProcessingError,
  Quarantined,
  EmptyFile
} from '../enums/FileState'

const HANGOUT_TIMEOUT = 3*60e3

const UPLOADER_MAP = new Map()
const CALLBACKS_MAP = new Map()

function tryReadingFile(file) {
  return new Promise((resolve, reject)=> {
    if (!(file instanceof File)) {
      reject(new Error('Not a File'));
      return;
    }
    let r = new FileReader();
    r.onload = (e)=> {
      resolve();
    };
    r.onerror = (e)=> {
      reject(r.error);
    };
    r.readAsArrayBuffer(file.size > 0 ? file.slice(-1) : file);
  });
}


export const useUploader = (fileToUpload, {params, onSuccess, onCancel, onFailure, jwt}) => {
  const [progress, setProgress] = useState(0)
  const firstProgress = useRefState(true)
  const hangoutFailure = useRefState(-1)
  const previousFileToUpload = usePrevious(fileToUpload)

  const manualUploader = useMemo(() => {
    if (!fileToUpload)
      return

    const [filename,extension] = getFileNameAndExtension(fileToUpload)
    const uploader = UPLOADER_MAP.get(fileToUpload)
    CALLBACKS_MAP.set(fileToUpload, {
      onAllComplete: (success, failed) => {
        console.log("Upload completed: ",fileToUpload.name)
        if (failed.length) {
          clearTimeout(hangoutFailure.get())
          setProgress(0)
        }
      },

      onProgress: (id, name, uploaded, total) => {
        manualUploader.setCustomHeaders({"Authorization": `Bearer ${callIfFunction(jwt)}`})
        clearTimeout(hangoutFailure.get())
        // hangoutFailure.set(setTimeout(() => {
        //   console.log("Upload timeout: ",name)
        //   manualUploader.cancelAll()
        //   onFailure(true)
        // },HANGOUT_TIMEOUT))
        if (firstProgress.get()) {
          firstProgress.set(false)
        } else {
          setProgress(uploaded / total)
        }
      },
      onComplete: (id, fileName, responseJSON, xhr) => {
        clearTimeout(hangoutFailure.get())
        if (!responseJSON.success) {
          console.log("Upload failed: ",fileName," Error:",responseJSON,xhr.status)
          if (xhr.status>0) {
            onFailure(
              xhr.status === 406
                ? InvalidFilename
                : xhr.status === 203
                ? Quarantined
               : ProcessingError
            )
          } else if (manualUploader.fileNotFound) {
            onFailure(FileNotFound)
            manualUploader.fileNotFound = false
          } else {
            tryReadingFile(fileToUpload).then(
              () => onFailure(ConnectionError),
              () => onFailure(FileNotFound)
            )
          }
        } else {
          setProgress(1)
          onSuccess(responseJSON)
          return true
        }
      }
    })
    if (uploader) {
      return uploader
    }
    console.log("Upload starting: ",fileToUpload.name)
    const newUploader = new qq.FineUploaderBasic({
      autoUpload: true,
      maxConnections: 2,
      // debug: true,
      request: {
        requireSuccessJson: false,
        endpoint: '/uploader/uploads',
        filenameParam:'filename',
        params: {
          ...params,
          qqfilename: filename + (extension ? "." : "") + extension,
        },
        customHeaders: {
          "Authorization": `Bearer ${callIfFunction(jwt)}`
        }
      },
      retry: {
        enableAuto: true,
        maxAutoAttempts:isDev ? 1 : 5
      },

      // resume: {
      //   recordsExpireIn: 1,
      //   enabled: true
      // },

      chunking: {
        enabled: true,
        concurrent: {
          enabled: true
        },
        success: {
          endpoint: '/uploader/chunksdone',
          customHeaders: {
            "Authorization": `Bearer ${callIfFunction(jwt)}`
          }
        },
        partSize: 2e6
      },

      callbacks: {
        onAllComplete: (success, failed) => {
          const callback = CALLBACKS_MAP.get(fileToUpload)
          return callback?.onAllComplete(success, failed)
        },
        onProgress: (id, name, uploaded, total) => {
          const callback = CALLBACKS_MAP.get(fileToUpload)
          return callback.onProgress(id, name, uploaded, total)
        },
        onComplete: (id, fileName, responseJSON, xhr) => {
          if (xhr?.status === 203){ // Custom response status - Virus found
            manualUploader.hasBeenQuarantined = true
            onFailure(Quarantined)
          } else if (responseJSON.success && !xhr.status) {
            tryReadingFile(fileToUpload).catch(() => {
              manualUploader.fileNotFound = true
            })
          }
          const callback = CALLBACKS_MAP.get(fileToUpload)
          return callback?.onComplete(id, fileName, responseJSON, xhr)
        },
        onError: (id,name,error) => {
          if(error.indexOf(name + ' is empty') >= 0){
            onFailure(EmptyFile)
          }
          // if (xhr?.status === 201) { 
          //   manualUploader.hasBeenQuarantined = true
          //   onFailure(Quarantined)
          // }
        },
        onUpload: () => {
          if (manualUploader.hasBeenQuarantined || manualUploader.fileNotFound) {
            return Promise.reject();
          }
        },
        onAutoRetry: (id,name) => {
          console.log("Auto retry event: ",name)
          manualUploader.setCustomHeaders({"Authorization": `Bearer ${callIfFunction(jwt)}`})
        },
        onManualRetry: (id,name) => {
          console.log("Manual retry event: ",name)
          manualUploader.setCustomHeaders({"Authorization": `Bearer ${callIfFunction(jwt)}`})
        },
        onUploadChunk: () => {
          manualUploader.setCustomHeaders({"Authorization": `Bearer ${callIfFunction(jwt)}`})
        }
      }

    })
    UPLOADER_MAP.set(fileToUpload, newUploader)
    return newUploader
  }, [fileToUpload])

  useEffect(() => {
    if (manualUploader && fileToUpload && (manualUploader.getNetUploads() + manualUploader.getInProgress()) === 0) {
      manualUploader.addFiles([fileToUpload])
    } else {
      if (!fileToUpload && previousFileToUpload) {
        UPLOADER_MAP.delete(previousFileToUpload)
        CALLBACKS_MAP.delete(previousFileToUpload)
      }
    }
  }, [fileToUpload])

  useEffect(() => () => { clearTimeout(hangoutFailure.get())},[])

  const cancel = fileToUpload
    ? (() => {
      manualUploader.cancelAll()
      onCancel()
    })
    : onCancel


  return [progress, cancel]

}