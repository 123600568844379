import { getColor } from '@startlibs/utils';
import styled from 'styled-components'
import { Icon } from '@startlibs/components';

export const FailedLabel = styled.div`
  margin-top: .5rem;
  .label-badge {
    border: 1px solid ${getColor('alert')};
    color: ${getColor('alert')};
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
    padding: .25rem .5rem;
    ${Icon} {
      margin-right: .5rem;
      font-size: 18px;
    }
  }
`
