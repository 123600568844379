import { Button } from '@startlibs/components';
import { getColor } from '@startlibs/utils';
import React, {useEffect} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';
import {setNotification} from '../../../components/Notifications'

const RetryButton = styled(Button)`
  color: ${getColor('alert')};
  font-weight: 600;
  margin-left: 1rem;
  margin-top: -2px;
  margin-bottom: -2px;
`

export const useExitSave = (action,form,onSuccess) => {

  useEffect(() => () => {
    const values = form && form.current && form.current.getTransformedValues()
    const trySave = () => {
      action(values)
        .catch(() => setNotification({type: "alert", msg: (close) => <>Changes made on the previous page could not be saved. <RetryButton small white onClick={_.flow(close,trySave)}>Retry</RetryButton></>}))
    }
    if (form && form.current && form.current.hasChanged) {
      trySave()
    }
  }, [])

}
