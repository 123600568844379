import {ExpertProfileCard} from '../experts/components/ExpertProfileCard';

import {Button} from '@startlibs/components';
import React from 'react'
import {CaseRequestCard} from '../CaseRequestCard';
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import {getJwt} from '../../hooks/useJwt'
import {jwtPostFetcher} from '../../utils/authFetch';


export const useToggleImageDialog = (expertShare, caseRequest, onSuccess) => {

  const isDisable = expertShare.allowStudyDownload
  const {expert} = expertShare

  return useConfirmDialog(
    <ConfirmDialog
      title={`${isDisable ? 'Disable' : 'Enable'} image download`}
      confirm={<Button highlight>{isDisable ? 'Disable' : 'Enable'}</Button>}
      action={() => jwtPostFetcher(getJwt())(
        isDisable ? `/api/expert/share/${expertShare.id}/disableDownload` : `/api/expert/share/${expertShare.id}/enableDownload`,
        '',
        {method: 'PUT'}
      )}
      onSuccess={onSuccess}
      notify={`Image download ${isDisable ? 'disabled' : 'enabled'} successfully.`}
    >
      <p>
        The expert will {isDisable && 'no longer'} be able to download any DICOM images from the following case:
      </p>
      <CaseRequestCard caseRequest={caseRequest}/>
      <p>Currently assigned to:</p>
      <ExpertProfileCard expert={expert} />
      <p>Are you sure you want to proceed?</p>
    </ConfirmDialog>
  )
}
