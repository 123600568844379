import React from 'react'
import _ from 'lodash/fp'
import {
  CASE_ARCHIVED,
  CASE_CLOSED, CASE_DRAFT, PENDING_REQUEST, REQUEST_REJECTED,
  WAITING_APPROVAL,
  WAITING_MEDICAL_RECORDS,
  WAITING_MORE_INFORMATION
} from '../../enums/CaseState'
import {isReadOnlyShare} from './caseRequestUtils'

export const isPendingAskingMore = (caseRequest) => caseRequest.state === PENDING_REQUEST && Object.values(caseRequest.caseFlags?.requestFlags || {}).find(_.identity)

export const getFirstPage = (caseRequest) => {
  if (isReadOnlyShare(caseRequest)) {
    return '/patient/overview'
  } else if (caseRequest.state === WAITING_MEDICAL_RECORDS || caseRequest.caseFlags?.requestFlags?.askForPayments || isPendingAskingMore(caseRequest)) {
    return '/patient/status'
  } else if (caseRequest.caseFlags?.requestFlags?.askedMedicalRelease && caseRequest.state === CASE_DRAFT) {
    return '/patient/release'
  } else if (caseRequest.state === CASE_CLOSED) {
    return '/patient/report'
  } else {
    return '/patient/status'
  }
}