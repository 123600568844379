import React, {useCallback, useContext} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {useToggle} from "@startlibs/core";
import {Editor} from "slate";

const WithDecoratorContext = React.createContext()

export const WithDecoration = ({children}) => {
  const decoration = useToggle()
  const callback = useCallback(([node,path]) => {
    return Editor.isEditor(node) ? decoration.get() : []
  },[])
  return <WithDecoratorContext.Provider value={decoration}>{children(decoration.isOpen ? callback : undefined)}</WithDecoratorContext.Provider>
}

export const useDecorationToggle = () => useContext(WithDecoratorContext)