import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {Button, Dialog, Loading} from "@startlibs/components";
import {UploadStatusIcon} from "../styled/UploaderStyled";
import {useUIDataSelector} from "../../service/hooks/useUIDataSelector";
import {useDoAction} from "../../service/hooks/useDoAction";
import {useUploadQueueSelector} from "../../service/hooks/useUploadQueueSelector";
import {UIAction} from "../../service/UIAction";

styled

export const CancelUploadsDialog = ({dropFileBoxRef}) => {
  const uploadQueue = useUploadQueueSelector()
  const doAction = useDoAction()
  const uploadsProcessing = uploadQueue.find(_.get('uploading.length'))
  const onlyFailedUploads = uploadQueue.find(_.get('failed.length')) && !uploadsProcessing
  const closeDialog = () => doAction(UIAction.ToggleCancelUploadsDialog)
  const cancelUploadsPromissable = useUIDataSelector('dialogs.cancelUploads')
  const confirmProceed = () => {
    cancelUploadsPromissable.res()
    closeDialog()
  }

  return <Dialog
    // title={
    //   uploadQueue.length ? 'There are still uploads remaining'
    //     : (onlyFailedUploads ? 'There are failed uploads to check'
    //       : 'Uploads are now finished')
    // }
    title={
      onlyFailedUploads ? 'There are failed uploads to check'
        : (  uploadQueue.length ? 'There are still uploads remaining'
          : 'Uploads are now finished')
    }
    closeDialog={closeDialog}
    footer={<>
      <Button
        onClick={() => {
          closeDialog();
          doAction(UIAction.ScrollToDropFilesBox)
        }}
      >Check uploads</Button>
      {
        !uploadQueue.length &&
        <Button highlight onClick={confirmProceed}>Proceed</Button>
      }
    </>}
  >
    {
      onlyFailedUploads
        ? <>
          <p>You are about to leave this page but there are some failed uploads to check.</p>
          <p>Please check your failed uploads before proceeding.</p>
          <UploadStatusIcon icon="failure" failure/>
        </>
        : uploadsProcessing
          ? <>
            <p>You are about to leave this page but there are still uploads to be handled.</p>
            <p>Please wait for the uploads to finish or review them before leaving.</p>
            <Loading css="margin: 2rem auto;"/>
          </>
          : <>
            <p>All your uploads have now finished. It is safe to leave this page.</p>
            <UploadStatusIcon icon="check" check/>
          </>
    }
  </Dialog>
}

export const CancelUploadsConfirmation = React.forwardRef((props,ref) => {
  const queueSize = useUploadQueueSelector('length')
  const doAction = useDoAction()
  const toggleDialog = () => doAction(UIAction.ToggleCancelUploadsDialog)

  React.useImperativeHandle(ref, () => ({
    confirm: () => {
      if (queueSize) {
        return toggleDialog().promise
      } else {
        return Promise.resolve()
      }
    }
  }), [queueSize])
  return null
})