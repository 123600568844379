import { Button } from '@startlibs/components';
import {callIfFunction, callMany, getColor, media} from '@startlibs/utils';
import {useNavigate} from 'react-router'
import React from 'react'
import _ from 'lodash/fp'
import styled, { css } from 'styled-components';
import { CASE_DRAFT } from '../../enums/CaseState';
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import { InfoBox } from '../../components/InfoBox';
import { confirmAndNavigate } from '../../hooks/useConfirmExitPage';
import {getJwt} from '../../hooks/useJwt'
import {jwtPostFetcher} from '../../utils/authFetch'
import {
  useSharedIsScrollOnBottom,
} from '../../components/CheckScrollOnBottom';
import {useAlreadyRevoked} from './useAlreadyRevoked'
import {useInvalidState} from './useInvalidState'

const FixedBottomBarComponent = styled.div `
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  box-shadow: rgba(0,0,0,0.15) 0px 0px 2px 0px, rgba(0,0,0,0.2) 0px 0px 5px 1px;
  font-size: 13px;
  transition: 0.25s ease-in;
  .bottomBarContent {
    padding: 1rem 1.5rem;
    min-height: 5.5rem;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    align-items: center;
  }
  .statusLink {
    text-decoration: underline;
    :hover {
      color: ${getColor('main')};
    }
  }
  ${props => props.isScrollOnBottom && css`
    background: ${getColor('main')};
    color: white;
    .statusLink {
      :hover {
        color: rgba(255,255,255,0.8);
      }
    }
  `}
  ${media.min(975)`
    padding-left: 22.5rem;
    .bottomBarContent {
      max-width: 792px;
    }
  `}
`
const ConfirmationList = styled(InfoBox)`
  color: ${getColor('gray60')};
  font-size: 13px;
  ul {
    list-style-type: disc;
    margin-left: 1.75rem;
  }
`

export const FixedBottomBar = ({caseRequest, values, action = () => Promise.resolve(), setCaseRequest, preConfirm = () => Promise.resolve()}) => {
  const navigate = useNavigate()
  const [isScrollOnBottom] = useSharedIsScrollOnBottom()

  const confirm = useConfirmSubmitUpdate(
    caseRequest,
    () => action(callIfFunction(values)).then(() => jwtPostFetcher(getJwt())('/api/sendMedicalRecordsToAdmin')),
    setCaseRequest,
    () => {
      setCaseRequest(_.flow(
        _.set('state', CASE_DRAFT),
        _.set('caseFlags.requestFlags',{})
      ))
      navigate("/patient/status")
    }
  )

  const requestedPayment = caseRequest.caseFlags.requestFlags.askForPayments
  const isPaid = caseRequest.paymentInfo?.paid


  const notPaidWarning = useConfirmDialog(<ConfirmDialog
    title="Payment is required to proceed"
    closeButton={<Button>Close</Button>}
    alternativeButton={<Button.Link highlight to="/patient/checkout">Go to payment</Button.Link>}
  >
    <p>
      Before submitting the updates it is required that you
      provide the requested payment for this case.
    </p>
  </ConfirmDialog>)

  return <FixedBottomBarComponent isScrollOnBottom={isScrollOnBottom}>
    <div className="bottomBarContent">
      <div>Have you completed the <a className="statusLink" onClick={confirmAndNavigate('/patient/',navigate)}>updates requested</a> by the institution?</div>
      <Button highlight white={isScrollOnBottom} onClick={() => Promise.resolve(preConfirm()).then(() => (requestedPayment && !isPaid) ? notPaidWarning() : confirm())}>Submit changes and notify the institution</Button>
    </div>
  </FixedBottomBarComponent>
}

export const useConfirmSubmitUpdate = (caseRequest, setCaseRequest, onSuccess) => {

  const invalidState = useInvalidState({isFinalSubmit:true,setCaseRequest})

  return useConfirmDialog(
    <ConfirmDialog
      title="Submit"
      confirm={<Button highlight>Submit</Button>}
      action={() => caseRequest.state === CASE_DRAFT ? Promise.resolve() : jwtPostFetcher(getJwt())('/api/sendMedicalRecordsToAdmin')}
      onSuccess={onSuccess}
      /*preValidation={buildValidation({
        acceptedTerms:requiredTerms(caseRequest)
      })}*/
      onFailure={(values,[,{status}],form,closeDialog) => status === 562 && _.flow(closeDialog,invalidState)() }
      navigate="/patient/status"
      notify="Updates submitted successfully."
    >
      <p>
        Indicate that you have completed all requested actions. You are only
        encouraged to do so if the following are done:
      </p>
      <ConfirmationList>
        <ul>
          {caseRequest.caseFlags.requestFlags.askForServiceTerms && <li>Agreed to the service terms</li>}
          {caseRequest.caseFlags.requestFlags.askedPatientDetails && <li>Contact details updated</li>}
          {caseRequest.caseFlags.requestFlags.askedMedicalRelease && <li>Authorization details updated</li>}
          {caseRequest.caseFlags.requestFlags.askedRecords && <li>Medical records updated</li>}
          {caseRequest.caseFlags.requestFlags.askForPayments && <li>Payment provided</li>}
        </ul>
      </ConfirmationList>
      <p>You will no longer be able to edit this case.</p>
      <p>Are you sure you want to proceed?</p>
      {/*<AcceptTermsCheckbox caseRequest={caseRequest}/>*/}
    </ConfirmDialog>
  )
}
