import React, {useState} from 'react'
import {wrapLazy} from '@startlibs/utils'
import {jwtGetFetcher} from '../../../utils/authFetch'
import {getJwt} from '../../../hooks/useJwt'
import {willUseSharedCallback} from '@startlibs/core'

export const lazyInstitutions = wrapLazy(() => jwtGetFetcher(getJwt())("/api/admin/institutionDetails"))

const [useRegisterRefreshInstitutions,listRefreshInstitutions] = willUseSharedCallback()

export const refreshInstitutions = () => Promise.all(listRefreshInstitutions())
export const useRefreshableInstitutions = () => {
  const [institutions,setInstitutions] = useState(lazyInstitutions.read())

  useRegisterRefreshInstitutions(() => {
    lazyInstitutions.refresh()
    return lazyInstitutions.promise().then(setInstitutions)
  })

  return institutions
}

export const getAddressLine = (institution) => institution.address + " - " + institution.city + ", " + institution.state
