import {Button, Dialog, DialogFooter} from '@startlibs/components'
import {Errors, Field, TextInput, useProvideErrors, WithForm} from '@startlibs/form'
import React, { useRef, useState } from 'react'
import _ from 'lodash/fp'
import {getJwt} from '../../hooks/useJwt'
import {jwtPostFetcher} from '../../utils/authFetch'
import { useToggle } from '@startlibs/core'
import styled from 'styled-components'
import { getColor } from '@startlibs/utils'
import { IfLocale } from '../../hocs/IfLocale'

const ExpertReviewList = styled.div`
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid ${getColor('gray210')};
`

const ExpertReviewItem = styled.div`
  padding: 1rem 1.25rem;
  background: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${getColor('gray210')};
  :last-child {
    margin-bottom: -1px;
  }
  .info-wrapper {
    display: flex;
    .expert {
      font-size: 14px;
      max-width: 305px;
    }
    .speciality {
      font-size: 13px;
      color: rgba(0,0,0,0.5);
      margin-top: 2px;
    }
  }
  .access-role {
    flex-shrink: 0;
    color: rgba(0,0,0,0.4);
    font-size: 14px;
  }
`

export const ListExpertsDialog = ({listExpertsPopup, coReviewersList, userInfo}) => {

  return <Dialog 
    title="Experts"
    closeDialog={listExpertsPopup.close}
    >
      <p css="font-size:14px!important;">The following Experts have access to this case:</p>
      
      <ExpertReviewList>
        <ExpertReviewItem >
          <div className="info-wrapper">
            <div className="expert">
              <span><strong>{userInfo.firstName} {userInfo.lastName}</strong> (you)</span>
            </div>
          </div>
          <div className="access-role">
            <i>Assigned expert</i>
          </div>
        </ExpertReviewItem>

        {coReviewersList.map((expert) => 
          <ExpertReviewItem >
            <div className="info-wrapper">
              <div className="expert">
                <span><strong>{expert.firstName} {expert.lastName}</strong> ({expert.email})</span>
                <IfLocale not contains="LEGAL">
                  {expert.specialization?.length > 0 &&
                    <div className="speciality">Speciality: {(expert.specialization || []).join(", ")}</div>
                  }
                </IfLocale>
              </div>
            </div>
            <div className="access-role">
              <i>Read only</i>
            </div>
          </ExpertReviewItem>
        )}
      </ExpertReviewList>

  </Dialog> 
}