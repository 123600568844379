import {ContextMenu, Li} from '@startlibs/components'
import {useNavigate} from 'react-router'
import React from 'react'
import {
  CASE_CLOSED,
  CASE_REVIEWED,
  UNDER_REVIEW,
  WAITING_ACCEPTANCE,
  WAITING_MORE_INFORMATION
} from '../enums/CaseState';
import {useAcceptCase} from './dialogs/useAcceptCase'
import {useDeclineCase} from './dialogs/useDeclineCase'

export const ExpertCaseCardMenu = ({caseRequest, refreshList, isOpen}) => {
  const navigate = useNavigate()

  const id = caseRequest.requestId || caseRequest.id
  const acceptCase = useAcceptCase(caseRequest,() => navigate("/expert/case/"+id))
  const declineCase = useDeclineCase(caseRequest,refreshList)


  return isOpen && <ContextMenu>
    {caseRequest.state === CASE_CLOSED && <Li label="View report" icon="report" linkTo={`/expert/case/${id}/report`}/>}
    <Li label="View case" linkTo={`/expert/case/${id}/overview`} />
    {caseRequest.state === WAITING_ACCEPTANCE && !caseRequest.isCoReview && <Li label="Accept" onClick={acceptCase}/>}
    {caseRequest.state === WAITING_ACCEPTANCE && !caseRequest.isCoReview && <Li label="Decline" icon="unavailable" onClick={declineCase}/>}
    {(caseRequest.state === UNDER_REVIEW || caseRequest.state === WAITING_MORE_INFORMATION) && !caseRequest.isCoReview && <Li label="Edit review" icon="edit-box" linkTo={`/expert/case/${id}/questions`}/>}
    {caseRequest.state === CASE_REVIEWED && <Li label="Check review" linkTo={`/expert/case/${id}/questions`}/>}
  </ContextMenu>
}
