import React from 'react'
import {parseDate} from '@startlibs/utils'
import _ from 'lodash/fp'

export const formatDateParams = (params) => {
  const addTime = (time,referenceDate) => (strDate) => {
    const date = parseDate(strDate,null,"MM-dd-yyyy")
    if (time) {
      const [hours,minutes] = time.split("-")
      date.setHours(Number(hours),Number(minutes || 0),referenceDate[2],referenceDate[3])
    } else {
      date.setHours(...referenceDate)
    }
    return date.getTime()
  }
  return _.flow(
    params.from ? _.update('from',addTime(params.timeFrom,[0,0,0,0])) : _.identity,
    params.to ? _.update('to',addTime(params.timeTo,[23,59,59,999])) : _.identity
  )(params)
}
