import React from 'react'
import {Button, Dialog} from '@startlibs/components'

export const FetchErrorDialog = ({closeDialog,message = "An error has occurred while loading the requested studies. Please refresh the page and try again."}) =>
  <Dialog
    closeDialog={closeDialog}
    title="Something went wrong."
    footer={<Button onClick={closeDialog}>Close</Button>
    }
    icon="warning"
  >
    <p>An error has occurred while loading the requested studies. Please refresh the page and try again.</p>
    <p>If the error persists please contact <a href="mailto:support@purview.net" className="link"
                                               target="_blank">support@purview.net</a> or <span
      className="nowrap">call <b>+1 800-501-1537</b>.</span></p>
  </Dialog>
