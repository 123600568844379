import React from 'react'

export const MonthDayYear = 'MonthDayYear'
export const YearMonthDay = 'YearMonthDay'
export const DayMonthYear = 'DayMonthYear'



export const DATE_FORMAT_LABELS = {
  [MonthDayYear]:'Month Day, Year',
  [YearMonthDay]:'Year, Month Day',
  [DayMonthYear]:'Day Month Year',
}
export const DATE_FORMAT_STR = {
  [MonthDayYear]:'LL dd, yyyy',
  [YearMonthDay]:'yyyy, LL dd',
  [DayMonthYear]:'dd LL yyyy',
}

export const DATE_FORMAT_OPTIONS = Object.keys(DATE_FORMAT_LABELS)
