import {Button} from '@startlibs/components';
import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';
import {Card, PageContainer, SectionHeading} from '../../components/PageLayout';
import { EmptyMedicalRecordsList } from '../../request/RequestOverview';
import {Header} from '../../components/Header';
import {InsuranceBox} from './info/InsuranceBox'
import {OFFLINE} from '../../enums/PaymentMethod'
import {PaymentCard} from '../../request/payment/PaymentCard'
import {PurviewFooter} from '../../components/PurviewFooter';
import {WaitingMedicalRecordsBox} from './info/WaitingMedicalRecordsBox';
import { isDigitalPaymentPending } from '../../request/utils';
import {useAddPayment} from '../../request/Payment'

const isPaymentPending = ({paid, revoked}) => !paid && !revoked
const reorderPayments = _.orderBy([isPaymentPending, 'paid', 'revoked'], ['desc', 'desc', 'asc'])

const ButtonsContainer = styled.div `
  display: flex;
  justify-content: flex-end;
`

export const PaymentStep = ({caseRequest, setCaseRequest}) => {

  // if (caseRequest.paymentDetails) {
  //   return <AcceptedAndPaid caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>
  // }

  const addPayment = useAddPayment(caseRequest, setCaseRequest)

  const hasPendingPayment = caseRequest.payments?.find(isPaymentPending)
  const hasDigitalPendingPayment = caseRequest.payments?.find(isDigitalPaymentPending)

  return <><PageContainer>
    <Header title="Manage payments">
    </Header>
    <WaitingMedicalRecordsBox caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>
    <SectionHeading>
      <h3>Fee and payment history</h3>
    </SectionHeading>
    <Card>
    {caseRequest.payments?.length > 0
      ? reorderPayments(caseRequest.payments).map(payment =>
        <PaymentCard
          payment={payment}
          caseRequest={caseRequest}
          setCaseRequest={setCaseRequest}
          canPay={isPaymentPending(payment) && payment.paymentMethod === OFFLINE}
          canRevoke={isPaymentPending(payment)}
        />)
      : <NoPaymentsRequested/>
    }
    </Card>
    <ButtonsContainer>
      <Button onClick={addPayment}>Add fee</Button>
      {
        hasDigitalPendingPayment &&
        <Button.Link highlight to={`/admin/request/${caseRequest.requestCode}/contact`}>Contact patient to provide payment</Button.Link>
      }
    </ButtonsContainer>
    {
      caseRequest.patientInfo?.insurances?.length > 0 && <>
        <SectionHeading css="margin-top: 3rem;">
          <h3>Insurance information</h3>
        </SectionHeading>
        <Card>
          {
            caseRequest.patientInfo.insurances.map(insurance => <InsuranceBox insurance={insurance}/>)
          }
        </Card>
      </>
    }
  </PageContainer>
    <PurviewFooter/>
  </>
}


const NoPaymentsRequested = () => <EmptyMedicalRecordsList>No fees have been added to this case.</EmptyMedicalRecordsList>
