import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {RecordRowContainer} from "./RecordRowContainer";
import {DescriptionField} from "./DescriptionField";
import {AttachmentDescription, AttachmentDetails} from "../AttachmentBoxStyles";
import {Pathology} from "../../enums/RecordClass";
import {CCDA, FormatToLabel} from "../../enums/RecordFormat";

styled

export const SingleFileRecordRow = ({record, group, dragProvided}) => {

  return <RecordRowContainer group={group} record={record} dragProvided={dragProvided}>{isMinified =>
    isMinified
      ? <>
        <AttachmentDescription className="fs-exclude">
          <b className="nowrap">{record.description}</b>
        </AttachmentDescription>
      </>
      : <>
        <DescriptionField record={record}/>
        {
          (record.recordClass === Pathology || record.format === CCDA) &&
          <AttachmentDetails>
            <b>Format: </b>{FormatToLabel[record.recordFormat] || record.recordFormat}
          </AttachmentDetails>
        }
      </>
  }</RecordRowContainer>
}