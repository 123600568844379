import { lighten } from 'polished';
import React, { forwardRef } from 'react';
import _ from 'lodash/fp';
import styled from 'styled-components';

import { ContextMenu, Li, ListSeparator, Button, Dialog, Loading, Icon } from '@startlibs/components';
import { getColor, callIfFunction } from '@startlibs/utils';
import {useToggle, usePopupToggle, willUseSharedCallback} from '@startlibs/core'

import { ADMIN } from '../enums/UserRoles';
import {
  AttachmentActions,
  AttachmentBox,
  AttachmentBoxFlexContainer,
  AttachmentBoxFooter,
  AttachmentCheckbox,
  AttachmentDescription,
  AttachmentIcon,
  AttachmentInfoContainer,
  DraggableCornerIcon,
  DropdownButton,
  DropdownIcon,
  ProgressBar,
  ProgressDetails,
  RejectLabel,
  TextButton,
  UploadedDate,
  UploadingRecords,
  UploadingStatus
} from './AttachmentBoxStyles';
import {DicomStudy} from '../enums/RecordFormat'
import { Pathology, Radiology } from '../enums/RecordClass';
import { RecordStateFooter } from './RecordStateFooter';
import { Rejected, Submitted } from '../enums/RecordState';
import {formatDateNoUTC} from '../utils'
import {FileNotFound, getFileStateLabel, IS_RETRY_POSSIBLE, Quarantined} from '../enums/FileState'
import {getMultiAttachmentState} from '../utils/businessUtils'
import { useNewDownload } from '../service/utils/downloadFile';
import {useUploader} from '../hooks/useUploader'
import {useAddRetryFilesNotFound} from './hooks/useFilesNotFound'
import { DEVICE, DISK } from '../enums/UploaderStepsManagement';

const [useRegisterCancel,callCancels] = willUseSharedCallback()

export const NonCompliantBox = (
  {
    disabled,
    isDragDisabled,
    jwt,
    appJwt,
    onFinishUpload,
    onCancel,
    onFailure,
    retry,
    removeFromQueue,
    removeAttachment,
    removeNonCompliantFile,
    attachments,
    isApp,
    withoutDelete,
    role,
    currentGroup,
    moveTo,
    updateState,
    downloadLoadUrl,
    downloadUrl,
    dragProvided,
    attachmentGroups,
    setAttachmentGroups,
    addGroupAndMove,
    setNotification,
    isSelectMode, 
    selectedRecords, 
    setSelectedRecords, 
    mode,
    expertViewJwt,
    requestId
}) => {

  const detailsDialog = useToggle()
  const cancelDialog = useToggle()
  const deleteDialog = useToggle()
  const loading = useToggle()
  const loadingState = useToggle()
  const contextMenu = usePopupToggle()

  const isMinified = () => (mode === DEVICE || mode === DISK);

  const uploadingInstances = attachments.filter(instance => instance.fileToUpload)
  const latestInstance = _.maxBy(({uploadDate})=> uploadDate || 0,attachments)

  const queuedInstances = attachments.filter(instance => instance.sourceFile)
  const doneInstances = attachments.filter(instance => !instance.sourceFile || !IS_RETRY_POSSIBLE(instance))
  const failedInstances = attachments.filter(instance => instance.failed || instance.quarantined)
  const quarantinedInstances = attachments.filter(instance => instance.quarantined)
  const hasQuarantined = quarantinedInstances.length > 0
  const hasOnlyQuarantined = quarantinedInstances.length === doneInstances.length
  const hasNewFiles = !!attachments.find(instance => instance.isNew)

  const uploaded = doneInstances.length
  const total = attachments.length

  const progress = uploaded/total
  const isFinished = uploaded === total

  const emptyList = []
  const nonCompliantsUIDsList = emptyList.concat(_.map((item) => item.recordUID,attachments))
  
  const cancelQueue = () => {
    removeFromQueue()
    callCancels()
    cancelDialog.close()
  }

  const cancelAndRemove = () => {
    cancelQueue()
    return removeAttachment(attachments.filter(i => !i.quarantined))
  }

  const deleteFile = (f) => {
    return removeNonCompliantFile(f)
  }

  const retryAll = () => {
    failedInstances.filter(IS_RETRY_POSSIBLE).forEach((instance) => retry(instance)())
  }
  useAddRetryFilesNotFound(failedInstances.find(_.matchesProperty(['failed',FileNotFound])), retryAll)

  const hasOnlyFailedFiles = queuedInstances.length === failedInstances.length && failedInstances.length > 0

  // const [tryDownload,downloadIsLoading,downloadIsStating,downloadFailed] = useDownload(appJwt,{nonCompliantFiles:attachments}, downloadLoadUrl)

  const [tryDownload,downloadIsLoading,downloadIsStating,downloadFailed] = useNewDownload(expertViewJwt, nonCompliantsUIDsList, downloadUrl, '',requestId)

  const setState= (state) => updateState(state,attachments)

  const downloadAllNonCompliants = () => {
    // Make an array with nonCompliants recordUid
    const emptyList = []
    const nonCompliantsUIDsArray = emptyList.concat(_.map((item) => item.recordUID,attachments))
    callIfFunction(downloadUrl(nonCompliantsUIDsArray))
  }

  function handleClick(nonCompliants){
    // Select all Non Compliants Files
    if(selectedRecords.findIndex(listItem => listItem === nonCompliants[0].recordUID) >= 0){
      // Found one Non Compliant File. Deselect all of them
      const nonCompliantsArray = []
      _.map((nonCompItem) => nonCompliantsArray.push(nonCompItem.recordUID),nonCompliants)
      let filtered = _.difference(selectedRecords,nonCompliantsArray)
      setSelectedRecords(filtered)
    }else{
      // Do not contain any Non Compliant Dicom File. Add all of them
      const added = selectedRecords.concat(_.map((item) => item.recordUID,nonCompliants))
      setSelectedRecords(added)
    }
  }

  return (
    (mode === DEVICE && attachments[0].mode === DEVICE) || 
    (mode === DISK && attachments[0].mode === DISK)  ||
    !isMinified()
   ) && <AttachmentBox
    failedUpload={hasOnlyFailedFiles} isApp={isApp}
    hasFooter={hasQuarantined || false && (getMultiAttachmentState(attachments) && isFinished && !hasOnlyFailedFiles && (role === ADMIN))}
    unavailable={hasQuarantined || false && (getMultiAttachmentState(attachments) === Rejected && isFinished && !hasOnlyFailedFiles && (role === ADMIN))}
    ref={dragProvided.innerRef}
    {...dragProvided.draggableProps}
    {...dragProvided.dragHandleProps}
    isSelectMode={isSelectMode}
    onClick={isSelectMode ? (() => {handleClick(attachments)}) : undefined}
    isSelected={selectedRecords.findIndex(listItem => listItem === attachments[0].recordUID) >= 0}
    mode={mode}
  >
    {!isDragDisabled && <DraggableCornerIcon icon="draggable-corner"/>}
    <AttachmentIcon icon="dicom" css="font-size:44px;" mode={mode}/>
    {
      !isFinished && (hasOnlyFailedFiles ?
      <UploadingStatus>
      {uploaded > 0 && !isMinified() && <ProgressDetails>{uploaded}/{total} files successfully uploaded</ProgressDetails>}
      <ProgressDetails failed>
        <a className="link error" onClick={detailsDialog.open}>{failedInstances.length}/{total} file upload{failedInstances.length > 1 && 's'} failed</a>
        {failedInstances.find(IS_RETRY_POSSIBLE) && <a className="link" onClick={retryAll}>Retry{failedInstances.length > 1 && ' all'}</a>}
      </ProgressDetails>
      </UploadingStatus>
      :
      <UploadingStatus>
        {!isMinified() &&
          <>
            <ProgressDetails>
              <span>Uploading files...</span><a className="link" onClick={cancelDialog.open}>Cancel</a>
            </ProgressDetails>
            <ProgressBar progress={progress * 100} completed={isFinished}/>
          </>
        }
        {isMinified() && 
        <div css="display: flex; align-items: center;"><UploadingRecords><Loading size={16} borderWidth={3}/>Uploading</UploadingRecords><a className="link" onClick={cancelDialog.open}>Cancel</a></div>}

        {failedInstances.length > 0 ?
          <div className="files-count"><a className="link error" onClick={detailsDialog.open}>{failedInstances.length} file upload{failedInstances.length > 1 && 's'} failed</a> <a className="link" onClick={retryAll}>Retry{failedInstances.length > 1 && ' all'}</a></div>
          :
          <div className="files-count"><a className="link" onClick={detailsDialog.open}>{uploaded}/{total} files uploaded</a></div>
        }
      </UploadingStatus>
    )}
    <AttachmentInfoContainer>
      <AttachmentDescription>
        <b>{total} Non compliant DICOM files</b> <a className="light-link" onClick={detailsDialog.open}>Details</a>
      </AttachmentDescription>
    </AttachmentInfoContainer>

    {isSelectMode 
      ? 
        <AttachmentCheckbox raw setValue={() => {handleClick(attachments)}} value={selectedRecords.findIndex(listItem => listItem === attachments[0].recordUID) >= 0}/>
      :
        <AttachmentActions>
          <div className="buttons-wrapper">
          {
            isFinished && !isApp && !hasOnlyFailedFiles && !isMinified() && 
            <TextButton disabled={hasOnlyQuarantined} isLoading={downloadIsLoading} 
              onClick={tryDownload}>
              {/* // onClick={() => { downloadAllNonCompliants()}}> */}
              {
                downloadIsLoading ?
                <><Loading size={15} borderWidth={3}/>Preparing...</>
                :
                downloadIsStating ?
                <><Icon icon="check"/>Started</>
                :
                <><Icon icon="download"/>Download</>
              }
            </TextButton>
          }
          {
            isFinished && !disabled && !withoutDelete && !isMinified() && 
            <DropdownButton onClick={contextMenu.open}>
              <DropdownIcon icon="arrow-down"/>
              {
                contextMenu.isOpen &&
                <ContextMenu>
                  {!isDragDisabled && <Li label="Move to..." icon="move-group">
                    <ContextMenu my="top left" at="top right">
                      {attachmentGroups.filter(g => g.id !== 'notclass').map(group =>
                        group.name 
                          ? <Li disabled={currentGroup === group.id} icon={currentGroup === group.id && 'check'} label={group.name} onClick={() => moveTo(group.id)}/>
                          : null
                      )}
                      <ListSeparator/>
                      <Li label="Move to a new group" icon="plus-circle" onClick={() => moveTo(addGroupAndMove())}/>
                      {currentGroup !== 'ungrouped' &&
                        <ListSeparator/>
                      }
                      {currentGroup !== 'ungrouped' &&
                        <Li disabled={currentGroup === 'ungrouped'} icon="remove-from-group" label="Remove from this group"
                            onClick={() => { 
                              let ungroup = attachmentGroups.filter(g => g.id === 'ungrouped')
                              if (ungroup.length > 0) {
                                moveTo("ungrouped")
                              }else{
                                setAttachmentGroups(() => {
                                  let groups = attachmentGroups.filter(g => g.id !== 'ungrouped')
                                  return [{ name: '', id: 'ungrouped', items: [] }].concat(groups)
                                })
                                moveTo("ungrouped")
                              }
                            }}
                        />
                      }
                    </ContextMenu>
                  </Li>}
                  {/* {role === ADMIN ?
                    getMultiAttachmentState(attachments) === Rejected ?
                      (loadingState.isOpen ? <Li disabled label="Undo reject" icon="x-circle-undo" onClick={() => loadingState.wait(setState(Submitted))} /> :
                        <Li label="Undo reject" icon="x-circle-undo" onClick={() => loadingState.wait(setState(Submitted))} />
                      )
                      :
                      (loadingState.isOpen ? <Li disabled label="Reject record" icon="x-circle" onClick={() => loadingState.wait(setState(Rejected))} /> :
                        <Li label="Reject record" icon="x-circle" onClick={() => loadingState.wait(setState(Rejected))} />
                      )
                    :
                    null
                  } */}
                  <Li label="Delete non compliant files" icon="delete" onClick={deleteDialog.open}/>
                </ContextMenu>
              }
            </DropdownButton>
          }
          {!disabled && !withoutDelete && isMinified() && <a className="link" onClick={deleteDialog.open}>Delete</a>}
          </div>
          <AttachmentBoxFlexContainer>
            {
              !isMinified() && latestInstance.uploadDate && <UploadedDate>
                Uploaded: {formatDateNoUTC(new Date(latestInstance.uploadDate),"MM/dd/yyyy - hh:mm")}
              </UploadedDate>
            }
            {/* {getMultiAttachmentState(attachments) === Rejected && isFinished && !hasOnlyFailedFiles && (role === ADMIN) && 
              <RejectLabel>Rejected {(loadingState.isOpen ? <Loading size={18} css="margin-left: 0.5rem;"/> :
                <Button small alpha noFocus onClick={() => loadingState.wait(setState(Submitted))}>Undo</Button>)}
              </RejectLabel>
            } */}
            {getMultiAttachmentState(attachments) !== Rejected && loadingState.isOpen && <Loading size={18} css="margin: 0.5rem 0 -0.5rem 0.5rem;"/> }
          </AttachmentBoxFlexContainer>
        </AttachmentActions>
    }
    {
      hasQuarantined ? <AttachmentBoxFooter alert>
          <div><b>Status: </b>Quarantined</div>
          <div>This medical record file has potentially harmful data.</div>
      </AttachmentBoxFooter>
      : null
    }
    {
      detailsDialog.isOpen &&
      <DetailsDialog
        closeDialog={detailsDialog.close}
        doneInstances={doneInstances}
        queuedInstances={queuedInstances}
        uploadingInstances={uploadingInstances}
        cancelDialog={cancelDialog}
        attachments={attachments}
        retry={retry}
        readOnly={disabled}
        withoutDelete={withoutDelete}
        uploaded={uploaded}
        total={total}
        progress={progress}
        deleteFile={deleteFile}
        retryAll={failedInstances.length > 0 && retryAll}
        failedInstances={failedInstances}
        isFinished={isFinished}
        hasOnlyFailedFiles={hasOnlyFailedFiles}
        hasNewFiles={hasNewFiles}
      />
    }
    {uploadingInstances.map(instance =>
      <UploadInstance
        jwt={jwt}
        key={instance.instanceUID || instance.key}
        instance={instance}
        onCancel={onCancel(instance)}
        onFinishUpload={onFinishUpload(instance)}
        onFailure={onFailure(instance)}
      />
    )}

    {
      cancelDialog.isOpen &&
      <Dialog
        closeDialog={cancelDialog.close}
        title="Cancel non compliant DICOM upload"
        footer={<>
          <Button alert isLoading={loading.isOpen} onClick={loading.willWait(cancelAndRemove)}>Delete all non compliant files</Button>
          <Button alert onClick={cancelQueue}>Stop remaining uploads only</Button>
        </>}
      >
      <p>Would you like to cancel only the remaining file uploads in queue or also delete all non compliant DICOM files?</p>
      </Dialog>
    }
    {
      deleteDialog.isOpen &&
      <Dialog
        closeDialog={deleteDialog.close}
        title="Delete all non compliant files"
        footer={<>
          <Button onClick={deleteDialog.close}>Cancel</Button>
          <Button alert isLoading={loading.isOpen} onClick={loading.willWait(cancelAndRemove)}>Delete non compliant files</Button>
        </>}
      >
      <p>Would you like to delete all non compliant DICOM files already uploaded?</p>
      <p>Once confirmed this cannot be undone.</p>
      </Dialog>
    }

  </AttachmentBox>
}

const FilesListHeader = styled.div `
  display: flex;
  justify-content: space-between;
`
const FilesList = styled.div `
  border-radius: 5px;
  height: 16rem;
  border: 1px solid ${getColor('gray210')};
  overflow: hidden;
  > div {
    overflow: auto;
    height: calc(100% + 1px);
  }
`
const FailedUploads = styled.div `
  color: ${getColor('alert')};
  margin-top: 1rem;
  font-weight: 600;
  margin-bottom: -1rem;
  background: ${props => lighten(0.51, props.theme.colors.alert)};
  border: 1px solid #e09f9f;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  ${Icon} {
    font-size: 18px;
    vertical-align: -4px;
    margin-right: 0.5rem;
  }
`

const DetailsDialog = ({hasNewFiles, readOnly, closeDialog, cancelDialog, attachments, deleteFile, retry, retryAll, uploadingInstances, queuedInstances, doneInstances, uploaded, total, progress, failedInstances, isFinished, hasOnlyFailedFiles, withoutDelete}) => {
  return <Dialog
    title="Non compliant DICOM study details"
    closeDialog={closeDialog}
    footer={<Button onClick={closeDialog}>Close</Button>}
  >
  <p>
    Some DICOM files were incompatible with our webviewer. They are grouped together here and will be available to download them.
  </p>
    { !readOnly && hasNewFiles && <>
      { isFinished ?
        <FilesListHeader><div><b>Upload completed</b> ({uploaded}/{total} files uploaded)</div></FilesListHeader>
        : (hasOnlyFailedFiles ?
        <FilesListHeader><div><b>Files uploaded</b> ({uploaded}/{total})</div><a className="link" onClick={cancelDialog.open}>Cancel</a></FilesListHeader>
        :
        <FilesListHeader><div><b>Uploading files</b> ({uploaded}/{total} uploaded)</div><a className="link" onClick={cancelDialog.open}>Cancel upload</a></FilesListHeader>
        )
      }
      <ProgressBar progress={progress * 100} completed={isFinished} failed={hasOnlyFailedFiles} css="margin: 0.5rem 0;"/>
      </>
    }
    <FilesList>
      <div>
      {attachments.map(file =>
        <InstanceRow
          key={file.instanceUID || file.uid}
          file={file}
          retry={retry}
          deleteFile={deleteFile}
          isUploading={uploadingInstances.indexOf(file) >= 0}
          isQueued={queuedInstances.indexOf(file) >= 0}
          isDone={doneInstances.indexOf(file) >= 0}
          readOnly={readOnly}
          withoutDelete={withoutDelete}
        />
      )}
      </div>
    </FilesList>
    {failedInstances.find(IS_RETRY_POSSIBLE) && retryAll && <FailedUploads><Icon icon="failure"/>{failedInstances.length} {failedInstances.length > 1 ? "file uploads have failed." : "file upload has failed."} <a className="link" onClick={retryAll}>Retry{failedInstances.length > 1 && ' all'}</a></FailedUploads>}
  </Dialog>
}

const InstanceRow = styled(({readOnly, withoutDelete,file, isUploading, isQueued, retry, className, deleteFile}) => {

  const deleteDialog = useToggle()
  const loading = useToggle()

  const failed = file.failed || (file.quarantined && Quarantined)

  return <div className={className}>
    <div className="file-details">
      <div><strong className="fs-exclude">{file.description}</strong></div>
    </div>
    { !readOnly &&
      <div className="right-info">
        { file.isNew ?
          (
            failed
              ? <span className="failed">
                {getFileStateLabel(failed)}
                {IS_RETRY_POSSIBLE(file) && <a className="link" css="margin: 0 .5rem;" onClick={retry(file)}>Retry</a>}
              </span>
              : isUploading
              ? <span><b><Loading size={12} borderWidth={2}/>Uploading</b></span>
              : isQueued
                ? <span>Waiting</span>
                : (!withoutDelete && <span>Uploaded <a className="link" onClick={deleteDialog.open}>Delete</a></span>)
          )
          :
          (!withoutDelete && <span><a className="link" onClick={deleteDialog.open}>Delete</a></span>)
        }
      </div>
    }

    {
      deleteDialog.isOpen &&
      <Dialog
        closeDialog={deleteDialog.close}
        title="Delete non compliant file"
        footer={<>
          <Button onClick={deleteDialog.close}>Cancel</Button>
          <Button alert isLoading={loading.isOpen} onClick={loading.willWait(() => deleteFile(file))}>Delete non compliant file</Button>
        </>}
      >
        <p>You are about to delete the following file:</p>
        <AttachmentBox nonCompliantDeleteFileBox css="margin-bottom:1rem;">
          <AttachmentIcon icon="dicom" css="font-size:44px;" className="desktop-only"/>
          <AttachmentInfoContainer>
          <div className="file-details">
            <div><strong>{file.fileName}</strong></div>
          </div>
        </AttachmentInfoContainer>
      </AttachmentBox>
      <p><b>Are you sure you want to delete it?</b> Once confirmed this cannot be undone.</p>
      </Dialog>
    }
  </div>
})`
  padding: 1rem;
  border-bottom: 1px solid ${getColor('gray210')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  span {
    flex-shrink: 0;
    margin-left: 1.5rem;
  }
  .failed {
    color: ${getColor('alert')};
  }
  ${Loading} {
    display: inline-block;
    margin-right: 4px;
    vertical-align: -1px;
  }
  .file-details {
    margin-right: .5rem;
    flex-grow: 1;
  }
  .right-info {
    span {
      margin-left: 0;
    }
  }
  .link {
    margin-left: .25rem;
  }
`

const UploadInstance = forwardRef(({instance, jwt, onFinishUpload, onCancel, onFailure}, ref) => {

  const params = {class: Radiology, format: DicomStudy}

  const [progress, cancel] = useUploader(instance.fileToUpload, {
    jwt,
    params,
    onSuccess:onFinishUpload,
    onCancel,
    onFailure
  })

  useRegisterCancel((i) => {
    cancel()
  })

  return null

})
