import { Button, Loading } from '@startlibs/components';
import { getColor, getFetcher } from '@startlibs/utils';
import { lighten, transparentize } from 'polished';
import React, { useEffect, useState } from 'react';
import _ from 'lodash/fp'
import { CardHeader, SignInCard, SignInLayout} from '../components/SigninLayout';
import { PurviewFooter } from '../components/PurviewFooter';
import { RedirectLoading } from './AdminSSOLogin';
import {wrapPromise} from '../hooks/useSuspense'

const lazyPasSystem = wrapPromise(() => getFetcher("/pasapi/systemref",null,{referrer:'/login/'}))

export const ExpertSSOLogin = ({navigate, setCaseRequest, isNurse}) => {

  const pasSystem = lazyPasSystem.read()
  const providerInfo = {logoURL:pasSystem.systemWebHooks.systemLogoWebHook}
  const [ssoUrl, setSsoUrl] = useState('')
  const [timeToRedirect, setTimeToRedirect] = useState(4);

  const refreshTimer = (time) => {
  
    if (time > 1) {
      return time - 1
    }

    // Redirecting...
    if(ssoUrl.length > 0){
      window.location.href = ssoUrl
    }

    return 0;
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeToRedirect(refreshTimer(timeToRedirect));
    }, 1000);
  });


  useEffect(() => {
    
    // OFICIAL
    if(pasSystem.ssoEntities.length == 1){
      setSsoUrl(pasSystem.ssoEntities[0].url)
    }
    
  }, [])

  return <><SignInLayout providerInfo={providerInfo} css="text-align:center;">
    <SignInCard>

      {/* Only one sso provider */}
      {pasSystem?.ssoEntities?.length == 1 && 
        <>
          <CardHeader>
            <h1>Loading Expert View</h1>
            <p>You will be redirected to {pasSystem?.ssoEntities[0].label || pasSystem.systemName} authentication page</p>
          </CardHeader>
          <RedirectLoading>
            <Loading size={52} borderWidth={5} absolute/>
            <div>{timeToRedirect}</div>
          </RedirectLoading>

          {pasSystem.ssoEntities?.map(sso => <Button.a highlight href={sso.url} css="min-width:12rem;">Take me now</Button.a>)}
          
        </>
      }

      {/* More than one sso provider */}
      {pasSystem?.ssoEntities?.length > 1 && <>
        <CardHeader>
          <h1>Expert View</h1>
          <p>Select your {pasSystem.systemName} authentication page</p>
        </CardHeader>
        {pasSystem.ssoEntities?.map(sso => 
          <div css="margin: 12px;">
            <Button.a highlight href={sso.url}>{sso.label || 'Use Single Sign On'}</Button.a>
          </div>)
        }
        </>
      }
      
    </SignInCard>
  </SignInLayout>
  <PurviewFooter />
  </>
}
