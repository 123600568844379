import React from 'react'
import styled from 'styled-components/macro'
import {jwtGetFetcher} from "../../utils/authFetch";

styled

export const DEFAULT_SHORT_TOKEN_URL = (jwt) => (requestId) => {
  return requestId
    ? jwtGetFetcher(jwt)(`/api/shortDownloaderToken`, {requestId: requestId})
    : jwtGetFetcher(jwt)(`/api/shortDownloaderToken`)
}
export const getShortTokenUrl = (config) =>
  config.apiEndpoints?.shortTokenUrl || DEFAULT_SHORT_TOKEN_URL(config.worklistViewerJwt)