import {Button, DraggableContainer, DraggableItem, Icon, Loading} from '@startlibs/components';
import {_s} from '@startlibs/utils';
import {useToggle} from '@startlibs/core'
import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';
import {NotProvided} from '../../ExpertAssignmentStep';
import {ReportField} from './ReportFrontCover';
import {ReportTextButton} from './EditLayoutButton';
import {defaultListAnimation} from '../../../../UIConfig/defaultListAnimation'
import {isRichText, multiWait, safeNewLineToDoubleBr} from '../../../../utils/utils'
import { RichTextDisplay } from '../../../../components/RichTextDisplay';

export const SectionContent = styled(DraggableContainer)`
  position: relative;
  padding: 0 1rem;
  margin: 0 -1rem 2rem;
`

export const ReportAdditionalFields = ({caseRequest, updateCaseInfo, form}) => {

  const fieldsSectionLoading = useToggle()
  const fieldsLoading = useToggle([])


  const setItemOrder = (from, to) => {
    fieldsSectionLoading.wait(updateCaseInfo(_.update('additionalFields',_s.moveElement(from,to))))
  }

  const toggleHide = (question) => {
    multiWait(fieldsLoading,updateCaseInfo(_.update('additionalFields',_.map(q => q.id === question.id ? {...q,hide:!q.hide} : q))),question.id)
  }
  const toggleSection = () => {
    form.setValue('reportLayout.additionalFields',v => !v)
    fieldsSectionLoading.wait(form.submitForm())
  }
  const isHidden = !caseRequest.reportData.reportLayout.additionalFields

  if (!caseRequest.caseInfo.additionalFields?.length) { return null}

  return <><ReportField css={isHidden && "opacity: 0.5"}>
      <div className="field-heading">
        <h4>Additional information</h4>
        <div className="field-actions">
          {fieldsSectionLoading.isOpen && <Loading size={16} borderWidth={3} />}
          <ReportTextButton small onClick={toggleSection}>
            {isHidden ?
              <><Icon icon="view" /> Show in report</>
            : <><Icon icon="hide" /> Hide from report</>
            }
          </ReportTextButton>
        </div>
      </div>
    </ReportField>
    <SectionContent
      animation={defaultListAnimation}
      setItemOrder={setItemOrder}
      disabled={caseRequest.caseInfo.additionalFields?.length === 1}
    >
      {caseRequest.caseInfo.additionalFields?.map(additionalField =>
        <DraggableItem
          key={additionalField.id}
          DragTag={ReportAdditionalFieldItem}
          additionalField={additionalField}
          caseRequest={caseRequest}
          toggleHide={toggleHide}
          isHidden={isHidden}
          isLoading={fieldsLoading.isOpen.indexOf(additionalField.id)>=0}
          isDraggable={caseRequest.caseInfo.additionalFields.length > 1}
        />)}
    </SectionContent>
  </>


}

const ReportAdditionalFieldItem = ({additionalField, caseRequest, toggleHide, onTouchStart, onMouseDown, children, className, isHidden,isLoading,isDraggable}) =>
  <ReportField
    css={(additionalField.hide || isHidden) && "opacity:0.5;"}
    onTouchStart={onTouchStart}
    onMouseDown={onMouseDown}
    className={className}
    subItem
    isDraggable={isDraggable}
  >
    <div className="field-heading">
      {/* <h4 className="fs-exclude">{additionalField.title}</h4> */}
      <h4 className="fs-exclude"><RichTextDisplay content={additionalField.title} /></h4>
      <div className="field-actions">
        {isLoading && <Loading size={16} borderWidth={3} />}
        {!isHidden && <ReportTextButton onClick={() => toggleHide(additionalField)}>
            {additionalField.hide ?
              <><Icon icon="view" /> Show in report</>
              : <><Icon icon="hide" /> Hide from report</>
            }
          </ReportTextButton>
        }
        <Button.Link small to={`/admin/request/${caseRequest.requestCode}/review`} state={{additionalField:additionalField.id}}>Edit</Button.Link>
        {isDraggable && <Icon icon="move" className="move-icon"/>}
      </div>
    </div>
    <div className="field-content">
      {additionalField.content ?
        // <div  dangerouslySetInnerHTML={{__html: safeNewLineToDoubleBr(additionalField.content)}}/>
        <RichTextDisplay className="field-content fs-exclude" content={additionalField.content} useSafeNewLine={!isRichText(additionalField.content)} />
        : <NotProvided />
      }
      {children}
    </div>
  </ReportField>
