import React from 'react'
import _ from 'lodash/fp'
import {addQueryString} from '@startlibs/utils'
import {jwtGetFetcher} from './authFetch'
import {getJwt} from '../hooks/useJwt'

let cache = {key: {}, values: {}}

const CACHE_SIZE = (maxResults) => (maxResults === 25 || maxResults === 10) ? 100 : (maxResults * 3)

const getWindowPage = ({maxResults, page}) => Math.ceil(maxResults * page / CACHE_SIZE(maxResults))

const isParamsEqual = (a, b) => _.isEqual(
  _.unset("page", a),
  _.unset("page", b)
)

const getWindowInsidePage = ({maxResults, page}) => (list) => {
  const windowSize = CACHE_SIZE(maxResults)
  const firstIndex = maxResults * (page - 1)
  const lastIndex = maxResults * page
  return list.slice(firstIndex % windowSize, ((lastIndex - 1) % windowSize) + 1)
}

const fetch = (url,params,transform) => jwtGetFetcher(getJwt())(addQueryString(url, transform({
  ...params,
  page: getWindowPage(params),
  maxResults: CACHE_SIZE(params.maxResults)
}))).then((result) => {
  if (isParamsEqual(cache.key, params)) { // confirm params are still the same to add to cache
    cache = {
      key: params, values: {...cache.values, [getWindowPage(params)]: result}
    }

  }
  return _.update('list', getWindowInsidePage(params), result)
})

const shouldPrefetch = (params) => {
  const numberOfPagesInCache = (CACHE_SIZE(params.maxResults) / params.maxResults)
  const windowPage = getWindowPage(params)
  const modalIndex = params.page % numberOfPagesInCache
  if (modalIndex === 1 && (params.page - 1 > 1) && !cache.values[windowPage - 1]) {
    return params.page - 1
  }
  if (modalIndex === 0 && !cache.values[windowPage + 1]) {
    return params.page + 1
  }
}

let prefetching = {}

export const cachedWorklistFetcher = (url, params, forceNewCache,transform = _.identity) => {
  const isCurrentParams = isParamsEqual(cache.key, params)
  const isCacheAvailable = isCurrentParams && !forceNewCache && cache.values[getWindowPage(params)]
  if (isCacheAvailable) {
    const prefetchPage = shouldPrefetch(params)
    if (prefetchPage !== undefined) {
      prefetching = {params, page: prefetchPage, promise: fetch(url, {...params, page: prefetchPage}, transform)}
    }
    return Promise.resolve(_.update('list', getWindowInsidePage(params), cache.values[getWindowPage(params)]))
  } else if (isCurrentParams && !forceNewCache && isParamsEqual(prefetching.params,params) && params.page === prefetching.page) {
    return prefetching.promise
  } else {
    if (isCurrentParams && !forceNewCache) {
      cache = {...cache, key: params}
    } else {
      cache = {key: params, values: {}}
    }
    return fetch(url,params,transform)
      .finally(() => {
        const prefetchPage = shouldPrefetch(params)
        if (prefetchPage !== undefined && isParamsEqual(cache.key, params)) {
          prefetching = {params, page: prefetchPage, promise: fetch(url,{...params, page: prefetchPage},transform)}
        }
      })
  }
}