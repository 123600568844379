import React, {useEffect, useImperativeHandle, useRef, useState} from 'react'
import {Loading} from "@startlibs/components";
import {Editable, ReactEditor, Slate, useSlate} from "slate-react";
import {serialize} from "./slateSerializer";
import {useToggle} from "@startlibs/core";
import {RichTextContainer, RichTextInput, ToolButton, ToolsContainer, ToolSeparator} from "./SlateStyles";
import {renderElement, renderLeaf} from "./SlateRenders";
import * as SlateUtils from "./slateUtils";

import {RemoteCursorOverlay} from "./SlateCursorOverlay";
import {Editor, Transforms} from "slate";
import {SlatePopups, useSlatePopups} from "./popups/SlatePopups";
import {WithDecoration} from "./WithDecoration";
import ReactDOM from 'react-dom'
import { PHRASES } from './slatePhrases';


// Add the initial value.
export const SLATE_EMPTY_VALUE = [
  {
    type: 'div',
    children: [{text: ''}],
  },
]

export const SlateEditor = React.forwardRef((
  {
    editor,
    value,
    setValue,
    rich = true,
    minHeight,
    autoHideToolbar,
    disabled,
    onFocus,
    onBlur,
    formatTools = ['link', 'bold', 'italic', 'underline', 'strikethrough'],
    focus,
    placeholder,
    labelClick,
    isEvaluating, 
    setIsEvaluating, 
    normalizationSuccess, 
    setNormalizationSuccess,
    requestId
  }, ref) => {
  const containerFocused = useToggle()
  const [internalValue, setInternalValue] = useState()
  const mentionsRef = useRef()

  const containerFocus = () => {
    containerFocused.openWith(2)
    triggerScrollForPanel(Date.now())
  }

  const containerBlur = (e) => {
    if (containerFocused.isOpen !== 1) {
      containerFocused.close()
      triggerScrollForPanel(Date.now())
    }
  }
  const triggerScrollForPanel = (d) => {
    requestAnimationFrame((time) => {
        if (time > d + 250) return
        window.dispatchEvent(new Event('scroll'))
        triggerScrollForPanel(d)
      }
    )
  }

  useEffect(() => {
    const timer = setTimeout(() => updateFormValue(), 200) // TIMER TO SERIALIZE
    return () => clearTimeout(timer)
  }, [internalValue])

  const updateFormValue = () => {
    if (editor?.children?.length) {
      setValue(serialize(editor))
    }
  }

  useImperativeHandle(ref, () => ({
    updateFormValue
  }), [])

  const [target, setTarget] = useState()
  const [index, setIndex] = useState(0)
  const [search, setSearch] = useState('')

  const phrases = PHRASES.filter(c =>
    c.toLowerCase().startsWith(search.toLowerCase())
  ).slice(0, 10)

  useEffect(() => {
    if(editor && editor.children.length > 0 && (labelClick || focus)) {
      if(normalizationSuccess){
        ReactEditor.focus(editor)
        Transforms.select(editor, Editor.end(editor, []))
      }
    }
  }, [editor,focus,labelClick,normalizationSuccess]);

  useEffect(() => {
    // Get position to show the autocomplete
    if (target && phrases.length > 0) {
      const el = mentionsRef?.current
      const domRange = ReactEditor.toDOMRange(editor, target)
      const rect = domRange.getBoundingClientRect()
      el.style.top = `${rect.top + window.pageYOffset + 24}px`
      el.style.left = `${rect.left + window.pageXOffset}px`
    }
  }, [phrases.length, editor, index, search, target])

  const onChange = (v) => {
    const { selection } = editor

    /*// Auto Complete
      // Opens the autocomplete with values
      if (selection && Range.isCollapsed(selection)) {
        const [start] = Range.edges(selection)
        const wordBefore = Editor.before(editor, start, { unit: 'word' })
        const before = wordBefore && Editor.before(editor, wordBefore)
        const beforeRange = before && Editor.range(editor, before, start)
        const beforeText = beforeRange && Editor.string(editor, beforeRange)
        const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/)
        const after = Editor.after(editor, start)
        const afterRange = Editor.range(editor, start, after)
        const afterText = Editor.string(editor, afterRange)
        const afterMatch = afterText.match(/^(\s|$)/)

        if (beforeMatch && afterMatch) {
          setTarget(beforeRange)
          setSearch(beforeMatch[1])
          setIndex(0)
          return
        }
      }
      setTarget(null)
    */
    return setInternalValue(v)
  }
    
  const Portal = ({ children }) => {
    return typeof document === 'object'
      ? ReactDOM.createPortal(children, document.body)
      : null
  }
  
  const insertMention = (editor, phrase) => {
    const phrasesAsMention = false
    const mention = phrasesAsMention ? 
    {
      type: 'mention',
      phrase: phrase,
      children: [{ text: '' }]
    }: {
      type:  'span',
      text: phrase
    }
    Transforms.insertNodes(editor, mention)
    Transforms.move(editor)
  }

  const onSlateKeyDown = (event, popup) => {
    SlateUtils.hotKeysActions(event, editor, popup, formatTools)
    SlateUtils.autoCompleteListActions(event, editor, popup, formatTools, phrases, index, search, target, setIndex, setTarget, insertMention)
  }

  return editor
    ? <RichTextContainer
        onFocus={containerFocus}
        onBlur={containerBlur}
        focus={containerFocused.isOpen}
        showToolbar={!disabled & !autoHideToolbar || containerFocused.isOpen}
    >
      <WithDecoration>{decorate =>
        <Slate editor={editor} value={value} onChange={onChange}>
          {normalizationSuccess ? <SlatePopups>{popup => 
            <>
              {rich && <ToolsContainer className="rich-text-tools">
                {formatTools.map((t, i) =>
                  t === 'separator'
                    ? <ToolSeparator className="separator" key={"separator-" + i}/>
                    : <ToolEditorButton tool={t} updateFormValue={updateFormValue} requestId={requestId} />
                )}
              </ToolsContainer>}
              <RemoteCursorOverlay>
                <Editable
                  placeholder={placeholder}
                  readOnly={disabled}
                  disabled={disabled}
                  decorate={decorate}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  renderLeaf={renderLeaf}
                  renderElement={props => renderElement(props, disabled, formatTools)}
                  minHeight={minHeight}
                  as={RichTextInput}
                  onKeyDown={(event) => onSlateKeyDown(event, popup)}
                />
                {target && phrases.length > 0 && (
                  <Portal>
                    <div
                      ref={mentionsRef}
                      style={{
                        top: '-9999px',
                        left: '-9999px',
                        position: 'absolute',
                        zIndex: 1,
                        padding: '3px',
                        background: 'white',
                        borderRadius: '4px',
                        boxShadow: '0 1px 5px rgba(0,0,0,.2)',
                      }}
                      data-cy="mentions-portal"
                    >
                      {phrases.map((char, i) => (
                        <div
                          key={char}
                          style={{
                            padding: '1px 3px',
                            borderRadius: '3px',
                            background: i === index ? '#B4D5FF' : 'transparent',
                          }}
                        >
                          {char}
                        </div>
                      ))}
                    </div>
                  </Portal>
                )}
              </RemoteCursorOverlay>
            </>
          }</SlatePopups> : <Loading/>}
        </Slate>
      }</WithDecoration> 
    </RichTextContainer>
    : <Loading/>
})

const TOOL_TO_FORMAT = {
  insertUnorderedList: 'bulleted-list',
  insertOrderedList: 'numbered-list',
  outdent: 'quote',
  indent: 'quote',
}
const TOOL_TO_WRAP = {
  indent: true
}
const TOOL_TO_UNWRAP = {
  outdent: true
}
const POPUP_TOOL = {
  link: true
}
const ToolEditorButton = ({tool, updateFormValue, requestId}) => {
  const editor = useSlate()
  const popups = useSlatePopups()
  const isActive = SlateUtils.isActive(editor, tool)

  const operation = (
    (POPUP_TOOL[tool] && popups.openTool) ||
    (TOOL_TO_WRAP[tool] && SlateUtils.wrap) ||
    (TOOL_TO_UNWRAP[tool] && SlateUtils.unwrap) ||
    (tool === 'image' && SlateUtils.selectImageUpload) ||
    SlateUtils.toggle
  )

  return <ToolButton
    tabIndex={-1}
    onMouseDown={event => {
      event.preventDefault()
      operation(editor, TOOL_TO_FORMAT[tool] || tool, requestId)
      updateFormValue()
    }}
    className={'button exec-command rich-text-button only-icon ' + (isActive ? 'activated' : '')}
    type="button"
  >
    <span className={'icon icon-' + tool}/>
  </ToolButton>
}

