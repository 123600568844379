import {getColor} from '@startlibs/utils';
import React from 'react'
import styled, {css} from 'styled-components'
import {formatCurrency} from '../utils'
import {getCoveredAndCharge} from './forms/utils'
import {lazyProviderInfo} from '../components/WithProvider'

const CostsAndCoverageDetailsBox = styled.div`
  box-shadow: 0 0 0 1px ${getColor('gray210')};
  padding: 1rem 1.5rem;
  background: ${props => props.white ? 'white' : getColor('gray240')};
  border-radius: 6px;
  margin-bottom: 1rem;
  position: relative;
`
const CostDetailRow = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.25rem;
  div ~ div {
    white-space: nowrap;
    margin-left: 1rem;
  }
  ${props => props.total && css`
    font-size: 16px;
    margin-bottom: 0.25rem;
    font-weight: 600;
    .totalPrice {
      font-size: 20px;
    }
  `}
`

export const CostsAndCoverageDetails = ({payment, white, remainderDesc, className}) => {
  const [coverage,charge] = getCoveredAndCharge(payment)
  const providerInfo = lazyProviderInfo.read()

  return <CostsAndCoverageDetailsBox white={white} className={className}>
    <CostDetailRow total><div>{payment.description ? payment.description : "Case cost"}</div><div className="totalPrice">{providerInfo?.currency?.label}{formatCurrency(Number(charge))}</div></CostDetailRow>
  </CostsAndCoverageDetailsBox>

}
