import {getColor} from '@startlibs/utils';
import React from 'react'
import styled from 'styled-components';
import {Card, SectionHeading} from '../../components/PageLayout';
import {MEDICARE} from '../../enums/InsuranceType'
import {QuestionList} from '../../components/QuestionList'
import {Insurance} from './Insurance'

export const OptionalHeading = styled.span`
  color: ${getColor('gray180')};
  font-weight: 400;
`

export const InsuranceDetails = ({readOnly, form}) => {
  return <>
    <SectionHeading>
      <h3>Insurance information <OptionalHeading>(Optional)</OptionalHeading></h3>
      {/* <p>Please include the patient’s insurance details for reference by the institution.</p> */}
      <p>In most cases, this service will not be covered by insurance. Only complete this section if you have been advised to by your care coordinator.
        This information will only be used should there be any covered procedure.
      </p>
    </SectionHeading>
    <Card css="margin-bottom: 1rem;">
      <QuestionList
        disabled={readOnly}
        hardLimit={3}
        min={0}
        getEmpty={() => ({id: Date.now(), type: MEDICARE})}
        path="patientInfo.insurances"
        addLabel="Add insurance"
      >{(insurance, i, arr) => <Insurance
        form={form}
        i={i}
        path={["patientInfo","insurances"]}
        disabled={readOnly}
        canRemove
      />
      }</QuestionList>
    </Card>
    {/* <p>
      Note: While some facilities may use this information if there is a
      reimbursable service provided, certain facilities will only collect
      an all-inclusive fee directly from the patient for this service.
      Please check with your facility for details.
    </p> */}
  </>
}
