import { Field } from '@startlibs/form';
import { Icon } from '@startlibs/components';
import React from 'react'
import styled from 'styled-components';
import {SignagureImageContainer, SignatureDate} from '../../../../request/Payment'
import {formatDateNoUTC} from '../../../../utils/utils'

const ConfirmedByAdmin = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  b {
    margin-right: 0.5rem;
  }
  ${SignatureDate} {
    margin: 0.5rem 0;
  }
`

export const TermSignature = ({caseRequest, isPatient}) =>
  caseRequest.acceptanceInfo.externallyAccepted
    ? <ConfirmedByAdmin css="margin-bottom:0.5rem">
      <b>The administrator informed that you already agreed to this term.</b>
      <SignatureDate><Icon icon="check"/>Marked as agreed on {formatDateNoUTC(new Date(caseRequest.acceptanceInfo.acceptedTermsDate), "MM/dd/yyyy at hh:mm")}</SignatureDate>
    </ConfirmedByAdmin>
    : <>{isPatient && <p><b>You already confirmed to have read and agreed to the above service agreement.</b></p>}
    <SignagureImageContainer css="position: relative; margin-top: 0.5rem;">
      <img src={caseRequest.acceptanceInfo.signature}/>
      <SignatureDate><Icon icon="check"/>Signed on <span>{formatDateNoUTC(new Date(caseRequest.acceptanceInfo.acceptedTermsDate), "MM/dd/yyyy at hh:mm")}</span></SignatureDate>
    </SignagureImageContainer>
  </>
