import React from 'react'
import {isPendingAskingMore} from '../patient/utils/patientUtils'

export const PENDING_REQUEST = 'PENDING_REQUEST'
export const CASE_DRAFT = 'CASE_DRAFT'
export const WAITING_MEDICAL_RECORDS = 'WAITING_MEDICAL_RECORDS'
export const WAITING_APPROVAL = 'WAITING_APPROVAL'
export const PENDING_ASSIGNMENT = 'PENDING_ASSIGNMENT'
export const WAITING_ACCEPTANCE = 'WAITING_ACCEPTANCE'
export const WAITING_MORE_INFORMATION = 'WAITING_MORE_INFORMATION'
export const UNDER_REVIEW = 'UNDER_REVIEW'
export const CASE_REJECTED = 'CASE_REJECTED'
export const REQUEST_REJECTED = 'REQUEST_REJECTED'
export const CASE_REVIEWED = 'CASE_REVIEWED'
export const CASE_CLOSED = 'CASE_CLOSED'
export const CASE_ARCHIVED = 'CASE_ARCHIVED'



export const getDefaultPath = (caseRequest,alreadyAccepted) => {
  const adminRecordsSubmitted = caseRequest?.caseFlags?.adminRecordsSubmitted
  const adminMedicalReleaseUpdated = caseRequest?.caseFlags?.adminMedicalReleaseUpdated

  // TODO check where to redirect the user to case-status
  switch (caseRequest.state) {
    case PENDING_REQUEST:
      if (isPendingAskingMore(caseRequest)) {
        return "contact" // here it shows what was asked to the patient
      } else {
        return "patient" // here it shows the patient's information and the ACCEPT BUTTON - IMPORTANT
      } // ALL OF THEN BELOW, I THINK CAN BE CHANGE TO "case-status"
    case WAITING_MEDICAL_RECORDS:
    case CASE_DRAFT:
      return "case-status"
      // if (alreadyAccepted || adminRecordsSubmitted) {
      //   return "payment"
      // } else if (adminMedicalReleaseUpdated) {
      //   return "records"
      // } else {
      //   return "releases"
      // }
    case WAITING_APPROVAL: return "case-status"; //return "payment"
    case PENDING_ASSIGNMENT:
    case UNDER_REVIEW:
    case WAITING_ACCEPTANCE:
    case WAITING_MORE_INFORMATION: return "case-status"; //return "expert"
    case CASE_REJECTED: return "rejected" // here it shows the reason why the case was rejected by the expert - KEEP IT
    case CASE_REVIEWED: return "review"  // here it shows the answers of the expert - Keep?
    case CASE_CLOSED: return "report" // Complete, here it shows the answers of the expert - Keep?
    case REQUEST_REJECTED:
    case CASE_ARCHIVED:return "overview"  // here it shows the reason rejected by the ADMIN and can open again
  }
}

export const getExpertDefaultPath = (state) => {
  switch (state) {
    case CASE_REVIEWED:
    case CASE_CLOSED:
    case WAITING_ACCEPTANCE: return "overview"
    case WAITING_MORE_INFORMATION: return "questions"
    case UNDER_REVIEW: return "questions"
  }
}