import React, {useContext, useMemo} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {LinkPopup} from "./LinkPopup";
import {useToggle} from "@startlibs/core";
import {ReactEditor, useSlateStatic} from "slate-react";
import {Transforms} from "slate";

styled

const PopupsContext = React.createContext({})

const POPUPS = {
  'link': LinkPopup
}

export const SlatePopups = ({children}) => {
  const popup = useToggle()
  const editor = useSlateStatic()
  const PopupComponent = POPUPS[popup.isOpen?.key]

  const closePopup = (refocus) => {
    popup.close()
    if (refocus !== false) {
      const range = editor.selection
      ReactEditor.focus(editor)
      Transforms.select(editor, range)
    }
  }

  const providerValue = useMemo(() => ({
    open: (key, props) => popup.openWith({key, props}),
    openTool: (editor, key) => popup.openWith({key}),
    isOpen: popup.isOpen
  }), [])

  return <PopupsContext.Provider
    value={providerValue}
  >
      {children(providerValue)}
      {
        popup.isOpen &&
        <PopupComponent editor={editor} closePopup={closePopup} {...(popup.isOpen.props || {})} />
      }
  </PopupsContext.Provider>
}

export const useSlatePopups = () => {
  return useContext(PopupsContext)
}