import {Dialog, DialogTitle, Icon} from '@startlibs/components';
import {customTheme, getColor, media} from '@startlibs/utils'
import React, {useContext} from 'react'
import styled from 'styled-components';
import {Worklist} from '../viva/Worklist';
import {updateActivityLogBy, updateActivityLogFile} from '../hooks/useActivityLog';
import {Deleted, Linked} from '../../enums/FileState';
import {UploaderConfigContext} from "../../service/UploaderConfigContext";
import {UIAction} from "../../service/UIAction";
import {useRecordsSelector} from "../../service/hooks/useRecords";
import {useDoAction} from "../../service/hooks/useDoAction";
import {UploaderAction} from "../../service/UploaderAction";

const WorklistDialog = styled(Dialog)`
  max-width: 100%;
  height: calc(100vh - 2rem);
  ${media.desktop `
    margin: -2rem 0.5rem;
  `}
`

export const LinkVivaStudies = () => {

  const {providerJwt, jwt, worklistViewerJwt, patientName, linkedStudies, setLinkedStudies, setNotification, apiEndpoints} = useContext(UploaderConfigContext)
  const {dicomViewer: viewerLoadUrl,storageHost} = apiEndpoints
  const records = useRecordsSelector()
  const doAction = useDoAction()
  const close = () => doAction(UIAction.ToggleImportStudiesDialog)

  const linkRecord = (study) => doAction(UploaderAction.LinkVivaStudyAsRecord,study)

  const removeStudy = async (study) => {
    await doAction(UploaderAction.DeleteManyByUID,[study.recordUID])
    updateActivityLogBy('recordUID',study.recordUID,Deleted)
  }

  const setLinkedStudiesNew = (lStudies,type,study) => {

    if(type=="Remove"){
      return removeStudy(study)
        .then((response) => {
          setLinkedStudies(lStudies,type,study)
        })
    }else{
      updateActivityLogFile({name: study.studyDescription},Linked,{recordUID: study.recordUID,resourceUID: study.resourceUID})
      setLinkedStudies(lStudies,type,study)
    }
  }

  return <WorklistDialog
    closeDialog={close}
  >
    <LinkVivaStudiesContainer>
      <div className="main-header">
        <DialogTitle css="font-size:18px;margin-bottom: 0;">Radiology Worklist</DialogTitle>
      </div>
      <Worklist 
        patientName={patientName} 
        linkedStudies={linkedStudies} 
        setLinkedStudies={setLinkedStudiesNew} 
        close={close} 
        records={records}
        linkRecord={linkRecord}
        providerJwt={providerJwt} 
        jwt={jwt} 
        setNotification={setNotification} 
        viewerLoadUrl={viewerLoadUrl} 
        worklistViewerJwt={worklistViewerJwt}
        storageHost={storageHost} 
      />
    </LinkVivaStudiesContainer>
  </WorklistDialog>
}

const LinkVivaStudiesContainer = styled.div`
  .main-header {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    background: white;
    top: 0;
    h3 {
      margin: 0 1rem 0 0;
      flex-grow: 1;
    }
    a.return-records {
      font-size: 11px;
    }
  }
  .table-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .row {
      width: 100%;
      flex-basis: 100%;
      display: flex;
      padding: 1rem;
      border-bottom: 1px solid ${getColor('gray240')};
      &.table-head {
        font-weight: 600;
        color: ${getColor('gray60')};
        top: 43px;
        background: white;
      }
      .timestamp {
        flex-basis: 120px;
        flex-shrink: 0;
      }
      .details {
        flex-grow: 1;
        margin: 0 2rem;
        font-weight: 600;
        .file-name {
          color: ${getColor('gray120')};
        }
        a {
          margin-left: .5rem;
        }
      }
      .state {
        flex-shrink: 0;
        flex-basis: 130px;
        display: inline-flex;
        align-items: center;
        ${Icon} {
          font-size: 14px;
          margin-right: .5rem;
        }
        &.failure {
          color: ${getColor('alert')};
        }
      }
    }
  }
  ${customTheme("ActivityLogContainer")};
`
