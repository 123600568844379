let resolveStorageHost = null
let storageHost = new Promise((res) => resolveStorageHost = res)


export const setStorageHost = (newStorageHost,sessionKey) => {
  storageHost = newStorageHost
  resolveStorageHost(storageHost)
  if (sessionKey) {
    window.localStorage.setItem(sessionKey+"-storageHost",newStorageHost)
  }
}
export const getStorageHost = () => storageHost
