import {Button} from '@startlibs/components'
import React, {Suspense} from 'react'
import {
  CardWithIcon,
  PageContainer,
  RequestStatusIcon
} from '../../components/PageLayout';
import {DIGITAL} from '../../enums/PaymentMethod'
import {Header} from '../../components/Header';
import { InfoBox } from '../../components/InfoBox';
import {PurviewFooter} from '../../components/PurviewFooter';
import {SELF} from '../../enums/InsuranceType'
import {getJwt} from '../../hooks/useJwt'
import {jwtGetFetcher} from '../../utils/authFetch'
import {willUseSuspense} from '../../hooks/useSuspense'

export const PatientPendingApproval = ({caseRequest}) => {


  return <>
    <PageContainer>
      <Header
        title="Expert View request"
        caseRequest={caseRequest}
      />
      <CardWithIcon>
        <RequestStatusIcon icon="clock"/>
        <h3>Your approval is needed to continue.</h3>
        <p>The institution asked you to review and approve this case.</p>
        {
          caseRequest.customMessages?.caseAcceptance &&
          <InfoBox css="margin-bottom:1.5rem;">{caseRequest.customMessages.caseAcceptance}</InfoBox>
        }
        <p>Please click below to review, approve and provide payment for this case.</p>
        <Button.Link to="/patient/approval">Review and approve case</Button.Link>
      </CardWithIcon>
      <Suspense fallback={null}>
        <RecordsReleaseWarning />
      </Suspense>
    </PageContainer>
    <PurviewFooter />
  </>
}


const useAuthSuspense = willUseSuspense((requestId) =>
  jwtGetFetcher(getJwt())(`/api/medicalRecordLocationForms`,{requestId})
)

const RecordsReleaseWarning = () => {
  const locations = useAuthSuspense()
  if (locations.filter(release => !release?.medicalRecordsReleaseLicense?.signature).length) {
    return <InfoBox lightYellow css="display: flex; align-items: center; justify-content: space-between;">
      <div>
        <strong>There are pending records release authorizations.</strong>
        <div>Please consider reviewing and signing-off on those before approving the case.</div>
      </div>
      <Button.Link to="/patient/release">Go to medical records release</Button.Link>
    </InfoBox>
  } else {
    return null
  }
}
