import {DatePicker, Field, WithForm} from '@startlibs/form'
import {Button, SplitColumnsContainer} from '@startlibs/components'
import {_s, formatDate, getColor, media} from '@startlibs/utils';
import {useToggle} from '@startlibs/core'
import React, {useEffect, useRef} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components'

import {DATE_FORMAT} from '../../config/DateFormat'
import {getDate, getDateValue} from '../../utils/utils'
import {CategoriesInput} from '../../admin/experts/hooks/useEditCategories'
import {buildValidation} from '../../utils/validation'

const AdvancedPopupComponent = styled.div`
  position: absolute;
  background-color: #FFF;
  border: 1px solid ${getColor('gray210')};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 1.25rem;
  z-index: 999;
  left: 0;
  width: 93vw;
  width: calc(100vw - 2rem);
  margin-top: -1px;
  .upload-dateshortcuts {
    margin-top: -0.25rem;
    margin-bottom: 1rem;
    a {
      color: ${getColor('gray150')};
      margin-right: 1rem;
      text-decoration: underline;
    }
  }
  .popup-buttons-container {
    text-align: right;
  }
  .close-icon {
    color: ${getColor('gray150')};
    font-size: 20px;
    position: absolute;
    top: 6px;
    right: 5px;
    cursor: pointer;
    width: 2rem;
    line-height: 2rem;
    text-align: center;
    border-radius: 50%;
    :hover {
      background: ${getColor('gray240')};
    }
  }
  ${media.desktop`
    max-width: 30rem;
  `}
`


const getValuesFromParams = (params,categories) => ({
  dob: getDateValue('dob',params),
  from: getDateValue('from',params),
  to: getDateValue('to',params),
  categories:!categories ? [] : (params.categories||"").split("|").map(key =>categories.find(({id})=>id.toString()===key)).filter(Boolean) || []
})
const transform = ({from,dob,to,categories}) => ({
  dob: _.isNumber(dob) || _.isDate(dob) ? formatDate(dob, "MM-dd-yyyy") : "",
  from: _.isNumber(from) || _.isDate(from) ? formatDate(from, "MM-dd-yyyy") : "",
  to: _.isNumber(to) || _.isDate(to) ? formatDate(to, "MM-dd-yyyy") : "",
  categories:categories.map(_.get('id')).filter(Boolean).join("|")
})

const preValidation = buildValidation({
  categories: (e) => e.find(_.isString) && "Invalid division"
})

export const AdvancedSearchPopup = ({params, focusedInput, categories, withoutCreateDate, setParams, closePopup}) => {
  const popupRef = React.useRef()

  const focusRef = useRef()

  useEffect(() => {
    const tryClose = (e) => !(popupRef.current.contains(e.target) || e.target === popupRef.current || e.defaultPrevented) && closePopup()
    document.addEventListener("click", tryClose)
    return () => document.removeEventListener("click", tryClose)
  }, [])

  useEffect(() => {
    if (focusRef.current) {
      focusRef.current.focus()
    }
  }, [])


  const action = (values) => {
    setParams(_s.assign(values))
    closePopup()
  }

  return <WithForm
    action={action}
    values={getValuesFromParams(params,categories)}
    transform={transform}
    preValidation={preValidation}
  >{form =>
    <AdvancedPopupComponent
    ref={popupRef}
    // onKeyDown={({key}) => key === "Enter" && form.submitForm()}
    onKeyDown={({key,defaultPrevented}) => key === "Enter" && !defaultPrevented && form.submitForm()}
    onClick={(e) => e.stopPropagation()}
  >
    {categories && <CategoriesInput autoFocus={focusedInput === "categories"}/>}
    <DatePicker
      ref={focusedInput === "dob" ? focusRef : undefined} label="Patient date of birth"
      placeholder={DATE_FORMAT().toUpperCase()} format={DATE_FORMAT()}
      path="dob"
    />
    {
      !withoutCreateDate && <DateRangeField
        form={form}
        initFocusRef={focusedInput === "from" ? focusRef : undefined}
        endFocusRef={focusedInput === "to" ? focusRef : undefined}
        label="Case created between"
      />
    }
    <div className="popup-buttons-container">
      <Button onClick={closePopup} tabIndex={2}>Cancel</Button>
      <Button highlight type="submit">Search</Button>
    </div>
  </AdvancedPopupComponent>
  }</WithForm>
}

const DateRangeField = ({label, initFocusRef, endFocusRef, form}) => {
  const updateFields = useToggle()

  return <Field label={label}>
    <SplitColumnsContainer key={updateFields.isOpen}>
      <DatePicker
        ref={initFocusRef} label="" placeholder={DATE_FORMAT().toUpperCase()} format={DATE_FORMAT()}
        path="from"
      />
      <DatePicker
        ref={endFocusRef} label="" placeholder={DATE_FORMAT().toUpperCase()} format={DATE_FORMAT()}
        path="to"
      />
    </SplitColumnsContainer>
    <div className="upload-dateshortcuts">
      <a onClick={() => {form.setValue('from',getDate(-30)); form.setValue("to",getDate(0)); updateFields.openWith(Date.now())}}>Last 30 days</a>
      <a onClick={() => {form.setValue('from',getDate(-7)); form.setValue("to",getDate(0)); updateFields.openWith(Date.now())}}>Last 7 days</a>
      <a onClick={() => {form.setValue('from',getDate(0)); form.setValue("to",getDate(0)); updateFields.openWith(Date.now())}}>Today</a>
    </div>
  </Field>
}
