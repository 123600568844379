import React from 'react'
import {willUseSharedCallback} from '@startlibs/core'

export const [useRegisterIsOrderingBy,getOrdering] = willUseSharedCallback()

export const isFilteringPastYearByUpload = () => getOrdering()[0] === 1

export const isUsingUploadedPastYear = (params) => {
  return (params.last12Months+"") !== "false" && isFilteringPastYearByUpload() && !params.updatedTimeInit && !params.updatedTimeEnd
}
export const isUsingStudyPastYear = (params) => {
  return (params.last12Months+"") !== "false" && !isFilteringPastYearByUpload() && !params.studyDatetimeEnd && !params.studyDatetimeInit
}

export const isPastYearToggleDisabled = (params) => {
  return (isFilteringPastYearByUpload() && (params.updatedTimeInit || params.updatedTimeEnd)) ||
    (!isFilteringPastYearByUpload() && (params.studyDatetimeEnd || params.studyDatetimeInit))
}