/* eslint-disable no-throw-literal */
import dicomParser from 'dicom-parser'
import {getFileNameAndExtension, getUUID} from '../utils/AttachmentsUtils'

const DicomTextValue = (attr, dataSet, required) => {
  var element = dataSet.elements[attr];
  var text = "";

  if (element !== undefined) {
    var str = dataSet.string(attr);
    if (str !== undefined) {
      text = str;
    }
  } else if (required) {
    throw `Element ${attr} not found`
  }

  /* patientID */
  /* studyUID */
  /* seriesUID */
  /* instanceUID */
  if (attr === "x00100020" || attr === "x0020000d" || attr === "x0020000e" || attr === "x00080018") {
    text = text.trim();
  }

  return text;
};

const isDicomDir = (dataSet) =>  {
  var element = dataSet.elements["x00020002"];

  if (element !== undefined)
  {
    var str = dataSet.string("x00020002");
    if (str === "1.2.840.10008.1.3.10")
    {
      return true;
    }
  }

  return false;
}

export const instanceFromServerAttributes = (serverInstance,prevInstance) => {
  return {
    isCompliant: true,
    isDicomDir: false,
    instanceNumber: serverInstance.InstanceNumber || prevInstance.instanceNumber,
    instanceUID: serverInstance.SOPInstanceUID || prevInstance.instanceUID,
    patientDoB: serverInstance.PatientBirthDate || prevInstance.patientDoB,
    patientID: serverInstance.PatientID || prevInstance.patientID,
    patientName: serverInstance.PatientName || prevInstance.patientName,
    patientSex: serverInstance.PatientSex || prevInstance.patientSex,
    referringPhysician: serverInstance.ReferringPhysicianName || prevInstance.referringPhysician,
    seriesDate: serverInstance.SeriesDate || prevInstance.seriesDate,
    seriesInstitutionName: serverInstance.InstitutionName || prevInstance.seriesInstitutionName,
    seriesModality: serverInstance.Modality || prevInstance.seriesModality,
    seriesTime: serverInstance.SeriesTime || prevInstance.seriesTime,
    seriesUID: serverInstance.SeriesInstanceUID || prevInstance.seriesUID,
    studyDate: serverInstance.StudyDate || prevInstance.studyDate,
    studyDescription: serverInstance.StudyDescription || prevInstance.studyDescription,
    studyUID: serverInstance.StudyUID || prevInstance.studyUID,
    fileName: (prevInstance && (prevInstance.fileName || (prevInstance.sourceFile && getFileNameAndExtension(prevInstance.sourceFile)[0]))) || "Missing filename",
    key: (prevInstance && prevInstance.key) || getUUID()
  }
}

export const getDicomInstanceJson = (byteArray,requireAll) => {
  const dataSet = dicomParser.parseDicom(byteArray,{untilTag: 'x00200013'})
  try {
    const dicomProperties = {
      patientID: DicomTextValue("x00100020", dataSet),

      patientName: DicomTextValue("x00100010", dataSet, requireAll),
      patientDoB: DicomTextValue("x00100030", dataSet, requireAll),
      patientSex: DicomTextValue("x00100040", dataSet, requireAll),

      studyUID: DicomTextValue("x0020000d", dataSet, true),
      studyDate: DicomTextValue("x00080020", dataSet),
      studyDescription: DicomTextValue("x00081030", dataSet, requireAll),

      seriesUID: DicomTextValue("x0020000e", dataSet, requireAll),
      seriesDate: DicomTextValue("x00080021", dataSet),
      seriesTime: DicomTextValue("x00080031", dataSet),
      seriesModality: DicomTextValue("x00080060", dataSet, requireAll),
      seriesInstitutionName: DicomTextValue("x00080080", dataSet),

      instanceUID: DicomTextValue("x00080018", dataSet, true),
      referringPhysician: DicomTextValue("x00080090", dataSet),
      instanceNumber: DicomTextValue("x00200013", dataSet, requireAll),

      isDicomDir: isDicomDir(dataSet)
    }
    const isCompliant = !!dicomProperties.studyUID
      && !!dicomProperties.seriesUID
      && !!dicomProperties.instanceUID

    return {...dicomProperties, isCompliant}
  } catch (e) {
    return {nonCompliant:true}
  }
}