import React from 'react'
import {formatDate} from '@startlibs/utils'

export const isoStrToDate = (str) => {
  if (!str) {
   return new Date(str);
  }
  const [date,time] = str.split(/[\sT]/)
  const [year,month,day] = date.split("-")
  const [hour,minutes] = time ? time.split(":") : [0,0]
  return new Date(Number(year),Number(month)-1,Number(day),Number(hour),Number(minutes))
}

export const formatNoUTCDate = (date, format, messages) => formatDate(date,format,messages,true)

const splitAt = (str,pos) => [str.slice(0,pos),str.slice(pos)]

export const formatCurrency = (price) => {
  const [left,right] = splitAt(("00"+Math.round(Math.abs(price*100))),-2)
  return left.replace(/^0+(\d)/,'$1').replace(/\d(?=(\d{3})+$)/g, '$&,') + '.' + right
}

export function dicomDateToDate(strDate) {
  if (!strDate) { return new Date(NaN) }
  const tryIso = new Date(strDate)
  if (!isNaN(tryIso.getTime())) { return tryIso}
  const ints = strDate.replace(/(\d{4})(\d\d)(\d\d)/, '$1 $2 $3').split(' ')
  return new Date(
    parseInt(ints[0], 10),
    parseInt(ints[1], 10) - 1,
    parseInt(ints[2], 10)
  )
}

// export function dicomDateToDate(strDate) {
//   if (!strDate) { return new Date(NaN) }
//   const tryIso = new Date(strDate)
//   if (!isNaN(tryIso.getTime())) {
//     return isoStrToDate(strDate)
//   }
//   const ints = strDate.replace(/(\d{4})(\d\d)(\d\d)/, "$1 $2 $3").split(" ");
//   return new Date(
//     parseInt(ints[0], 10),
//     parseInt(ints[1], 10)-1,
//     parseInt(ints[2], 10)
//   );
// }

export const formatDateNoUTC = (date,format,messages) => formatDate(date,format,messages,true)
