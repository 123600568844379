import {Button} from '@startlibs/components'
import React, {Suspense} from 'react'
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import {InfoBox} from '../../components/InfoBox';
import {lazyProviderInfo} from '../../components/WithProvider';
import {CASE_DRAFT} from '../../enums/CaseState'
import {useNavigate} from 'react-router'
import _ from 'lodash/fp'
import {WithLazyResource} from '../../hooks/useSuspense'

export const useInvalidState = ({isFinalSubmit,setCaseRequest} = {}) => {
  const navigate = useNavigate()

  const sendToStatus = () => {
    setCaseRequest(_.flow(
      _.set('state',CASE_DRAFT),
      _.set('caseFlags.requestFlags',{}),
      _.set('caseFlags.patientDoneFlags',{})
    ))
    navigate("/patient/status")
  }

  return useConfirmDialog(<ConfirmDialog
    title={isFinalSubmit ? "Unable to notify institution" : "Unable to save changes"}
    closeButton={(close) => <Button onClick={sendToStatus}>Close</Button>}
  >
    <p>
      We were not able to {isFinalSubmit ? "notify the institution of your updates" : "save your changes"} at this time.
      {isFinalSubmit ? " These updates might have been sent already" : " The requested updates might have been finished previously"} or the institution might have revoked the update request.</p>
    <p>Any previously saved changes will not be lost and are available for the institution.</p>

    <InfoBox lightBlue>
      You can contact the institution via <strong><Suspense fallback={null}><WithLazyResource value={lazyProviderInfo}>{providerInfo => providerInfo.address.phoneNumber}</WithLazyResource></Suspense></strong> if you have any question.
    </InfoBox>
  </ConfirmDialog>)
}
