/* eslint-disable  max-classes-per-file */
export class BlackListedError extends Error {
  constructor(...rest) {
    super(...rest)
    this.name = 'InvalidExtensionError'
  }
}

export class EmptyFileError extends Error {
  constructor(...rest) {
    super(...rest)
    this.name = 'EmptyFileError'
  }
}
