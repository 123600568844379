import {willUseSharedState} from '@startlibs/core'
import {useEffect} from 'react'

export const [useSharedIsScrollOnTop, useSharedIsScrollOnTopRef] = willUseSharedState(!window.pageYOffset)

export const CheckScrollOnTop = () => {

  const [getScrollOnTop,setScrollOnTop] = useSharedIsScrollOnTopRef()

  const handleWindowScroll = () => !window.pageYOffset !== getScrollOnTop() && setScrollOnTop(!window.pageYOffset)

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll)
    handleWindowScroll()
    return () => window.removeEventListener('scroll', handleWindowScroll)
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  return null
}
