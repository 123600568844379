import React from 'react'
import _ from 'lodash/fp'
import {Editor, Node, Element, Transforms, Range, Path} from "slate";
import isHotkey from "is-hotkey";
import { insertTable } from './table';
import { jwtPostFetcher } from '../../utils/authFetch';
import { getJwt } from '../../hooks/useJwt';
import { setNotification } from '@startlibs/components';

export const PARAGRAPH_CLASSES = ['frame-object'];
export const RTF_IMAGE_CLASSES = ['rtfImgSmall', 'rtfImgMedium', 'rtfImgLarge', 'rtfImgFull'];
export const RTF_IMAGE_EXTENSIONS = ["png","jpg","jpeg","webp","jfif","gif","bmp"];
const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+k': 'link'
}
// Check Images to upload
export const canUploadImageSlate = (file) => {
  if (file.size > 6291456) {
    return ['Your file needs to be smaller than 6mb.']
  }
  if (RTF_IMAGE_EXTENSIONS.indexOf(file.type.split('/')[1].toLowerCase()) < 0
    ) {
    return ['Invalid file format. Please select a .JPG or .PNG file.']
  }
  return true
}
// Image upload
export const uploadImageFetcher = (requestId) => (file) => {
  
  const formData = new FormData()
  formData.append('file', file)
  return jwtPostFetcher(getJwt())(`/api/reportImage?requestId=${requestId}`, formData)
}
export const checkAndReplaceBlankLineOnTop = (editor) => {
  if(editor.children.length > 1 && editor.children.length <= 3){
    // check if the first node is an empty div and remove it
    if(editor && editor.children && editor?.children[0] && editor?.children[0]?.type === 'div' && editor?.children[0]?.children?.length == 1 && editor?.children[0]?.children[0]?.text === ''){
      Transforms.removeNodes(editor, {at: [0]})
      Transforms.insertNodes(editor, {type: 'paragraph', class: 'frame-object', children: [{text: ''}]}, {at: [0]})
    }
  }
}

export const insertImage = (editor, url) => {
  const text = { text: '' }
  const image = [
    { 
      type: 'image', 
      url: url, 
      children: [text],
      size: RTF_IMAGE_CLASSES[1]
    }
  ];
  Transforms.insertNodes(editor, image)
  checkAndReplaceBlankLineOnTop(editor)
}

export const loadingImageOverlay = () => {
  
  const loading = document.createElement('div')
  
  loading.style.position = 'fixed'
  loading.style.top = '0'
  loading.style.left = '0'
  loading.style.width = '100%'
  loading.style.height = '100%'
  loading.style.backgroundColor = 'rgba(0,0,0,0.5)'
  loading.style.zIndex = '1000'
  loading.style.display = 'flex'
  loading.style.flexDirection = 'column'
  loading.style.justifyContent = 'center'
  loading.style.alignItems = 'center'
  loading.style.color = 'white'
  loading.style.fontSize = '2rem'
  loading.style.fontWeight = 'bold'
  loading.style.textAlign = 'center'
  // create a loading spinner 
  const spinner = document.createElement('div')
  spinner.style.border = '8px solid #f3f3f3'
  spinner.style.borderTop = '8px solid #3498db'
  spinner.style.borderRadius = '50%'
  spinner.style.width = '60px'
  spinner.style.height = '60px'
  spinner.style.animation = 'spin 1s linear infinite'
  loading.appendChild(spinner)
  // create a loading text
  const text = document.createElement('div')
  text.style.marginTop = '1rem'
  text.innerHTML = 'Uploading image...'
  loading.appendChild(text)

  return loading
}

export const selectImageUpload = (editor, format, requestId = 0) => {
  
  const input = document.createElement('input')

  input.type = 'file'
  input.accept = RTF_IMAGE_EXTENSIONS.map(ext => `image/${ext}`).join(',')
  input.multiple = false
  input.click()
  input.onchange = () => {
    const file = input.files[0]
    if (file) {
      const [mime] = file.type.split('/')
      if (mime === 'image') {
        const canUpload = canUploadImageSlate(file)
        if (canUpload === true) {
          const loading = loadingImageOverlay()
          document.body.appendChild(loading)
          uploadImageFetcher(requestId)(file)
            .then(response => {
              if(response && response?.url && response?.url.length > 0){
                insertImage(editor, response.url)
                document.body.removeChild(loading)
              }else{
                document.body.removeChild(loading)
              }
            })
            .catch(error => {
              document.body.removeChild(loading)
              if (error?.status === 413) {
                setNotification({type:"alert", timeout: 4000,msg:(close) => <span>File size too large</span>})
              }else{
                setNotification({type:"alert", timeout: 4000,msg:(close) => <span>An error has occurred while uploading</span>})
              }
            })
        }else{
          setNotification({type:"alert", timeout: 4000,msg:(close) => <span>{canUpload}</span>}) 
        }
      }
    }
    return;
  }

}

export const hotKeysActions = (event, editor, popup, formatTools) => {
  for (const hotkey in HOTKEYS) {
    if (isHotkey(hotkey, event)) {
      event.preventDefault()
      const mark = HOTKEYS[hotkey]
      if(formatTools.indexOf(mark) >= 0){
        if (mark === 'link') {
          if(!popup.isOpen){
            popup.openTool(editor, 'link')
          }
        }else{
          toggleMark(editor, mark)
        }
      }
    }
  }
}

export const autoCompleteListActions = (event, editor, popup, formatTools, phrases, index, search, target, setIndex, setTarget,insertMention) => {
  if (target && phrases.length > 0) {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault()
        const prevIndex = index >= phrases.length - 1 ? 0 : index + 1
        setIndex(prevIndex)
        break
      case 'ArrowUp':
        event.preventDefault()
        const nextIndex = index <= 0 ? phrases.length - 1 : index - 1
        setIndex(nextIndex)
        break
      case 'Tab':
      case 'Enter':
        event.preventDefault()
        Transforms.select(editor, target)
        insertMention(editor, phrases[index])
        setTarget(null)
        break
      case 'Escape':
        event.preventDefault()
        setTarget(null)
        break
    }
  }
} 

const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']
const MARK_TYPES = ['bold', 'italic', 'underline']

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )
  const hasImage = hasImageSelected(editor)
  if(hasImage){
    return
  }
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      Element.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  })
  let newProperties
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      type: isActive ? 'div' : isList ? 'list-item' : format,
    }
  }
  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)
  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor, format) => {
  const blockType = TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        Element.isElement(n) &&
        n[blockType] === format,
    })
  )

  return !!match
}

const hasImageSelected = (editor) => {
  const { selection } = editor
  if (!selection) return false
  const [hasImg] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        Element.isElement(n) &&
        n.type === 'image'
    })
  )
  return !!hasImg
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

export const isActive = (editor, format) => {
  if (MARK_TYPES.includes(format)) {
    return isMarkActive(editor,format)
  }
  return isBlockActive(editor,format)
}
export const toggle = (editor, format) => {
  if(format === 'table'){
    insertTable(editor)
    checkAndReplaceBlankLineOnTop(editor)
  }
  if (format === 'link'){
    wrapLink(editor)
  }
  if (MARK_TYPES.includes(format)) {
    toggleMark(editor,format)
  }else{
    toggleBlock(editor,format)
  }
}
export const unwrap = (editor,format) => {
  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      Element.isElement(n) &&
      n.type === format,
    split: true,
  })
}
export const wrap = (editor,format) => {
  const block = { type: format, children: [] }
  Transforms.wrapNodes(editor, block)
}

// Links
const isLinkActive = editor => {
  const [link] = Editor.nodes(editor, {
    match: n =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
  })
  return !!link
}

export const unwrapLink = editor => {
  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
  })
}

export const wrapLink = (editor, url, text) => {
  const range = editor.selection

  const originalText = range && Editor.string(editor,range)
  const emptySelection = range && Range.isCollapsed(range)
  // When there is no selection it is collapsed
  const isTextUpdate =  originalText !== text

  if (isLinkActive(editor) && !isTextUpdate) {
    unwrapLink(editor)
  }

  const link = {
    type: 'link',
    url,
    children: emptySelection || isTextUpdate ? [{ text }] : [],
  }

  if (emptySelection) {
    Transforms.insertNodes(editor, link)
  } else if (isTextUpdate) {
    Transforms.removeNodes(editor, {
      match: (n) => n.type === 'link'
    })
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
  }
  Transforms.collapse(editor, { edge: 'end' })
  const [nextNode,nextNodePath] = Editor.next(editor)
  Transforms.select(editor, {path:nextNodePath,offset:0});
}

export const PathUtils = {
  getClosestBlockAncestor: (editor,path) => {
    if (!path) { return [] }
    const ancestors = Node.ancestors(editor, path, {reverse:true})
    for (let [ancestor,ancestorPath] of ancestors) {
      if (Editor.isBlock(editor,ancestor)) {
        return [ancestor,ancestorPath]
      }
    }
    return []
  },

  getFirstBlockRange: (editor) => {
    const texts = Node.texts(editor,{from:editor.selection.anchor.path,to:editor.selection.focus.path})
    const [firstText,firstPath] = texts.next().value || []
    const [firstBlockAncestor,firstBlockAncestorPath] = PathUtils.getClosestBlockAncestor(editor,firstPath)

    if (!firstText || !firstBlockAncestor) {
      return editor.selection
    }

    let previousPath = firstPath
    let previousText = firstText

    const makeRange = () => {
      return {
        anchor:{
          offset:editor.selection.anchor.offset,
          path:firstPath
        },
        focus:{
          offset: Path.equals(editor.selection.focus.path,previousPath) ? editor.selection.focus.offset : (previousText.text.length),
          path:previousPath
        }
      }
    }

    for (let [text,path] of texts) {
      const commonPath = Path.common(firstPath,path)
      if (!Path.equals(firstBlockAncestorPath,commonPath) && !Path.isAncestor(firstBlockAncestorPath,commonPath)) {
        return makeRange()
      }
      previousPath = path
      previousText = text
    }
    return makeRange()
  }
}

export const removeElementsFromHtml = (html) => {
  return html
    .replace(/<thead[^>]*>/g, '')  // THEAD
    .replace(/<\/thead>/g, '')
    .replace(/<tbody[^>]*>/g, '')  // TBODY
    .replace(/<\/tbody>/g, '')
    .replace(/<tfoot[^>]*>/g, '')  // TFOOT
    .replace(/<\/tfoot>/g, '')
    .replace(/(<colgroup)(.*)(<\/colgroup>)/g, '') // COLGROUP
    .replace(/(<style)(.*)(<\/style>)/g,'') // STYLE
}

export const wrapTdContentInsideHtml = (html) => {
  // These replacements are needed to wrap the content inside a td into a <table-content> and <div> element
  // Then we can perform table operations on the content
  return html.replace(/<td[^>]*>/g, '<td><table-content><div>')
    .replace(/<\/td>/g, '</div></table-content></td>')
    .replace(/<th[^>]*>/g, '<td><table-content><div>')
    .replace(/<\/th>/g, '</div></table-content></td>')
}

export const fixImageElementDragAndDropInsideEditor = (html) => {
  // This is needed to fix the drag and drop of images inside the editor
  // return html.replace(/<div data="slatebtcontainer"([\s\S])*span><\/div>/g,"")
  return html
    .replace(/(<div data="slatebtcontainer")(.*)(\/span><\/div>)/g,"")
    // .replace(/(<div style="position: relative;"><div contenteditable="false" class="cardbar__TableToolbar)(.*)(<\/div><\/div><\/div>)/g,"")
    .replace(/(<div style="position: relative;"><div contenteditable="false" class="cardbar__TableToolbar)(.*)(<\/div><\/div><div contenteditable="false" class="table-horizontal-toolbar"><\/div>)/g,"")
    // .replace(/(<div style="position: relative;"><div contenteditable="false" class="cardbar__TableToolbar)(.*)(<\/div><\/div><div contenteditable="false" class="table-horizontal-toolbar"><\/div>)/g,"")
    .replace(/(<div contenteditable="false" class="cardbar__TableToolbar)(.*)(<\/div><\/div><div contenteditable="false" class="table-horizontal-toolbar"><\/div>)/g,"")
    .replace(/(<div contenteditable="false" class="cardbar__TableToolbar)(.*)(<\/div><\/div><div contenteditable="false" class="table-horizontal-toolbar">)/g,"")
    .replace(" imageSelected","")
    .replace("imageSelected","")
    .replace(" imageSelected","")
    .replace("imageSelected","")
}

export const removeImagesFromHtml = (html) => {
  return html.replace(/<img[^>]*>/g,"");
}

