import {Button, Loading} from '@startlibs/components';
import React, {useEffect, useRef} from 'react'
import {
  PanelButton,
  WidgetBody,
  WidgetFooter,
  WidgetHeader,
  WidgetIcon,
  WidgetPanelComponent
} from './PanelStyles';
import {touchClick} from './utils'
import {usePanelUtils} from './usePanelUtils'
import { CaseNotesInformationPanel } from './CaseNotesInformationPanel';
import _ from 'lodash/fp';

export const CaseNotesPanel = ({caseRequest, open, close, caseNotes, setCaseNotes, isLoadingNotes, formRef}) => {

  const [{refs}, maximized, toggleMaximized, isRefocusingPanel, isHighlighting] = usePanelUtils()
  const panelRef = refs.panel
  const scrollContainerRef = refs.scrollContainer

  // const formRef = useRef()
  useEffect(() => {
    open && toggleMaximized(true)
  },[open])

  if (!open) {
    return null
  }

  return <WidgetPanelComponent
    className="JS-fix-dialog-scroll" maximized={maximized}
    ref={panelRef}
  >
    <WidgetHeader onClick={toggleMaximized} onTouchStart={maximized ? () => null : touchClick(toggleMaximized)}>
      <WidgetIcon icon="study-notes"/>
      <div>
        <h4>Case Notes</h4>
        {maximized && <span css="opacity:0.8;">Case Id: {caseRequest.requestCode}</span>}
      </div>
      <PanelButton icon="x" onClick={close}/>
    </WidgetHeader>
    <WidgetBody ref={scrollContainerRef}>
      {isLoadingNotes && <Loading css="margin: 2rem auto;"/>}
      {!isLoadingNotes && caseNotes && <>
        <p css="margin:-0.25rem 0 0.75rem;opacity:0.6;">These notes will not be presented in the final report.</p>
        <CaseNotesInformationPanel
          caseRequest={caseRequest}
          caseNotes={caseNotes}
          setCaseNotes={setCaseNotes}
          formRef={formRef}
          values={caseNotes}
        ></CaseNotesInformationPanel>
      </>}

    </WidgetBody>
    <WidgetFooter>
      <Button css="width:100%;" highlight onClick={
        () => setCaseNotes(_.concat(caseNotes,{external: false, info: {note: ''}}))
      }>
        Add note
      </Button>
    </WidgetFooter>
  </WidgetPanelComponent>
}
