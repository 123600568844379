import React from 'react'


const capitalize = (value) => value.slice(0,1).toUpperCase() + value.slice(1).toLowerCase()

const MONTHS = {
  '0': 'january',
  '1': 'february',
  '2': 'march',
  '3': 'april',
  '4': 'may',
  '5': 'june',
  '6': 'july',
  '7': 'august',
  '8': 'september',
  '9': 'october',
  '10': 'november',
  '11': 'december',
}

const WEEKDAYS = {
  '0': 'sunday',
  '1': `monday`,
  '2': `tuesday`,
  '3': `wednesday`,
  '4': `thursday`,
  '5': `friday`,
  '6': 'saturday',
}


export const i18nDateMessages = (type, n, capitalized) => capitalized !== false ? capitalize(type === 'month' ? MONTHS[n] : WEEKDAYS[n]) : type === 'month' ? MONTHS[n] : WEEKDAYS[n]