import { Icon, SplitColumnsContainer, Button } from '@startlibs/components'
import { DatePicker, Field } from '@startlibs/form'
import { getColor, formatDate, parseDate, _s } from '@startlibs/utils';
import { useToggle } from '@startlibs/core'
import React, { useEffect, useRef } from 'react'
import _ from 'lodash/fp'
import styled, {css} from 'styled-components'

const DATE_FORMAT = () => "yyyy-MM-dd"
const getDateValue = (key,params) => params[key] ? parseDate(params[key],new Date(),"yyyy-MM-dd") : NaN

const AdvancedPopupComponent = styled.div`
  position: absolute;
  margin-top: 3rem;
  background-color: #FFF;
  border: 1px solid ${getColor('gray210')};
  border-radius: 6px;
  padding: 1.25rem 1.25rem 0.5rem;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  width: calc(100vw - 2rem);
  max-width: 100%;
  .upload-dateshortcuts {
    margin-top: -0.25rem;
    margin-bottom: 1rem;
    a {
      color: ${getColor('gray150')};
      margin-right: 1rem;
      text-decoration: underline;
    }
  }
  .popup-buttons-container {
    text-align: right;
  }
  strong {
    display: flex;
    align-items: center;
    ${Icon} {
      position: static;
      font-size: 22px;
      width: 2.5rem;
      line-height: 0;
      margin-left: -0.75rem;
      text-align: center;
    }
  }
`

const PopupFooter = styled.div`
  text-align: right;
  margin-top: -1rem;
  border-top: 1px solid ${getColor('gray210')};
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
`

export const AdvancedPopup = ({ closePopup, params, setParams, focusedInput }) => {
  const popupRef = React.useRef()
  const focusRef = useRef()

  const dateStart = useToggle(getDateValue('dateStart', params))
  const dateEnd = useToggle(getDateValue('dateEnd', params))

  useEffect(() => {
    const tryClose = (e) => !(popupRef.current.contains(e.target) || e.target === popupRef.current || e.defaultPrevented) && closePopup()
    document.addEventListener("click", tryClose)
    return () => document.removeEventListener("click", tryClose)
  }, [])

  useEffect(() => {
    if (focusRef.current) {
      focusRef.current.focus()
    }
  }, [])

  return <AdvancedPopupComponent ref={popupRef} onKeyDown={({ key }) => key === "Enter"}
    onClick={(e) => e.stopPropagation()}>
    <div>
      <DateRangeField
        initFocusRef={focusedInput === "dateStart" ? focusRef : undefined}
        endFocusRef={focusedInput === "dateEnd" ? focusRef : undefined}
        endToggle={dateEnd}
        initToggle={dateStart}
        label="Dates between"
        params={params}
        setParams={setParams}
      />
      <PopupFooter>
        <Button onClick={() => {closePopup()}}>Cancel</Button>
        <Button highlight 
          onClick={() => {
            if (dateStart.isOpen){
              var string1 = formatDate(dateStart.isOpen, "yyyy-MM-dd")+" "
              var string2 = params.dateStart+" "
              if(string1 != string2){ // If they are the same, it doesnt need to request again
                setParams(_s.assign({
                  dateStart: _.isNumber(dateStart.isOpen) || _.isDate(dateStart.isOpen) ? formatDate(dateStart.isOpen, "yyyy-MM-dd") : "",
                }));
              }
            }
            if (dateEnd.isOpen){
              var string1 = formatDate(dateEnd.isOpen, "yyyy-MM-dd")+" "
              var string2 = params.dateStart+" "
              if(string1 != string2){ // If they are the same, it doesnt need to request again
                setParams(_s.assign({
                  dateEnd: _.isNumber(dateEnd.isOpen) || _.isDate(dateEnd.isOpen) ? formatDate(dateEnd.isOpen, "yyyy-MM-dd") : "",
                }));
              }
            }
            closePopup()
          }}>
            Apply
        </Button>
      </PopupFooter>
    </div>
  </AdvancedPopupComponent>
}

const DateRangeField = ({ label, initFocusRef, endFocusRef, initToggle, endToggle, params, setParams }) => {
  const updateFields = useToggle()

  const handleSelectInitDate = (date) => {
    initToggle.openWith(date);
  }

  const handleSelectEndDate = (date) => {
    endToggle.openWith(date);
  }

  return <Field label={label}>
    <SplitColumnsContainer key={updateFields.isOpen}>
      <DatePicker ref={initFocusRef} label="" placeholder={DATE_FORMAT().toUpperCase()} format={DATE_FORMAT()} raw
        value={initToggle.isOpen} setValue={handleSelectInitDate} />
      <DatePicker ref={endFocusRef} label="" placeholder={DATE_FORMAT().toUpperCase()} format={DATE_FORMAT()} raw
        value={endToggle.isOpen} setValue={handleSelectEndDate} />
    </SplitColumnsContainer>
    
  </Field>
}
