import React from 'react'

export const touchClick = (cb,sensibility = 5) => (e) => {
  if (e.touches?.length !== 1) { return }
  const target = e.target
  const originalX = e.touches[0].screenX
  const originalY = e.touches[0].screenY
  const isInsideBounds = (touch) => Math.sqrt( Math.pow((originalX-touch.screenX), 2) + Math.pow((originalY-touch.screenY), 2) ) < sensibility

  const checkBounds = (e) => {
    if (e.touches.length !== 1) { return }
    if (!isInsideBounds(e.changedTouches[0])) {
      maybeClick()
    }
  }
  const maybeClick = (e) => {
    target.removeEventListener('touchend',maybeClick)
    target.removeEventListener('touchmove',checkBounds)
    if (e?.changedTouches?.length !== 1) { return }
    if (isInsideBounds(e.changedTouches[0])) {
      e.preventDefault()
      cb(e)
    }
  }
  target.addEventListener('touchend',maybeClick)
  target.addEventListener('touchmove',checkBounds)
}
