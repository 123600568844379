import {Button} from '@startlibs/components'
import {TextInput} from '@startlibs/form';
import React from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import _ from 'lodash/fp'
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import {ContactCheckbox, ContactCheckboxGroup} from '../../components/ContactCard'
import {REF_PHYSICIAN} from '../../enums/ContactRelationType'
import {buildValidation} from '../../utils/validation'
import {getContact, getRelationLabel, PRIMARY} from '../../enums/ContactRelationKind'
import {getJwt} from '../../hooks/useJwt'
import {jwtFormFetcher} from '../../utils/authFetch'

export const useSendRemoteConsultationLink = (caseRequest,meetingURL) => {
  const referringPhysicianAsSecondary = caseRequest.contactDetails.contactRelationType !== "REF_PHYSICIAN" && caseRequest.referringPhysician.email
  const intl = useIntl()
  const primary = getContact(intl, caseRequest, PRIMARY)
  const physician = getContact(intl, caseRequest, REF_PHYSICIAN)

  const getChecked = (email) => emails => emails.indexOf(email)>=0
  const setValue = (email) => emails => getChecked(email)(emails) ? _.without([email],emails) : emails.concat(email)

  return useConfirmDialog(
    <ConfirmDialog
      title="Send invitation"
      confirm={<Button highlight icon="email">Send</Button>}
      action={jwtFormFetcher(getJwt())('/api/expert/sendRemoteVideoConsultNotification?requestId=' + caseRequest.requestId)}
      values={{customMessage: "", meetingURL, sendTo: []}}
      transform={_.update('sendTo',(emails) => !referringPhysicianAsSecondary ? [primary.email] : emails)}
      preValidation={buildValidation({'sendTo': (emails) => referringPhysicianAsSecondary && !emails.length && "Please select at least one contact before proceeding."})}
      notify="Email sent successfully"
    >
        <p>Send the consultation link to:</p>
      <ContactCheckboxGroup>
        <ContactCheckbox
          getValue={getChecked(primary.email)}
          setValue={setValue(primary.email)}
          path="sendTo"
          email={primary.email}
          name={primary.name}
          key="patient"
          type={primary.relationType}
        />
        {
          referringPhysicianAsSecondary &&
          <ContactCheckbox
            getValue={getChecked(physician.email)}
            setValue={setValue(physician.email)}
            path="sendTo"
            name={physician.name}
            email={physician.email}
            type={getRelationLabel(intl,REF_PHYSICIAN)}
            key="Referring physician"
          />
        }
      </ContactCheckboxGroup>
      <p>
        The above contact(s) will receive the consultation link with
        the following message:
      </p>
      <TextInput
        textarea
        autoResize
        label="Message"
        path="customMessage"
        placeholder="Insert a custom message here. Ex: Let's have a conversation at..."
      />
    </ConfirmDialog>
  )
}
