import React from 'react'
import _ from 'lodash/fp'

const abstractReadFile = (readType = (reader,file) => reader.readAsArrayBuffer(file)) => file => new Promise((res, rej) => {
  const fileReader = new FileReader();
  fileReader.onload = (evt) => {
    res(evt.target.result)
  }
  fileReader.onerror = rej
  readType(fileReader,file)
})

export const readFile = abstractReadFile()

export const readFileAsText = abstractReadFile((reader,file) => reader.readAsText(file))

export const discardMacOSApps = files => {
  const invalidPaths = _.uniq(
    files
      .map(({ path }) => path.match(/(.*\.[aA][pP][pP]\/)Contents\/MacOS(\/|$)/))
      .filter(Boolean)
      .map(([wholePath, initialPath]) => initialPath)
  )
  return files.filter(
    ({ path }) => !invalidPaths.find(invalidPath => path.indexOf(invalidPath) === 0)
  )
}