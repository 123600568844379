export const SHORTCUTS = {
    '.intakequestions': [,
        "What is the problem?",
        "What treatment do you recommend?",
        "What is the diagnosis?",
        "What medications are you taking?"
    ],
    '.intakeq1': ["What is the problem?"],
    '.intakeq2': ["What treatment do you recommend?"],
    '.intakeq3': ["What is the diagnosis?"],
    '.intakeq4': ["What medications are you taking?"],
}

export const PHRASES = [
    'Alternative treatment for the common cold',
    'A new way to treat cancer',
    'Bacteria found in the human gut',
    'Cancer treatment using a new drug',
    'Cure for the common cold',
    'Discovery of a new antibiotic',
    'Discovery of a new cancer drug',
    'Early detection of cancer',
    'Early detection of heart disease',
    'Fighting cancer with a new drug',
    'Gene therapy for cancer',
    'Heart disease treatment using a new drug',
    'Here is a long mention that will wrap',
    'Innovative cancer treatment',
    'Innovative heart disease treatment',
    'Kidney disease treatment using a new drug',
    'Killing cancer cells',
    'Lung disease treatment using a new drug',
    'Latest research on the common cold',
    'Most effective treatment for the common cold',
    'Method to treat cancer',
    'New antibiotic',
    'New cancer treatment',
    'New drug for cancer',
    'New drug for heart disease',
    'One more thing about the common cold',
    'Preventing the common cold',
    'Preventing the spread of the common cold',
    'Quitting smoking',
    'Research on the common cold',
    'Research on cancer',
    'Research on heart disease',
    'Saving lives with a new drug',
    'The common cold',
    'The common cold and its treatment',
    'Using a new drug to treat cancer',
    'Using a new drug to treat heart disease',
    "What is the problem?",
    "What treatment do you recommend?",
    "What is the diagnosis?",
    "What medications are you taking?"
];