import React from 'react'
import {wrapPromise} from '../hooks/useSuspense'
import {jwtGetFetcher} from '../utils/authFetch'
import {getJwt} from '../hooks/useJwt'


export const lazyProviderInfo = wrapPromise(() => {
  if (process.env.PDF_SERVER) {
    return Promise.resolve({locale: 'en-US'})
  } else {
    return jwtGetFetcher()("/api/providerInfo")
  }
} )


export const lazyUserInfo = wrapPromise(() => jwtGetFetcher(getJwt())("/api/userInfo"))
