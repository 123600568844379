import React from 'react'
import _ from 'lodash/fp'

let suggestionsList = []

export const getExistingTags = () => suggestionsList
export const getTagsFromParam = (tagsStr) => (tagsStr ? tagsStr.split(",") : []).map(id => getExistingTags().find(({ idTag }) => Number(id) === Number(idTag))).filter(_.identity)

export const setTags = (tags) => {
  suggestionsList = tags;
  return true
}

export const fetchSuggestionsTags = () => (s) => {
  return suggestionsList.filter(({ description }) => description.toLowerCase().indexOf(s) >= 0)
}
