import React, {useEffect, useRef} from 'react'
import {useToggle} from '@startlibs/core'
import qq from 'fine-uploader'

export const useDropFileEvents = (setFile) =>  {

  const isDraggingOver = useToggle()
  const dragBoxRef = useRef()

  useEffect(() => {
    function isValidFileDrag(e) {
      // e.dataTransfer currently causing IE errors
      // IE9 does NOT support file API, so drag-and-drop is not possible
      if (!qq.supportedFeatures.fileDrop) {
        return false;
      }

      var effectTest, dt = e.dataTransfer,
        // do not check dt.types.contains in webkit, because it crashes safari 4
        isSafari = qq.safari();

      // dt.effectAllowed is none in Safari 5

      // dt.effectAllowed crashes IE 11 & 10 when files have been dragged from
      // the filesystem
      effectTest = qq.ie() && qq.supportedFeatures.fileDrop ? true : dt.effectAllowed !== "none";
      return dt && effectTest &&
        (
          (dt.files && dt.files.length) ||                                     // Valid for drop events with files
          (!isSafari && dt.types.contains && dt.types.contains("Files")) ||  // Valid in Chrome/Firefox
          (dt.types.includes && dt.types.includes("Files"))               // Valid in IE
        );
    }

    const overCallback = (e) => {
      if (!isValidFileDrag(e)) {
        return;
      }
      // dt.effectAllowed crashes IE 11 & 10 when files have been dragged from
      // the filesystem
      var effect = qq.ie() && qq.supportedFeatures.fileDrop ? null : e.dataTransfer.effectAllowed;
      if (effect === "move" || effect === "linkMove") {
        e.dataTransfer.dropEffect = "move"; // for FF (only move allowed)
      } else {
        e.dataTransfer.dropEffect = "copy"; // for Chrome
      }
      isDraggingOver.openWith(dragBoxRef.current.contains(e.target))
      e.stopPropagation();
      e.preventDefault();
    }
    const closeCallback = (e) => {
      if (!isValidFileDrag(e)) {
        return;
      }
      if (!dragBoxRef.current.contains(e.target)) {
        isDraggingOver.close()
      }
    }
    const dropCallback = (e) => {
      if (!isValidFileDrag(e)) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      if (dragBoxRef.current.contains(e.target)) {
        setFile(e)
      }
    }

    document.body.addEventListener("dragover", overCallback);
    document.body.addEventListener("dragleave", closeCallback);
    document.body.addEventListener("drop", dropCallback)
    return () => {
      document.body.removeEventListener("dragover", overCallback);
      document.body.removeEventListener("dragleave", closeCallback);
      document.body.removeEventListener("drop", dropCallback)
    }
  }, [])

  return [isDraggingOver,dragBoxRef]
}