import {
  Button,
  ContextMenu,
  Dialog,
  Li,
  ListSeparator,
  Loading,
  Tooltip
} from '@startlibs/components';
import { getColor } from '@startlibs/utils';
import {usePopupToggle, useToggle} from '@startlibs/core'
import React from 'react'
import _ from 'lodash/fp'
import styled, { css } from 'styled-components';
import { AddButton } from '../../../components/AddButton';
import {ConfirmDialog, useConfirmDialog} from '../../../hooks/useConfirmDialog'
import {EditInstitution} from './EditInstitution'
import { InfoBox } from '../../../components/InfoBox';
import {buildValidation} from '../../../utils/validation'
import { getAddressLine, refreshInstitutions } from './lazyInstitutions';
import {getJwt} from '../../../hooks/useJwt'
import {getReportResource} from '../../../utils/utils'
import {jwtPostFetcher} from '../../../utils/authFetch'
import { lazyProviderInfo } from '../../../components/WithProvider';

const InstitutionItemBox = styled(InfoBox)`
  display: flex;
  align-items: center;
  background: white;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  :hover:after {
    content: '';
    background: rgba(0,0,0,0.05);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 2;
  }
  ${props => !props.readOnly && `padding-right: 3rem;`}
  color: ${getColor('gray90')};
  .institution-logo {
    flex-basis: 120px;
    flex-shrink: 0;
    width: 120px;
    height: 3rem;
    margin-right: 1rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    ${props => props.image && `
      background-image: url('${props.image}');
    `}
  }
  .address-details {
    flex-basis: 250px;
    flex-grow: 1;
    max-width: 27rem;
    border-left: 1px solid ${getColor('gray180')};
    padding-left: 1.5rem;
    .institution-name {
      display: flex;
      line-height: 18px;
      strong {
        max-width: calc(100% - 2rem);
        ${props => props.isDefault && css`
          margin-right: .25rem;
          max-width: calc(100% - 7rem);
        `}
        > div {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
        }
      }
      .default-label {
        flex-shrink: 0;
      }
    }
    .address-line {
      max-width: calc(100% - 2rem);
      > div {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      }
    }
  }
  ${Button} {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
  }
  strong {
    font-size: 14px;
  }
  .default-label {
    color: ${getColor('gray180')};
  }
  ${Loading} {
    position: absolute;
    top: .25rem;
    right: .25rem;
  }
`

export const ManageInstitutionsDialog = ({closeDialog, institutions, defaultInstitution, providerInfo}) => {

  const editInstitutionDialog = useToggle()

  if (editInstitutionDialog.isOpen) {
    return <EditInstitution closeDialog={editInstitutionDialog.close} institution={editInstitutionDialog.isOpen === true ? undefined : editInstitutionDialog.isOpen} providerInfo={providerInfo}/>
  }

  return <Dialog
    closeDialog={closeDialog}
    title="Manage institution options"
    footer={<Button onClick={closeDialog}>Close</Button>}
  >
    <p>Customize address and logo options to be used in reports.</p>
    {
      _.map(institution =>
        <InstitutionItem
          isDefault={defaultInstitution.id === institution.id}
          institution={institution}
          edit={editInstitutionDialog.openWith}
        />,institutions)
    }
    <AddButton onClick={editInstitutionDialog.open}>Add institution</AddButton>
  </Dialog>


}

const InstitutionItem = ({institution,isDefault,edit}) => {
  const menu = usePopupToggle()
  const savingDefault = useToggle()
  const providerInfo = lazyProviderInfo.read()

  const removeOption = useConfirmDialog(
    <ConfirmDialog
      title="Confirm removing option"
      action={() => jwtPostFetcher(getJwt())( `/api/admin/institutionDetails`,institution,{method:'DELETE'} )}
      onSuccess={() => refreshInstitutions()}
      preValidation={buildValidation({
        "": ()=> isDefault && "Removing an option marked as default is not possible. Please set another option as default first."
      })}
      confirm={<Button alert>Remove option</Button>}
    >
      <p>By removing this institution option it will not be available in any report.</p>
      <InstitutionItemBox image={institution.logoPath ? getReportResource(institution.logoPath) : providerInfo.logoURL} readOnly>
        <div className="institution-logo" />
        <div className="address-details">
          <div>
            <strong>{institution.name}</strong>
            {isDefault && <span className="default-label"> (Default)</span>}
          </div>
          <div>{getAddressLine(institution)}</div>
        </div>
      </InstitutionItemBox>
      {!isDefault && <p>Reports currently using this option will change to the default option.</p>}
      <p>Are you sure?</p>
    </ConfirmDialog>
  )

  const setAsDefault = () => savingDefault.wait(
    jwtPostFetcher(getJwt())('/api/admin/defaultInstitutionDetails',institution,{method:"PUT"})
      .then(() => refreshInstitutions())
  )


  return <InstitutionItemBox image={institution.logoPath ? getReportResource(institution.logoPath) : providerInfo.logoURL} isDefault={isDefault} onClick={() => edit(institution)}>
    <div className="institution-logo" />
    <div className="address-details">
      <div className="institution-name">
        <strong>
          <Tooltip whenEllipsis={({target}) => target.offsetWidth < target.scrollWidth} content={institution.name}>
            {institution.name}
          </Tooltip>
        </strong>
        {isDefault && <span className="default-label"> (Default)</span>}
      </div>
      <div className="address-line">
        <Tooltip whenEllipsis={({target}) => target.offsetWidth < target.scrollWidth} content={getAddressLine(institution)}>
          {getAddressLine(institution)}
        </Tooltip>
      </div>
    </div>
    <Button small onlyDropdown onClick={(e) => {e.preventDefault() ; e.stopPropagation(); menu.open()}}>
      {
        menu.isOpen &&
        <ContextMenu>
          <Li icon="edit" label="Edit institution details" onClick={() => edit(institution)}/>
          <Li icon="delete" label="Remove option" onClick={removeOption}/>
          <ListSeparator/>
          <Li label="Set as default" onClick={setAsDefault}/>
        </ContextMenu>
      }
    </Button>
    {savingDefault.isOpen && <Loading size={12} borderWidth={2} />}
  </InstitutionItemBox>

}
