import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import { useSlate, useEditor } from 'slate-react';
import { Editor, Transforms } from 'slate';
import { TableToolSeparator, Cardbar } from '../table';
import { removeTable } from '../table';
import {
  insertAbove,
  insertBelow,
  insertLeft,
  insertRight,
  mergeSelection,
  removeColumn,
  splitCell,
  removeRow,
} from './commands';
import { splitedTable } from './selection';
import { Icon, Tooltip } from '@startlibs/components';


export const TableCardbar = ({className}) => {
  const editor = useSlate();
  
  const [table] = Array.from(
    Editor.nodes(editor, {
      match: n => n.type === 'table',
    }),
  );

  const run = (action) => () => action(table, editor);

  return (
    <Cardbar
      delete={run(removeTable)}
    >
      <Tooltip content="Insert row above"><Icon icon={'add-row-above'} onMouseDown={run(insertAbove)} /></Tooltip>
      <Tooltip content="Insert row below"><Icon icon={'add-row-below'} onMouseDown={run(insertBelow)} /></Tooltip>
      <Tooltip content="Insert column left"><Icon icon={'add-column-left'} onMouseDown={run(insertLeft)} /></Tooltip>
      <Tooltip content="Insert column right"><Icon icon={'add-column-right'} onMouseDown={run(insertRight)} /></Tooltip>
      <TableToolSeparator/>
      <Tooltip content="Merge selected cells"><Icon icon={"merge-cells"} title="Merge" onMouseDown={run(mergeSelection)} /></Tooltip>
      <Tooltip content="Split selected cells"><Icon icon={"split-cells"} title="splitCell" onMouseDown={run(splitCell)} /></Tooltip>
      <TableToolSeparator/>
      <Tooltip content="Delete column"><Icon icon={"delete-column"} title="removeColumn" onMouseDown={run(removeColumn)} /></Tooltip>
      <Tooltip content="Delete row"><Icon icon={"delete-row"} title="removeRow" onMouseDown={run(removeRow)} /></Tooltip>
    </Cardbar>
  );
};

let startFromX = 0;

export const HorizontalToolbar = ({ table, tableNode }) => {
  const ref = useRef(null);
  const editor = useEditor();
  const [cols, setCols] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const tableHeight = table?.offsetHeight;

  const resizeTable = useCallback(() => {
  if (table) {
      table.querySelectorAll('td').forEach(cell => {
        Transforms.setNodes(
          editor,
          {
            width: cell.offsetWidth,
            height: cell.offsetHeight,
          },
          {
            at: [],
            match: n => n.key === cell.dataset.key,
          },
        );
      });
    }
  }, [editor]);
  
  useEffect(() => {
    const { gridTable = [] } = splitedTable(editor, tableNode);
    if (!gridTable.length) return;
    const colsArray = [];
    for (let i = 0; i < gridTable[0]?.length; i++) {
      for (let j = 0; j < gridTable.length; j++) {
        const currCol = gridTable[j][i];
        if (!currCol) continue;

        const td = table.querySelector(
          `[data-key=${currCol.cell.key}]`,
        );

        if (!td) continue;

        if (!colsArray[i]) {
          colsArray[i] = {
            width: 0,
            el: [],
          };
        }

        colsArray[i].width = !colsArray[i].width
          ? td.offsetWidth + td.offsetLeft
          : Math.min(colsArray[i].width, td.offsetWidth + td.offsetLeft);

        if (
          colsArray[i].el.findIndex(
            ({ dataset }) => dataset.key === td.dataset.key,
          ) < 0
        ) {
          colsArray[i].el.push(td);
        }
      }
    }

    for (let i = 1; i < colsArray.length; i++) {
      const leftSumWidth = colsArray
        .slice(0, i)
        .reduce((p, c) => p + c.width, 0);
      colsArray[i].width = colsArray[i]?.width - leftSumWidth;
    }
    setCols(colsArray.filter(item => item.width));
  }, [editor, table, tableNode, refresh]);


  const onDragNew = (e, item, index, table, cols) => {
    
    const changedWidth = e.clientX - startFromX;

    // This method is called very frequently, so changeWidth values used to be very small - Something between -100 and + 100
    // Sometimes, theres a glitch where the value is a big negative number, like -954 or -531. Then we do not want to break the table...
    if (item.el && changedWidth > -300) {
      
      const dragger = ref.current?.querySelector(
        `#horizontal-dragger-item-${index}`,
      )

      if (!dragger) return;
      const draggerWidth = dragger.offsetWidth;
      dragger.style.width = `${draggerWidth + changedWidth}px`;

      const savedChangedWidth = item.el.map(cell => ({
        target: cell,
        width: cell.offsetWidth + changedWidth,  
      }))

      // Change width of the next column
      const nextChangedWith =  index < (cols.length - 1) 
        ? cols[index + 1].el.map(cell => ({
            target: cell,
            width: cell.offsetWidth - changedWidth,  
          })) 
        : [];

      // Apply the changes
      savedChangedWidth.forEach(item => {
        item.target.style.width = `${item.width}px`;
      });
        
      nextChangedWith.forEach(item => {
        item.target.style.width = `${item.width}px`;
      });
        
    }
    startFromX = e.clientX;

  };

  return (
    <div contentEditable={false} className="table-horizontal-toolbar" ref={ref}>
      {cols.map((item, index) => (
        <div
          key={index}
          className="table-dragger-item"
          style={{ width: `${item.width}px` }}
          id={`horizontal-dragger-item-${index}`}
        >
          <div
            className="table-trigger"
            style={{height: tableHeight}}
            draggable
            onMouseDown={e => startFromX = e.clientX}
            onDrag={(e) => {
              onDragNew(e, item, index, table, cols);
            }}
            onDragEnd={() => {
              setRefresh(refresh + 1);
              resizeTable();
              
            }}
          ></div>
        </div>
      ))}
    </div>
  );
};