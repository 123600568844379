import {FormContext, TextInput} from '@startlibs/form'
import {callIfFunction, isMobile} from '@startlibs/utils'
import React, {useContext, useRef} from 'react'
import {formatPrice, priceConstraint} from '../utils/utils'

export const PriceInput = (props) => {

  const form = useContext(FormContext)
  const ref = useRef()

  const onBlur = (e) => {
    setValue(v => formatPrice(v))
    callIfFunction(props.onBlur,e)
  }

  const setValue = (v) => {
    if (props.raw) {
      props.setValue(callIfFunction(v,props.value))
    } else {
      form.setValue(props.path,v)
    }
  }


  return <TextInput
    {...props}
    ref={ref}
    constraint={priceConstraint}
    onBlur={onBlur}
    type={isMobile() ? "number" : "text"}
  />

}