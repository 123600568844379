import React from 'react'

export const DISPLAY = 'DISPLAY'
export const UPLOAD = 'UPLOAD'

export const START = 'START'
export const DEVICE = 'DEVICE'
export const DISK = 'DISK'
export const INSTITUTION = 'INSTITUTION'
export const REVIEW = 'REVIEW'

