import { Icon, Tooltip } from '@startlibs/components';
import { darken } from 'polished';
import { formatDate, getColor, media } from '@startlibs/utils';
import React, {useEffect, useState} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components'
import {DATE_FORMAT} from './config/DateFormat'
import {fetchSuggestionsTags, getTagsFromParam} from '../../utils/tagFetch'
import {getDateValue} from '../../utils/utils'


const ActiveFilters = styled.div `
  margin-bottom: -1.25rem;
  ${media.max(800) `
    margin-bottom: -0.75rem;
  `}
  margin-top: 0.5rem;
  > span {
    margin-top: 0.25rem;
    background: rgba(0,0,0,0.075);
    border-radius: 50px;
    padding: 0 0.5rem;
    margin-left: 0.25rem;
    line-height: 2rem;
    display: inline-block;
  }
  > span:hover {
    background: rgba(0,0,0,0.1);
    cursor: pointer;
  }
  ${props => props.popupIsOpen && `
    opacity: 0.4;
    pointer-events: none;
  `}
  ${Icon} {
    background: rgba(0,0,0,0.075);
    border-radius: 50%;
    width: 18px;
    height: 18px;
    line-height: 18px;
    margin: 0 -0.25rem 0 0.25rem;
    text-align: center;
    display: inline-block;
    vertical-align: -1px;
    :hover {
      background: #dec0c1;
      color: ${getColor('alert')};
    }
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  .tag {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: -8px;
  }
`

export const ActiveAdvancedFilters = ({advancedPopup,params,setParams}) => {

  const patBirthdate = getDateValue('patBirthdate',params)
  const studyDatetimeInit = getDateValue('studyDatetimeInit',params)
  const studyDatetimeEnd = getDateValue('studyDatetimeEnd',params)
  const updatedTimeInit = getDateValue('updatedTimeInit',params)
  const updatedTimeEnd = getDateValue('updatedTimeEnd',params)
  const [suggestionsLoaded,setSuggestionsLoaded] = useState()
  const studyDateSameDay = studyDatetimeEnd && studyDatetimeInit && studyDatetimeInit.getTime() === studyDatetimeEnd.getTime()
  const uploadDateSameDay = updatedTimeEnd && updatedTimeInit && updatedTimeEnd.getTime() === updatedTimeInit.getTime()

  const hasFilters = patBirthdate || studyDatetimeInit || studyDatetimeEnd || updatedTimeInit || updatedTimeEnd || params.idsTagsArray

  const removeKeys = (...keys) => (e) => {
    keys.forEach(key => {
      setParams(_.unset(key))
    })
    e.stopPropagation()
  }

  const removeTag = (id) => (e) => {
    setParams(_.update('idsTagsArray',tags => tags.split(",").filter(idTag => Number(idTag) !== Number(id)).join(",") || undefined))
    e.stopPropagation()
  }

  useEffect(() => {
    if (params.idsTagsArray) {
      fetchSuggestionsTags()
      setSuggestionsLoaded(true)
    }
  },[params.idsTagsArray])

  if (!hasFilters) return null

  return <ActiveFilters popupIsOpen={advancedPopup.isOpen}>
    <b>Filtering:</b>
    {
      !!patBirthdate && <span onClick={advancedPopup.willOpenWith("patBirthdate")}>DOB: {formatDate(patBirthdate,DATE_FORMAT())}<a onClick={removeKeys("patBirthdate")}><Icon icon="x"/></a></span>
    }
    {
      !studyDateSameDay && !!studyDatetimeInit && !!studyDatetimeEnd && <span onClick={advancedPopup.willOpenWith("studyDatetimeInit")}>Study date between: {formatDate(studyDatetimeInit,DATE_FORMAT())} and {formatDate(studyDatetimeEnd,DATE_FORMAT())}<a onClick={removeKeys("studyDatetimeInit","studyDatetimeEnd")}><Icon icon="x"/></a></span>
    }
    {
      !!studyDateSameDay && <span onClick={advancedPopup.willOpenWith("studyDatetimeInit")}>Study date: {formatDate(studyDatetimeInit,DATE_FORMAT())}<a onClick={removeKeys("studyDatetimeInit","studyDatetimeEnd")}><Icon icon="x"/></a></span>
    }
    {
      !studyDatetimeEnd && !!studyDatetimeInit && <span onClick={advancedPopup.willOpenWith("studyDatetimeInit")}>Study date after: {formatDate(studyDatetimeInit,DATE_FORMAT())} <a onClick={removeKeys('studyDatetimeInit')}><Icon icon="x"/></a></span>
    }
    {
      !!studyDatetimeEnd && !studyDatetimeInit &&
      <span onClick={advancedPopup.willOpenWith("studyDatetimeEnd")}>Study date before: {formatDate(studyDatetimeEnd,DATE_FORMAT())}<a onClick={removeKeys('studyDatetimeEnd')}><Icon icon="x"/></a></span>
    }
    {
      !uploadDateSameDay && !!updatedTimeEnd && !!updatedTimeInit && <span onClick={advancedPopup.willOpenWith("updatedTimeInit")}>Uploaded between: {formatDate(updatedTimeInit,DATE_FORMAT())} and {formatDate(updatedTimeEnd,DATE_FORMAT())}<a onClick={removeKeys('updatedTimeEnd','updatedTimeInit')}><Icon icon="x"/></a></span>
    }
    {
      !!uploadDateSameDay && <span onClick={advancedPopup.willOpenWith("updatedTimeInit")}>Uploaded on: {formatDate(updatedTimeInit,DATE_FORMAT())} <a onClick={removeKeys('updatedTimeInit', 'updatedTimeEnd')}><Icon icon="x"/></a></span>
    }
    {
      !updatedTimeEnd && !!updatedTimeInit && <span onClick={advancedPopup.willOpenWith("updatedTimeInit")}>Uploaded after: {formatDate(updatedTimeInit,DATE_FORMAT())} <a onClick={removeKeys('updatedTimeInit')}><Icon icon="x"/></a></span>
    }
    {
      !!updatedTimeEnd && !updatedTimeInit && <span onClick={advancedPopup.willOpenWith("updatedTimeEnd")}>Uploaded before: {formatDate(updatedTimeEnd,DATE_FORMAT())}<a onClick={removeKeys('updatedTimeEnd')}><Icon icon="x"/></a></span>
    }
    {
      getTagsFromParam(params.idsTagsArray).map(tag => <span key={tag.description} onClick={advancedPopup.willOpenWith("tags")}><Tooltip whenEllipsis content={tag.description}><span className="tag fix-safari-alignment">Tag: {tag.description}</span></Tooltip><a onClick={removeTag(tag.idTag)}><Icon icon="x"/></a></span>)
    }
  </ActiveFilters>
}
