import { Button, Icon } from '@startlibs/components';
import {TextInput} from '@startlibs/form'
import React, { useEffect, useState } from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components'
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import {RemoveIcon} from './StyledComponents'
import { FormSlateEditor } from '../../components/slateEditor/FormSlateEditor';

const QuestionContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;
  ${Icon} {
    top: 8px;
    right: .75rem;
  }
  ${TextInput} {
    padding-right: 2.5rem;
  }
`

export const Question = (
  {
    path, i, form, optional, disabled,
    questionKey = "question", placeholder = "Type your question here",
    shouldConfirmDelete = _.identity,
    confirmDeleteTitle ="Confirm question deletion",
    confirmDeleteBody = <p><b>Are you sure you want to delete this question?</b> Notice that at least one written question is needed.</p>,
    className, onMouseDown, onTouchStart
  }) => {

  useEffect(() => {
    // Remove div tags from question. This could be beacuse:
    // 1. A rollback from Updated Version or;
    // 2. Opening a case created with the Updated Version on a cached version (this one)
    // if(form.getValue([...path, i, questionKey]).includes('<div>')){
    //   form.setValue([...path, i, questionKey], form.getValue([...path, i, questionKey]).replace(/<div>/g, "").replace(/<\/div>/g, ""))
    // }
  },[])

  const confirmRemove = () => form.setValue(path, _.pullAt([i]))
  const [focus, setFocus] = useState(false)

  const handleEditorFocus = (event, change) => {
    setFocus(true)
  };

  const confirmDelete = useConfirmDialog(
    <ConfirmDialog
      title={confirmDeleteTitle}
      confirm={<Button alert>Delete</Button>}
      action={confirmRemove}
    >
      {confirmDeleteBody}
      <p>Once confirmed this cannot be undone.</p>
    </ConfirmDialog>
  )

  const removeQuestion = () => {
    if (shouldConfirmDelete(form.getValue([...path, i, questionKey]))) {
      confirmDelete()
    } else {
      confirmRemove()
    }
  }


  return <QuestionContainer
  className={className}
  onMouseDown={onMouseDown}
  onTouchStart={onTouchStart}
  >
    {/* <TextInput
      path={[...path, i, questionKey]}
      placeholder={placeholder}
      textarea
      autoResize
      disabled={disabled}
      minHeight={36}
    /> */}
    <div data-drag={false}>
      <FormSlateEditor
        path={[...path, i, questionKey]}
        placeholder={placeholder}
        disabled={disabled}
        // css="margin-bottom: .5rem;"
        formatTools={[]}
        autoHideToolbar
        minHeight={36}
        onFocus={handleEditorFocus}
        focus={focus}
        singleLineSimpleInput={true}
      />
    </div>
    {!disabled && <RemoveIcon data-drag={false} icon='x' onClick={removeQuestion}/>}
  </QuestionContainer>
}
