import React from 'react'
import styled from 'styled-components/macro'
import {Button} from "@startlibs/components";
import {ConfirmDialog} from "@startlibs/form";
import {formatDate} from "@startlibs/utils";
import {useUIDataSelector} from "../../service/hooks/useUIDataSelector";
import {
  AttachmentBox,
  AttachmentDescription,
  AttachmentDetails,
  AttachmentIcon,
  AttachmentInfoContainer
} from "../AttachmentBoxStyles";
import {Pathology} from "../../enums/RecordClass";
import {CCDA, FormatToLabel} from "../../enums/RecordFormat";
import {useDoAction} from "../../service/hooks/useDoAction";
import {UIAction} from "../../service/UIAction";
import {UploaderAction} from "../../service/UploaderAction";
import {isDicom, isMultiFile} from "../../service/utils";
import {getRecordIcon} from "../../service/utils/recordUtils";
import {dicomDateToDate} from "../../utils";

styled

export const DeleteRecordDialog = () => {
  const record = useUIDataSelector('dialogs.deleteRecord')
  const doAction = useDoAction()
  const closeDialog = () => doAction(UIAction.ToggleDeleteRecordDialog)
  const deleteRecord = () => doAction(UploaderAction.Delete, record)

  return <ConfirmDialog
    closeDialog={closeDialog}
    title="Confirm medical record deletion"
    action={deleteRecord}
    confirm={<Button
      alert>{isMultiFile(record) || isDicom(record) ? "Delete entire medical record" : "Delete record"}</Button>}
    cancelLabel="Cancel"
  >
    <p>You are about to delete the following medical record:</p>
    {
      isDicom(record)
        ? <AttachmentBox css="margin-bottom:1rem;">
          <AttachmentInfoContainer>
            <AttachmentIcon icon="dicom" css="font-size:44px;" className="desktop-only"/>
            <AttachmentDescription
              className="fs-exclude"><b>{record.description} {record.info?.studyDate && `(${formatDate(dicomDateToDate(record.info?.studyDate), 'MM-dd-yyyy')})`}</b></AttachmentDescription>
            <AttachmentDetails
              className="fs-exclude"><b>Modalities: </b>{record.info?.modalities?.join(", ")} ({record.info?.seriesUIDs?.length} series)</AttachmentDetails>
            <AttachmentDetails className="fs-exclude"><b>Patient: </b>{record.info?.patientName}</AttachmentDetails>
          </AttachmentInfoContainer>
        </AttachmentBox>
        : <AttachmentBox css="margin-bottom:1rem;">
          <AttachmentIcon icon={getRecordIcon(record)} className="desktop-only"/>
          <AttachmentInfoContainer>
            <AttachmentDescription className="fs-exclude"><b>{record.description}</b></AttachmentDescription>
            {(record.recordClass === Pathology || record.recordFormat === CCDA) &&
              <AttachmentDetails><b>Format: </b>{FormatToLabel[record.recordFormat] || record.recordFormat}
              </AttachmentDetails>}
          </AttachmentInfoContainer>
        </AttachmentBox>
    }
    <p><b>Are you sure you want to delete it?</b> Once confirmed this cannot be undone.</p>
  </ConfirmDialog>
}