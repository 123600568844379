import React from 'react'
import {willUseSharedCallback} from '@startlibs/core'

export const [useSetConfirmExitPage,listConfirmExitPage] = willUseSharedCallback()
export const confirmExitPage = () => Promise.all(listConfirmExitPage()).then(([first])=>first)
export const confirmAndNavigate = (to,navigate) => (e) => {
  if (e) {
    e.preventDefault()
    e.stopPropagation()
  }
  Promise.all(listConfirmExitPage()).then(() => navigate(to))
}