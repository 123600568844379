import React from 'react'
import styled, {css} from 'styled-components'
import {Icon} from '@startlibs/components'
import {getColor} from '@startlibs/utils'
import {DIGITAL, OFFLINE} from '../../enums/PaymentMethod'
import {MEDICARE, PRIVATE, SELF} from '../../enums/InsuranceType'


export const CheckList = styled.ul`
  li ~ li {
    margin-top: 0.25rem;
  }
  ${Icon} {
    font-size: 14px;
    vertical-align: -2px;
    margin-right: 0.5rem;
  }
`

export const PaymentSectionHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const PaymentConfirmationTag = styled.div`
  background: ${getColor('lightGreen')};
  color: ${getColor('success')};
  border: 1px solid ${getColor('success')};
  padding: .75rem 1rem;
  border-radius: 5px;
  width: auto;
  font-weight: 400;
  display: flex;
  align-items: center;
  max-width: 150px;
  ${Icon} {
    font-size: 20px;
    line-height: 27px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    flex-shrink: 0;
    border: 2px solid ${getColor('success')};
    margin-left: .5rem;
    text-align: center;
  }
  ${props => props.pending && css`
    background: ${getColor('lightYellow')};
    border: 1px solid ${getColor('warning')};
    color: ${getColor('warning')};
    max-width: 240px;
    ${Icon} {
      border: 0px;
      font-size: 32px;
      padding-top: 2px;
    }
  `}
`

export const PAYMENT_TYPE_LABELS = {
  [SELF]: "Self pay",
  [MEDICARE]: "Medicare",
  [PRIVATE]: "Insurance",
}

export const PAYMENT_METHOD_LABELS = {
  [DIGITAL]: "Paid digitally (credit card)",
  [OFFLINE]: "Offline",
}