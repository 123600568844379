import React from 'react'
import styled from 'styled-components'
import {Icon} from '@startlibs/components'
import _ from 'lodash/fp'
import {Clinical} from '../enums/RecordClass'


export const FileType = styled.div`
  white-space: nowrap;
  text-transform: uppercase;
  ${Icon} {
    font-size: 26px;
    line-height: 1;
    vertical-align: -7px;
    margin-right: 0.5rem;
    min-width: 20px;
    display: inline-block;
    text-align: center;
  }
`

export const FILE_TYPES = {
  "pdf":"pdf",
  "heic":'image-line',
  "jpg":'image-line',
  "jpeg":'image-line',
  "png":'image-line',
  "gif":'image-line',
  "bmp":'image-line',
  "webp":'image-line',
  "webm":'video',
  "mpeg":'video',
  "avi":'video',
  "ogg":'video',
  "mpg":'video',
  "mp4":'video',
  "mov":'video',
  "m4a":'video',
  "wmv":'video',
  "ogv":'video',
  "zip":'zip',
  "gz":'zip',
  "xz":'zip',
  "bz":'zip',
  "bz2":'zip',
  "7z":'zip',
  "s7z":'zip',
  "rar":'zip',
  "doc":'note',
  "docx":'note',
  'rtf':'note',
  'odt':'note',
  'tif':'pathology',
  'tiff':'pathology',
  'bif':'pathology',
  'scn':'pathology',
  'svslide':'pathology',
  'svs':'pathology',
  'vms':'pathology',
  'mvu':'pathology',
  'isyntax':'pathology',
  'ndpi':'pathology',
  'txt':'txt',
  'xml':'xml'
}

export const getFileTypeIcon = (fileExt) => {
  const icon = FILE_TYPES[(fileExt || "").toLowerCase()]
  return icon || 'file'
}

export const getFileType = (attachment) => {
  return attachment.fileExt.slice(0,7).toUpperCase()
}
