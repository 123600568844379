import React, {useRef} from 'react'
import {FlipList} from '@startlibs/components'
import {oneWayAnimation} from '@startlibs/utils'

const DEFAULT_ANIMATION = oneWayAnimation(`opacity: 1;`, `opacity: 0;`)

export const TransitionDiv = ({children,animation,...rest}) => {

  return children
    ? <FlipList animation={animation || DEFAULT_ANIMATION} {...rest}>{[<div key="first">{children}</div>]}</FlipList>
    : <FlipList animation={animation || DEFAULT_ANIMATION} {...rest}>{[]}</FlipList>
}