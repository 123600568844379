import React from 'react'

// export const PATIENT = 'PATIENT'
// // export const ADMIN = 'ADMIN'
// export const CA = 'CA'
// export const POC = 'POC'
// // export const SERVICE = 'SERVICE'

// export const UserRoles = [PATIENT/*, ADMIN*/, CA, POC/*, SERVICE*/]

export const PATIENT = 'PATIENT'
export const ADMIN = 'ADMIN'
export const EXPERT = 'EXPERT'
export const PROVIDER = 'PROVIDER'

 export const UserRoles = [PATIENT, ADMIN, EXPERT, PROVIDER]