import React, {useEffect, useState} from 'react'
import {RichText, useFormValue} from "@startlibs/form";
import {withReact} from "slate-react";
import {createEditor, Editor, Range, Transforms} from "slate";
import {withHistory} from 'slate-history'
import {checkNormalization, deserialize, hasContent, hasText, serialize} from "./slateSerializer";
import {SLATE_EMPTY_VALUE, SlateEditor} from "./SlateEditor";
import styled from 'styled-components';
import { customTheme } from '@startlibs/utils';
import { RTF_TOOLBAR_OPTIONS } from '../../utils/utils';
import { withHtml, withImages, withPhrases, withSimpleInput, withTable } from './SlateWrappers';
import _ from 'lodash/fp';

const Label = styled.label`
  font-weight: 500;
  display: inline-block;
  font-size: ${props => props.labelFontSize ? props.labelFontSize : '14'}px;
  margin-bottom: .5rem;
  ${props => props.isOnImage && 'color: #fff;'}
  ${customTheme("FieldLabel")}
`
const HelpText = styled.span`
  color: rgba(0,0,0,0.4);
  font-weight: 400;
`
const FieldDescription = styled.span`
  color: rgba(0,0,0,0.4);
  display: block;
  font-size: 12px;
  margin: 0.25rem 0 0;
  font-weight: 400;
`
const BellowFieldDescription = styled(FieldDescription)`
  margin: 0.5rem 0 0;
`

export const FormSlateEditor = ({path, useHtml = false, label, helpText, descText, bellowDescText, disabled, placeholder, form, requestId = 0, singleLineSimpleInput = false, ...props}) => {

  const [formValue, setFormValue] = useFormValue(path);
  const [value, setValue] = useState(() => [])
  const [editor, setEditor] = useState()
  const [labelClick, setLabelClick] = useState(false)
  const [isEvaluating, setIsEvaluating] = useState(true)
  const [normalizationSuccess, setNormalizationSuccess] = useState(false)
  const editorParams = {
    formatTools: props.formatTools,
    requestId: requestId
  }
  useEffect(() => {
    const v = formValue ? deserialize(formValue) : SLATE_EMPTY_VALUE
    // console.log("v", v)
    setValue(hasText(v) ? v : SLATE_EMPTY_VALUE)
    const editor = singleLineSimpleInput 
      ? _.flow(
          withHistory,
          withReact,
          withPhrases,
          withSimpleInput
        )(createEditor())
      : useHtml 
        ? _.flow(
            withHistory,
            withReact,
            withTable,
            (editor) => withHtml(editor, editorParams),
            withPhrases
          )(createEditor())
        :  _.flow(
            withHistory,
            withReact,
            withTable,
            (editor) => withImages(editor, editorParams),
            withPhrases
          )(createEditor())
      
    const { normalizeNode } = editor
    editor.normalizeNode = entry => {
      const [node, path] = entry
      normalizeNode(entry)
    }
    const {isInline} = editor
    editor.isInline = element => {
      return element.type === 'link' ? true : isInline(element)
    }
    setEditor(editor)
  // }, [path])
  }, [])

  useEffect(() => {
    if(editor){
      if(editor.children.length > 0){
        editor.normalizeNode([editor, []])
        try{
          // to ensure the text is normalized correctly, we will pass a copy by value and modify it if it is breaking a rule.
          const normTest = JSON.parse(JSON.stringify(value));
          const normalization = checkNormalization(normTest)
          // We will set normalization success if: our deserialization is equals to 1- the editor autonormalization
          // and also 2- the value returned by the checkNormalization function
          setNormalizationSuccess(
            JSON.stringify(value) == JSON.stringify(editor.children) &&
            JSON.stringify(editor.children) == JSON.stringify(normalization) &&
            JSON.stringify(value) == JSON.stringify(normalization)
          )
          setIsEvaluating(false)
        }catch(e){
          setNormalizationSuccess(value === editor.children)
          setIsEvaluating(false)
        }
      }
    }
  }, [editor])

  return  (isEvaluating || normalizationSuccess) ? 
    <>
      {label && <Label onClick={() => setLabelClick(Date.now())}>
        {label}
        {helpText && <HelpText> {helpText}</HelpText>}
        {descText && <FieldDescription>{descText}</FieldDescription>}
      </Label>}
      
      
      <SlateEditor editor={editor} value={value} setValue={setFormValue} labelClick={labelClick} 
        isEvaluating={isEvaluating} setIsEvaluating={setIsEvaluating} 
        normalizationSuccess={normalizationSuccess} setNormalizationSuccess={setNormalizationSuccess}
        disabled={disabled} placeholder={placeholder} requestId={requestId}
        {...props}/>
      {bellowDescText && <BellowFieldDescription>{bellowDescText}</BellowFieldDescription>}
      
    </>
   : 
    <RichText
      formatTools={RTF_TOOLBAR_OPTIONS}
      label={label}
      helpText={helpText}
      // inputRef={rtRef}
      path={path}
      descText={descText}
      disabled={disabled}
      placeholder={placeholder}
    />

}