import { Icon } from '@startlibs/components';
import { getColor } from '@startlibs/utils';
import {useToggle} from '@startlibs/core'
import React from 'react'
import styled from 'styled-components';
import {EditLayoutDialog} from '../EditLayoutDialog'

export const ReportTextButton = styled.a`
  color: ${getColor('gray150')};
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  position: relative;
  ${Icon} {
    font-size: 16px;
    margin-right: .25rem;
    &.context-icon {
      font-size: 13px;
      margin-left: .25rem;
    }
  }
`

export const EditLayoutButton = ({caseRequest, setCaseRequest}) => {

  const editLayout = useToggle()

  return <>
    <ReportTextButton onClick={editLayout.open}>
      <Icon icon="edit-box"/> Layout preferences
    </ReportTextButton>
    {
      editLayout.isOpen &&
      <EditLayoutDialog caseRequest={caseRequest} closeDialog={editLayout.close} setCaseRequest={setCaseRequest}/>
    }
  </>

}
