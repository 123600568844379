import { Button, Icon, Popup } from '@startlibs/components';
import { darken } from 'polished';
import { getColor, media, oneWayAnimation } from '@startlibs/utils';
import { usePopupToggle } from '@startlibs/core';
import React from 'react';
import styled, {css} from 'styled-components';

export const PageContainer = styled.div`
  padding: 2rem 1.5rem 90px;
  margin: 0 auto;
  position: relative;
  min-width: 300px;
  max-width: 105rem;
  min-height: 100vh;
  ${props => props.fullWidth && `
    max-width: 100%;
  `}
  ${media.max(739)`
    padding-left: 1rem;
    padding-right: 1rem;
  `}
  ${media.max(520)`
    padding-top: 6.25rem;
  `}
  h3 {
    font-weight: 600;
  }
  p {
    font-size: 13px;
  }
`

export const SectionHeading = styled.div `
  margin-bottom: 1rem;
  h3 {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
`

export const Card = styled.div`
  padding: 2.0rem;
  background: white;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin: 0 auto;
  & ~ ${SectionHeading} {
    margin-top: 3rem;
  }
  ${media.max(520)`
    padding: 1.5rem;
  `}
`

const fadeInUp = oneWayAnimation('transform: translateY(10px); pointer-events: none; opacity: 0;', 'transform: translateY(0); pointer-events: none; opacity: 1;')