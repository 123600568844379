import {getColor} from '@startlibs/utils';
import React from 'react'
import styled from 'styled-components'
import {MEDICARE, OTHER, PRIVATE} from '../../../enums/InsuranceType'
import {safeNewLineToDoubleBr} from '../../../utils/utils'

const InsuranceDetailsBox = styled.div`
  box-shadow: 0 0 0 1px ${getColor('gray210')};
  padding: 1rem 1.25rem;
  background: white;
  border-radius: 6px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: rgba(0,0,0,0.6);
  margin-bottom: 1rem;
  :last-child {
    margin-bottom: 0;
  }
  .title {
    font-size: 13px;
    color: rgba(0,0,0,0.9);
    font-weight: 600;
  }
  .detail {
    margin-top: 0.25rem;
    span + span {
      margin-left: 1.5rem;
    }
  }
`

export const InsuranceBox = ({insurance}) => <>
  {
    insurance.type === MEDICARE &&
    <InsuranceDetailsBox>
      <div>
        <div className="title">Medicare</div>
        <div className="detail">{insurance.code}</div>
      </div>
    </InsuranceDetailsBox>
  }
  {
    insurance.type === PRIVATE &&
    <InsuranceDetailsBox>
      <div>
        <div className="title">Private insurance</div>
        <div className="detail">
          <span><b>Carrier: </b>{insurance.insuranceCarrier}</span>
          {insurance.insurancedName && <span><b>Name: </b>{insurance.insurancedName}</span>}
        </div>
        <div className="detail">
          <span><b>Member #: </b>{insurance.memberNumber}</span>
          <span><b>Group #: </b>{insurance.groupNumber}</span>
        </div>
      </div>
    </InsuranceDetailsBox>
  }
  {
    insurance.type === OTHER &&
    <InsuranceDetailsBox>
      <div>
        <div className="title">Other</div>
        <div className="detail" dangerouslySetInnerHTML={{__html:safeNewLineToDoubleBr(insurance.info)}}/>
      </div>
    </InsuranceDetailsBox>
  }

</>
