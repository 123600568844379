import React from 'react'
import {useIntl} from 'react-intl'

export const IfLocale = ({children,contains,not}) =>{
  const isLocale = useIsLocale({contains,not})
  return isLocale ? children : null
}

export const useIsLocale = ({contains,not}) => {
  const intl = useIntl()
  const matches = intl.locale.indexOf(contains) >= 0
  return (not ? !matches : matches)
}