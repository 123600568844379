import React, {useEffect} from 'react'


export const useConfirmExit = (form) => {

  useEffect(() => {
    const onUnload = function (e) {
      if (!form || !form.current || !form.current.hasChanged) {
        return;
      }
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = '';
    }
    window.addEventListener('beforeunload', onUnload);
    return () => window.removeEventListener('beforeunload', onUnload)
  },[])
}