import React, {useEffect} from 'react'
import {willUseSharedState} from '@startlibs/core'
import {FileNotFound} from '../../enums/FileState'

const [useSharedState,,setState] = willUseSharedState()

export const useFilesNotFoundRetry = () => {
  const [retries] = useSharedState([])
  return retries.length ? () => {
    retries.map(retry => retry())
    setState([])
  } : false
}

export const addToRetryFilesNotfound = (fn) => setState((list) => [...list,fn])
export const removeRetryFilesNotfound = (fn) => setState((list) => list.filter(fnitem => fnitem !== fn))

export const useAddRetryFilesNotFound = (test,retry) => {
  useEffect(() => {
    if (test) {
      addToRetryFilesNotfound(retry)
      return () => removeRetryFilesNotfound(retry)
    }
  },[test])
}
