import { Button, Loading } from '@startlibs/components';
import {TextInput} from '@startlibs/form'
import {addQueryString} from '@startlibs/utils'
import {useConstant, usePrevious, useToggle} from '@startlibs/core'
import React, {useEffect, useState} from 'react'
import _ from 'lodash/fp'
import {AuditFilterInputs, auditUtils} from './AdvancedSearchPopup';
import {ConfirmDialog, useConfirmDialog, useConfirmDialogWithProps} from '../../../hooks/useConfirmDialog'
import {InfoBox} from '../../../components/InfoBox'
import {downloadFile} from '../../../utils/downloadFile'
import {formatDateParams} from './utils'
import {getJwt} from '../../../hooks/useJwt'
import { jwtGetFetcher } from '../../../utils/authFetch';
import {getTimezoneOffset} from '../../../utils/utils'

const removeEmpty = _.pickBy(_.identity)

export const useCsvAuditDialog = (params,events, getResult) => {

  const action = (params) => new Promise((res) => {
    downloadFile(addQueryString('/api/admin/searchAudits', {
      ...removeEmpty(formatDateParams(params)),
      csv: true,
      timezoneOffset:getTimezoneOffset(),
      t: getJwt()
    }))
    setTimeout(res, 1500)
  })

  const [counterParams, setCounterParams] = useState(auditUtils.getValuesFromParams(params,events))
  const [count, setCount] = useState(getResult()?.count)

  const debounceSetParams = useConstant(_.debounce(200, setCounterParams))

  const toggle = useConfirmDialogWithProps(() => <ConfirmDialog
    values={auditUtils.getValuesFromParams(params,events)}
    transform={auditUtils.transform}
    preValidation={auditUtils.preValidation}
    onChange={(v, v2) => debounceSetParams(v2)}
    title="Export audit log to CSV file"
    notify="Audit log exported."
    action={action}
    confirm={<Button disabled={count===0} highlight>Export to CSV</Button>}
  >
    <p>Please review the filters and date range below before exporting:</p>
    <TextInput
      path="search"
      label="Filtering by text string"
      placeholder="Search"
    />
    <AuditFilterInputs/>
    <Counter params={counterParams} count={count} setCount={setCount}/>
  </ConfirmDialog>, [counterParams,count])

  useEffect(() => {
    setCount(getResult()?.count)
    setCounterParams(auditUtils.getValuesFromParams(params,events))
  },[toggle.isOpen])

  return toggle.open
}

const Counter = ({params, count, setCount}) => {
  const previousParams = usePrevious(params)
  const loading = useToggle()

  const loadCount = () => loading.wait(
    jwtGetFetcher(getJwt())('/api/admin/searchAudits', {
      ...removeEmpty(formatDateParams(auditUtils.transform(params))),
      rows: 1
    })
      .then(({count}) => setCount(count))
  )

  React.useEffect(() => {
    if (!_.isNumber(count) && params) {
      loadCount()
    } else if (previousParams) {
      loadCount()
    }
  }, [params])
  if (!_.isNumber(count)) {
    return null
  }
  return <div>
    {loading.isOpen ?
      <InfoBox lightBlue css="padding: 7px 0 8px;">
        <Loading css="margin: 0 auto;"/>
      </InfoBox>
    : (count > 0 ?
      <InfoBox lightBlue>You filter returned <strong>{count} entries</strong> that will be part of the exported CSV.</InfoBox>
      : <InfoBox lightYellow>You filter returned <strong>no entries</strong>, please review the filtering  before exporting.</InfoBox>
    )}
  </div>
}
