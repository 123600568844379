import {Button, ConfirmDialog, DefaultConfirmDialog} from '@startlibs/components'
import React from 'react'
import {i18nDateMessages} from './i18nDateMessages'

export default {
  dialogs: {
    'submit-draft': ({submit, cancel, isLoading, discard}) =>
      <ConfirmDialog
        title="Confirm changes made in this step"
        closeDialog={cancel}
        onConfirm={submit}
        footer={<>
          <Button onClick={cancel}>Cancel</Button>
          <Button hover="alert" onClick={discard}>Discard changes</Button>
          <Button autoFocus success isLoading={isLoading} onClick={submit}>Save changes</Button>
        </>}
      >
        <p>You are about to navigate to a different step.</p>
        <p>Would you like to keep the changes made in the current step or to discard them?</p>
      </ConfirmDialog>,
    'discard-expert-edit': ({submit, cancel, isLoading, discard}) =>
      <ConfirmDialog
        css={`max-width: 50rem;`}
        title="Changes to this profile were not saved yet"
        closeDialog={cancel}
        onConfirm={submit}
        footer={<>
          <Button onClick={cancel}>Cancel</Button>
          <Button hover="alert" onClick={discard}>Discard changes</Button>
          <Button autoFocus success isLoading={isLoading} onClick={submit}>Save changes</Button>
        </>}
      >
        <p>You are about to leave this page but its contents were not saved yet. </p>
        <p>How would you like to proceed?</p>
      </ConfirmDialog>,
    'discard-expert-create': ({submit, cancel, isLoading, discard}) =>
      <ConfirmDialog
        css={`max-width: 50rem;`}
        title="Changes to this profile were not saved yet"
        closeDialog={cancel}
        onConfirm={submit}
        footer={<>
          <Button onClick={cancel}>Cancel</Button>
          <Button hover="alert" onClick={discard}>Discard expert</Button>
          <Button autoFocus success isLoading={isLoading} onClick={submit}>Save and add expert</Button>
        </>}
      >
        <p>You are about to leave this page but its contents were not saved yet. </p>
        <p>How would you like to proceed?</p>
      </ConfirmDialog>,
    'discard-remote-log': ({submit, cancel, isLoading, discard}) =>
      <ConfirmDialog
        title="Confirm discarding changes"
        closeDialog={cancel}
        onConfirm={submit}
        footer={<>
          <Button onClick={cancel}>Cancel</Button>
          <Button hover="success" isLoading={isLoading} onClick={submit}>Save changes</Button>
          <Button alert autoFocus onClick={discard}>Discard changes</Button>
        </>}
      >
        <p>You are about to discard changes made to this consultation.</p>
        <p>Would you like to keep the changes or to discard them?</p>
      </ConfirmDialog>,
    'discard-draft': ({submit, cancel, isLoading, discard}) => <DefaultConfirmDialog
      onCancel={cancel}
      onConfirm={discard}
      isLoading={isLoading}
      onSaveInstead={submit}
    />
  },
  confirmDialogLabels: {
    title: 'Your changes will not be saved',
    content: 'The changes made in this screen were not saved. Would you like to quit anyway?',
    confirm: 'Save changes',
    discard: 'Discard changes',
    cancel: 'Cancel'
  },
  labels: {
    EditableBox: {
      save: 'Save',
      saveNew: 'Save',
      cancel: 'Cancel'
    },
    RichText: {
      linkTextError: 'Text can not be empty',
      linkUrlError: 'Please check the URL',
      popupLinkText: 'Text to display',
      popupLinkUrl: 'Url',
      popupRemove: 'Remove link',
      popupCancel:'Cancel',
      popupConfirm:'Apply',
    }
  },
  FormUploadingImageError: 'The image is being uploaded, please wait a moment.',
  ErrorDialog: {
    title: 'This content could not be loaded',
    content: <React.Fragment>Please refresh the page and try again. <br/>If the problem persists, contact
      us.</React.Fragment>,
    close: 'Close'
  },
  dateMessages: i18nDateMessages,
  fetchRoutes: []
}
