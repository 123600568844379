import {Button} from '@startlibs/components'
import {ErrorsContext} from '@startlibs/form'
import { callIfFunction } from '@startlibs/utils';
import {useToggle} from '@startlibs/core'
import React, {useContext} from 'react'
import {FileInputBoxStyle, getFileList, useDropFileEvents, ProgressBar} from 'uploader-frontend-legacy/src'
import {getJwt} from '../../hooks/useJwt'

function futch(url, opts = {}, onProgress) {
  var xhr = new XMLHttpRequest();
  return [xhr, new Promise((res, rej) => {

    xhr.open(opts.method || 'get', url);
    for (var k in opts.headers || {})
      xhr.setRequestHeader(k, opts.headers[k]);
    xhr.onload = e => {
      if (e.target.status >= 400) {
        rej(e.target)
      } else {
        res(e.target.responseText,e.target.status);
      }
    }
    xhr.onerror = rej;
    xhr.onabort = rej;
    if (xhr.upload && onProgress)
      xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
    xhr.send(opts.body);
  })];
}
const validateFileExtension = (f) => (/.pdf$/i).test(f.name)

export const ExternalReportUpload = ({url,onSuccess,isUploading}) => {

  const progress = useToggle(0)
  const uploading = useToggle()
  const errors = useContext(ErrorsContext)

  const addFile = (e) => {
    errors.clearErrors()
    getFileList(e)
      .finally(isDraggingOver.close)
      .then((files) => {
        const file = files[0]
        if (!file) {
          return Promise.reject({})
        }
        if (!validateFileExtension(file)) {
          errors.setError("file","Invalid file format")
          return Promise.reject({})
        }
        const formData = new FormData()
        formData.append('file', file)
        const [xhr, result] = futch(url, {
            headers: {'Authorization': `Bearer ${getJwt()}`},
            method: "POST",
            body: formData
          },
          (e) => progress.openWith(Math.round((e.loaded / e.total) * 100))
        )
        uploading.openWith({filename: file.name, xhr})
        isUploading?.open()
        return result
      })
      .then((result) => {
        uploading.close()
        isUploading?.close()
        progress.openWith(0)
        callIfFunction(onSuccess)
      })
      .catch((error) => {
        uploading.close()
        isUploading?.close()
        progress.openWith(0)
        if (error?.status === 413) {
          errors.setError('upload','File size too large')
        } else if (error?.type !== "abort") {
          errors.setError('upload','An error has occurred while uploading')
        }
      })

  }

  const [isDraggingOver, dragBoxRef] = useDropFileEvents(addFile)

  return <FileInputBoxStyle
    draggingOver={isDraggingOver.isOpen}
    uploading={uploading.isOpen}
    ref={dragBoxRef}
  >
    {
      uploading.isOpen
        ? <div>
          <p css="margin-bottom:0.5rem"><b>Uploading report file:</b> {uploading.get().filename}</p>
          <ProgressBar progress={progress.isOpen}/>
          <Button small alpha css="margin-top:1rem" onClick={() => uploading.get().xhr.abort()}>Cancel</Button>
        </div>
        : <div>
        {isDraggingOver.isOpen ?
        <>
          <b>Drop files heres to upload them</b>
        </>
        :
        <>
          <Button.a highlight small css="margin-bottom:0.5rem;"><input
            type="file"
            onChange={addFile}
          />
            Select file
          </Button.a>
          <div>Or drag and drop the PDF into this page</div>
        </>}
        </div>
    }
  </FileInputBoxStyle>

}
