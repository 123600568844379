import React, {useRef} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {ConfirmDialog, useConfirmDialog} from "@startlibs/form";
import {Button} from "@startlibs/components";
import {useDoAction} from "../../service/hooks/useDoAction";
import {GroupAction} from "../../service/GroupAction";

styled

export const useUngroup = (group) => {
  const ungroupRef = useRef()
  const doAction = useDoAction()

  const confirmUngroup = useConfirmDialog(<ConfirmDialog
    title={`Ungroup records`}
    action={() => doAction(GroupAction.Ungroup,group)}
    confirm={<Button alert>Proceed</Button>}
    cancelLabel="Cancel"
    css="max-width: 42rem;"
  >
    <p>
      Any records in this group will be separated and moved to the top of the records list.
    </p>
    <p>Would you like to proceed?</p>
  </ConfirmDialog>)

  const ungroup = () => {
    if (!group.items.length) {
      doAction(GroupAction.Ungroup,group)
    } else {
      confirmUngroup()
    }
  }

  return [ungroup,ungroupRef]
}