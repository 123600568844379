import {Errors, TextInput, WithForm} from '@startlibs/form'
import {formatDate, formFetchWithResponse, getFetcher} from '@startlibs/utils'
import {useToggle} from '@startlibs/core';
import React from 'react'
import _ from 'lodash/fp'
import {CardActionButton, CardHeader, LinkSSO, SignInCard, SignInLayout} from '../components/SigninLayout';
import {EmailVerificationPage} from '../patient/status/EmailVerificationPage'
import {buildValidation, required, responseFailure} from '../utils/validation'
import {lazyProviderInfo} from '../components/WithProvider'
import {wrapPromise} from '../hooks/useSuspense'
import { PurviewFooter } from '../components/PurviewFooter';

const transformDoB = _.update('dob', (dob) => {
  return formatDate(dob, 'MM-dd-yyyy')
})

export const ACCESS_ERROR = 'Invalid email'

const DEFAULT_VALUES = {login:''}

const lazyPasSystem = wrapPromise(() => getFetcher("/pasapi/systemref",null,{referrer:'/auth/'}))

export const AdminLogin = ({navigate, setCaseRequest, isNurse}) => {

  const emailVerificationPage = useToggle()
  const pasSystem = lazyPasSystem.read()
  const providerInfo = {logoURL:pasSystem.systemWebHooks.systemLogoWebHook}


  const preValidation = buildValidation({login: [required]})

  const onSuccess = (req, [{jwt, url}, {status}]) => {
    if (url) {
      window.location = url
      return new Promise(() => {})
    } else if (status === 256) {
      emailVerificationPage.open()
    }
  }

  const onFailure = responseFailure((data, {status}) =>
    (status === 401 && {
      'login': ACCESS_ERROR,
      'dob': ACCESS_ERROR
    }) ||
    (status === 403 && {
      'login': ['This user is suspended. Please contact an administrator.']
    }) ||
    (status === 404 && {'login': [ACCESS_ERROR]})
  )

  if (emailVerificationPage.isOpen) {
    return <EmailVerificationPage providerInfo={providerInfo}/>
  }

  return <><SignInLayout providerInfo={providerInfo}>
    <SignInCard>
      <CardHeader>
        <h1>Expert View Admin</h1>
        <p css="max-width:22rem;margin:1rem auto 1rem;">
          Enter your details below.
        </p>
      </CardHeader>
      <WithForm
        alwaysSave
        transform={transformDoB}
        preValidation={preValidation}
        onSuccess={onSuccess}
        onFailure={onFailure}
        values={DEFAULT_VALUES}
        action={formFetchWithResponse('/api/admin/authenticate')}
      >{form => <>
        <TextInput
          autoFocus
          name="username"
          autoComplete="username"
          label="Your email address"
          placeholder="Ex: username@email.com"
          path="login"
        />
        <Errors/>
        <CardActionButton highlight isLoading={form.isLoading} type="submit">Access Expert View Admin</CardActionButton>
        {pasSystem.ssoEntities?.map(sso => <LinkSSO href={sso.url}>{sso.label || "Use single sign-on (SSO) instead"}</LinkSSO>)}
        </>}</WithForm>
    </SignInCard>
  </SignInLayout>
  <PurviewFooter />
  </>
}
