import React from 'react'

const isIE = 'ActiveXObject' in window

export const checkCCDAXSLT = async xmlStr => {
  if (isIE) {
    const source = new window.ActiveXObject('Msxml2.DOMDocument.3.0')
    source.async = false
    source.loadXML(xmlStr)
    const stylesheet = new window.ActiveXObject('Msxml2.DOMDocument.3.0')
    stylesheet.async = false
    stylesheet.load('cda.xsl')
    const result = source.transformNode(stylesheet)
    const transformedNode = new DOMParser().parseFromString(result, 'text/xml')
    return !!transformedNode.childNodes.length
  }

  const processor = new XSLTProcessor()
  const xmlDoc = new DOMParser().parseFromString(xmlStr, 'text/xml')
  return fetch(process.env.NODE_ENV === 'development' ? '/public/cda.xsl' : "/public/cda.xsl")
    .then(e => e.text())
    .then(text => new DOMParser().parseFromString(text, 'text/xml'))
    .then(xsl => {
      if (typeof processor.transformDocument === 'function') {
        // obsolete Mozilla interface
        const resultDoc = document.implementation.createDocument('', '', null)
        const transformedNode = processor.transformDocument(xmlDoc, xsl, resultDoc, null)
        return !!transformedNode.childNodes.length
      }
      processor.importStylesheet(xsl)
      const transformedNode = processor.transformToFragment(xmlDoc, document)
      return !!transformedNode.childNodes.length
    })
}
