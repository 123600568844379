import {_s, getColor} from '@startlibs/utils';
import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';
import { NoteInfoPanel } from './NoteInfoPanel';

export const EmptyNotesText = styled.div`
  color: ${getColor('gray150')};
  font-size: 13px;
  text-align: center;
  margin-top: 1rem;
  border-radius: 6px;
  padding: 3rem 1rem;
  background: ${getColor('gray240')};
`

export const CaseNotesInformationPanel = ({caseRequest, caseNotes, setCaseNotes, formRef}) => {

  return <>
    {caseNotes.length === 0 && <EmptyNotesText>No notes have been added to case</EmptyNotesText>}
    {caseNotes.map((note, i) => 
      <NoteInfoPanel
        caseRequest={caseRequest}
        caseNotes={caseNotes} 
        setCaseNotes={setCaseNotes}
        formRef={formRef}
        i={i} 
        key={note.id}
        note={note}
      />
    )}
  </>

}

