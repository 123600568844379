import React from 'react'
import { useIntl } from 'react-intl'
import {hasAcceptedAllTerms, lazyTerms} from '../../../patient/utils/caseRequestUtils'
import {isPaymentPending} from '../../../request/utils'
import {PendingBox} from '../hooks/useNotifyClosedCase'

export const PendingInfo = ({caseRequest, hideServiceTerms = false}) => {
  

  const intl = useIntl()
  var acceptedTerms = ''
  if(hideServiceTerms){
    acceptedTerms = true
  }else{
    acceptedTerms = hasAcceptedAllTerms(lazyTerms.read(),caseRequest)
  }

  const hasNoPaidPayment = !caseRequest.payments.find(payment => payment.paid)
  const hasAnyPendingPayment = caseRequest.payments.find(isPaymentPending)

  return (hasNoPaidPayment || hasAnyPendingPayment || !acceptedTerms) && intl.locale.indexOf("LEGAL") < 0 &&
    <PendingBox lightYellow>
      <ul>
        {hasAnyPendingPayment ? <li>There is a payment due for this case. </li>
          : hasNoPaidPayment ? <li>No payment has been made. </li>
            : null }
        {(!acceptedTerms && !hideServiceTerms )&&
        <li>The service terms have not been signed.  </li>
        }
      </ul>
      Would you still like to proceed?
    </PendingBox>
}