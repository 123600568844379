import { ContextMenu, Icon, Li, Loading, Tooltip, Button } from '@startlibs/components';
import { ToggleCheckbox, RadioboxGroup, TabRadiobox, TextInput } from '@startlibs/form'
import {
  addQueryString, callIfFunction, formatDate,
  getColor,
  getFetcher,
  media,
  postFetcher,
  isMobile, smoothScroll
} from '@startlibs/utils'

import {jwtGetFetcher } from '../../utils/authFetch'
import { useConstant, Slot, usePopupToggle, useToggle, Fill, useRefState } from '@startlibs/core'
import React, { Suspense, useEffect, useState, useMemo, useImperativeHandle, useRef } from 'react'
import _ from 'lodash/fp'
import styled, { css } from 'styled-components'

import { ActiveAdvancedFilters } from './ActiveAdvancedFilters'
import { AdvancedSearchPopup } from './AdvancedSearchPopup'
import { Pagination } from '../Pagination'
import { SectionHeading } from '../PageLayout';
import { Table } from './Table';
import { willUseSuspense } from '../hooks/useSuspense'
import { callUpdateParams, updateParams, useUpdateParams } from '../hooks/useUpdateParams'
import { getDate, getDateYear, } from '../../utils/utils'

import {
  isPastYearToggleDisabled,
  isUsingStudyPastYear,
  isUsingUploadedPastYear,
  useRegisterIsOrderingBy
} from '../hooks/useIsOrderingBy'
import { fetchSuggestionsTags, getExistingTags, setTags } from '../../utils/tagFetch';
import { isDev } from '../../utils/isDev';

let authenticated = process.env.NODE_ENV !== 'development'


const WorklistDialogFooter = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  margin-top: auto;
`

const WorklistPageContainer = styled.div`
  position: relative;
  ${props => !props.isMobile && css`
    min-width: ${props => props.visibleColumnsAmount > 8 ? '100%' : '100%'};
    max-width: ${props => props.visibleColumnsAmount > 8 ? '100%' : '100%'};
  `}
  ${props => props.isMobile && css`
    min-width: 300px;
  `}
  p {
    font-size: 13px;
  }
`

const WorklistCard = styled.div`
  & ~ ${SectionHeading} {
    margin-top: 1rem;
  }
  ${media.desktop`
    background: white;
    margin: 0 auto;
  `}
`

const WorklistSectionHeading = styled(SectionHeading)`
  ${media.mobile`
    padding-left: 1rem;
  `}
  ${props => props.isMobile && css`
    padding-left: 1rem;
  `}
`

const WorklistHeaderComponent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  z-index: 30;
  .right-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
  }
  ${media.max(700)`
    flex-wrap: wrap;
    .right-wrapper {
      flex-grow: 1;
      flex-wrap: wrap;
      flex-direction: column-reverse;
      align-items: flex-end;
      flex-basis: 560px;
      max-width: 560px;
      ${RadioboxGroup} {
        margin-bottom: 1rem;
      }
    }
  `}
  ${media.min(701)`
    ${RadioboxGroup} {
      position: sticky;
      right: 2.0rem;
      max-width: 560px;
      margin-left: 1rem;
    }
  `}
  ${media.max(700)`
    display: block;
    .right-wrapper {
      padding-top: 1.5rem;
      margin-bottom: -0.5rem;
      flex-wrap: nowrap;
      flex-direction: row;
      ${RadioboxGroup} {
        width: auto;
        flex-basis: 0;
        flex-grow: 1;
        margin-bottom: 0;
      }
    }
  `}
  ${media.max(700)`
    .right-wrapper {
      flex-basis: 100%;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: stretch;
      ${RadioboxGroup} {
        flex-basis: auto;
      }
    }
  `}
  ${media.mobile`
    padding: 0 1rem;
  `}
`

const PastYearCheckbox = styled(ToggleCheckbox)`
  margin-bottom: 0;
  margin-left: 1rem;
  white-space: nowrap;
  min-width: 195px;
  ${props => props.disabled && css`
    color: ${getColor('gray150')};
  `}
  ${media.max(700)`
    margin: 0 1rem 0 0;
  `}
  ${media.max(601)`
    padding: 13px 1rem;
  `}
  ${media.max(700)`
    margin: 0 0 1rem 0;
    flex-grow: 1;
  `}
  ${media.max(450)`
    position: absolute;
    top: -4.25rem;
    right: 0;
  `}
  ${media.max(350)`
    position: static;
  `}
`

const LinkButton = styled.a`
  position: relative;
  display: inline-block;
  text-decoration: underline;
  user-select: none;
`
const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${getColor('gray120')};
  ${media.max(450)`
    display: block;
  `}
`
// width: 100%;
// max-width: 40rem;
const SearchInput = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 1rem;
  
  
  ${media.max(450)`
    margin-right: 0;
    margin-bottom: .5rem;
    max-width: 100%;
  `}
  ${LinkButton} {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
  > input {
    padding-right: 8.75rem;
  }
  ${props => props.expandedInput && `
    > input {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  `}
`
const ClearSearchButton = styled(Icon)`
  color: ${getColor('gray90')};
  font-size: 12px;
  position: absolute;
  right: 80px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  border-radius: 50%;
  background: rgba(0,0,0,0.1);
  :hover {
    background: #f7d3d4;
    color: ${getColor('alert')};
  }
`

const PaginationContainer = styled.div`
  text-align: left;
`

const MaxResultsMenu = styled(ContextMenu)`
  li > * {
    padding: 11px 2rem;
  }
`
const LoadingWorklist = styled.div`
  border-radius: 5px;
  height: 26rem;
  height: calc(100vh - 18.5rem);
`

export const Worklist = ({ location, patientName, linkedStudies, setLinkedStudies, close, records, linkRecord, providerJwt, jwt, setNotification, viewerLoadUrl, worklistViewerJwt, storageHost}) => {

  const visibleColumnsAmount = 6;
  const loading = useToggle()
  const [params, rawSetParams] = useState(() => _.set('last12Months', true, { page: 1, maxResults: 10, search: patientName }))
  const updateParams = (params) => {
    rawSetParams(prevParams => _.set('last12Months', prevParams.last12Months, params))
    callUpdateParams(params)
  }

  useEffect(() => {
    updateParams(params);
  }, [location])

  useEffect(() => {

  }, [params])


  const debounceSetParams = useConstant(_.debounce(500, rawSetParams))

  const setParams = (updater, debounce) => {
    const verifyPage = (updater) => (state) => {
      const updatedState = callIfFunction(updater, state)

      if (
        state.search !== updatedState.search ||
        state.patBirthdate !== updatedState.patBirthdate ||
        state.studyDatetimeInit !== updatedState.studyDatetimeInit ||
        state.studyDatetimeEnd !== updatedState.studyDatetimeEnd ||
        state.updatedTimeInit !== updatedState.updatedTimeInit ||
        state.updatedTimeEnd !== updatedState.updatedTimeEnd ||
        state.last12Months !== updatedState.last12Months
      ) {
        return _.set('page', 1, updatedState)
      } else if (state.maxResults !== updatedState.maxResults) {
        const firstRow = ((state.maxResults || 10) * (updatedState.page - 1) + 1)
        return _.set('page', Math.max(Math.floor(firstRow / (updatedState.maxResults || 10)) + 1, 1), updatedState)
      } else {
        return updatedState
      }
    }
    if (debounce) {
      debounceSetParams(verifyPage(updater))
    } else {
      setTimeout(() => rawSetParams(verifyPage(updater)), 0)
    }
    setTimeout(loading.open, 0)

  }

  return <>
    <WorklistPageContainer visibleColumnsAmount={visibleColumnsAmount} isMobile={isMobile()}>
      <WorklistCard>
        <WorklistHeader
          allTabs={false}
          params={params}
          setParams={setParams}
          location={location}
          loading={loading}
        />
        <Suspense fallback={null}>
          <WorklistTable
            blankWorklist={false}
            allTabs={false}
            updateParams={updateParams}
            loading={loading} params={params} setParams={setParams} location={location}
            linkedStudies={linkedStudies}
            setLinkedStudies={setLinkedStudies}
            close={close}
            records={records} linkRecord={linkRecord}
            providerJwt={providerJwt}
            jwt={jwt}
            setNotification={setNotification}
            viewerLoadUrl={viewerLoadUrl}
            worklistViewerJwt={worklistViewerJwt}
            storageHost={storageHost}
          />
        </Suspense>
      </WorklistCard>
    </WorklistPageContainer>

  </>
}

const WorklistHeader = ({ location, allTabs, loading, params, setParams }) => {

  const query = useToggle(params.search || '')
  const status = useToggle(params.studyStatus || '')
  const maxResults = useToggle(params.maxResults || '')

  const updateUrl = useConstant(
    _.throttle(200, (obj) => {
      window.top.history.pushState('', '', ('?' + addQueryString('', _.pickBy((value, key) => key !== "last12Months" && value, obj))).replace(/\?\?/, '?'))
    })
  )

  useUpdateParams((params) => {
    query.openWith(params.search || "")
    status.openWith(params.studyStatus || "")
    maxResults.openWith(params.maxResults || "")
  })

  useEffect(() => {

  }, [params])


  const maxResultsMenu = usePopupToggle()
  const advancedPopup = useToggle()

  const updateSearch = (v) => {
    query.openWith(v)
    setParams(_.set('search', v), true)
  }

  const updateStatus = (v) => {
    status.openWith(v)
    setParams(_.set('studyStatus', v), true)
  }
  const updateMaxResult = (v) => {
    maxResults.openWith(v)
    setParams(_.set('maxResults', v), true)
  }

  return <WorklistHeaderComponent>
    <div css="flex-grow:2;">
      <SearchInputWrapper>
        <SearchInput expandedInput={advancedPopup.isOpen}>
          <TextInput raw value={query.isOpen} setValue={updateSearch} placeholder="Search cases" />
          {query.isOpen && <ClearSearchButton icon="x" onClick={() => updateSearch("")} />}
          <LinkButton onClick={advancedPopup.open}>
            Advanced
          </LinkButton>
          {
            advancedPopup.isOpen &&
            <AdvancedSearchPopup
              focusedInput={advancedPopup.isOpen}
              closePopup={advancedPopup.close}
              setParams={setParams}
              params={params}
            />
          }
        </SearchInput>
        <div>
          <Slot name="QueryCount" />
          <div className="nowrap">Showing <LinkButton onClick={maxResultsMenu.open}>{(maxResults.isOpen || 10)} per page
            {
              maxResultsMenu.isOpen &&
              <MaxResultsMenu offset="0 4">
                <Li label="10" onClick={() => updateMaxResult(10)} />
                <Li label="20" onClick={() => updateMaxResult(20)} />
                <Li label="50" onClick={() => updateMaxResult(50)} />
              </MaxResultsMenu>
            }
          </LinkButton>
          </div>
        </div>
      </SearchInputWrapper>
      <ActiveAdvancedFilters params={params} setParams={setParams} advancedPopup={advancedPopup} location={location} />
    </div>
    <div className="right-wrapper">
      <PastYearToggle params={params} setParams={setParams} />
    </div>
  </WorklistHeaderComponent>
}

const PastYearToggle = ({ params, setParams }) => {
  const value = isUsingStudyPastYear(params) || isUsingUploadedPastYear(params)
  const isDisabled = isPastYearToggleDisabled(params)
  const setValue = (v) => setParams(_.set('last12Months', v))

  const toggle = <PastYearCheckbox
    raw
    // label="Display last 12 months only"
    label="Limit to last 12 months"
    value={value}
    disabled={isDisabled}
    setValue={setValue}
  />

  return isDisabled ? <Tooltip content="This limit is disabled while filtering.">{toggle}</Tooltip> :
    <Tooltip content="Pages load faster if enabled.">{toggle}</Tooltip>
}

const WorklistTable = React.memo(({ params, allTabs, blankWorklist, updateParams, loading, setParams, linkedStudies, setLinkedStudies, close, linkRecord, records, providerJwt, jwt, setNotification, viewerLoadUrl, worklistViewerJwt, storageHost, ...props }) => {

  // const worklistUrl = window.WORKLIST_URL || "https://purviewimage.purview.net/worklist/api";
  const worklistUrl = isDev ? window.WORKLIST_URL || "" : window.WORKLIST_URL || "https://purviewimage.purview.net/worklist/api";
  const [worklist, setWorklist] = useState()
  const lastParams = useRefState(params)
  const lastSuccessParams = useRefState(params)
  const errorDialog = useToggle()

  const [isLoadingAction,setLoadingAction] = useState(false);

  const maxResults = Number(params.maxResults) || 10
  const currentPage = Number(params.page) || 1

  const loadList = useConstant((currentParams, closeLoading, refreshing) => {
    lastParams.set(currentParams)
    if (lastParams.get() === lastSuccessParams.get() && !refreshing) {
      callIfFunction(closeLoading)
      return Promise.resolve()
    }
    if (blankWorklist && (currentParams.search || "").length < 3) {
      lastSuccessParams.set(currentParams)
      window.scroll(0, window.pageYOffset);
      smoothScroll(0)
      setWorklist({ worklist: { blankingWorklist: true, dbCount: 0, rows: [] }, key: Date.now() })
      callIfFunction(closeLoading)
      return Promise.resolve()
    }
    const lastYearDate = formatDate(getDateYear(-1), "yyyy-MM-dd")
    const todayDate = formatDate(getDate(0), "yyyy-MM-dd")
    const filteringYearDates = isUsingUploadedPastYear(currentParams)
      ? { updatedTimeInit: lastYearDate, updatedTimeEnd: todayDate }
      : isUsingStudyPastYear(currentParams)
        ? { studyDatetimeInit: lastYearDate, stuydDateTimeEnd: todayDate }
        : {}

    let idsTagsStrings = ''
    if (currentParams?.idsTagsArray?.length > 0) {
      let tagArray = currentParams.idsTagsArray.split(',');
      tagArray.map((tag, index) => {
        if (index == 0) {
          idsTagsStrings = '?idsTags=' + tag
        } else {
          idsTagsStrings += '&idsTags=' + tag
        }
      });
    }
    
    return jwtGetFetcher(providerJwt())(addQueryString(worklistUrl+'/studies' + idsTagsStrings,
      {
        size: currentParams.maxResults,
        last12Months: currentParams.last12Months,
        patName: currentParams.search,
        modsInStudy: currentParams.search,
        studyDesc: currentParams.search,
        ..._.pickBy(_.identity, currentParams),
        page: currentParams.page - 1,
        sort: 'studyDesc,patient.patName'
      })
    ).then((response) => {
      
      if (lastParams.get() === currentParams) {
        lastSuccessParams.set(currentParams)
        setWorklist({ worklist: { rows: response.studies }, key: Date.now(), blankingWorklist: true, dbCount: response.totalCount })
        let newTagsArray = [];
        response.allTagsStudy.map((item) => {
          newTagsArray.push(item.tags);
        })
        if (newTagsArray.length >= getExistingTags().length) {
          setTags(newTagsArray);
        }
        callIfFunction(closeLoading)
      }
    })
    .catch(() => {
      if (lastParams.get() === currentParams) {
        callIfFunction(closeLoading)
        errorDialog.open()
        updateParams(lastSuccessParams.get())
      }
    })
  })

  const refreshList = () => loadList(params, null, true)

  useEffect(() => {
    loading.open()
    loadList(params, loading.close)
  }, [params])


  if (!worklist) {
    return <>
      <Fill name="QueryCount"><b>Loading...</b></Fill>
      <LoadingWorklist><Loading size={40} borderWidth={6} absolute /></LoadingWorklist>
    </>
  }
  const { worklist: { blankingWorklist, rows }, dbCount, key } = worklist

  return <>
    <Fill name="QueryCount"><b>{loading.isOpen ? 'Loading...' : <>{dbCount} case{dbCount !== 1 ? "s" : ""}{params.search && " found"}</>}</b></Fill>
    <Fill name="Pagination"><Pagination
      setPageParam={(page) => () => setParams(_.set('page', page))}
      currentPage={currentPage}
      totalPages={Math.ceil((Number(dbCount)) / maxResults)}
    /></Fill>
    <Table
      key={key}
      blankingWorklist={blankingWorklist}
      refreshList={refreshList}
      isLoading={loading.isOpen}
      setParams={setParams} {...props}
      queryCount={Number(dbCount)}
      rows={rows}
      linkedStudies={linkedStudies}
      setLinkedStudies={setLinkedStudies}
      records={records} linkRecord={linkRecord}
      providerJwt={providerJwt}
      jwt={jwt}
      setNotification={setNotification}
      setLoadingAction={setLoadingAction}
      isLoadingAction={isLoadingAction}
      viewerLoadUrl={viewerLoadUrl}
      worklistViewerJwt={worklistViewerJwt}
      storageHost={storageHost}
    />
    <WorklistDialogFooter>
      <PaginationContainer><Slot name="Pagination" /></PaginationContainer>
      <Button highlight onClick={close} isLoading={isLoadingAction} disabled={isLoadingAction}>Done</Button>
    </WorklistDialogFooter>
  </>
})
