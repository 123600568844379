import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {RecordRowContainer} from "./RecordRowContainer";
import {DescriptionField} from "./DescriptionField";
import {AttachmentDescription, AttachmentDetails} from "../AttachmentBoxStyles";
import {Pathology} from "../../enums/RecordClass";
import {CCDA, FormatToLabel} from "../../enums/RecordFormat";
import {formatDate} from "@startlibs/utils";
import {dicomDateToDate} from "../../utils";
import {useToggle} from "@startlibs/core";
import {Button, Dialog} from "@startlibs/components";
import {UploaderAction} from "../../service/UploaderAction";
import {RecordDetailsDialog} from "./RecordDetailsDialog";
import {useDoAction} from "../../service/hooks/useDoAction";

styled

export const PathologyRecordRow = ({record, group, dragProvided}) => {
  const doAction = useDoAction()
  const recordDetailsDialog = useToggle()
  const cancelDialog = useToggle()
  const cancelLoading = useToggle()
  const deleteLoading = useToggle()

  const deleteRecord = async () => {
    await deleteLoading.wait(doAction(UploaderAction.Delete, record))
    cancelDialog.close()
  }

  return <RecordRowContainer
    group={group}
    record={record}
    dragProvided={dragProvided}
    cancelUpload={cancelDialog.open}
    openDetails={recordDetailsDialog.open}
    isMultiFile
  >{isMinified =>
    <>
      <AttachmentDescription className="fs-exclude">
        <b>{record.description}</b> <a className="light-link" onClick={recordDetailsDialog.open}>Details</a>
      </AttachmentDescription>
      <AttachmentDetails><b>Format: </b>{FormatToLabel[record.recordFormat] || record.recordFormat}</AttachmentDetails>
      {
        recordDetailsDialog.isOpen &&
        <RecordDetailsDialog
          title="Pathology record details"
          closeDialog={recordDetailsDialog.close}
          cancelUpload={cancelDialog.open}
          record={record}
          withCancelSingleFile
        />
      }
      {
        cancelDialog.isOpen &&
        <Dialog
          closeDialog={cancelDialog.close}
          title="Cancel pathology records upload"
          footer={<>
            <Button onClick={cancelDialog.close}>Continue upload</Button>
            <Button alert isLoading={cancelLoading.isOpen} onClick={cancelLoading.willWait(deleteRecord)}>
              Confirm and remove record
            </Button>
          </>}
        >
          <p>This pathology record will be removed.</p>
          <p>Are you sure you want to proceed?</p>
        </Dialog>
      }
    </>
  }</RecordRowContainer>
}