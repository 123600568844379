import React from 'react'
import {PDF} from './RecordFormat'

export const Pathology = 'Pathology'
export const Radiology = 'Radiology'
export const Clinical = 'Clinical'
export const Other = 'Other'

// Pseudo class, group only
export const Document = 'Id Documents'

export const isDocumentRecord = (record) => {
  return record?.format === PDF || ['png','jpg','jpeg','gif','bmp','webp'].indexOf(record?.fileExtension?.toLowerCase()) >= 0
}


export const RecordClassList = [Pathology,Radiology,Clinical,Other]