import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {AttachmentBoxFooter} from "../AttachmentBoxStyles";

styled

export const RecordRowQuarantinedFooter = () =>
  <AttachmentBoxFooter alert>
    <div><b>Status: </b>Quarantined</div>
    <div>This medical record file has potentially harmful data.</div>
  </AttachmentBoxFooter>