let resolveJwt = null
let jwt = new Promise((res) => resolveJwt = res)


export const setJwt = (newJwt,sessionKey) => {
  jwt = newJwt
  resolveJwt(jwt)
  if (sessionKey) {
    window.localStorage.setItem(sessionKey+"-jwt",newJwt)
  }
}
export const getJwt = () => jwt
