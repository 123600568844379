import { Button, Icon } from '@startlibs/components';
import { FormValue } from '@startlibs/form';
import React from 'react'
import _ from 'lodash/fp'
import {CategoriesBox} from '../../experts/hooks/useEditCategories'
import {ConfirmDialog, useConfirmDialogWithProps} from '../../../hooks/useConfirmDialog'
import { DIVISION, OPERATOR } from '../../../enums/UserRole';
import { InfoBox } from '../../../components/InfoBox';
import {buildValidation} from '../../../utils/validation'
import {jwtPostFetcher} from '../../../utils/authFetch'
import {getJwt} from '../../../hooks/useJwt'

const formatCategories = _.update('categories',_.map(_.get('id')))

export const useEditStaffCategories = (onSuccess) => {

  // const saveCategories = (caseRequest,categories) => jwtPostFetcher(getJwt())('/api/admin/caseCategory?requestId='+getId(caseRequest),categories.map(_.get('id')),{method:"PUT"})

  const preValidation = buildValidation({
    categories:(list,k,values) => values.role === DIVISION && !list.filter(v => v && !_.isString(v)).length && "Division admin users must be tied to at least one division."
  })


  const action = (categories) => {
    /*const newIds = categories.map(_.get('id'))
    if (!userInfo.categories.length || userInfo.categories.find(cat => newIds.indexOf(cat.id)>=0)) {
      return saveCategories(toggleWithCaseRequest.get(),categories)
    } else {
      confirmDialog.openWith(categories)
      return Promise.reject([])
    }*/
  }

  const isEmptyCategories = v => !v.filter(v => v && !_.isString(v)).length

  const toggleWithCaseRequest = useConfirmDialogWithProps((staff) => <ConfirmDialog
      action={(v) => jwtPostFetcher(getJwt())("/api/admin/user",formatCategories(v), {method:"PUT"})}
      confirm={<Button highlight>Update</Button>}
      preValidation={preValidation}
      values={staff}
      isLoading={false}
      onSuccess={({categories}) => onSuccess({...staff,categories})}
      notify="User divisions updated."
      title="Edit user divisions"
    >{ form => <>
      <p>Manage divisions that this user can access.</p>
      <CategoriesBox userRelated form={form} mandatory={staff.role === DIVISION}/>
      {
        staff.role === OPERATOR && <FormValue path="categories">{ categories =>
          isEmptyCategories(categories) &&
          <InfoBox lightBlue withIcon><Icon icon="info"/> Operators that are not tied to any division can access cases from all divisions.</InfoBox>
        }</FormValue>
      }
    </>
    }</ConfirmDialog>
  )

  // const confirmDialog = useConfirmMissingCategories(() => toggleWithCaseRequest.get(),(values) => {onSuccess(toggleWithCaseRequest.get())(values); toggleWithCaseRequest.close()})

  return toggleWithCaseRequest
}
