import { Radiobox } from '@startlibs/form';
import { getColor, ifProp, media } from '@startlibs/utils';
import React from 'react';
import styled, { css } from 'styled-components';

const RadioboxContent = styled.div `
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  flex-basis: 100%;
  border: 1px solid ${getColor('gray210')};
  border-radius: 6px;
  overflow: hidden;
  :hover {
    background: rgba(0,0,0,0.025);
  }
  :active {
    background: rgba(0,0,0,0.075);
  }
  ${props => props.hasErrors && css`
    border-color: ${getColor('alert')} !important;
  `}
  ${props => props.checked && css`
    border-color: ${getColor('main')};
    ${TextLabelContainer} {
      background: ${getColor('main')};
    }
    ${TextLabel} {
      color: white;
    }
    ${CssCircle} {
      :before {
        background-color: ${getColor('main')};
      }
    }
  `}
  ${props => props.disabled && css`
    background-color: rgba(0,0,0,0.075) !important;
    &[disabled] {
      opacity: 0.75 !important;
    }
    ${TextLabelContainer} {
      background: ${getColor('gray210')};
    }
    ${TextLabel} {
      color: rgba(0,0,0,0.45);
    }
    ${PermissionList} {
      color: rgba(0,0,0,0.25);
    }
    ${CssCircle} {
      background: rgba(255,255,255,0.25);
    }
  `}
`

const TextLabelContainer = styled.div `
  display: flex;
  align-items: center;
  position: relative;
  background: ${getColor('gray240')};
  min-height: 3rem;
  padding: .5rem 1rem;
`

const CssCircle = styled.div`
  border-radius: 50%;
  content: '';
  border: 1px solid ${getColor('gray210')};
  width: 18px;
  height: 18px;
  background-color: white;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);

  ${ifProp('focused', css`
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    border-color: ${getColor('gray180')};
  `)}

  ${ifProp('checked', css`
    :before {
      content: '';
      width: 8px;
      height: 8px;
      top: 50%;
      transform: translateY(-50%);
      left: 4px;
      border-radius: 50%;
      position: absolute;
      background-color: ${getColor('gray90')};
    }
  `)}

`
const TextLabel = styled.span `
  font-size: 13px;
  line-height: 16px;
  margin: 2px 0;
  display: block;
  flex-grow: 1;
`
const PermissionList = styled.ul `
  display: block;
  font-size: 12px;
  font-weight: 400;
  padding: .5rem;
  flex-grow: 1;
  flex-basis: 100%;
  color: ${getColor('gray90')};
  .false {
    color: rgba(0,0,0,0.4);
  }
`

export const RoleRadioBox = styled((props) =>
  <Radiobox {...props}>{({disabled, checked, hasErrors, focused, tabIndex, framedBoxes, highlightSelected, ultraHighlightSelected}) =>
    <RadioboxContent checked={checked} framed={props.framedBoxes} highlightSelected={props.highlightSelected} ultraHighlightSelected={props.ultraHighlightSelected} hasErrors={hasErrors} disabled={disabled}>
      <TextLabelContainer>
        <CssCircle checked={checked} focused={focused} tabIndex={tabIndex}/>
        <TextLabel>{props.label}</TextLabel>
      </TextLabelContainer>
      {props.permissionList && <PermissionList>{props.permissionList}</PermissionList>}
    </RadioboxContent>
  }</Radiobox>
)`
  cursor: pointer;
  position: relative;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  flex-basis: 33%;
  flex-grow: 1;
  max-width: 50%;
  & ~ & {
    margin-left: .5rem
  }
  ${ifProp('disabled', css`
    pointer-events: none;
  `)}
  ${media.max(480)`
    flex-basis: 100%;
    max-width: 100%;
    & ~ & {
      margin-left: 0;
    }
  `}
`
