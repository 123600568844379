import React from 'react'

export const PATIENT = 'PATIENT'
export const CAREGIVER = 'CAREGIVER'
export const REF_PHYSICIAN = 'REF_PHYSICIAN'
export const OTHER_RELATION = 'OTHER_RELATION'
export const OTHER = 'OTHER'

export const LEGALGUARDIAN = 'LEGAL_GUARDIAN'
export const EXECUTOR = 'EXECUTOR'
export const PARENT = 'PARENT_OF_MINOR'