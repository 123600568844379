import { v4 as uuid } from 'uuid';
import { Node, Element } from 'slate';

export function createTable(columns, rows){
  const rowNodes = [...new Array(rows)].map(() => createRow(columns));

  return {
    type: 'table',
    children: rowNodes,
    data: {},
  };
}


export function createRow(columns) {
  const cellNodes = [...new Array(columns)].map(() => createCell({}));

  return {
    type: 'tr',
    key: `row_${uuid()}`,
    data: {},
    children: cellNodes,
  };
}


export function createCell({
  elements,
  colspan,
  rowspan,
  height,
  width}
){
  const content = createContent(elements);

  return {
    type: 'td',
    key: `cell_${uuid()}`,
    children: [content],
    width: width,
    height: height,
    colspan: colspan,
    rowspan: rowspan,
  };
}

export function createContent(elements) {
  
  return {
    type: 'table-content',
    children: elements ? elements : [{ type: 'div',  children: [{text: '' }]}]
  };
}
