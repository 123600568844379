import {Errors, RichText, WithForm} from '@startlibs/form'
import {_s, callIfFunction, getColor, smoothScroll} from '@startlibs/utils';
import {usePrevious} from '@startlibs/core'
import {DraggableItem} from '@startlibs/components'
import React, {useEffect, useState} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';
import {requiredTerms} from './components/AcceptTermsCheckbox'
import {Card, SectionHeading} from '../components/PageLayout';
import {Question} from './forms/Question'
import {QuestionList} from '../components/QuestionList'
import {jwtGetFetcher} from '../utils/authFetch'
import {buildValidation, required} from '../utils/validation'
import {getJwt} from '../hooks/useJwt'
import {useSetConfirmExitPage} from '../hooks/useConfirmExitPage'
import {willUseSuspense} from '../hooks/useSuspense'
import {DEFAULT_ADDITIONAL_FIELDS} from './utils/createCaseUtils'
import {IfLocale} from '../hocs/IfLocale'
import {FormattedMessage, useIntl} from 'react-intl';
import {isRichText, RTF_TOOLBAR_OPTIONS, safeMultipleNewLineToBr, safeMultipleNewLineToBrOnly} from '../utils/utils';
import {FormSlateEditor} from "../components/slateEditor/FormSlateEditor";
import { isStrEmpty } from '../utils';

export const EmptyLocationRecordText = styled.div`
  color: ${getColor('gray150')};
  font-size: 13px;
`

const useAuthSuspense = willUseSuspense((url) => jwtGetFetcher(getJwt())(url))
const WithUploaderToken = ({children, requestId}) => {
  const {jwt} = useAuthSuspense('/api/uploaderToken' + (requestId ? "?requestId=" + requestId : ""))
  return children(jwt)
}

const GET_EMPTY_QUESTION = () => ({question: '', answer: '', id: Date.now().toString(36) + ''})
const GET_EMPTY_ADDITIONAL_INFO = () => ({title: '', content: '', id: Date.now().toString(36) + ''})

const EMPTY_FORM = {
  questionsConsultant: [],
}

const transform = ({caseInfo: {questionsConsultant, caseSummary, additionalFields}}) =>
  _.flow(
    _.update('questionsConsultant', _.filter(({question}) => question)),
    _.update('additionalFields', _.filter(({title}) => title))
  )({questionsConsultant, caseSummary, additionalFields})


const parse = _.flow(
  _.update('caseInfo.questionsConsultant', (list) => (list && list.length) ? list : [{
    question: '',
    answer: '',
    id: Date.now().toString(36) + ''
  }]),
  // _.update('caseInfo.caseSummary', (summary) => (summary && summary.includes("\n")) ? safeMultipleNewLineToBr(summary)  : summary)
  _.update('caseInfo.caseSummary', (summary) => {
    return summary && summary.includes("\n") 
      ? isRichText(summary) ? safeMultipleNewLineToBrOnly(summary) : safeMultipleNewLineToBr(summary)
      : summary 
  })
)

export const ClinicalInformation = ({readOnly, isPatient, action, limitQuestions = 5, alwaysSave, caseRequest, onSuccess, formRef, optional, children, values = EMPTY_FORM}) => {

  const intl = useIntl()
  const preValidation = buildValidation({
      'caseInfo.caseSummary':(field,k,values) => {
        const hasImage = field.includes('<img')
        if(!hasImage && isStrEmpty(field.replace(/<[^>]*>/g, '').trim())){
          return "Please provide a Clinical summary."
        }
      },
      // 'caseInfo.caseSummary': required,
      'acceptedTerms': isPatient && requiredTerms(caseRequest),
    },
    ({caseInfo}) =>
      (!caseInfo || !caseInfo.questionsConsultant || !caseInfo.questionsConsultant.length || !caseInfo.questionsConsultant.find(({question}) => { /*return !!question*/ return !isStrEmpty(question.replace(/<[^>]*>/g, '').trim())})) && {"caseInfo.questionsConsultant.0.question": "At least one question is required."}
  )

  // useSetConfirmExitPage(() => formRef.current.confirm())

  const hasAnyQuestion = caseRequest.caseInfo?.questionsConsultant?.find(({question, answer}) => question || answer)
  const hasAnyAdditional = caseRequest.caseInfo?.additionalFields?.find(({title, content}) => title || content)

  const setItemOrder = (from, to) => {
    const form = formRef.current
    const path = "caseInfo.additionalFields"
    const oldOrder = form.getValue(path)
    const newOrder = _s.moveElement(from, to, oldOrder)
    form.setValue(path,newOrder)
  }
  const [focus, setFocus] = useState(false)

  const handleEditorFocus = (event, change) => {
    setFocus(true)
  };

  return <WithForm
    ref={formRef}
    alwaysSave={alwaysSave}
    transform={transform}
    action={action}
    onSuccess={onSuccess}
    preValidation={optional ? undefined : preValidation}
    values={parse(values)}
  >{form => <>
    <SectionHeading>
      {/* <h3>Clinical summary<sup>*</sup></h3> */}
      <h3><FormattedMessage defaultMessage="Clinical summary" description="Admin additional clinical information title"/><sup>*</sup></h3>
      <p>Provide additional relevant information about this case.</p>
    </SectionHeading>
    <Card>
        <FormSlateEditor
          path="caseInfo.caseSummary"
          mandatory
          formatTools={RTF_TOOLBAR_OPTIONS}
          autoHideToolbar
          disabled={readOnly}
          placeholder={intl.formatMessage({defaultMessage:"Type a case summary here", description:"Admin additional clinical information desc placeholder"})}
          useHtml={true}
          onFocus={handleEditorFocus}
          focus={focus}
          requestId={caseRequest.requestId}
        />
    </Card>
    <SectionHeading>
      <h3>Questions<sup>*</sup></h3>
      <p>Require assigned expert(s) to answer the following questions</p>
    </SectionHeading>
    <Card>
      {readOnly && !hasAnyQuestion
        ? <EmptyLocationRecordText>No questions provided yet.</EmptyLocationRecordText>
        : <QuestionList
          disabled={readOnly}
          limit={limitQuestions}
          path="caseInfo.questionsConsultant"
          addLabel="Add question"
          getEmpty={GET_EMPTY_QUESTION}
        >{(question, i) =>
          <Question
            disabled={readOnly}
            optional={optional}
            i={i}
            key={question.id}
            form={form}
            path={["caseInfo", "questionsConsultant"]}
          />
        }</QuestionList>
      }
    </Card>

    <IfLocale not contains="LEGAL">
    <SectionHeading>
      <h3>Request additional information</h3>
      <p>Require assigned expert(s) to provide the following information</p>
    </SectionHeading>
    <Card>

      {readOnly && !hasAnyAdditional
        ? <EmptyLocationRecordText>No additional information added yet.</EmptyLocationRecordText>
        : <QuestionList
          setItemOrder={setItemOrder}
          disabled={readOnly}
          limit={limitQuestions}
          path="caseInfo.additionalFields"
          addLabel="Add field"
          getEmpty={GET_EMPTY_ADDITIONAL_INFO}
        >{(question, i) =>
          <DraggableItem
            key={question.id}
            DragTag={Question}
            shouldConfirmDelete={v => v && DEFAULT_ADDITIONAL_FIELDS.indexOf(v)<0}
            questionKey="title"
            placeholder="Describe your request here"
            disabled={readOnly}
            confirmDeleteTitle="Confirm field deletion"
            confirmDeleteBody={<p><b>Are you sure you want to delete this field?</b></p>}
            optional={optional}
            i={i}
            form={form}
            path={["caseInfo", "additionalFields"]}
          />
        }</QuestionList>
      }
    </Card>
    </IfLocale>
    {/*{isPatient && !readOnly && <AcceptTermsCheckbox caseRequest={caseRequest}/>}*/}
    <ScrollOnError form={form}/>
    <Errors/>
    {callIfFunction(children, form)}
  </>}</WithForm>

}

const ScrollOnError = ({form}) => {
  const previous = usePrevious(form.getErrorsInsidePath([]))
  useEffect(() => {
    const current = form.getErrorsInsidePath([]).length
    if (current > 0 && (previous || []).length <= current) {
      smoothScroll(window.outerHeight)
    }
  }, [form.getErrorsInsidePath([])])
  return null

}
