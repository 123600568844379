import {Button, Loading, Icon} from '@startlibs/components'
import {callIfFunction,getColor} from '@startlibs/utils'
import { darken } from 'polished';
import {useToggle} from '@startlibs/core'
import React from 'react'
import styled,{css} from 'styled-components';

import {Accepted, Approved, Rejected, Routed, Routing, Submitted} from '../enums/RecordState'
import { AttachmentBoxFooter } from './AttachmentBoxStyles';
import {ADMIN} from '../enums/UserRoles'

const AttachmentStatusIcon = styled(Icon)`
  font-size: 30px;
  display: block;
  color: ${getColor('gray150')};
  ${props => props.success && css`
    color: ${getColor('success')};
  `}
`

const STATE_TO_INFO = {
  // [Submitted]: 'Pending acceptance',
  // [Accepted]: (role,isPathology) => (role === CA && isPathology && 'Waiting for POC approval') || (role === POC && !isPathology && 'Waiting for CA approval') || 'Pending approval',
  [Submitted]: 'Submitted',
  [Accepted]: 'Pending approval',
  [Approved]: 'Approved',
  [Rejected]: 'Rejected',
  [Routed]: 'Routed',
  [Routing]: 'Routing'
}

export const RecordStateFooter = ({state,setState,role,isPathology}) => {

  const loading = useToggle()

  return <AttachmentBoxFooter>
    <div>
      <b>Status: </b>{callIfFunction(STATE_TO_INFO[state],role,isPathology)}
    </div>
    <div className="nowrap">
      {
        state === Submitted && role === ADMIN && 
        (loading.isOpen ? <Loading size={22}/> :
        <>
          <Button small onClick={() => loading.wait(setState(Rejected))}>Reject</Button>
          {/* <Button small highlight onClick={() => loading.wait(setState(Accepted))}>Accept</Button> */}
        </>)
      }
      {
        state === Rejected && role === ADMIN &&
        (loading.isOpen ? <Loading size={22}/> :
        <Button small onClick={() => loading.wait(setState(Submitted))}>Undo reject</Button>
        )
      }
      {
        (state === Accepted && role === ADMIN) 
        // && (isPathology ?
        // <AttachmentStatusIcon icon="waiting"/>
        // :
        && (loading.isOpen ? <Loading size={22}/> :
        <Button small success onClick={() => loading.wait(setState(Approved))}>Approve</Button>
        )
        //)
      }
      {/* {
        state === Accepted && role === POC && (isPathology ?
        (loading.isOpen ? <Loading size={22}/> :
        <Button small success onClick={() => loading.wait(setState(Approved))}>Approve</Button>
        )
        :
        <AttachmentStatusIcon icon="waiting"/>)
      } */}
      {
        state === Approved && <AttachmentStatusIcon success icon="check-circle"/>
      }
    </div>
  </AttachmentBoxFooter>
}