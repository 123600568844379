import React from 'react'

export const FileState = {
  Waiting: 'Waiting',
  Uploading: 'Uploading',
  Uploaded: 'Uploaded',
  ConnectionError: 'ConnectionError',
  FileNotFound: 'FileNotFound',
  EmptyFile: 'EmptyFile', // Added this EmptyFile
  InvalidFilename: 'InvalidFilename',
  ProcessingError: 'ProcessingError',
  Quarantined: 'Quarantined',
  Shortlisted: 'Shortlisted',
  Canceled: 'Canceled',
  Deleted: 'Deleted',
}

export const getFileStateLabel = state =>
  ({
    [FileState.Quarantined]: 'Harmful file',
    [FileState.ProcessingError]: 'Processing error',
    [FileState.FileNotFound]: 'File not found',
    [FileState.InvalidFilename]: 'Invalid filename',
    [FileState.ConnectionError]: 'Connection error',
  }[state] || state)

const NO_RETRY_FAILURES = [FileState.InvalidFilename, FileState.Quarantined]

export const IS_RETRY_POSSIBLE = ({ failed, quarantined }) =>
  NO_RETRY_FAILURES.indexOf(failed) < 0 && !quarantined

export const IS_QUARANTINED = ({ failed, quarantined }) =>
  failed === FileState.Quarantined || quarantined

export const IS_FAILURE = state =>
  [
    FileState.ConnectionError,
    FileState.FileNotFound,
    FileState.InvalidFilename,
    FileState.ProcessingError,
    FileState.Quarantined,
    FileState.Shortlisted,
  ].indexOf(state) >= 0
