import React from 'react'
import {ExternalReportUpload} from '../../request/components/ExternalReportUpload'
import _ from 'lodash/fp'
import {useToggle, willUseSharedState} from '@startlibs/core'
import {ConfirmDialog, useConfirmDialog, useConfirmDialogWithProps} from '../../hooks/useConfirmDialog'

export const [useUploadDialogIsOpen,,setUploadDialogIsOpen] = willUseSharedState()

export const useUploadCustomReport = (caseRequest, setCaseRequest,{isReplacing} = {}) => {

    const isUploading = useToggle()

  const dialog = useConfirmDialogWithProps(() =>
    <ConfirmDialog
      title={isReplacing ? "Replace custom report" : "Upload custom report"}
      onSuccess={() => setCaseRequest(_.flow(_.set('report.useCustomReport', true),_.set('pdfKey',Date.now()), _.set('report.customReport', true)))}
    >{form => <>
      <p>{isUploading.isOpen ? 'Uploading...' : 'Upload a PDF file to be used as the case report:'}</p>
      <ExternalReportUpload
        url={"/api/customReport?requestId=" + caseRequest.requestId}
        onSuccess={() => form.submitForm()}
        isUploading={isUploading}
      />
      <p css="margin-top:1rem">{isUploading.isOpen ? 'Preview will be available once upload is finished.' : 'You will be able to switch back to the default report at any time.'}</p>
    </>}</ConfirmDialog>,[isUploading.isOpen])

  React.useEffect(() => setUploadDialogIsOpen(dialog.isOpen) ,[dialog.isOpen])

  return dialog.open

}