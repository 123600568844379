import { Button, Icon } from '@startlibs/components';
import {TextInput} from '@startlibs/form'
import { getColor, will } from '@startlibs/utils';
import React from 'react'
import _ from 'lodash/fp'
import styled, { css } from 'styled-components';
import {CASE_DRAFT, PENDING_REQUEST, WAITING_MEDICAL_RECORDS} from '../../../enums/CaseState'
import {CaseRequestCard} from '../../CaseRequestCard'
import {ConfirmDialog, useConfirmDialog} from '../../../hooks/useConfirmDialog'
import {ContactSelectBoxes} from '../RecordsStep'
import {ContactSelectInput} from '../../../components/ContactSelectInput'
import {getContact, REQUESTED} from '../../../enums/ContactRelationKind'
import {getJwt} from '../../../hooks/useJwt'
import { isPendingAskingMore } from '../../../patient/utils/patientUtils';
import {jwtPostFetcher} from '../../../utils/authFetch'
import {useIntl} from 'react-intl'

export const WaitingMedicalRecordsBox = ({caseRequest,setCaseRequest, small = false}) => {
  const revokeConfirm = useConfirmDialog(
    <ConfirmDialog
      title="Revoke request"
      confirm={<Button alert>Revoke</Button>}
      action={(v) => jwtPostFetcher(getJwt())("/api/admin/revokeRequestMedicalRecords?requestId="+caseRequest.requestId,v)}
      onSuccess={will(setCaseRequest,_.flow(caseRequest.state === PENDING_REQUEST ? _.identity : _.set("state",CASE_DRAFT),_.set('caseFlags.requestFlags',{})))}
    >
      <p>The contacted party will no longer have editing permissions.</p>
      <CaseRequestCard caseRequest={caseRequest} viewOnly />
      <p>Do you wish to proceed?</p>
    </ConfirmDialog>
  )

  const reminderConfirm = useConfirmDialog(
    <ConfirmDialog
      title="Send reminder"
      confirm={<Button highlight icon="email">Send</Button>}
      values={caseRequest.caseFlags}
      action={(v) => jwtPostFetcher(getJwt())("/api/admin/sendRequestMedicalRecordsReminder?requestId="+caseRequest.requestId,v)}
      notify="Reminder sent successfully."
    >
      <p>Remind the listed party to complete requested actions on the following case:</p>
      <CaseRequestCard caseRequest={caseRequest} viewOnly />
      <p>The reminder will be sent to:</p>
      <ContactSelectBoxes path="contactRelationType">
        <ContactSelectInput
          raw
          value={true}
          fieldValue={true}
          caseRequest={caseRequest}
          relationKind={REQUESTED}
          viewOnly
        >
        </ContactSelectInput>
      </ContactSelectBoxes>
      <TextInput
        textarea
        autoResize
        label="Message"
        path="message"
        descText="This will be included in the reminder email."
        placeholder="Insert custom message here."
      />
    </ConfirmDialog>
  )

  const intl = useIntl()

  if (caseRequest.state !== WAITING_MEDICAL_RECORDS && !isPendingAskingMore(caseRequest)) {
    return null
  }

  const contact = getContact(intl, caseRequest,REQUESTED)

  return <WaitingBox small={small}>
    <div className="waitingBoxContent">
      <Icon icon="clock"/>
      <h4>
        Waiting for updates
      </h4>
      <p><b className='fs-exclude'>{contact.name} ({contact.relationType.charAt(0).toUpperCase() + contact.relationType.substring(1)})</b> has been requested to update this case. You will be notified as soon as they are finished.</p>
    </div>
    <div className="buttonsContainer">
      <Button small highlight icon="email" onClick={reminderConfirm}>Send a reminder</Button>
      <Button small hover="alert" css="margin-top:0.5rem" onClick={revokeConfirm}>Revoke request</Button>
    </div>
  </WaitingBox>
}

export const WaitingBox = styled.div`
  background: ${getColor('lightYellow')};
  color: rgba(0,0,0,0.65);
  display: flex;
  align-items: center;
  padding: 1.5rem;
  margin-bottom: 2rem;
  border: 1px solid ${getColor('gray210')};
  border-radius: 6px;
  ${props => props.small == true && css`
    padding: 12px;
    margin-bottom: 1rem;
    border: 0px;
  `}
  .waitingBoxContent {
    position: relative;
    padding-left: 5.5rem;
    p {
      margin-bottom: 0;
    }
    ${Icon} {
      font-size: 62px;
      position: absolute;
      left: 0;
      top: 0;
    }
    ${props => props.small == true && css`
    padding-left: 3.8rem;
    ${Icon} {
      font-size: 24px;
      width: 3rem;
      height: 3rem;
      line-height: 3rem;
      text-align: center;
      margin-right: 1.5rem;
      color: black;
      background: rgba(0,0,0,0.075);
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 0;
    }
  `}
  }
  .buttonsContainer {
    flex-shrink: 0;
    margin-left: 1rem;
    ${Button} {
      display: block;
      margin-left: 0;
      width: 100%;
    }
  }
`
