import { Button, Icon, SplitColumnsContainer } from '@startlibs/components';
import {
  FormValue,
  RadioboxGroup,
  TextInput,
  ToggleCheckbox
} from '@startlibs/form';
import { media } from '@startlibs/utils';
import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';
import {CategoriesInput} from '../../experts/hooks/useEditCategories'
import {ConfirmDialog, useConfirmDialogWithProps} from '../../../hooks/useConfirmDialog'
import {DIVISION, OPERATOR, SUPER} from '../../../enums/UserRole'
import {InfoBox} from '../../../components/InfoBox'
import { RoleRadioBox } from '../../../components/RoleRadioBox';
import {buildValidation, confirmEmails, emailValidation, required} from '../../../utils/validation'
import {getJwt} from '../../../hooks/useJwt'
import { jwtPostFetcher } from '../../../utils/authFetch';

const RoleRadioGroup = styled(RadioboxGroup)`
  display: flex;
  align-items: stretch;
  ${media.max(480)`
    flex-wrap: wrap;
  `}
`
const isEmptyCategories = v => !v.filter(v => v && !_.isString(v)).length

const preValidation = buildValidation({
  firstName: [(v,k,values) => v.trim() === "" && required()],
  lastName: [(v,k,values) => v.trim() === "" && required()],
  email: [required, emailValidation],
  confirmEmail: [(v,k, values) => !values.id && confirmEmails('email')(v,k,values)],
  role: [required],
  categories: [(v,k,values) => values.role === DIVISION && isEmptyCategories(v) && required()],
})

const formatCategories = _.update('categories',_.map(_.get('id')))

export const useEditStaff = (role,onSuccess) => {
  const onFailure = (e,[resp,{status}],form) => status === 409 && form.setError("email","There's an user already registered in the system with that email.")

  return useConfirmDialogWithProps((values) =>
    <ConfirmDialog
      onFailure={onFailure}
      values={values}
      action={(v) =>
        values.id
          ? jwtPostFetcher(getJwt())("/api/admin/user",formatCategories(v), {method:"PUT"})
          : jwtPostFetcher(getJwt())("/api/admin/user",formatCategories(v))
      }
      transform={_.flow(
        (v) => v.role === SUPER ? _.set('categories',[],v) : v,
        _.update('email',e => e?.trim())
      )}
      onSuccess={onSuccess}
      notify={values.id ? "Changes saved successfully." : "User invited successfully."}
      preValidation={preValidation}
      title={values.id ? "Edit user details" : "Add new user"}
      confirm={<Button highlight>{values.id ? "Save changes" : "Add new user"}</Button>}
      css="max-width: 48rem;"
    >
      <SplitColumnsContainer viewportMinWidth={480}>
        <TextInput path="firstName" label="First name" mandatory placeholder="Ex: John" />
        <TextInput path="lastName" label="Last name" mandatory placeholder="Ex: Doe" />
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={480}>
        <TextInput path="email" label="Email" mandatory locked={values.id} disabled={values.id} placeholder="Ex: your@email.com" />
        <TextInput path="confirmEmail" label="Confirm email" mandatory locked={values.id} disabled={values.id} raw={values.id} value={values.email} placeholder="Ex: your@email.com" />
      </SplitColumnsContainer>
      <RoleRadioGroup path="role" label="User role" mandatory>
        {role === SUPER &&
          <RoleRadioBox
            fieldValue={SUPER}
            label="Super admin"
            permissionList={<>
              <li><Icon icon="check" /> Access to cases (all)</li>
              <li><Icon icon="check" /> Manage staff (all)</li>
              <li><Icon icon="check" /> Notification settings</li>
              <li><Icon icon="check" /> Access to audit log</li>
            </>}
          />
        }
        <RoleRadioBox
          fieldValue={DIVISION}
          label="Division admin"
          permissionList={<>
            <li><Icon icon="check" /> Access to cases (division)</li>
            <li><Icon icon="check" /> Manage staff (division)</li>
            <li className="false"><Icon icon="unavailable" /> Notification settings</li>
            <li className="false"><Icon icon="unavailable" /> Access to audit log</li>
          </>}
        />
        <RoleRadioBox
          fieldValue={OPERATOR}
          label="Operator"
          permissionList={<>
            <li><Icon icon="check" /> Access to cases (division)</li>
            <li className="false"><Icon icon="unavailable" /> Manage staff</li>
            <li className="false"><Icon icon="unavailable" /> Notification settings</li>
            <li className="false"><Icon icon="unavailable" /> Access to audit log</li>
          </>}
        />
      </RoleRadioGroup>
      <ToggleCheckbox
        path="notification"
        label="Receive administrative email notifications"
        descText="According to notification settings and division restrictions (if applicable)"
      />
      <FormValue path="role">{role => <>
        {role && role !== SUPER
          ? <CategoriesInput mandatory={role === DIVISION} userRelated />
          : (role === SUPER && values.id && values.role !== SUPER)
          ? <InfoBox lightYellow>
            By changing an user into a super admin role it will no longer be tied to any division, but it will be able
            to access all cases from all divisions.
          </InfoBox>
        : ""}
        {
          role === OPERATOR && <FormValue path="categories">{ categories =>
            isEmptyCategories(categories) &&
            <InfoBox lightBlue withIcon><Icon icon="info"/> Operators that are not tied to any division can access cases from all divisions.</InfoBox>
          }</FormValue>
        }
      </>}</FormValue>
    </ConfirmDialog>
  )

}
