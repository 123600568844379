let resolveUploaderJwt = null
let uploaderJwt = new Promise((res) => resolveUploaderJwt = res)


export const setUploaderJwt = (newUploaderJwt,sessionKey) => {
  uploaderJwt = newUploaderJwt
  resolveUploaderJwt(uploaderJwt)
  if (sessionKey) {
    window.localStorage.setItem(sessionKey+"-uploaderJwt",newUploaderJwt)
  }
}
export const getUploaderJwt = () => uploaderJwt
