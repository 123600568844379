import {FormContext, FormValue} from '@startlibs/form'
import React, {useContext} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';
import {AddButton} from './AddButton';
import {callIfFunction, ifUndefined} from '@startlibs/utils'
import {DraggableContainer, FlipList} from '@startlibs/components'
import {defaultListAnimation} from '../UIConfig/defaultListAnimation'
import { INSTITUTION } from '../enums/UploaderManagement';
import { OTHER_FACILITIES } from '../enums/MedicalRecordLocationType';

const slice = (list, hardLimit) => hardLimit !== undefined ? list.slice(0, hardLimit) : list


const DefaultContainer = ({className,length,...props}) => <div className={className}><FlipList on={length} {...props}/></div>

export const QuestionList = styled((
  {
    disabled,
    whenEmpty,
    getEmpty = () => ({id: Date.now()}),
    path,
    addLabel,
    className,
    filter = _.identity,
    children,
    setItemOrder,
    addButtons = (add, list) =>
      <AddButton onClick={() => add(getEmpty())}>{callIfFunction(addLabel, list ? list.length : 0)}</AddButton>,
    limit = 20,
    hardLimit, 
    step
  }) => {

  const form = useContext(FormContext)

  const max = ifUndefined(hardLimit, limit)

  const Container = setItemOrder ? DraggableContainer : DefaultContainer

  addButtons(getEmpty())

  return <FormValue path={path + ".length"}>{length => {

    let completeList = form.getValue(path) || []
    if((step === INSTITUTION ) && (completeList.length == 0)){
      let id = Date.now();
      completeList.push({id: id, locationType: OTHER_FACILITIES})
    }
    const list = slice(filter(completeList), hardLimit)
    
    return <Container
      disabled={setItemOrder && (list.length <= 1 || disabled)}
      animation={defaultListAnimation}
      className={className}
      setItemOrder={setItemOrder}
      length={list.length}
      ghostScale={1.03}
    >
      {list.map((v,i) => children(v,filter === _.identity ? i : completeList.indexOf(v),list))}
      {
        (!list.length || list.length < max) && !disabled &&
        callIfFunction(addButtons,(value = getEmpty()) => form.setValue(path, (l) => (l || []).concat(value)),form.getValue(path) || [])
      }
      {
        list.length<1 && callIfFunction(whenEmpty)
      }
    </Container>
  }}</FormValue>
})`
position:relative;
`
