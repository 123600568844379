import {
  Button,
  ContextMenu,
  Icon,
  Li,
  Link,
  Tooltip
} from '@startlibs/components';
import {TextInput} from '@startlibs/form'
import { media } from '@startlibs/utils';
import {usePopupToggle, useToggle} from '@startlibs/core';
import React, {useMemo, useState} from 'react';
import _ from 'lodash/fp';
import styled from 'styled-components';
import {BoxPageContainer, BoxPageLayout, BoxPageWrapper, BoxWrapperHeading} from '../../components/BoxPageLayout';
import {DATE_FORMAT} from '../../config/DateFormat'
import {
  DropdownButton,
  EmptyListPlaceholder,
  SearchInput,
  StyledRow,
  StyledTable,
  StyledTableHead,
  TextButton
} from '../../components/StyledTable'
import {NotProvided} from '../steps/ExpertAssignmentStep'
import {Pagination} from '../../components/Pagination'
import {PurviewFooter} from '../../components/PurviewFooter';
import {filterExpert, formatDateNoUTC} from '../../utils/utils'
import {getJwt} from '../../hooks/useJwt'
import {jwtGetFetcher} from '../../utils/authFetch'
import {useDeleteExpertDialog} from './hooks/useDeleteExpertDialog'
import {useResetExpertPasswordDialog} from './hooks/useResetExpertPasswordDialog'
import {willUseSuspense} from '../../hooks/useSuspense'
import {IfLocale} from '../../hocs/IfLocale'
import { lazyProviderInfo } from '../../components/WithProvider';

const ExpertBrowserPage = styled(BoxPageLayout)`
  .heading-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem 2rem;
  }
  ${StyledTable} {
    overflow: visible;
  }
  ${StyledTableHead} {
    position: sticky;
    top: 0;
    z-index: 3;
    background: white;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  ${StyledRow} {
    > div {
      &.name {
        flex-basis: 190px;
        min-width: 140px;
        max-width: 210px;
      }
      &.email {
        flex-grow: 1;
        flex-basis: 180px;
        min-width: 140px;
        max-width: 200px;
      }
      &.specialities {
        flex-grow: 1;
        flex-basis: 180px;
        min-width: 140px;
        max-width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        white-space: nowrap;
        > div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
      }
      &.date-added {
        flex-basis: 84px;
        min-width: 84px;
      }
      &.cases-count {
        flex-basis: 85px;
        min-width: 54px;
      }
      &.actions {
        flex-basis: 160px;
        min-width: 160px;
        .wide-only {
          margin-left: .25rem;
        }
      }
    }
    &:last-child {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    ${media.max(960)`
      > div.actions {
        min-width: 160px;
      }
      .wide-only {
        display: none;
      }
    `}
  }
  ${media.max(840)`
    ${StyledRow} {
      > div {
        &.cases-count {
          position: absolute;
          top: 1.5rem;
          right: 1.5rem;
          border-radius: 5px;
          background-color: rgba(0,0,0,0.05);
          padding: .25rem .5rem;
          text-align: right;
          &:before {
            content: "Open cases: ";
            font-size: 13px;
            display: inline;
          }
        }
        &.name {
          font-size: 1.75rem;
          margin-bottom: .75rem;
          padding-right: 110px;
          max-width: 100%;
          &:before {
            content: "Name: ";
            font-size: 13px;
            display: block;
          }
        }
        &.email {
          margin-bottom: .75rem;
          &:before {
            content: "Email: ";
          }
        }
        &.specialities {
          min-width: 0;
          max-width: 100%;
        }
        &.date-added {
          display: block;
          padding-bottom: 1.5rem;
          &:before {
            content: "Added on: ";
          }
        }
      }
  `}
  ${media.max(520)`
    .heading-actions {
      flex-wrap: wrap;
      ${Button} {
        margin-top: 1rem;
        margin-left: auto;
      }
    }
    ${StyledRow} {
      > div {
        &.cases-count {
          position: absolute;
          bottom: 4rem;
          right: 2rem;
          top: auto;
          text-align: right;
          padding: 0 0 .25rem;
          margin-bottom: 1rem;
          background: transparent;
          border-radius: 0;
          &:before {
            display: block;
          }
        }
        &.name {
          padding-right: 0;
        }
      }
    }
  `}
}
`

export const PaginationWrapper = styled.div`
  margin-top: 1.5rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const useAuthSuspense = willUseSuspense(() =>
  jwtGetFetcher(getJwt())(`/api/experts`)
)

export const ExpertList = () => {

  const [experts,setExperts] = useState(useAuthSuspense())

  const query = useToggle('')
  const pageToggle = useToggle(1)

  const filteredExperts = useMemo(() =>
      query.isOpen
        ? experts.filter(filterExpert(query.isOpen))
        : experts
    , [query.isOpen, experts])

  return <ExpertBrowserPage>
    <BoxPageContainer>
      <BoxPageWrapper>
        <BoxWrapperHeading className="heading-actions">
            <SearchInput>
              <Icon icon="search"/>
              <TextInput raw value={query.isOpen} setValue={(v) => { pageToggle.openWith(1); query.openWith(v)}} placeholder="Search"/>
            </SearchInput>
            <Button.Link highlight icon="plus-circle" to="new">Add expert</Button.Link>
        </BoxWrapperHeading>
        {
          filteredExperts.length > 0 && experts.length > 0 ?
            <StyledTable>
              <StyledTableHead>
                <div className="name">Name</div>
                <div className="email">Email</div>
                <IfLocale not contains="LEGAL"><div className="specialities">Specialties</div></IfLocale>
                <div className="date-added">Added on</div>
                <div className="cases-count">Open cases</div>
                <div className="actions"/>
              </StyledTableHead>
              {
                filteredExperts.slice((pageToggle.isOpen-1)*10,pageToggle.isOpen*10).map(expert => <ExpertRow setExperts={setExperts} expert={expert} key={expert.id}/>)
              }
            </StyledTable>
            : (
              <EmptyListPlaceholder>
                {experts.length > 0 && filteredExperts.length === 0 &&
                <span>There are no experts matching your search criteria.</span>}
                {experts.length === 0 && <span>There are no experts registered in this system yet.</span>}
              </EmptyListPlaceholder>
            )
        }
        <PaginationWrapper>
          <Pagination setPageParam={(page) => () => pageToggle.openWith(page)}
                      currentPage={pageToggle.isOpen}
                      totalPages={Math.ceil((Number(filteredExperts.length)) / 10)}/>
        </PaginationWrapper>
      </BoxPageWrapper>
    </BoxPageContainer>
    <PurviewFooter />
  </ExpertBrowserPage>
}

const ExpertRow = ({expert,setExperts}) => {
  
  const providerInfo = lazyProviderInfo.read()
  
  const openResetPassword = useResetExpertPasswordDialog(expert)
  const openDeleteDialog = useDeleteExpertDialog(expert,() => setExperts(_.without([expert])))
  const contextMenu = usePopupToggle()

  const dateAdded = formatDateNoUTC(new Date(expert.whenCreatedEpochMilli), DATE_FORMAT())
  const expertName = expert.firstName + " " + expert.lastName

  return <StyledRow>
    <div className="name">{expertName.length > 2 ? expertName : <NotProvided />}</div>
    <div className="email">{expert.expertEmail}</div>
    <IfLocale not contains="LEGAL"><div className="specialities">
      {(expert.specialization||[]).join(", ").length > 0 &&
      <Tooltip whenEllipsis={({target}) => target.offsetWidth < target.scrollWidth} content={(expert.specialization||[]).join(", ")}>
        {(expert.specialization||[]).join(", ")}
      </Tooltip>
      }
    </div></IfLocale>
    <div className="date-added">{dateAdded}</div>
    <div className="cases-count">{expert.openCases}</div>
    <div className="actions">
      <TextButton as={Link} to={`view/${expert.id}`}>View</TextButton>
      <TextButton as={Link} to={`edit/${expert.id}`}>Edit</TextButton>
      <DropdownButton onClick={contextMenu.open}>
        <Icon icon="arrow-down"/>
        {
          contextMenu.isOpen && <ContextMenu>
            <Li label="Remove expert" onClick={openDeleteDialog}/>
            {((!providerInfo.ssoEnabled) || expert.expertEmail.indexOf('@purview') >= 0) ? <Li label="Reset password" onClick={openResetPassword}/>  : null}
          </ContextMenu>
        }
      </DropdownButton>
    </div>
  </StyledRow>
}
