import {Button, Icon} from '@startlibs/components'
import {RichText, TextInput} from '@startlibs/form'
import {darken} from 'polished';
import {getColor} from '@startlibs/utils'
import React, { useEffect } from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components'
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import {useAutoScrollAndFocus} from '../../hooks/useAutoScrollAndFocus'
import { RTF_TOOLBAR_OPTIONS } from '../../utils/utils';
import { useState } from 'react';
import { FormSlateEditor } from '../../components/slateEditor/FormSlateEditor';

const QuestionContainer = styled.div`
  position: relative;
  margin-bottom: 2rem;
  box-shadow: 0 0 0 1px ${getColor('gray210')};
  padding: 1.25rem;
  background: ${getColor('gray240')};
  border-radius: 6px;
  ${TextInput} ~ ${TextInput} {
    margin-top: .75rem;
  }
`

const RemoveIcon = styled(Icon)`
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  font-size: 18px;
  color: ${getColor('gray240')};
  background: ${getColor('gray120')};
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 50%;
  text-align: center;
  :hover {
    background: ${props => darken(0.05, getColor('gray120')(props))};
  }
  :active {
    background: ${props => darken(0.08, getColor('gray120')(props))};
  }
`

export const QuestionAndAnswer = ({path, className, i, form, disabled, onMouseDown, onTouchStart, autoFocus, testkey,questionsConsultant, isAdmin = false,requestId}) => {

  const confirmRemove = () => form.setValue(path, _.pullAt([i]))
  const [value, setValue] = useState(questionsConsultant ? questionsConsultant[i].answer : form.getValue([...path, i, "answer"]))
  const [focus, setFocus] = useState(false)

  const handleEditorFocus = (event, change) => {
    setFocus(true)
  };

  useEffect(() => {
    // Remove div tags from question. This could be beacuse:
    // 1. A rollback from Updated Version or;
    // 2. Opening a case created with the Updated Version on a cached version (this one)
    // if(form.getValue([...path, i, "question"]).includes('<div>')){
    //   form.setValue([...path, i, "question"], form.getValue([...path, i, "question"]).replace(/<div>/g, "").replace(/<\/div>/g, ""))
    // }
  },[])

  const confirmDelete = useConfirmDialog(
    <ConfirmDialog
      title="Confirm question deletion"
      confirm={<Button alert>Delete</Button>}
      action={confirmRemove}
    >
      <p>
        <b>Are you sure you want to delete this question?</b> Notice that at least one written question is needed for generating a default expert view report.
      </p>
      <p>Once confirmed this cannot be undone.</p>
    </ConfirmDialog>
  )

  const removeQuestion = () => {
    if (form.getValue([...path, i, 'question']) || form.getValue([...path, i, 'answer'])) {
      confirmDelete()
    } else {
      confirmRemove()
    }
  }

  return <QuestionContainer
    onMouseDown={onMouseDown}
    onTouchStart={onTouchStart}
    className={className}
  >
    <div data-drag={false}>
      <FormSlateEditor
        // ref={inputRef}
        path={[...path, i, "question"]}
        placeholder="Question"
        disabled={disabled}
        css="margin-bottom: .5rem;"
        formatTools={[]}
        autoHideToolbar
        minHeight={30}
        onFocus={handleEditorFocus}
        focus={autoFocus ? autoFocus : focus}	
        singleLineSimpleInput={true}
      />
    </div>
    {/* {isAdmin && <div data-drag={false}> */}
    <div data-drag={false}>
      <FormSlateEditor
        path={[...path, i, "answer"]}
        placeholder="Answer"
        disabled={disabled}
        formatTools={RTF_TOOLBAR_OPTIONS}
        autoHideToolbar
        css="margin-top: .75rem;"
        useHtml={true}
        onFocus={handleEditorFocus}
        focus={focus}
        requestId={requestId}
      />
    </div>
    {!disabled && <RemoveIcon data-drag={false} icon='x' onClick={removeQuestion}/>}
  </QuestionContainer>
}
