import React, {Suspense} from 'react'
import styled from 'styled-components'
import {Loading, PageLoader} from '@startlibs/components'
import {useToggle} from '@startlibs/core'

export const LoadingPage = styled(({className}) =>
  <div className={className}>
    <Loading size={40} borderWidth={6}/>
  </div>
)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  * {
    margin-left: auto;
    margin-right: auto;
  }
  img {
    max-width: 11rem;
    margin-bottom: 4rem;
  }
`

const PurviewPageLoader = styled(PageLoader)`
  background: #00D2FF;
`

export const PageLoadingSuspense = ({children}) => {
  const usedFirst = useToggle()
  return usedFirst.isOpen
    ? <Suspense fallback={<LoadingPage/>}>{children}</Suspense>
    : <Suspense fallback={<LoadingPage/>}>{children}</Suspense>
}

