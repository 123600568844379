import React, {useEffect, useRef} from 'react'

export const useAutoScroll = (check) => {

  const inputRef = useRef()

  useEffect(() => {
    if (check) {
      setTimeout(() => inputRef.current.scrollIntoView({block: 'center', behavior: 'smooth'}), 50)
    }
  }, [])

  return inputRef

}