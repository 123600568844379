import { Button, Icon, Tooltip } from '@startlibs/components';
import React from 'react';
import { ToolsContainer } from '../../SlateStyles';
import styled from 'styled-components/macro'
import { getColor } from '@startlibs/utils';

const TableToolbar = styled.div `
  background: white;
  z-index: 10;
  padding: 0.25rem;
  border-radius: 7px;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.10), 0px 0px 2px 0px rgba(0,0,0,0.25);
  display: inline-flex;
  align-items: center;
  user-select: none;
  margin-top: 0.5rem;
  cursor: default;
  ${Icon} {
    font-size: 22px;
    width: 2.5rem;
    line-height: 2.5rem;
    height: 2.5rem;
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    margin: 0 1px;
    :hover {
      cursor: pointer;
      background: rgba(0,0,0,0.05);
    }
    :first-child, :last-child {
      margin: 0;
    }
  }
`
export const TableToolSeparator = styled.div`
  border-left: 1px solid ${getColor('gray230')};
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  height: 1.5rem;
  user-select: none;
`

const exec = (func, ...args) => (e) => {
  e && e.preventDefault();
  return func(...args);
};

export const Cardbar = (props) => {
  return (
    <TableToolbar contentEditable={false}>
      {/* <Button> */}
        {props.children}
        {props.delete && (
          <>
            <TableToolSeparator/>
            <Tooltip content="Delete table"><Icon icon='delete' onMouseDown={exec(props.delete)} /></Tooltip>
          </>
        )}
      {/* </Button.Group> */}
    </TableToolbar>
  );
};
