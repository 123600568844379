import React from 'react'
import { FormBox, RemoveFormBox } from '../../components/FormBox';
import {useRemoveQuestion} from './useRemoveQuestion'
import _ from 'lodash/fp'

export const locationIsNotEmpty = ({id,locationType,...value}) => Object.values(value).filter(_.identity).length>0

export const MedicalRecordLocationForm = ({children,form,path,i,disabled, locationIsNotEmpty = locationIsNotEmpty, toggle}) => {
  const removeQuestion = useRemoveQuestion(form,path,i,locationIsNotEmpty, "locations")

  return <FormBox>
    {!disabled && <RemoveFormBox onClick={() => {form.getValues().records.length > 1 ? removeQuestion() : toggle()}}/>}
    {children}
  </FormBox>
}
