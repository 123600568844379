import {ConfirmDialog, Field, FormValue, useConfirmDialog} from '@startlibs/form';
import {useToggle} from '@startlibs/core'
import React, {useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import {CopyButton, CopyLinkComponent} from 'sharecomponents/src/pages/ShareDialog'
import {ExpirationSelect} from 'sharecomponents/src/components/ExpirationSelect'
import {CaseRequestCard} from '../../CaseRequestCard'
import {getJwt} from '../../../hooks/useJwt';
import {jwtPostFetcher} from '../../../utils/authFetch';
import {setNotification} from '../../../components/Notifications'

export const useReadOnlyShare = (caseRequest) => {

  const shareLink = useToggle()

  return useConfirmDialog((closeDialog) =>
    <ConfirmDialog
      values={{expirationTime: 0}}
      title="Share case"
      alternativeButton={() => <CopyButton url={shareLink.isOpen} highlight onCopy={(supported) => { closeDialog(); setNotification(supported ? 'Shareable link copied to clipboard' : "You've finished the share function")}}/>}
      className="JS-copy-focus-container"
      cancelLabel="Cancel"
    >{(form) => <>
      <p>Review the details below for sharing the case in read only mode:</p>
      <CaseRequestCard caseRequest={caseRequest}/>
      <FormattedMessage defaultMessage="The recipient will be asked to provide the patient's last name and date of birth." description='useReadOnlyShare share dialog security message' />
      <Field label="Security Options">
        <div css="margin-top: .25rem;">
          <ExpirationSelect />
        </div>
      </Field>
      <FormValue path='expirationTime'>{expirationTime =>
        <Link shareLink={shareLink} expirationTime={expirationTime} caseRequest={caseRequest} form={form}/>
      }</FormValue>
    </>}</ConfirmDialog>, [shareLink.isOpen]
  )
}

const Link = ({expirationTime, shareLink, caseRequest, form}) => {

  const loadingShareableLink = useToggle()

  useEffect(() => updateShareableLink(), [expirationTime])

  const updateShareableLink = () => {
    shareLink.close()
    form.clearErrors()
    loadingShareableLink.wait(
      jwtPostFetcher(getJwt())( `/api/admin/readOnlyAccess?requestId=${caseRequest.requestId}`,{expirationInHours:expirationTime})
        .then(({url}) => shareLink.openWith(url))
        .catch(() => {
          form.setError('shareLink', 'Link failed to load')
          shareLink.close()
        })
    )
  }

  return <>
    <CopyLinkComponent url={shareLink.isOpen} label='Copy the link below to share this case:' loadingShareableLink={loadingShareableLink.isOpen}/>
    </>
}
