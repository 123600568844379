import { ContextMenu, Icon, Li, ListHeading } from '@startlibs/components';
import { getColor } from '@startlibs/utils';
import {usePopupToggle, useToggle} from '@startlibs/core'
import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';
import {ManageInstitutionsDialog} from '../ManageInstitutionsDialog'
import { ReportTextButton } from './EditLayoutButton';
import {getAddressLine} from '../lazyInstitutions'
import {getJwt} from '../../../../hooks/useJwt'
import {getReportResource} from '../../../../utils/utils'
import {jwtPostFetcher} from '../../../../utils/authFetch'
import { lazyProviderInfo } from '../../../../components/WithProvider';

const InstitutionMenuOption = styled(Li)`
  max-width: 320px;
  overflow-X: hidden;
  .item-content {
    display: flex;
    align-items: center;
    padding-right: 1.5rem;
    .institution-logo {
      flex-basis: 3rem;
      width: 3rem;
      height: 3rem;
      flex-shrink: 0;
      border-radius: 50%;
      border: 1px solid ${getColor('gray210')};
      margin-right: 1rem;
      padding: .25rem;
      ${props => props.image && `
        background-image: url('${props.image}');
      `}
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-origin: content-box;
      background-color: white;
    }
    .institution-details {
      flex-basis: 4rem;
      flex-grow: 1;
      max-width: calc(100% - 2rem);
      .institution-name {
        font-size: 14px;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .address-line {
        color: ${getColor('gray150')};
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
      }
    }
    .default-label {
      color: ${getColor('gray180')};
    }
  }
  :hover {
    background: ${getColor('main')};
    color: white;
    .item-content {
      .default-label, .address-line {
        color: white;
      }
    }
  }
`

const MenuFooter = styled(Li)`
  background: ${getColor('gray240')};
  border-top: 1px solid ${getColor('gray210')};
  color: ${getColor('gray120')};
  padding: .5rem 0;
  :hover {
    background: ${getColor('main')};
    color: white;
  }
`

export const InstitutionDetailsSelector = ({caseRequest,setCaseRequest, institutions, defaultInstitution}) => {
  const updateReportInstitution = (institution) => jwtPostFetcher(getJwt())("/api/admin/reportInstitutionDetails?requestId=" + caseRequest.requestId, institution, {method: "PUT"})
    .then(() => {
      setCaseRequest(_.set('institutionDetails', institution))
    })


  const institutionsMenu = usePopupToggle()
  const manageInstitutions = useToggle()
  const providerInfo = lazyProviderInfo.read()

  return <>
    <ReportTextButton onClick={institutionsMenu.open}>
      Institution details <Icon className="context-icon" icon="arrow-down" />
      {
        institutionsMenu.isOpen &&
        <ContextMenu css="padding-bottom: 0;">
          <ListHeading>
            <span>Select institution details</span>
          </ListHeading>
          {
            _.map(institution => <InstitutionMenuOption
              onClick={() => updateReportInstitution(institution)}
              image={institution.logoPath ? getReportResource(institution.logoPath) : providerInfo.logoURL}
              label={<div className="item-content">
                {institution.id === caseRequest.institutionDetails?.id && <Icon icon="check" />}
                <div className="institution-logo"/>
                <div className="institution-details">
                  <div className="institution-name">
                    <strong>{institution.name}</strong>
                    {institution.id === defaultInstitution.id && <span className="default-label"> (Default)</span>}
                  </div>
                  <div className="address-line">{getAddressLine(institution)}</div>
                </div>
              </div>}
            />,institutions)
          }
          <MenuFooter
            label="Manage options"
            icon="settings"
            onClick={manageInstitutions.open}
          />
        </ContextMenu>
      }
    </ReportTextButton>

    {
      manageInstitutions.isOpen &&
      <ManageInstitutionsDialog closeDialog={manageInstitutions.close} institutions={institutions} defaultInstitution={defaultInstitution} providerInfo={providerInfo}/>
    }
  </>
}
