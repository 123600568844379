import {_s, getColor} from '@startlibs/utils';
import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';
import { NoteInfo } from './forms/NoteInfo';

export const EmptyNotesText = styled.div`
  color: ${getColor('gray150')};
  font-size: 13px;
  text-align: center;
  margin: 1rem;
`

export const CaseNotesInformation = ({caseRequest, caseNotes, setCaseNotes, formRef, noteId}) => {
  
  return <>
    {caseNotes.length === 0 && <EmptyNotesText>No notes have been added to case</EmptyNotesText>}
    {caseNotes.map((note, i) => 
      <NoteInfo
        caseRequest={caseRequest}
        caseNotes={caseNotes} 
        setCaseNotes={setCaseNotes}
        formRef={formRef}
        i={i} 
        key={note.id}
        note={note}
        noteId={noteId}
      />
    )}
  </>

}

