import {AutoComplete, Errors, RichText, TextInput, WithForm, WithImageInput} from '@startlibs/form';
import {Button, Icon, Loading, SplitColumnsContainer} from '@startlibs/components'
import {callIfFunction, ifUndefined} from '@startlibs/utils';
import {useNavigate, useLocation, useParams} from 'react-router'
import {useRefState, useToggle} from '@startlibs/core'
import React, {useRef, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import _ from 'lodash/fp'
import {BoxPageContainer, BoxPageWrapper, BoxWrapperFooter, BoxWrapperHeading} from '../../components/BoxPageLayout';
import {ImageInputContainer, ProfilePage, ThumbnailContainer} from './components/ExpertProfileLayout';
import {PurviewFooter} from '../../components/PurviewFooter';
import {buildValidation, confirmEmails, emailValidation, required} from '../../utils/validation';
import {getJwt} from '../../hooks/useJwt'
import {jwtGetFetcher, jwtPostFetcher} from '../../utils/authFetch'
import {useResetExpertPasswordDialog} from './hooks/useResetExpertPasswordDialog'
import {willUseSuspense} from '../../hooks/useSuspense'
import {lazyExperts} from '../steps/finalReport/reportPages/lazyExperts'
import {ConfirmEmailInput} from '../../components/ConfirmEmailInput'
import {IfLocale, useIsLocale} from '../../hocs/IfLocale'
import { lazyProviderInfo } from '../../components/WithProvider';
import { isRichText, RTF_TOOLBAR_OPTIONS_STARTLIBS, safeMultipleNewLineToBr, safeMultipleNewLineToBrOnly } from '../../utils/utils';

// Specialties autocomplete:
let fetchedSuggestions = null

const fetchSuggestions = (s) => {
  const fetch = fetchedSuggestions || jwtGetFetcher(getJwt())("/api/specializations").then(_.map(({label}) => label))
  fetchedSuggestions = fetch
  return fetch.then(specializations =>
    specializations.filter(t => t.toLowerCase().indexOf(s) >= 0)
  ).then(l => l.indexOf(s) >= 0 ? l : l.concat([{newValue: s}]))
}

const getLabel = (v) => _.isString(v.newValue) ? `Create option "${v.newValue}"` : v
const transform = (l) => l ? l.map(v => (v && v.newValue) || v) : l

/************************/

// Image upload
const uploadFetcher = (expertId) => (file) => {
  const formData = new FormData()
  formData.append('file', file)
  return jwtPostFetcher(getJwt())(`/api/expert/${expertId}/picture`, formData).then(console.log)
}

export const checkImage = (file) => {
  if (file.size > 4194304/4) {
    return ['Your file needs to be smaller than 1mb.']
  }
  if (['image/gif', 'image/png', 'image/jpeg', 'image/jpg'].indexOf(file.type.toLowerCase()) < 0) {
    return ['Invalid file format. Please select a .JPG or .PNG file.']
  }
}

/*****************/


const useExpertViewSuspense = willUseSuspense((id) =>
  (!id && !_.isString(id))
    ? Promise.resolve()
    // : jwtGetFetcher(getJwt())(`/api/expert/${id}`)
    : id ? jwtGetFetcher(getJwt())(`/api/expert/${id}`) : jwtGetFetcher(getJwt())(`/api/expert`)
    .then(_.update('info.specialization', (v) => v || []))
)

const EMPTY_EXPERT = {info: {specialization: []}}
// const updateResumeToRTF = (expert) => _.update('info.resume', (resume) => (resume && resume.includes("\n")) ? safeMultipleNewLineToBr(resume)  : resume, expert)
// const updateResumeToRTF = (expert) => _.update('info.resume', (resume) => resume && isRichText(resume) && resume.includes("\n") ? safeMultipleNewLineToBrOnly(resume) : isRichText(resume) ? resume : safeMultipleNewLineToBr(resume),expert)
const updateResumeToRTF = (expert) => _.update('info.resume', (resume) => resume && resume.includes("\n") ? isRichText(resume) ? safeMultipleNewLineToBrOnly(resume) : safeMultipleNewLineToBr(resume) : resume,expert)
const addConfirmEmail = (expert) => _.update("picture",(image) => !!image, expert ? updateResumeToRTF({...expert, confirmEmail: expert.email}) : updateResumeToRTF(expert))

export const EditExpert = ({action, cancelDefaultUrl = "/admin/experts",onSuccess, isExpert = false, footer, isIncomplete, emptyId}) => {
  const {id} = useParams()
  const expertId = id || emptyId;
  const location = useLocation()
  const navigate = useNavigate()
  const onSuccessWithDefault = onSuccess || (() => navigate(location.state?.nextLocation || ("/admin/experts/view/"+id)))
  const providerInfo = lazyProviderInfo.read()
  const expert = useExpertViewSuspense(expertId)
  const formRef = useRef()
  const openResetPassword = useResetExpertPasswordDialog(expert)
  const fileToUpload = useRefState()
  const uploadingFile = useToggle()
  const errorRef = useRef()
  
  const checkImageAndScroll = (file) => {
    if (file.size > 4194304/4) {
      setTimeout(() => errorRef.current.scrollIntoView({block: 'center', behavior: 'smooth'}), 50)
      return ['Your file needs to be smaller than 1mb.']
    }
    if (['image/gif', 'image/png', 'image/jpeg', 'image/jpg'].indexOf(file.type.toLowerCase()) < 0) {
      setTimeout(() => errorRef.current.scrollIntoView({block: 'center', behavior: 'smooth'}), 50)
      return ['Invalid file format. Please select a .JPG or .PNG file.']
    }
  }

  const isNotLegal = useIsLocale({not:true,contains:"LEGAL"})

  const preValidation = buildValidation({
      firstName: required,
      lastName: required,
      email: [required, emailValidation, confirmEmails('confirmEmail')],
      confirmEmail: required,
      'info.specialization': [v => (!v || !v.length) && isNotLegal && required()],
    }
  )


  const setFile = (file) => {
    fileToUpload.set(file)
    const image = URL.createObjectURL(file)
    formRef.current.setValue("picture",image)
    return Promise.resolve({image})
  }

  const onSubmit = (values) => {
    if (fileToUpload.get()) {
      uploadingFile.open()
    }
    return action(values)
      .then((expert) => {
        const uploadId = expert.id || id
        if (fileToUpload.get()) {
          return uploadFetcher(uploadId)(fileToUpload.get()).then(() => expert)
        } else {
          return expert
        }
      })
      .finally(() => uploadingFile.close())
  }

  const pictureId = ifUndefined(expert && expert.id,id)


  const onFailure = (e,[resp,{status}],form) => status === 409 && form.setError("email","There's an expert already registered in the system with that email.")

  return <WithForm
    alwaysSave
  onFailure={onFailure}
    onSuccess={(values,resp) => {
      lazyExperts.refresh()
      onSuccessWithDefault(values,resp)
    }} ref={formRef} preValidation={preValidation} action={onSubmit}
                   transform={_.update("info.specialization",_.filter(_.get("length")))}
                   values={addConfirmEmail(expert) || EMPTY_EXPERT}>{form =>
    <ProfilePage>
      <BoxPageContainer>
      <BoxPageWrapper>

        <BoxWrapperHeading>
          {
            isIncomplete
              ? <h2>Please complete your profile</h2>
              : expert
              ? <h2>Expert: {(expert.firstName || expert.lastName) ? <span>{expert.firstName} {expert.lastName}</span> : <span>{expert.email}</span>}</h2>
              : <h2><FormattedMessage defaultMessage='Add a new expert' description='EditExpert title for new'/></h2>
          }
        </BoxWrapperHeading>

        <div className="profile-content">

          <div className="fields-wrapper">
            <SplitColumnsContainer viewportMinWidth={850}>
              <TextInput
                readOnly={form.isLoading}
                autoFocus
                label="First name:"
                path="firstName"
                placeholder="John"
                mandatory
              />
              <TextInput
                readOnly={form.isLoading}
                label="Last name:"
                path="lastName"
                placeholder="Doe"
                mandatory
              />
            </SplitColumnsContainer>
            <SplitColumnsContainer viewportMinWidth={850}>
              <TextInput
                readOnly={form.isLoading}
                label="Email:"
                path="email"
                placeholder="expert@email.com"
                mandatory
              />
              <ConfirmEmailInput
                readOnly={form.isLoading}
                label="Confirm email:"
                path="confirmEmail"
                confirmingPath='email'
                placeholder="expert@email.com"
                mandatory
              />
            </SplitColumnsContainer>
            <SplitColumnsContainer
              viewportMinWidth={850}
              className="split-wrapper"
            >
              <TextInput
                readOnly={form.isLoading}
                label="Phone:"
                constraint={/[^\d-,+ ]/}
                path="info.phone"
                placeholder="+1 123-456-789"
              />
              <IfLocale not contains="LEGAL"><AutoComplete
                mandatory
                readOnly={form.isLoading}
                label="Specialization(s):"
                confirmIfValueKeys={['Tab']}
                path="info.specialization"
                fetchSuggestions={fetchSuggestions}
                getLabel={getLabel}
                transform={transform}
                bellowDescText="Use comma, enter or tab to add multiple."
                className="specializations-input"
              /></IfLocale>
            </SplitColumnsContainer>
            {/* <TextInput
              readOnly={form.isLoading}
              label={<FormattedMessage defaultMessage="Resume:" description="expert resume label"/>}
              path="info.resume"
              textarea
              autoResize
            /> */}
            <RichText
              path="info.resume"
              label={<FormattedMessage defaultMessage="Resume:" description="expert resume label"/>}
              formatTools={RTF_TOOLBAR_OPTIONS_STARTLIBS}
              autoHideToolbar
              disabled={form.isLoading}
            />
          </div>

          <div className="image-wrapper">
            <WithImageInput preValidation={checkImageAndScroll} uploadFetcher={setFile}
                            path={["picture"]}>{(input, {image, isLoading}) =>
              <ImageInputContainer hasImage={image} image={image===true ? `/api/expert/${pictureId}/picture?t=${getJwt()}&no-cache=${expert.picture}` : image}>
                {!(uploadingFile.isOpen || form.isLoading || isLoading) && input}
                <ThumbnailContainer css={image === true ? `background-image:url('/api/expert/${pictureId}/picture?t=${getJwt()}&no-cache=${expert.picture}');` : (image && `background-image:url('${image}')`)}>
                  {!image && <Icon icon="physician"/>}
                  {(uploadingFile.isOpen || isLoading) && <Loading white absolute/>}
                </ThumbnailContainer>
                {!image ?
                  <Button small disabled={uploadingFile.isOpen || form.isLoading || isLoading}>Add picture</Button>
                  : <Button small highlight disabled={uploadingFile.isOpen || form.isLoading || isLoading}>Change image</Button>
                }
              </ImageInputContainer>
            }</WithImageInput>
          </div>

        </div>
        <div ref={errorRef}>
          <Errors/>
        </div>

        <BoxWrapperFooter>
          {
            callIfFunction(footer,form, expert, providerInfo) || <>
              {
                expert && ((!providerInfo.ssoEnabled) || expert.email.indexOf('@purview') >= 0) && 
                <div className="left-block">
                  <Button onClick={openResetPassword}>Reset password</Button>
                </div>
              }
              <div className="right-block">
                <Button onClick={() => form.confirm(expert ? "discard-expert-edit" : "discard-expert-create").then(() => navigate((location.state && location.state.prevLocation) || cancelDefaultUrl))}>Cancel</Button>
                <Button isLoading={form.isLoading} highlight type="submit">{expert ? "Update" : "Add expert"}</Button>
              </div>
            </>
          }
        </BoxWrapperFooter>
      </BoxPageWrapper>
      </BoxPageContainer>
      <PurviewFooter />
    </ProfilePage>
  }</WithForm>
}
