import {Button, Dialog, Icon, willUseFlipSwitch} from '@startlibs/components';
import {FormValue, SimpleRadiobox, TextInput, ToggleCheckbox, WithForm} from '@startlibs/form';
import {getColor, media, twoWayAnimation} from '@startlibs/utils';
import {useLazyConstant, useToggle} from '@startlibs/core'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import styled, {css} from 'styled-components';
import _ from 'lodash/fp';
import {BoxWrapperFooter} from '../../../components/BoxPageLayout';
import {
  EmptyListPlaceholder,
  SearchInput,
  StyledRow,
  StyledTable,
  StyledTableHead
} from '../../../components/StyledTable';
import {ExpertProfileCard} from '../components/ExpertProfileCard'
import {CaseRequestCard} from '../../CaseRequestCard'
import {jwtGetFetcher, jwtPostFetcher} from '../../../utils/authFetch'
import {getJwt} from '../../../hooks/useJwt'
import {PENDING} from '../../../enums/CaseFilter'
import {useNotification} from '../../../components/Notifications'

const [onEnter, onLeave] = twoWayAnimation('opacity:0; transform:translateY(50%)', 'opacity:1;transform:translateY(0)')
const [loadingEnter, loadingLeave] = twoWayAnimation('opacity:0;', 'opacity:1;')

const ExpertShareDialog = styled(Dialog)`
  max-width: 48rem;
  ${StyledTable} {
    margin-top: 1rem;
    ${media.min(739)`
      height: 16rem;
      overflow-y: auto;
      position: relative;
    `}
    ${StyledTableHead} {
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      background: white;
      z-index: 3;
    }
  }
  ${EmptyListPlaceholder} {
    margin-top: 1rem;
    padding: 4rem 1rem;
    height: 16rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${StyledRow} {
    > div {
      &.name {
        flex-basis: 220px;
      }
      &.id {
        flex-grow: 1;
        flex-basis: 185px;
        min-width: 125px;
      }
      &.check {
        flex-basis: 30px;
        min-width: 30px;
        flex-shrink: 0;
        position: relative;
        z-index: 1;
      }
    }
  }
  ${ToggleCheckbox} {
    margin-top: 1rem;
  }
  ${media.max(739)`
    ${StyledTable} {
      margin-top: 0;
    }
    ${StyledRow} {
      padding: 1.5rem 1.5rem 1rem;
      & ~ ${StyledRow} {
        margin-top: .5rem;
      }
      > div {
        &.name {
          font-size: 1.75rem;
          margin-bottom: .75rem;
          padding-right: 50px;
          display: inline-block;
          width: 50%;
          font-size: 13px;
          &:before {
            content: "Patient name: ";
            display: block;
          }
        }
        &.id {
          margin-bottom: .75rem;
          display: inline-block;
          width: 50%;
          &:before {
            content: "Case ID: ";
          }
        }
        &.check {
          position: absolute;
          top: 1.5rem;
          right: -.5rem;
          flex-basis: 50px;
          min-width: 50px;
        }
      }
    }
  `}
  ${media.max(520)`
    ${StyledRow} {
      > div {
        &.name, &.id {
          display: block;
          width: 100%;
        }
      }
    }
  `}
`

const ExpertShareHeading = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 2rem;
    p {
      flex-grow: 1;
      margin: .5rem .5rem .5rem 0 ;
    }
    ${SearchInput} {
      flex-basis: 210px;
      flex-shrink: 0;
      flex-grow: 1;
    }
`

const StyledExpertRow = styled(StyledRow)`
  cursor: pointer;
  &:hover {
    background: rgba(0,139,210,0.15);
  }
  ${props => props.selected && css`
    &:nth-child(odd), &:nth-child(even) {
      background-color: ${getColor('main')};
      color: white;
      &:hover {
        background: ${getColor('main')};
      }
    }
    ${Icon} {
      position: absolute;
      left: 50%;
      right: 50%;
      transform: translate(-50%,-50%);
      color: ${getColor('main')};
      width: 10px;
      height: 10px;
      font-weight: 600;
    }
  `}
`

const ExpertRadioBox = styled(SimpleRadiobox)`
  position: absolute;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  > div > div {
    position: static;
    > div {
      left: 50%;
      transform: translate(-50%,-50%);
      ${props => props.selected && css`
        background: white;
        border: transparent;
        &:before {
          background: transparent;
        }
      `}
    }
    > span {
      display: none;
    }
  }
`

const SecurityDataWarning = styled.div`
  color: rgba(0,0,0,0.5);
  margin-top: -0.25rem;
  h4 {
    font-size: 12px;
    margin-bottom: 0.25rem;
  }
  p {
    font-size: 11px;
    margin-bottom: -0.5rem;
    max-width: 36rem;
  }
`

const FooterButtons = styled(BoxWrapperFooter)`
  margin-top: 0;
`

const StickyWrapper = styled.div`
  height: auto;
`

const filterRequest = (query) => (request) => {
  const tokens = [(request.firstName|| "").toLowerCase().trim(),(request.lastName || "").toLowerCase().trim(), (request.requestCode || "").toLowerCase()]
  const queryTokens = query.toLowerCase().trim().split(" ")
  return queryTokens.filter(q => tokens.find(t => t.indexOf(q.trim()) >= 0)).length === queryTokens.length
}

export const ExpertShareCaseDialog = ({refreshCases, closeDialog, providerInfo, expert}) => {

  const [,setNotification] = useNotification()

  const onSuccess = () => {
    return refreshCases().then(() => {
      setNotification("Case assigned successfully.")
      closeDialog()
    })
  }
  const [caseRequests,setCaseRequests] = useState()

  useEffect(() => {
    jwtGetFetcher(getJwt())('/api/admin/searchRequests?state='+PENDING)
      .then(_.get('list'))
      .then(setCaseRequests)
  },[])

  const flipAnimation = useLazyConstant(willUseFlipSwitch)

  const query = useToggle("")
  const confirmDialog = useToggle("")

  const action = ({selectedCase,allowDownload,sendEmail,message}) => confirmDialog.isOpen ? jwtPostFetcher(getJwt())(`/api/expert/${expert.id}/share/${selectedCase.id}/download/${!!allowDownload}`,{message,sendEmail}) : confirmDialog.open()

  const filteredRequests = useMemo(() =>
      query.isOpen
        ? caseRequests.filter(filterRequest(query.isOpen))
        : caseRequests
    , [query.isOpen, caseRequests])

  const formRef = useRef()

  return <WithForm
    alwaysSave
    values={{sendEmail: providerInfo.notifications?.assignExpert}}
    action={action}
    ref={formRef}
    onSuccess={confirmDialog.isOpen ? onSuccess : undefined}
  >{form =>
    <ShareDialog
      closeDialog={closeDialog}
        expert={expert}
        caseRequests={caseRequests}
        action={action}
        confirmDialog={confirmDialog}
        form={form}
        query={query}
        filteredRequests={filteredRequests}
      />
  }</WithForm>
}

const ShareDialog = ({closeDialog, confirmDialog, caseRequests, expert, form, query, filteredRequests}) => {

  return !confirmDialog.isOpen
    ?  <ExpertShareDialog
      isLoading={!caseRequests}
      title="Assign a case to this expert"
      closeDialog={closeDialog}
      footer={<>
        <FooterButtons>
          <div className="right-block">
            <Button onClick={closeDialog}>Cancel</Button>
            <FormValue path="selectedCase">{selectedCase=><Button disabled={!selectedCase} highlight type="submit" onClick={form.willSubmitForm}>Assign selected case</Button>}</FormValue>
          </div>
        </FooterButtons>
      </>}
    >{() => caseRequests && <>
        <p>You are about to assign a case to this expert:</p>
        <ExpertProfileCard expert={expert} />
        <ExpertShareHeading>
          <p><strong>Select a case below to assign:</strong></p>
          <SearchInput>
            <Icon icon="search"/>
            <TextInput placeholder="Search" raw value={query.isOpen} setValue={query.openWith}/>
          </SearchInput>
        </ExpertShareHeading>
        {
          filteredRequests.length > 0 && caseRequests.length > 0 ?
            <StyledTable>
              <StickyWrapper>
                <StyledTableHead>
                  <div className="name"><FormattedMessage description="patient name label" defaultMessage="Patient name"/></div>
                  <div className="id">Case ID</div>
                  <div className="check"></div>
                </StyledTableHead>
                {
                  filteredRequests.map(caseRequest =>
                    <FormValue path="selectedCase">{(selectedCase, setSelectedCase) =>
                      <CaseRow selected={selectedCase === caseRequest} setSelectedCase={setSelectedCase}
                                 caseRequest={caseRequest}/>
                    }</FormValue>
                  )
                }
              </StickyWrapper>
            </StyledTable>
            : (
              <EmptyListPlaceholder>
                {caseRequests.length > 0 && filteredRequests.length === 0 &&
                <span>There are no pending assignment cases matching your search criteria.</span>}
                {caseRequests.length === 0 && <span>There are no pending assignment cases available.</span>}
              </EmptyListPlaceholder>
            )
        }
        <ToggleCheckbox
          label="Enable expert to download image files from this case"
          path="allowDownload"
          css="font-weight:600"
        />
    </>}</ExpertShareDialog>
    :  <SecondStepDialog
      form={form}
      closeDialog={closeDialog}
      expert={expert}
      confirmDialog={confirmDialog}
    />
}

const SecondStepDialog = ({closeDialog, confirmDialog, form, expert}) => {
  return <ExpertShareDialog
    title="Confirm case assignment"
    closeDialog={closeDialog}
    form={form}
    footer={<>
      <Button onClick={confirmDialog.close}>Cancel</Button>
      <Button autoFocus highlight isLoading={form.isLoading} type="submit" onClick={form.willSubmitForm}>Confirm assignment</Button>
    </>}
  >
    <p>Please review the case and expert details below before assigning.</p>
    <FormValue path="selectedCase">{caseRequest =>
      <CaseRequestCard caseRequest={caseRequest} viewOnly />
    }</FormValue>
    <p><b>Assinging this case to:</b></p>
    <ExpertProfileCard expert={expert} />

    <ToggleCheckbox
      label={<strong>Send an email notifying the expert that the case was assigned</strong>}
      path="sendEmail"
    />
    <FormValue path="sendEmail">{sendEmail => sendEmail && <>
      <TextInput
        path="message"
        label="Message"
        descText="This will be included in the email sent to the expert."
        textarea
        autoResize
      />
      <SecurityDataWarning>
        <h4>This email may contain confidential and protected health care information.</h4>
        <p>
          Please be sure that the email of the recipient has been entered 
          correctly and that you are using appropriately confidential mechanisms
          for this communication.
        </p>
      </SecurityDataWarning>
    </>}</FormValue>
  </ExpertShareDialog>
}

const CaseRow = ({selected, caseRequest, setSelectedCase}) => {
  return <StyledExpertRow selected={selected} onClick={() => setSelectedCase(caseRequest)}>
    <div className="name">{caseRequest.firstName + ' ' + caseRequest.lastName}</div>
    <div className="id">{caseRequest.requestCode}</div>
    <div className="check">
      <ExpertRadioBox selected={selected} path="selectedCase" fieldValue={caseRequest}/>
      {selected && <Icon icon="check"/>}
    </div>
  </StyledExpertRow>
}
