import {Button} from '@startlibs/components';
import {formatDate, will} from '@startlibs/utils';
import {useNavigate} from 'react-router'
import React from 'react'
import _ from 'lodash/fp';
import {Header} from '../components/Header';
import {PageContainer, PageFooter} from '../components/PageLayout';
import {PurviewFooter} from '../components/PurviewFooter';
import {RequestOverview} from '../request/RequestOverview'
import {UNDER_REVIEW, WAITING_ACCEPTANCE} from '../enums/CaseState';
import {useAcceptCase} from './dialogs/useAcceptCase'
import {useDeclineCase} from './dialogs/useDeclineCase'
import { EXPERT } from '../enums/UserRoles';
import { FeedbackDialog } from './dialogs/FeedbackDialog';

export const ExpertOverview = ({caseRequest,setCaseRequest, feedbackPopup}) => {
  const navigate = useNavigate()

  const acceptCase = useAcceptCase(caseRequest,_.flow(
    will(setCaseRequest,_.set("state",UNDER_REVIEW)),
    will(navigate,`/expert/case/${caseRequest.requestId}`)
  ))

  const rejectCase = useDeclineCase(caseRequest,will(navigate,"/expert"))

  return <> <PageContainer>
      <Header isExpert title="Case overview">
        {
          caseRequest.state === WAITING_ACCEPTANCE ?
          <>
            <Button onClick={rejectCase}>Decline case</Button>
            <Button highlight onClick={acceptCase}>Accept case</Button>
          </> :
            <Button highlight onClick={() => navigate(`/expert/case/${caseRequest.requestId}/questions`)}>
              Go to case review
            </Button>
        }
      </Header>
      {
         feedbackPopup.isOpen && <FeedbackDialog feedbackPopup={feedbackPopup} requestId={caseRequest.requestId} />
      }

      <RequestOverview caseRequest={caseRequest} setCaseRequest={setCaseRequest} withClinicalSummary noContacts allowDownload allowDownloadMedicalImages={caseRequest.canDownloadMedicalRecords} role={EXPERT} />

      <PageFooter>
        {
          caseRequest.state === WAITING_ACCEPTANCE ?
          <>
            <Button onClick={rejectCase}>Decline case</Button>
            <Button highlight onClick={acceptCase}>Accept case</Button>
          </> :
            <Button highlight onClick={() => navigate(`/expert/case/${caseRequest.requestId}/questions`)}>
              Go to case review
            </Button>
        }
      </PageFooter>
    </PageContainer>
    <PurviewFooter />
  </>
}
