import {getFetcher} from '@startlibs/utils'
import {useEffect, useMemo} from 'react'

export const SuspenseError = function(el,invalidateCache,cache) { this.get = () => el; this.invalidateCache = invalidateCache, this.cache=cache}

export const willUseSuspense = (loadResource) => {

  const cache = new Map()
  const suspenderCache = new Map()

  function read(key ) {
    if (cache.has(key)) {
      const cached = cache.get(key)
      if (cached instanceof SuspenseError) {
        throw cached
      } else {
        return cached
      }
    }
    if (suspenderCache.get(key)) {
      throw suspenderCache.get(key)
    }
    const suspender = loadResource(key)
    suspenderCache.set(key,suspender)
    suspender.then(thing => {
      cache.set(key,thing)
      suspenderCache.delete(key)
      return thing
    }).catch(e => {
      cache.set(key, new SuspenseError(e,() => remove(key),cache))
      suspenderCache.delete(key)
    })
    throw suspender
  }

  const remove = (key) => cache.delete(key)

  return (key) => {
    useEffect(() => () => remove(key),[key])
    return useMemo(() => read(key),[key])
  }
}

export const useSuspense = willUseSuspense((url) => getFetcher(url))