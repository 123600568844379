import {willUseSharedState} from '@startlibs/core'
import {useEffect} from 'react'

export const [useSharedIsScrollOnBottom, useSharedIsScrollOnBottomRef] = willUseSharedState()

export const CheckScrollOnBottom = () => {

  const [getScrollOnBottom,setScrollOnBottom] = useSharedIsScrollOnBottomRef()

  const handleWindowScroll = () => {
    const newValue = window.innerHeight + window.scrollY >= document.body.scrollHeight && window.scrollY>0
    if (newValue!==getScrollOnBottom()) {
      setScrollOnBottom(newValue)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll)
    handleWindowScroll()
    return () => window.removeEventListener('scroll', handleWindowScroll)
  }, [])

  return null
}