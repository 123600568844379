import { Button, Icon } from '@startlibs/components';
import { getColor } from '@startlibs/utils';
import React from 'react'
import _ from 'lodash/fp'
import styled, { css } from 'styled-components';
import {AdminCaseCardCategories, CategoryTag} from '../dashboard/AdminCaseCardCategories'
import {
  SidebarCard,
  SidebarContainer,
  SidebarSeparator
} from './RequestSidebar';
import {useEditCategories} from '../experts/hooks/useEditCategories'


const Sidebar = styled.div`
  h4 {
    font-size: 14px;
    color: ${getColor('gray120')};
    margin: .5rem 0 1.5rem;
    padding-left: 1.5rem;
  }
`

const PriorityButton = styled(Button)`
  margin-left: 1.5rem;
  box-shadow: none;
  width: calc(100% - 3.5rem);
  justify-content: flex-start;
  :before {
    border: 1px solid ${getColor('gray210')};
  }
  ${props => props.isPriority && css`
    background: ${getColor('lightYellow')};
    color: ${getColor('warning')};
    :before {
      border: none;
    }
    ${Icon} {
      color: ${getColor('warning')};
    }
    :hover {
      opacity: 75%;
      background: ${getColor('lightYellow')};
    }
  `}
`


export const CreateRequestSidebar = ({caseRequest, setCaseRequest, lazyCategories}) => {

  const editCategories = useEditCategories({categories:[]},(caseRequest) => (categories) =>
    setCaseRequest(_.set('categories', categories)),
    {saveAction:() => {},notify:null, lazyCategories}
  )


  const toggleAsPriority = () => setCaseRequest(_.update('markAsPriority',(v) => v ? null : true))

    return <SidebarContainer>
      <Sidebar>
        <SidebarCard css="padding-bottom: 2rem;">
          <h4>Case creation settings</h4>
          <SidebarSeparator withTitle css="padding-bottom: .25rem;">
            <span>DIVISIONS</span>
          </SidebarSeparator>
          <AdminCaseCardCategories
            inSidebar
            categories={caseRequest.categories}
            quantityVisible={2}
            whenEmpty={
              <CategoryTag onClick={editCategories.willOpenWith(caseRequest)}>Edit categories</CategoryTag>
            }
            editCategories={editCategories.willOpenWith(caseRequest)}
          />
          <SidebarSeparator withTitle css="margin-top: 1.5rem">
            <span>CASE PRIORITY</span>
          </SidebarSeparator>
          <PriorityButton
            outline
            icon={caseRequest.markedAsPriority ? "star" : "star-line"}
            onClick={toggleAsPriority}
            isPriority={caseRequest.markedAsPriority}
          >
            {caseRequest.markedAsPriority ? "Remove as priority" : "Mark as priority"}
          </PriorityButton>
        </SidebarCard>
      </Sidebar>
    </SidebarContainer>
}
