import {Button, Dialog, Link} from '@startlibs/components'
import {TextInput} from '@startlibs/form'
import {useToggle} from '@startlibs/core'
import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';
import {CaseRequestCard} from '../../CaseRequestCard'
import {ConfirmDialog, useConfirmDialog} from '../../../hooks/useConfirmDialog'
import {ContactCheckbox, ContactCheckboxGroup} from '../../../components/ContactCard'
import { ContactSelectorHeading } from './useConfirmFinishCase';
import {InfoBox} from '../../../components/InfoBox'
import {REF_PHYSICIAN} from '../../../enums/ContactRelationType'
import {buildValidation} from '../../../utils/validation'
import {getContact, PRIMARY} from '../../../enums/ContactRelationKind'
import {getJwt} from '../../../hooks/useJwt'
import {isPaymentPending} from '../../../request/utils'
import {jwtFormFetcher} from '../../../utils/authFetch'
import {hasAcceptedAllTerms, lazyTerms} from '../../../patient/utils/caseRequestUtils'
import {PendingInfo} from '../info/PendingInfo'
import {useIntl} from 'react-intl'
import { lazyProviderInfo } from '../../../components/WithProvider'

export const PendingBox = styled(InfoBox)`
  ul {
    margin-bottom: .5rem;
    padding-left: 1.25rem;
  }
  li {
    list-style-type: disc;
  }
  li:only-child {
    list-style-type: none;
    margin-left: -1.25rem;
  }
`

export const useNotifyClosedCase = (caseRequest) => {

  const providerInfo = lazyProviderInfo.read()

  const referringPhysicianAsSecondary = caseRequest.contactDetails.contactRelationType !== "REF_PHYSICIAN" && caseRequest.referringPhysician.email

  const intl = useIntl()

  const primary = getContact(intl, caseRequest, PRIMARY)
  const physician = getContact(intl, caseRequest, REF_PHYSICIAN)

  const requiredCheck = (v, k, props) => referringPhysicianAsSecondary && !props.sendToCaseContact && !props.sendToReferringPhysician && "No contact was selected."

  return useConfirmDialog(<ConfirmDialog
    confirmChanges={false}
    isLoading={false}
    notify="Email notification submitted."
    transform={_.update('sendToCaseContact', (v) => v || !referringPhysicianAsSecondary)}
    preValidation={buildValidation({'sendToCaseContact': requiredCheck, 'sendToReferringPhysician': requiredCheck})}
    title="Notify contact"
    action={jwtFormFetcher(getJwt())("/api/admin/sendReportNotification?requestId=" + caseRequest.requestId)}
    confirm={<Button highlight icon="email">Notify</Button>}
  >
    <p>Inform selected contacts that the report for the following case is available:</p>
    <CaseRequestCard caseRequest={caseRequest}/>

    <ContactSelectorHeading>
      {referringPhysicianAsSecondary ?
      "Select which contact(s) you would like to notify:"
      : "An email will be sent to the following contact:"
      }
      <Link
        className="link"
        to={`/admin/request/${caseRequest.requestCode}/patient`}
        state={{scrollToContact: true}}
      >Edit contacts</Link>
    </ContactSelectorHeading>
    <ContactCheckboxGroup>
      <ContactCheckbox
        path="sendToCaseContact"
        email={primary.email}
        name={primary.name}
        key="patient"
        type={primary.relationType}
      />
      {
        referringPhysicianAsSecondary && <ContactCheckbox
          path="sendToReferringPhysician"
          name={physician.name}
          email={physician.email}
          type="Referring physician"
          key="Referring physician"
        />
      }
    </ContactCheckboxGroup>
    <TextInput
      label="Message"
      path="message"
      textarea
      autoResize
      descText="This will be included in the email notification."
    />
    <PendingInfo caseRequest={caseRequest} hideServiceTerms={!providerInfo.serviceTerms || !providerInfo.requiresAgreement}/>

  </ConfirmDialog>)
}
