import {Button, Icon} from '@startlibs/components';
import {Errors, Field, SimpleCheckbox, WithForm} from '@startlibs/form';
import {useNavigate} from 'react-router'
import React from 'react'
import _ from 'lodash/fp'
import {Card, PageContainer, PageFooter, SectionHeading} from '../components/PageLayout';
import {DIGITAL} from '../enums/PaymentMethod'
import {Header} from '../components/Header';
import {InfoBox} from '../components/InfoBox';
import {PurviewFooter} from '../components/PurviewFooter';
import {RequestOverview} from '../request/RequestOverview';
import {buildValidation, required, responseFailure} from '../utils/validation'
import {getJwt} from '../hooks/useJwt'
import {jwtPostFetcher} from '../utils/authFetch'
import {useAlreadyRevoked} from './utils/useAlreadyRevoked'
import {lazyProviderInfo} from '../components/WithProvider'
import {SELF} from '../enums/InsuranceType'
import {PENDING_ASSIGNMENT, WAITING_MEDICAL_RECORDS} from '../enums/CaseState'
import {setNotification} from '../components/Notifications'
import {AcceptTermsCheckbox, requiredTerms} from '../request/components/AcceptTermsCheckbox'
import {CheckList} from './utils/paymentUtils'
import { PATIENT } from '../enums/UserRoles';

export const PatientApproval = ({caseRequest, setCaseRequest}) => {

  const navigate = useNavigate()
  const alreaydRevoked = useAlreadyRevoked()
  const providerInfo = lazyProviderInfo.read()

  const needsCard = caseRequest.paymentDetails.paymentMethod === DIGITAL

  const isSelfPay = caseRequest.paymentDetails.paymentType === SELF

  const approve = () => needsCard
    ? navigate("../checkout")
    : jwtPostFetcher(getJwt())('/api/approveCase').then(() => setCaseRequest(_.flow(_.set('state', PENDING_ASSIGNMENT))))
      .then(() => setNotification("Case approved successfully."))
      .then(() => navigate("../status"))
      .catch((response) => response && response[1] && response[1].status === 401 && alreaydRevoked())

  if (caseRequest.state !== WAITING_MEDICAL_RECORDS) {
    return <PageContainer>
      <Header
        title="Expert View request"
        caseRequest={caseRequest}
      />
    </PageContainer>
  }

  return <>
    <PageContainer>
      <Header
        title="Expert View request"
        caseRequest={caseRequest}
      />
      <InfoBox lightBlue css="margin: -1rem 0 2rem;">
        You have been requested to <strong>review and approve the following case</strong>,
        built according to your Expert View request. In case you have any question or correction please contact <span>{providerInfo.address.phoneNumber}</span>.
      </InfoBox>
      <RequestOverview withoutRecords caseRequest={caseRequest} setCaseRequest={setCaseRequest} role={PATIENT}/>
      {/*{
        isSelfPay && <>
          <SectionHeading>
            <h3>Case costs</h3>
          </SectionHeading>
          <CostsAndCoverageDetails
            paymentDetails={caseRequest.paymentDetails}
            remainderDesc={caseRequest.paymentDetails.paid && "paid already"}
            white
            css="margin-bottom:2rem"
          />
        </>
      }*/}
      <SectionHeading>
        <h3>Agreements and confirmation</h3>
      </SectionHeading>
      <Card>
        <Field label="By proceeding you confirm the following items:" css="margin-bottom:0">
          <InfoBox css="margin-bottom:0">
            <CheckList>
              <li><Icon icon="check"/>You have read and agree to the <a
                className="link"
                target="_blank"
                href="https://www.purview.net/expert-opinion-portal-terms"
              >License Terms</a> of this platform and service;
              </li>
              <li><Icon icon="check"/>You have read and agree to the <a
                className="link"
                target="_blank"
                href="https://www.purview.net/expert-view-remote-second-opinion-services-agreement"
              >Remote Second Opinion Services Agreement</a>;
              </li>
              {
                isSelfPay
                  ? <li>
                    <Icon icon="check"/>You agree to the defined case costs and {caseRequest.paymentDetails.paid === false ? "to provide" : "have already provided"} payment accordingly;
                  </li>
                  : <li><Icon icon="check"/>You provided valid insurance information for covering this case's costs;
                  </li>
              }
              <li>
                <Icon icon="check"/>You have reviewed and approve this case, as it is now, to be assigned and reviewed by an expert.
              </li>
            </CheckList>
          </InfoBox>
        </Field>
      </Card>
      <InfoBox lightBlue css="margin-bottom: 2rem;">
        In case you have any question or correction please contact <span>{providerInfo.address.phoneNumber}</span>.
      </InfoBox>
      <WithForm
        alwaysSave
        onFailure={responseFailure()}
        preValidation={buildValidation({confirm: required,acceptedTerms: requiredTerms(caseRequest)})}
        action={approve}
      >{form =>
        <>
          <Errors/>
          <PageFooter css="flex-wrap: wrap;">
            <SimpleCheckbox
              path="confirm"
              label={"I acknowledge and approve this case to be submitted for review" + (isSelfPay ? " and agree to pay the stated charge." : "")}
              framedBoxes
              ultraHighlightSelected
              css="width: 100%; margin-top: -.5rem;"
            />
            <AcceptTermsCheckbox caseRequest={caseRequest}/>
            <Button
              highlight
              isLoading={form.isLoading}
              type="submit"
            >
              {
                needsCard ? "Proceed to payment" : "Approve case"
              }
            </Button>
          </PageFooter>
        </>
      }</WithForm>
    </PageContainer>
    <PurviewFooter/>
  </>
}
