import {Button} from '@startlibs/components'
import {callIfFunction} from '@startlibs/utils'
import {RadioboxGroup, SimpleRadiobox} from '@startlibs/form'
import React from 'react'
import _ from 'lodash/fp'
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import { ExpertCaseCard } from '../ExpertCaseCard';
import {getJwt} from '../../hooks/useJwt'
import {jwtPostFetcher} from '../../utils/authFetch'
import {responseFailure} from '../../utils/validation'
import {useConfirmAnswers} from '../customReport/useConfirmAnswers'


export const useSubmitCase = (caseRequest, values, onSuccess) => {

  const confirmAnswers = useConfirmAnswers(caseRequest)

  return useConfirmDialog(<ConfirmDialog
      values={{useCustomReport: !!caseRequest.report?.useCustomReport}}
      title="Submit case review"
      confirm={<Button highlight>Submit</Button>}
      preValidation={({useCustomReport}) => (useCustomReport ? Promise.resolve() : confirmAnswers())}
      action={({useCustomReport = false}) =>
        jwtPostFetcher(getJwt())(`/api/expert/submitReview?requestId=${caseRequest.requestId}&useCustomReport=${useCustomReport}`, callIfFunction(values))
      }
      onSuccess={onSuccess}
      onFailure={responseFailure((n,{status}) => status === 401 && "Access denied")}
      navigate="../report"
    >
      <p>Your review of the following case will be shared with your administrator:</p>
      <ExpertCaseCard caseRequest={caseRequest}/>

    {
      caseRequest.report?.customReport && <>
        <p>A custom report was uploaded. Would you like to use it as the final report?</p>
        <RadioboxGroup path="useCustomReport" framedBoxes highlightSelected horizontal wide css="margin-bottom:1.5rem">
        <SimpleRadiobox
          fieldValue={false}
          label="Use default report"
        />
        <SimpleRadiobox
          fieldValue={true}
          label="Use custom report"
        />
      </RadioboxGroup>
      </>
    }
      <p>
        You will no longer be able to edit this case, unless you are
        requested to provide additional information.
      </p>
      <p>Are you sure you want to proceed?</p>
    </ConfirmDialog>
  )
}
