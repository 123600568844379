import {useRemoteCursorOverlayPositions} from '@slate-yjs/react';
import React, {useRef} from 'react';
import styled from 'styled-components'

const Caret = styled(({className, position, data}) => {
  const caretStyle = {
    ...position,
    background: data.color,
  };

  const labelStyle = {
    transform: 'translateY(-100%)',
    background: data.color,
  };

  return (
    <div style={caretStyle} className={className}>
      <div
        className=""
        style={labelStyle}
      >
        {data.name}
      </div>
    </div>
  );
})`
position:absolute;
& > div {
  position: absolute;
  font-size: 0.75rem;
  line-height: 1rem;
  color: white;
  white-space: nowrap;
  top:0;
  border-radius: 0.25rem;
  border-bottom-left-radius: 0px;
  padding: 0.125rem 0.375rem;
}
`

const RemoteSelection = styled(
  ({
     className,
     data,
     selectionRects,
     caretPosition,
   }) => {
    if (!data) {
      return null;
    }

    const selectionStyle = {
      backgroundColor: `${data.color}66`,
    };

    return (
      <React.Fragment>
        {selectionRects.map((position, i) => (
          <div
            style={{...selectionStyle, ...position}}
            className={className}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          />
        ))}
        {caretPosition && <Caret position={caretPosition} data={data}/>}
      </React.Fragment>
    );
  })`
  position:absolute;
  pointer-events: none;
  `

export const RemoteCursorOverlay =
  styled(({
            className,
            children,
          }) => {
    const containerRef = useRef(null);
    const {cursors} = useRemoteCursorOverlayPositions({containerRef});

    return (
      <div className={className} ref={containerRef}>
        {children}
        {cursors.map((cursor) => (
          <RemoteSelection key={cursor.clientId} {...cursor} />
        ))}
      </div>
    )
  })`
  position: relative;
  `