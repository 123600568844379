import _ from 'lodash/fp'
import {getFileNameAndExtension} from "../service/utils";
import {augmentFile} from "../service/utils/common";


export const getFileInstanceList = (e) => {
  const dataTransfer = e.dataTransfer
  if (dataTransfer) {
    if (isFolderDropSupported(dataTransfer)) {
      return Promise.all(
        Array.from(dataTransfer.items)
          .map(item => getFilesFromEntry(item.webkitGetAsEntry(),item,""))
      ).then(nestedArrays => _.flatten(nestedArrays).map(({file,path}) => augmentFile(file,path)))
    } else {
      return Promise.resolve(Array.from(dataTransfer.files).map(file => augmentFile(file,"")))
    }
  }
  return Promise.resolve(Array.from(e.target.files).map(file =>
    augmentFile(
      file,
      "/"+(file.webkitRelativePath||"").substr(0, (file.webkitRelativePath||"").lastIndexOf("/"+file.name))
    )
  ))
}

const getFilesFromEntry = (entry,item,path) => {
  if (entry) {
    if (entry.isFile) {
      if (item) {
        return Promise.resolve([{file:item.getAsFile(),path}])
      }
      return new Promise((resolve) =>
        entry.file(
          (file) => resolve([{file,path}]),
          (fileError) => resolve([]) /* handle error */
        )
      )
    } else if (entry.isDirectory) {
      return getFilesInDirectory(entry,path)
    } else {
      /* handle error */
      return Promise.resolve([])
    }
  } else {
    /* handle error  */
    return Promise.resolve([])
  }
}

const isFolderDropSupported = (dataTransfer) =>
  dataTransfer.items && dataTransfer.items.length > 0 && dataTransfer.items[0].webkitGetAsEntry


const getFilesInDirectory = (entry,rootPath) => {
  const entryPath = rootPath+"/"+entry.name

  return new Promise((res) => {
    const dirReader = entry.createReader();
    let entries = []

    const getEntries = () => {
      dirReader.readEntries((results) => {
        if (results.length) {
          entries = entries.concat(Array.from(results));
          getEntries();
        } else {
          res(entries)
        }
      }, (error) => {
        res(entries)
        /* handle error -- error is a FileError object */
      })
    };
    getEntries()
  }).then(entries =>
    Promise.all(entries.map(entry => getFilesFromEntry(entry, undefined, entryPath)))
      .then(nestedArrays =>
        _.flatten(nestedArrays)
      )
  )
}