import {useState, useRef} from 'react'
import {callIfFunction} from '@startlibs/utils'

export const useGetState = (initial,callback) => {

  const [state,rawSetState] = useState(initial)
  const stateRef = useRef(initial)

  const setState = (newState) => {
    const prevState = stateRef.current
    const nextState = callIfFunction(newState,prevState)
    callIfFunction(callback,nextState,prevState)
    stateRef.current = nextState
    rawSetState(nextState)
  }

  const getState = () => stateRef.current

  return [state,setState,getState]

}