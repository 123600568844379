import { Loading } from '@startlibs/components';
import {getColor} from '@startlibs/utils'
import styled, {css} from 'styled-components'

export const PdfReportFrame = styled.iframe`
  width: 100%;
  max-width: 210mm;
  min-width: 300px;
  min-height: 300px;
  border-radius: 4px;
  border: 1px solid ${getColor('gray180')};
  background: white;
  ${props => props.isUploadDialogOpen && 'visibility:hidden;'}
  ${props => props.txtReport && css`
    background: white;
    padding: 2rem;
    border: 1px solid ${getColor('gray210')};
  `}
  ${props => (props.pdfReport || props.htmlReport) && css `
    height: 64vh;
  `}
  ${props => props.htmlReport && css `
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0,0,0,.5);
        -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }
  `}
`

export const PdfReportFrameWrapper = styled.div `
  position: relative;
`
