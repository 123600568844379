import {Field, FormValue, RadioboxGroup, SimpleRadiobox, TextInput} from '@startlibs/form';
import {SplitColumnsContainer} from '@startlibs/components'
import React from 'react'
import {FormBox, RemoveFormBox} from '../../components/FormBox';
import {useRemoveQuestion} from './useRemoveQuestion'
import {MEDICARE, OTHER, PRIVATE} from '../../enums/InsuranceType'
import _ from 'lodash/fp'
import styled from 'styled-components';
import {removeExtraInsuranceFields} from './utils'

const InsuranceEditBox = styled(FormBox)`
  ${SimpleRadiobox} {
    overflow: hidden;
    border-radius: 6px;
    background: white;
  }
`

export const INSURANCE_ORDER_TITLE = {
  0: "Primary insurance",
  1: "Secondary insurance",
  2: "Tertiary insurance",
}

const constraint = /[^\d\w\s]/

export const Insurance = ({form, path, i, disabled, canRemove}) => {
  const removeQuestion = useRemoveQuestion(form, path, i,({id,...rest}) => Object.values(removeExtraInsuranceFields(rest)).filter(_.identity).length>1)

  const indexedPath = [...path,i]

  return <InsuranceEditBox>
    {!disabled && canRemove && <RemoveFormBox onClick={removeQuestion}/>}
    <RadioboxGroup
      disabled={disabled}
      mandatory
      label={`${INSURANCE_ORDER_TITLE[i]} type`}
      horizontal
      path={[...indexedPath, "type"]}
      framedBoxes
      wide
      highlightSelected={!disabled}
    >
      <SimpleRadiobox fieldValue={MEDICARE} label="Medicare"/>
      <SimpleRadiobox fieldValue={PRIVATE} label="Private insurance"/>
      <SimpleRadiobox fieldValue={OTHER} label="Other"/>
    </RadioboxGroup>
    <FormValue path={["patientInfo", "insurances", i, "type"]}>{(insuranceType) =>
      <>
        {
          insuranceType === MEDICARE &&
          <MedicareFields
            canRemove
            disabled={disabled}
            withTitle
            form={form}
            path={path}
            i={i}
          />
        }
        {
          insuranceType === PRIVATE &&
          <PrivateFields
            i={i}
            path={path}
            disabled={disabled}
          />
        }
        {
          insuranceType === OTHER &&
          <Field label="Please insert the insurance information below">
            <TextInput
              path={["patientInfo", "insurances", i, "info"]}
              textarea
              placeholder="Type other insurance information here"
              disabled={disabled}
              className='fs-exclude'
            />
          </Field>
        }
      </>
    }</FormValue>

  </InsuranceEditBox>
}


const PrivateFields = ({disabled, path,i}) => <>
  <SplitColumnsContainer>
    <TextInput
      disabled={disabled}
      label="Insurance carrier"
      path={[...path,i,'insuranceCarrier']}
      mandatory
      placeholder="Ex: UnitedHealth"
    />
    <TextInput
      disabled={disabled}
      label="Member #"
      constraint={constraint}
      path={[...path,i,'memberNumber']}
      mandatory
      placeholder="Ex: X X X X - X X X - X X X X"
    />
    <TextInput
      disabled={disabled}
      label="Group #"
      constraint={constraint}
      path={[...path,i,'groupNumber']}
      mandatory
      placeholder="Ex: X X X X - X X X - X X X X"
    />
  </SplitColumnsContainer>
  <SplitColumnsContainer>
    <TextInput
      disabled={disabled}
      label="Name of insured"
      path={[...path,i,'insurancedName']}
      helpText="(if not the patient)"
      placeholder="Ex: John Doe"
    />
  </SplitColumnsContainer>
</>

const formatMedicode = (v) => v.replace(/[\W-]/g, '').replace(/(\w{0,4})(\w{0,3})(\w{0,4}).*/, "$1-$2-$3").replace(/-*$/, "")

const MedicareFields = ({disabled,path,i}) => <>
  <TextInput
    disabled={disabled}
    label="Medicare code"
    path={[...path,i,"code"]}
    constraint={formatMedicode}
    mandatory
    helpText="(X X X X - X X X - X X X X)"
    placeholder="Ex: X X X X - X X X - X X X X"
  />
</>
