export const PATHOLOGY_EXTENSIONS = [
  'tiff',
  'tif',
  'bif',
  'scn',
  'svslide',
  'svs',
  'ndpi',
  'isyntax',
]

export const VALID_EXTENSIONS = PATHOLOGY_EXTENSIONS.concat([
  'xml',
  'cda',
  'ccda',
  'jpg',
  'jpeg',
  'jpe',
  'gif',
  'png',
  'bmp',
  // 'ico',
  'asf',
  'asx',
  'wax',
  'wmv',
  'wmx',
  'webm',
  'avi',
  'divx',
  'flv',
  'mov',
  'qt',
  'mpeg',
  'mpg',
  'mpe',
  'mp4',
  'm4v',
  'ogv',
  'mkv',
  'txt',
  'asc',
  'cc',
  'csv',
  'tsv',
  'ics',
  'rtx',
  'htm',
  'html',
  'mp3',
  'm4a',
  'm4b',
  'ra',
  'ram',
  'wav',
  'ogg',
  'oga',
  'mid',
  'midi',
  'wma',
  'mka',
  'rtf',
  'pdf',
  'swf',
  'tar',
  'zip',
  'gz',
  'gzip',
  'rar',
  '7z',
  'doc',
  'pot',
  'pps',
  'ppt',
  'wri',
  'xla',
  'xls',
  'xlt',
  'xlw',
  'mdb',
  'mpp',
  'docx',
  'docm',
  'dotx',
  'dotm',
  'xlsx',
  'xlsm',
  'xlsb',
  'xltx',
  'xltm',
  'xlam',
  'pptx',
  'pptm',
  'ppsx',
  'ppsm',
  'potx',
  'potm',
  'ppam',
  'sldx',
  'sldm',
  'onetoc',
  'onetoc2',
  'onetmp',
  'onepkg',
  'odt',
  'odp',
  'ods',
  'odg',
  'odc',
  'odb',
  'odf',
  'wp',
  'wpd',
  'dcm',
  'heic',
])

export const IGNORED_FILES = [
  'DICOMDIR',
  '.DS_Store',
  '.AppleDouble',
  '.LSOverride',
  'Icon\r\r',
  '.DocumentRevisions-V100',
  '.fseventsd',
  '.Spotlight-V100',
  '.TemporaryItems',
  '.Trashes',
  '.VolumeIcon.icns',
  '.com.apple.timemachine.donotpresent',
  '.AppleDB',
  '.AppleDesktop',
  '.apdisk',
  'Thumbs.db',
  'Thumbs.db:encryptable',
  'ehthumbs.db',
  'ehthumbs_vista.db',
  'Desktop.ini',
  'desktop.ini',
]

export const IGNORED_FILE_PATTERNS = [/^\._.*/]

export const EXECUTABLES_EXTENSIONS = [
  'XLM',
  '8CK',
  'RUN',
  'APK',
  'CMD',
  'EXE',
  '0XE',
  'A6P',
  'JAR',
  'FXP',
  'EBS2',
  '73K',
  'XAP',
  'IPA',
  'CACTION',
  'M3G',
  'BIN',
  'WIDGET',
  'GPE',
  'ELF',
  'BIN',
  'ACTC',
  'AC',
  'JSX',
  'AHK',
  'SCAR',
  'COFFEE',
  'AIR',
  'PLSC',
  'EAR',
  'ICD',
  'CGI',
  'EX_',
  'APP',
  'SCR',
  'MCR',
  'SEED',
  'PWC',
  'XQT',
  'FRS',
  'GPU',
  'HMS',
  'WSH',
  'XYS',
  'COF',
  'OUT',
  'MS',
  'CELX',
  '89K',
  'MSL',
  'SCPTD',
  'APPIMAGE',
  'ZL9',
  'VEXE',
  'CYW',
  'BAT',
  'GADGET',
  'VXP',
  'ACTION',
  'PHAR',
  'EX_',
  'SCRIPT',
  'FAS',
  'COM',
  'COMMAND',
  'PAF.EXE',
  'RFU',
  'A7R',
  'EX4',
  'RXE',
  'SCB',
  'TCP',
  'MLX',
  'EBM',
  'ISU',
  'MCR',
  'CSH',
  'WCM',
  'MRP',
  'JSF',
  'U3P',
  'ACR',
  'EPK',
  'JS',
  'EXE1',
  'NEXE',
  'EXE',
  'RBF',
  'WORKFLOW',
  'RGS',
  'XBAP',
  'ECF',
  'HTA',
  'APPLESCRIPT',
  'DLD',
  'VLX',
  'WIDGET',
  'PRG',
  'VPM',
  'PYC',
  'SERVER',
  'SPR',
  'RPJ',
  'ACC',
  'PYO',
  'MRC',
  'DEK',
  'BA_',
  'IIM',
  'VBS',
  'PIF',
  'MEL',
  'SCPT',
  'PAF',
  'WS',
  'MM',
  'PS1',
  'EX5',
  'SNAP',
  'OTM',
  'PLX',
  'MAC',
  'FPI',
  'EBS',
  'MXE',
  'GS',
  'OSX',
  'PRG',
  'X86',
  'WIZ',
  'FAS',
  'AZW2',
  'E_E',
  'SCT',
  'FKY',
  'APP',
  'SHB',
  'ACTM',
  'CEL',
  'N',
  'TIAPP',
  'THM',
  'APP',
  'KIX',
  'WSF',
  'VBE',
  'WPM',
  'VDO',
  'PEX',
  'S2A',
  'VBSCRIPT',
  'MS',
  'LO',
  'LS',
  'QIT',
  'DS',
  'EZS',
  'TMS',
  'PRC',
  'ESH',
  'SCA',
  'DXL',
  'ARSCRIPT',
  'JSE',
  'BEAM',
  'UDF',
  'RBX',
  'KX',
  'MEM',
  'KSH',
  'MAM',
  'UPX',
  'ROX',
  'BTM',
  'MIO',
  'ES',
  'IPF',
  'ASB',
  'SBS',
  'ITA',
  'HPF',
  'EHAM',
  'EZT',
  'QPX',
  'EXOPC',
  'DMC',
  'NCL',
  'X86_64',
  'ORE',
  'STS',
  'SMM',
  'HAM',
  'WPK',
  'PVD',
  'SH',
  'PHP',
  'PL',
  'LIB',
  'JAVA',
  'C',
  'CPP',
  'H',
  "RUBY'",
]

export const SETTINGS_EXTENSIONS = [
  'MYCOLORS',
  'FVP',
  'VIM',
  'GODOT',
  'ALX',
  'STB',
  'PMP',
  'PC3',
  'GXT',
  'FLW',
  'L4D',
  'MSN',
  'AUX',
  'DBG',
  'FFX',
  'TPF',
  'SET',
  'ASEF',
  'BKS',
  'ZON',
  'DSD',
  'VSTPRESET',
  'EQP',
  'AIP',
  'RPROJ',
  'SKZ',
  'ATN',
  'CMP',
  'THEMEPACK',
  'STYLE',
  'PKG',
  'SBV',
  'SETTINGS',
  'CNF',
  'CFG',
  'BLW',
  'FTP',
  'MNU',
  'IRS',
  'NSX',
  'MCW',
  'UDCX',
  'MCL',
  'QSS',
  'THE',
  'CFG',
  'BLOB',
  'BOOT',
  'CFG',
  'A7P',
  'RTS',
  'LH3D',
  'ISS',
  'SIF',
  'PRM',
  'CFG',
  'RMSKIN',
  'WCZ',
  'BS7',
  'AVS',
  'SED',
  'CEX',
  'OFFICEUI',
  'XTP',
  'ACO',
  'LRTEMPLATE',
  'ASL',
  'RDP',
  'A2THEME',
  'RPS',
  'TDF',
  'MDS',
  'OBI',
  'DINFO',
  'VSSETTINGS',
  'TSI',
  'MST',
  'ODC',
  'PRFPSET',
  'OVPN',
  'PROPDESC',
  'CLG',
  'CUIX',
  'CNF',
  'SKIN',
  'INF',
  'CONF',
  'CFG',
  'VCPREF',
  'M2S',
  'IIP',
  'HD3D',
  'DICPROOF',
  'NP4',
  'WLVS',
  'NVP',
  'BCP',
  'PROPERTIES',
  'SKIN',
  'QVPP',
  'CHL',
  'NKP',
  'ATZ',
  'IHW',
  'DOLPHINVIEW',
  'UIS',
  'ICD',
  'OBT',
  'QTP',
  'XMS',
  'SZ',
  'UCT',
  'FXP',
  'CPDX',
  'DCST',
  'COSTYLE',
  'DOK',
  'OSS',
  'COPRESET',
  'NPS',
  'SOL',
  'WMS',
  'TSCPROJ',
  'MLK',
  'VQC',
  'MAL',
  'RLL',
  'ISP',
  'FETCHMIRROR',
  'C2R',
  'GMW',
  'EHI',
  'PIP',
  'BIN',
  'MST',
  'SFO',
  'XTREME',
  'ACROBATSECURITYSETTINGS',
  'DS_STORE',
  'CUI',
  'ICM',
  'BGI',
  'DOWNLOADHOST',
  'GID',
  'BTSEARCH',
  'ONETOC2',
  'INI',
  'ISS',
  'EFTX',
  'DRP',
  'CONF',
  'DCP',
  'TRX',
  'RCF',
  'ACB',
  'XPADDERCONTROLLER',
  'ZAP',
  'CTB',
  'AIU',
  'VMC',
  'CVA',
  'APPREF-MS',
  'PLIST',
  'DUCK',
  'TSK',
  'PEN',
  'GRD',
  'WFP',
  'OPT',
  'WFC',
  'XDR',
  'PR',
  'QRC',
  'CHX',
  'AXT',
  'ATC',
  'IPYNB',
  'FT',
  'DIRECTORY',
  'CPX',
  'RDF',
  'APPLICATION',
  'PS1XML',
  'LVF',
  'IPCC',
  'AVS',
  'PERFMONCFG',
  'IDPP',
  'OSDX',
  'VPS',
  'TPARK',
  '3DL',
  'SPP',
  'PRF',
  'ASP',
  'PROFILE',
  'PSF',
  'INS',
  'VMXF',
  'BCMX',
  'ARS',
  'PJS',
  'THEME',
  'WVE',
  'ACT',
  'KSF',
  'FCC',
  'GPS',
  'DSX',
  'VNC',
  'CDT',
  'FC',
  'ENS',
  'KBD',
  'A2M',
  'PRF',
  'CDRT',
  'SETTINGCONTENT-MS',
  'RULESET',
  'VBOX',
  'KYS',
  'OPS',
  'MSM',
  'PSC1',
  'DR5',
  'RDW',
  'PROPS',
  'WCX',
  'ALV',
  'OTZ',
  'TTS',
  'BCS',
  'QXW',
  'CSF',
  'TMTHEME',
  'TVC',
  'OEM',
  'ZPI',
  'STORYISTTHEME',
  'MXS',
  'VUE',
  'CSKIN',
  'FAVORITEMETADATA',
  'TLO',
  'EQF',
  'CSPLAN',
  'STARTUPINFO',
  'ENZ',
  'NPFX',
  'PTF',
  'UTZ',
  'LRSMCOL',
  'FAT',
  'WMZ',
  'PRX',
  'ICA',
  'AIA',
  'FMT',
  'VMCX',
  'SLT',
  'FMP',
  'FTPQUOTA',
  'LOOK',
  'JWS',
  'VLT',
  'VSPROPS',
  'JDF',
  'BAU',
  'IX',
  'EMM',
  'STE',
  'SW2',
  'PRF',
  'TERMINAL',
  'FD',
  'PIE',
  'ASE',
  'VMX',
  'DSF',
  'SQD',
  'VIMRC',
  'FLST',
  'PREF',
  'ACW',
  'GWS',
  'MPT',
  'USERPROFILE',
  'SCPRESETS',
  'AMS',
  'TSM',
  'RPK',
  'MOTI',
  'SL',
  'COS',
  'ACV',
  'ALL',
  'CPG',
  'PAL',
  'WPS',
  'INI',
  'SSS',
  'HID',
  'MNS',
  'P2M',
  'HT',
  'DSW',
  'JKM',
  'PMC',
  'SMT',
  'AIT',
  'REG',
  'NGRR',
  'MOF',
  'UPF',
  'MMP',
  'THEME',
  'VTPR',
  'FBT',
  'XUI',
  'FRAMES',
  'T3D',
  'SCH',
  'SCRIBE',
  'PHB',
  'SKI',
  'NDC',
  'CAMP',
  'PIO',
  'CMATE',
  'THMX',
  'NJI',
  'STT',
  'TWC',
  'ATH',
  'HME',
  'MAILHOST',
  'LVA',
  'ADPP',
  'DCL',
  'SSL2',
  'SRS',
  'SPF',
  'EUM',
  'TSZ',
  'LCC',
  'OMS',
  'VMTM',
  'NVC',
  'S2ML',
  'GROWLTICKET',
  'ICST',
  'CPTM',
  'RWSTYLE',
  'OTPU',
  'MOTR',
  'BITPIM',
  'TVTEMPLATE',
  'MOEF',
  'STD',
  'EWPRJ',
  'LIGHTKEYPROJ',
  'XLB',
  'EQL',
  'WME',
  'COMP',
  'PCTL',
  'DEFT',
  'AHU',
  'NCFG',
  'MTF',
  'VCOMPS',
  'KCB',
  'MOTN',
  'NWV',
  'ARG',
  'AHL',
  'RAD',
  'PXB',
  'RDO',
  'PDADJ',
  'BRG',
  'DVTCOLORTHEME',
  'MSKN',
  'ICURSORFX',
  'DDF',
  'EXE4J',
  'POLICY',
  'XST',
  'AST',
  'SGT',
  'ITT',
  'CST',
  'EXPORTEDUI',
  'PGP',
  'GIN',
  'TYPEIT4ME',
  'MATERIAL',
  'ASK',
  'SKN',
  'INI',
  'ICC',
  'ACB',
  'VMPL',
  'ND',
  'JOY',
  'ZPF',
  'WC',
  'MXSKIN',
  'KDS',
  'XPL',
  'CTBODYFITTING',
  'PDP',
  'WIF',
  'FWT',
  'FTH',
  'MOBIRISE',
  'AWCAV',
  'RESMONCFG',
  'ELM',
  'DAR',
  'AVE',
  'PVS',
  'MGK',
  'AOM',
  'IIT',
  'GTKRC',
  'PRO5TEMPLATE',
  'QAT',
  'XUR',
  'WORK',
  'XVM',
  'XTODVD',
  'PMJ',
  'SYNW-PROJ',
  'RCT',
  'IKMP',
  'CGR',
  'AHS',
  'CPR',
  'IAF',
  'SNX',
  'ABS',
  'CPF',
  'DBB',
  'DTSCONFIG',
  'DUN',
  'PAPERS',
  'CYBERDUCKPROFILE',
  'QF',
  'MMDC',
  'SSL',
  'GCSX',
  'GQSX',
  'VBX6SETTINGS',
  'ASW',
  'DXLS',
  'XLB',
  'RCPROJECT',
  'QPX',
  'PROFIMAIL',
  'GVIMRC',
  'PRS',
  'GTP',
  'BCP',
  'DXP',
  'MPDCONF',
  'XCU',
  'RFQ',
  'SPFX',
  'VPH',
  'SCACFG',
  'SCPCFG',
  'PSY',
  'CSAPLAN',
  'MASK',
  'WZCONFIG',
  'LOP',
  'FTH',
  'LXSOPT',
  'VMX',
  'PXG',
  'DRM',
  'EXP',
  'QVT',
  'LYT',
  'IKF',
  'BLT',
  'CPS',
  'SKN',
  'GPS',
  'PSP',
  'MSW',
  'SPJ',
  'PML',
  'SEESTYLE',
  'CLR',
  'RASKINPLACE',
  'ZON',
  'CYBERDUCKLICENSE',
  'FNC',
  "CLS",
  "TLS",
  "AAR",
  "ARK",
  "BASE",
  "BACKUP",
  "SITE",
  "MJ",
  "EMD",
  "DCT",
  "DPF",
  "DPS",
  "FIX",
  "AW",
  "LOG",
]

export const SYSTEM_EXTENSIONS = [
  'ADMX',
  '73U',
  'MAPIMAIL',
  'EBD',
  'HIV',
  'REG',
  'ICONPACKAGE',
  'HTT',
  'CUR',
  'AML',
  'SEARCHCONNECTOR-MS',
  'REGTRANS-MS',
  'PCK',
  'ANI',
  'DLL',
  'DESKTHEMEPACK',
  'DVD',
  'BASH_HISTORY',
  'DESKLINK',
  'LNK',
  'FTF',
  'WDGT',
  'SCR',
  'VGA',
  'CLB',
  'DMP',
  'ICNS',
  'CPL',
  'PK2',
  'ION',
  'NFO',
  'INF_LOC',
  'MDMP',
  'ICO',
  'NT',
  'LIBRARY-MS',
  'QVM',
  'THEME',
  'LOG1',
  'SYS',
  'ASEC',
  'RC1',
  'SFCACHE',
  'CAB',
  'MSC',
  'MANIFEST',
  '000',
  'PROP',
  'DAT',
  'BIN',
  'BASHRC',
  'LOCKFILE',
  'FOTA',
  'PAT',
  'H1S',
  'DRPM',
  'EDJ',
  'SERVICE',
  'CANNEDSEARCH',
  'MI4',
  'RMT',
  'CI',
  'CGZ',
  'ZONE.IDENTIFIER',
  'FFX',
  'PWL',
  'TIMER',
  'VX_',
  'SDT',
  'MUI',
  'ETL',
  'SYS',
  'MTZ',
  'MOBILECONFIG',
  'PROFILE',
  'BASH_PROFILE',
  '3FS',
  'BCD',
  'WDF',
  'MLC',
  'BIO',
  'MSSTYLES',
  'CM0013',
  'ELF',
  'MSP',
  'BOM',
  'LFS',
  'SDB',
  '0',
  'C32',
  'PNF',
  'PDR',
  'HHK',
  'DIMAX',
  'GROUP',
  'DEV',
  'WEBPNP',
  'MBR',
  '208',
  'EFI',
  'PIT',
  'ADM',
  'DIAGCAB',
  'INS',
  'DRV',
  'JOB',
  'FIRM',
  'POL',
  'CNT',
  'FX',
  'LOG2',
  'IME',
  'SAVEDSEARCH',
  'IOPLIST',
  'PREFPANE',
  'IMG3',
  'CAT',
  'PPD',
  'VXD',
  'DTHUMB',
  'HCD',
  'WPX',
  'SBF',
  'DIT',
  'KEY',
  'CRASH',
  'PRF',
  'AOS',
  '8XU',
  'LST',
  '386',
  'ADML',
  'MBN',
  'GRP',
  'MOD',
  'THUMBNAILS',
  'SCHEMAS',
  'CHG',
  '8CU',
  'CPQ',
  'CMO',
  'TDZ',
  'FFO',
  'ITEMDATA-MS',
  'SQM',
  'HDMP',
  'SWP',
  'SBN',
  'SHSH',
  'HLP',
  'FLG',
  'DFU',
  'IDX',
  'ODEX',
  'PS2',
  'CHK',
  'BLF',
  'SHD',
  'CPI',
  '2FS',
  'PRINTEREXPORT',
  'THA',
  'ICL',
  'FPBF',
  'PRO',
  'PFX',
  'WER',
  'ADV',
  'NB0',
  'CHT',
  'KO',
  'HHC',
  'NBH',
  'MSSTYLE',
  'ATAHD',
  'FL1',
  'TRX_DLL',
  'PRT',
  'KS',
  'IM4P',
  'MUI_CCCD5AE0',
  'RUF',
  'SAVER',
  'METADATA_NEVER_INDEX',
  'STR',
  'KEXT',
  'XRM-MS',
  'CUSTOMDESTINATIONS-MS',
  'SB',
  'CAP',
  'UCE',
  'DLX',
  'FID',
  'HSH',
  'XFB',
  'CHS',
  'PID',
  'RS',
  'IDI',
  'IFW',
  'GMMP',
  'HPJ',
  'RFW',
  'SPL',
  'EFIRES',
  'CONFIGPROFILE',
  'PROVISIONPROFILE',
  'ESCOPY',
  'MEM',
  'TA',
  'LPD',
  'BK2',
  'CPR',
  'MUM',
  'SO.0',
  'GRL',
  'SCF',
  'BMK',
  'NLS',
  'AUTOMATICDESTINATIONS-MS',
  'DYC',
  'DEVICEMETADATA-MS',
  'KBD',
  'ME',
  'TRASHES',
  'PS1',
  'EVTX',
  'QKY',
  'PPM',
  'FFA',
  'FTS',
  'DSS',
  'NTFS',
  'VGD',
  'JPN',
  'LPD',
  'IPTHEME',
  'WPH',
  'RCO',
  'IPOD',
  'MYDOCS',
  'SCAP',
  'BUD',
  'WGZ',
  'SPX',
  'BK1',
  'NETWORKCONNECT',
  'DIAGCFG',
  'CDMP',
  'MMV',
  'PANIC',
  'EMERALD',
  'SIN',
  'FTG',
  'EVT',
  'REGLNK',
  'HVE',
  'PLASMOID',
  'RCV',
  'KOR',
  'FFL',
  'IUS',
  'ANN',
  'HELP',
  'INTERNETCONNECT',
  'DIAGPKG',
  'RC2',
  'ITS',
  'RVP',
  'SO',
]
