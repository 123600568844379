import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import {
  useSelected,
  useSlateStatic,
} from 'slate-react';
import { Editor, Transforms } from 'slate';
import { removeSelection, addSelection } from './selection';
import { options } from './options';
import { TableCardbar, HorizontalToolbar } from './ui';

export const TableElement = (props) => {
//   const selected = useSelected();
//   return <TableElementMemoized {...props} selected={selected} />;
//   Memoizing the function was causing other crashes in editor
// }
// export const TableElementMemoized =  React.memo((props) => {
  const selected = useSelected();
  const { attributes, children, element, disabled} = props;
  // const editor = useEditor();
  const editor = useSlateStatic();
  switch (element?.type) {
    case 'table': {
      let existSelectedCell = false;
      let table = null;

      if (selected && editor.selection) {
        [table] = Editor.nodes(editor, {
          match: n => n.type === 'table',
          at: Editor.path(editor, editor.selection),
        });

        if (table) {
          const [selectedCell] = Editor.nodes(editor, {
            at: Editor.range(editor, table[1]),
            match: n => n.selectedCell,
          });

          if (selectedCell) {
            existSelectedCell = true;
          }
        }
      }

      return (
        <div style={{ position: 'relative' }}>
          {selected && !disabled && <TableCardbar/>}
          <TableComponent {...props} table={table}>
            {children}
          </TableComponent>
        </div>
      );
    }
    default:
      return <div {...props} />;
  }
}
// ,(prevProps, nextProps) => {
//   // return false; 
//   // Using false, because memoizing the function was causing other crashes
//   // return !nextProps.selected && prevProps.selected === nextProps.selected;
// })

const TableComponent = (props) => {

  const { table, children, className } = props;
  const editor = useSlateStatic();
  const selected = useSelected();
  const ref = useRef(null);
  const [hasResized, setHasResized] = useState(false);

  const resizeTable = useCallback(() => {
    // console.log('resizeTable');
    if (ref.current) {
      ref.current.querySelectorAll('td').forEach(cell => {
        Transforms.setNodes(
          editor,
          {
            width: cell.offsetWidth,
            height: cell.offsetHeight,
          },
          {
            at: [],
            match: n => n.key === cell.dataset.key,
          },
        );
      });
    }
  }, [editor]);

  useEffect(() => {
    if(selected && !hasResized) {
      resizeTable();
      setHasResized(true);
    }
  }, [selected]);
  // }, [resizeTable, selected, editor.selection]);

  useEffect(() => {
    if (!selected) removeSelection(editor);
  }, [selected, editor]);

  const [startKey, setStartKey] = useState('');

  const startNode = useMemo(() => {
    const [node] = Editor.nodes(editor, {
      match: n => n.key === startKey,
      at: [],
    });

    return node;
  }, [startKey, editor]);

  const ResizeToolbar = useMemo(() => {
    return (
      // selected &&
      ref.current &&
      table && (
        <>
          <HorizontalToolbar table={ref.current} tableNode={table} />
        </>
      )
    );
  }, [table]);

  return (
    <>
      {ResizeToolbar}
      <table
        className={className ? "table "+className : "table"}
        slate-table-element="table"
        ref={ref}
        style={options.tableStyle}
        onDragStart={e => e.preventDefault()}
        onMouseDown={e => {
          removeSelection(editor)
          const cell = (e.target).closest('td');
          const key = cell?.getAttribute('data-key') || '';
          setStartKey(key);
        }}
        onMouseMove={e => {
          const cell = (e.target).closest('td');
          if (cell && startKey) {
            const endKey = cell.getAttribute('data-key');

            const [endNode] = Editor.nodes(editor, {
              match: n => n.key === endKey,
              at: [],
            });

            addSelection(
              editor,
              table,
              Editor.path(editor, startNode[1]),
              Editor.path(editor, endNode[1]),
            );
          }
        }}
        onMouseUp={() => {
          setStartKey('');
          // resizeTable is being done only on dragEnd in the ui.jsx
          // resizeTable();
        }}
        onMouseLeave={() => {
          // remove start key after 500 ms
          setTimeout(() => {
            setStartKey('');
          }, 800);
          // setStartKey('');
        }}
      >
        <tbody slate-table-element="tbody">{children}</tbody>
      </table>
    </>
  );
};

export const CellComponent = ({ attributes, node, dataKey, children }) => {
  const { selectedCell } = node;
  let classNames = 'table-td'
  if (selectedCell) {
    classNames += ' selectedCell';
  }

  return (
    <td
      {...attributes}
      className={classNames}
      slate-table-element="td"
      data-key={dataKey}
      colSpan={node?.colspan}
      rowSpan={node?.rowspan}
      onDragStart={e => e.preventDefault()}
      style={{
        position: 'relative',
        minWidth: '50px',
        width: node?.width ? `${node?.width}px` : 'auto',
        height: node?.width ? `${node?.height}px` : 'auto',
      }}
    >
      {children}
    </td>
  );
};
