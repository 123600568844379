import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {useConstant, useForceUpdate, useLazyConstant} from '@startlibs/core'
import {_s, callIfFunction} from '@startlibs/utils'
import _ from 'lodash/fp'

const IS_ARRAY_ITEMS_EQUALS = (a,b) => (a.length === b.length && !a.find((itemA,i) => !Object.is(itemA,b[i])))
const IS_DEPS_EQUALS = (a = [],b = []) => Object.is(a,b) || IS_ARRAY_ITEMS_EQUALS(a,b)

const SHALLOW_IS_EQUAL = (a,b) => Object.is(a,b) || (_.isArray(a) && _.isArray(b) && IS_ARRAY_ITEMS_EQUALS(a,b))

const EMPTY_DEPS = []
export const useUpdateSignal = (initialValue) => {
  const value = useRef(initialValue)
  value.current = initialValue
  return useLazyConstant(() => {
    let registered = []
    const register = (selector = _.identity, isEqual = SHALLOW_IS_EQUAL) => {
      const selectorRef = useRef()
      selectorRef.current = selector
      const update = useForceUpdate()
      const lastValueRef = useRef(_s.select(selectorRef.current, value.current))
      useEffect(() => {
        const item = [update, selectorRef, isEqual, lastValueRef]
        registered = [...registered, item]
        return () => {
          registered = registered.filter(v => v !== item)
        }
      }, [])
      return lastValueRef.current
    }
    const call = (...args) =>
      registered.forEach(([fn, selectorRef, isEqual, lastValueRef]) => {
        const nextValue = _s.select(selectorRef.current, ...args)
        if (!callIfFunction(isEqual,nextValue,lastValueRef.current)) {
          lastValueRef.current = nextValue
          fn(nextValue)
        }
      })
    return [register, call]
  })
}

export const useSelectorSignal = (value) => {
  const prevValue = useRef(value)
  const prevDeps = useRef(EMPTY_DEPS)
  const [currentDeps,setCurrentDeps] = useState(prevDeps.current)
  const [_register,call] = useUpdateSignal(value)
  const register = useCallback((selector, deps, isEqual) => {
    if (!IS_DEPS_EQUALS(deps,prevDeps.current)) {
      setCurrentDeps(deps)
    }
    return _register(selector,isEqual)
  }, [])
  useEffect(() => {
    if (prevValue.current !== value || currentDeps !== prevDeps.current) {
      prevValue.current = value
      prevDeps.current = currentDeps
      call(value)
    }
  },[value,currentDeps])
  return register
}
export const createStatefulContext = (v) => {
  const Ctx = React.createContext(v)
  const Provider = ({value, setValue, utils,children}) => {
    const register = useSelectorSignal(value)
    const v = useLazyConstant(() => [register,setValue, utils])
    return <Ctx.Provider value={v}>{children}</Ctx.Provider>
  }
  const useSelectValue = (selector,deps,isEqual) => {
    const [useRegister] = useContext(Ctx)
    return useRegister(selector,deps,isEqual)
  }
  const useState = (path,deps) => {
    const [useRegister, set, utils] = useContext(Ctx)
    const setValue = useConstant((update) => set(state =>
      path
        ? _.update(path,(v) => callIfFunction(update,v),state)
        : callIfFunction(update,state)
    ))
    return [useRegister(path,deps), setValue, utils]
  }
  return [Provider,useState,useSelectValue]
}
export const createSelectableContext = (v) => {
  const [Provider,,useSelectValue] = createStatefulContext(v)
  return [Provider,useSelectValue]
}