import {Icon} from '@startlibs/components';
import {getColor, media} from '@startlibs/utils';
import React from 'react'
import _ from 'lodash/fp'
import styled, {css} from 'styled-components';
import {getAvatarBackgroundImage} from '../utils/avatar'

export const ExpertCardStyle = styled.div`
  background: white;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  position: relative;
  padding: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  ${media.max(440)`
    padding: 2.5rem 1.5rem;
  `}
  .avatar-wrapper {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    min-width: 4rem;
    min-height: 4rem;
    width: 4rem;
    height: 4rem;
    box-shadow: inset 0 0 0 1px rgba(0,0,0,0.15);
    border-radius: 4rem;
    margin-right: 1rem;
    ${props => props.noPic && css`
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${getColor('gray240')};
      ${Icon} {
        font-size: 2rem;
        margin-top: -.25rem;
        color: ${getColor('gray180')};
      }
    `}
  }
  .expert-name {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 1px;
  }
`

export const RecentlyInvited = styled.span`
    color: ${getColor('gray150')};
    font-style: italic;
    font-weight: 400;
`

export const ExpertProfileCard = ({expert,checked}) => <ExpertCardStyle noPic={!expert.picture}>
  <div className="avatar-wrapper" css={expert.picture ? getAvatarBackgroundImage(expert) : ""}>{!expert.picture && <Icon icon="physician"/>}</div>
  <div>
    <div className="expert-name">{
      (expert.expertName && expert.expertName.length >2)
      ? <span>{expert.expertName}</span>
      : (expert.firstName || expert.lastName)
        ? <span>{expert.firstName} {expert.lastName}</span>
        : <RecentlyInvited>Recently added expert</RecentlyInvited>
    }</div>
    <div className="email"><b>Email:</b> {expert.email || expert.expertEmail}</div>
    {_.isNumber(expert.openCases) && <div><b>Open cases:</b> {expert.openCases}</div>}
  </div>
</ExpertCardStyle> 
