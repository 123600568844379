import React, {useContext} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {ADMIN} from "../../enums/UserRoles";
import {Rejected} from "../../enums/RecordState";
import {UploaderConfigContext} from "../../service/UploaderConfigContext";
import {AttachmentBox, AttachmentIcon, AttachmentInfoContainer, DraggableCornerIcon} from "../AttachmentBoxStyles";
import {useUIDataSelector} from "../../service/hooks/useUIDataSelector";
import {useDoAction} from "../../service/hooks/useDoAction";
import {Failed} from "../../service/enums/RecordStatus";
import {UIAction} from "../../service/UIAction";
import {useRecordsSelector} from "../../service/hooks/useRecords";
import {getItemPersistedUID, getRecordIcon} from "../../service/utils/recordUtils";
import {getFileTypeIcon} from "../../utils/FileType";
import {Pathology} from "../../enums/RecordClass";
import {NotesField} from "./NotesField";
import {MetadataField} from "./MetadataField";
import {DEVICE, DISK} from "../../enums/UploaderStepsManagement";
import {callIfFunction} from "@startlibs/utils";
import {RecordRowActions} from "./RecordRowActions";
import {RecordRowQuarantinedFooter} from "./RecordRowQuarantinedFooter";
import {RecordRowProgressStatus} from "./RecordRowProgressStatus";

styled

export const RecordRowContainer = (
  {
    record,
    group,
    dragProvided,
    children,

    cancelUpload,
    deleteRecord,
    openDetails,

    withNotes = true,
    withMeta = true
  }) => {
  const {isApp, mode, allowReorder, role} = useContext(UploaderConfigContext)
  const doAction = useDoAction()
  const recordKeys = useRecordsSelector(_.map(getItemPersistedUID))
  const isSelectMode = useUIDataSelector('selectMode')
  const selectedRecords = useUIDataSelector('selectedRecords')
  const groupsLength = useUIDataSelector('groups.length')

  const isDragDisabled = !allowReorder || (recordKeys.length < 2 && groupsLength === 1)
  const isMinified = mode === DEVICE || mode === DISK

  return <AttachmentBox
    failedUpload={record.status === Failed}
    metadaisApp={isApp}
    hasFooter={record.quarantined || record.partiallyQuarantined}
    unavailable={record.quarantined}
    ref={dragProvided.innerRef}
    {...dragProvided.draggableProps}
    {...dragProvided.dragHandleProps}
    isSelectMode={isSelectMode}
    onClick={isSelectMode ? (() => doAction(UIAction.ToggleSelectedRecord, record.key)) : undefined}
    isSelected={selectedRecords.includes(record.key)}
    mode={mode}
  >
    {!isDragDisabled && <DraggableCornerIcon icon="draggable-corner"/>}
    <AttachmentIcon icon={getRecordIcon(record)} mode={mode}/>
    <RecordRowProgressStatus openDetails={openDetails} record={record} isMinified={isMinified} cancelUpload={cancelUpload}/>
    <AttachmentInfoContainer>
      {
        callIfFunction(children, isMinified)
      }
      {role === ADMIN && withNotes && record.recordUID && <NotesField record={record}/>}
      {role === ADMIN && withMeta && record.recordUID && <MetadataField record={record}/>}
    </AttachmentInfoContainer>
    <RecordRowActions group={group} record={record} isMinified={isMinified} isDragDisabled={isDragDisabled} deleteRecord={deleteRecord}/>
    {(record.quarantined || record.partiallyQuarantined) && <RecordRowQuarantinedFooter />}
  </AttachmentBox>
}