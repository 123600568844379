import React from 'react'
import {useNavigate, Routes, Route} from 'react-router'
import {PageLoadingSuspense} from '../components/PageLoading'
import {useNotification} from '../components/Notifications'
import {getJwt} from '../hooks/useJwt'
import {ExpertList} from './experts/ExpertList'
import {EditExpert} from './experts/EditExpert'
import {ViewExpert} from './experts/ViewExpert'
import {jwtGetFetcher, jwtPostFetcher} from '../utils/authFetch'

const DEV_JWT = process.env.NODE_ENV === 'development' && 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2Nlc3NUeXBlIjoiQURNSU4iLCJyb2xlIjoiU1VQRVIiLCJwcm92aWRlcklkIjoiMSIsImxvZ2luIjoiZ21vbnRlaXJvQHB1cnZpZXcubmV0In0.uYfUEv13-D-CFAphbxpw5J_45QC05BcaiU5QACXkSEE'

export const AdminExpertsRoutes = () => {
  const navigate = useNavigate()
  // const jwt = useConstant(new URLSearchParams(window.location.search).get("jwt") || DEV_JWT)
  //
  // useRegisterJwt(() => jwt)
  const [,setNotification] = useNotification()

  const addOrUpdateExpert = (newExpert) => (
    newExpert.id
      ? jwtPostFetcher(getJwt())('/api/expert', newExpert, {method: 'PUT'})
      : jwtPostFetcher(getJwt())('/api/expert', newExpert)
  )
    .then((expert) =>
      jwtGetFetcher(getJwt())(`/api/experts?nocache=${new Date().getTime()}`)
        .then(() =>  setNotification(newExpert.id ? "Expert profile updated successfully." : "Expert added successfully."))
        .then(() => expert)
    )


  return <PageLoadingSuspense>
    <Routes>
      <Route path="edit/:id" element={<EditExpert action={addOrUpdateExpert}/>}/>
      <Route path="new" element={<EditExpert action={addOrUpdateExpert} onSuccess={(values,{id}) => navigate("/admin/experts/view/"+id)} />}/>
      <Route path="view/:id" element={<ViewExpert />}/>
      <Route path="*" element={<ExpertList />}/>
    </Routes>
  </PageLoadingSuspense>
}
