import {FormContext, TextInput, useFormValue} from '@startlibs/form'
import {callIfFunction, isMobile} from '@startlibs/utils'
import React, {useContext, useEffect, useRef} from 'react'

export const QuantityInput = ({min = 0,max = Number.MAX_SAFE_INTEGER,...props}) => {

  const fixNaN = (v,other = min) => isNaN(v) ? other : v
  const [value] = useFormValue(props.path)
  const form = useContext(FormContext)
  const ref = useRef()

  useEffect(() => {
    ref.current.focus()
  },[])

  useEffect(() => {
    if (form) {
      if ((value > max || value < min) && !props.raw) {
        form.setError(props.path)
      } else {
        form.removeErrorPath(props.path)
      }
    }
  },[value])

  const onFocus = (e) => {
    e.target.select()
    callIfFunction(props.onFocus,e)
  }

  const onBlur = (e) => {
    setValue(v => fixNaN(Math.max(Number(v),min)))
    callIfFunction(props.onBlur,e)
  }

  const setValue = (v) => {
    if (props.raw) {
      props.setValue(callIfFunction(v,props.value))
    } else {
      form.setValue(props.path,v)
    }
  }

  const onKeyDown = (e) => {
    if (e.key === "ArrowUp") {
      e.preventDefault()
      setValue(v => fixNaN(Math.min(Number(v)+1,max)))
      e.target.select()
    } else if (e.key === "ArrowDown") {
      e.preventDefault()
      setValue(v => fixNaN(Math.max(Number(v)-1,min),max))
      e.target.select()
    }
  }

  return <TextInput
    {...props}
    ref={ref}
    constraint={/\D/g}
    onKeyDown={onKeyDown}
    onFocus={onFocus}
    onBlur={onBlur}
    type={isMobile() ? "number" : "text"}
  />

}