import {callIfFunction, getFetcher, postFetcher} from '@startlibs/utils'

export const jwtGetFetcher = (jwt) => (url, params = null, options) => getFetcher(url, params, {...options,headers: new Headers({'Authorization': `Bearer ${callIfFunction(jwt)}`})})
export const jwtPostFetcher = (jwt) => (url, params = null, options) => postFetcher(url, params, {...options,headers: new Headers({'Authorization': `Bearer ${callIfFunction(jwt)}`,'Content-Type': 'application/json; charset=UTF-8'})})


export const authGetFetcher = jwtGetFetcher(() => window.sessionStorage.getItem("jwt"))
export const authPostFetcher = jwtPostFetcher(() => window.sessionStorage.getItem("jwt"))


