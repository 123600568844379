import React from 'react'
import _ from 'lodash/fp'


export const DEFAULT_ADDITIONAL_FIELDS = [
  'Summary for the patient',
  'Background information',
  'Recommendation and links'
]

// export const LEGAL_ADDITIONAL_FIELDS = [
//   'Summary for the client',
//   'Background information',
//   'Recommendation and links'
// ]

export const LEGAL_ADDITIONAL_FIELDS = []

export const createCaseTransform = _.flow(
  _.update('categories', (categories) => (categories || []).filter(_.identity).map(({id})=> id)),
  _.set('caseInfo.additionalFields', DEFAULT_ADDITIONAL_FIELDS.map((title,i) => ({title,id: Date.now().toString(36)+i,content:''})))
)

export const createLegalCaseTransform = _.flow(
  _.update('categories', (categories) => (categories || []).filter(_.identity).map(({id})=> id)),
  _.set('caseInfo.additionalFields', LEGAL_ADDITIONAL_FIELDS.map((title,i) => ({title,id: Date.now().toString(36)+i,content:''})))
)