let resolveProviderJwt = null
let providerJwt = new Promise((res) => resolveProviderJwt = res)


export const setProviderJwt = (newProviderJwt,sessionKey) => {
  providerJwt = newProviderJwt
  resolveProviderJwt(providerJwt)
  if (sessionKey) {
    window.localStorage.setItem(sessionKey+"-providerJwt",newProviderJwt)
  }
}
export const getProviderJwt = () => providerJwt