import { Button, Icon } from '@startlibs/components';
import { getColor, media } from '@startlibs/utils';
import React from 'react'
import styled from 'styled-components'
import { Card } from '../../components/PageLayout';
import {lazyProviderInfo} from '../../components/WithProvider'

const SSOErrorPage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  max-width: 400px;
  width: 100%;
  ${Card} {
    margin-bottom: 0;
    padding-bottom: 4rem;
  }
  ${Icon} {
    font-size: 5rem;
    color: ${getColor('gray180')};
  }
  h1 {
    font-size: 18px;
    margin: 1rem auto 2rem;
    color: ${getColor('main')};
  }
  p {
    margin: 0 .25rem 1.25rem;
    font-size: 13px;
  }
  ${Button} {
    margin-top: 1rem;
  }
`

const SystemLogo = styled.div`
  margin: 0 auto 3rem;
  text-align: center;
  div {
    position: relative;
    display: inline-block;
    :before {
      content: '';
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0,0,0,0.05);
    }
  }
  img {
    background: white;
    max-width: 320px;
    max-height: 180px;
    margin: 0 auto;
    ${media.max(400)`
      max-width: 240px;
    `}
  }
`

const StyledSupportMessage = styled.div `
  text-align: center;
  color: rgba(0,0,0,0.4);
  padding: 0.75rem 0 1rem;
  font-weight: 600;
  max-width: 35rem;
  margin: auto;
  a {
    text-decoration;
    color: rgba(0,0,0,0.4);
    :hover {
      color: rgba(0,0,0,0.6);
    }
  }
`


export const SSONotFoundError = () => {
  const providerInfo = lazyProviderInfo.read()
  const redirect = new URLSearchParams(window.location.search).get("role") || "/"
  return <SSOErrorPage>
    <SystemLogo><div><img src={providerInfo.logoURL}/></div></SystemLogo>
    <Card>
      <Icon icon="warning" />
      <h1>User not registered in Expert View.</h1>
      <p>The single sign-on (SSO) account you are trying to use to access Expert View is not registered to the system.</p>
      <p><strong>Make sure you are using the correct account  or contact your system administrator.</strong></p>
      <Button.Link highlight to={redirect}>Return to login</Button.Link>
    </Card>
    <StyledSupportMessage>Need support? Call (800) 501-1537 or email <a href="mailto:support@purview.net">support@purview.net</a></StyledSupportMessage>
  </SSOErrorPage>
}
