import { Button,} from '@startlibs/components';
import {RadioboxGroup} from '@startlibs/form'
import React, {useEffect, useRef, useState} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components'
import {CaseNotesInformation} from '../../request/CaseNotesInformation'
import {Header} from '../../components/Header'
import {Card, PageContainer} from '../../components/PageLayout'
import {PurviewFooter} from '../../components/PurviewFooter'
import { AddButton } from '../../components/AddButton';
import { LoadingPage } from '../../components/PageLoading';
import { useNavigate, useLocation } from 'react-router';
import { useAutoScroll } from '../../hooks/useAutoScroll';

export const ContactSelectBoxes = styled(RadioboxGroup)`
  display: block;
  margin-bottom: 1rem;
`

export const SelectorTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  p {
    margin-bottom: 0;
  }
`

export const CaseNotesStep = ({caseRequest, caseNotes, setCaseNotes, openPanel,isLoadingNotes}) => {
  const location = useLocation()
  const navigate = useNavigate()
  var urlNoteId = location.pathname.split('/notes/',9)[1]
  const [noteId, setNoteId] = useState(urlNoteId)
  const formRef = useRef()
  const bottomScroll = useAutoScroll(!noteId)

   // This will be triggered on close tab/window when case notes page is open and note active / not saved 
   useEffect(() => {
    const onUnload = function (e) {
      if (!formRef.current || !formRef.current.hasChanged) {
        return;
      }
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = '';
    }
    window.addEventListener('beforeunload', onUnload);
    return () => window.removeEventListener('beforeunload', onUnload)
  },[location, formRef.current])

  // This will be triggered on browser back button when case note page is open and note active / not saved 
  useEffect(() => {
    const warningText =
	  'You have unsaved changes - are you sure you wish to leave this page?';
    const onPopState = function (e) {
     
      if (!formRef.current || !formRef.current.hasChanged) {
        return;
      }

      if(e.target.location.pathname.includes('admin/request/notes')){
        return;
      }
      
      if (window.confirm(warningText)){ return;}
      
      navigate(location.pathname);

    }
    window.addEventListener('popstate', onPopState);
    return () => window.removeEventListener('popstate', onPopState)
  },[location, formRef.current ])

  return <>
    <PageContainer onMouseDown={() => setNoteId()}>
      <Header title="Case Notes">
        <Button onClick={() => openPanel.openWith(true)} icon="open-panel">Open floating panel</Button>
      </Header>
      {isLoadingNotes && <LoadingPage></LoadingPage>}
      {!isLoadingNotes && caseNotes && <Card>
        <p css="margin-bottom:1.5rem;">Notes are for internal use and can be created by Administrators here, or by anyone accessing a shared case. These notes will not be presented in the final report, and are not visible to Experts or patient representatives.</p>
        <CaseNotesInformation
          caseRequest={caseRequest}
          caseNotes={caseNotes} 
          setCaseNotes={setCaseNotes}
          formRef={formRef}
          values={caseNotes}
          noteId={noteId}
        ></CaseNotesInformation>
        <div ref={bottomScroll}>
          <AddButton css="width:100%;" onClick={
            () => setCaseNotes(_.concat(caseNotes,{external: false, info: {note: ''}}))
          }>
            Add note
          </AddButton>
        </div>
      </Card>}
    </PageContainer>
    
    <PurviewFooter/>
  </>
}
