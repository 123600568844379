import {Button} from '@startlibs/components'
import React from 'react'
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import { ExpertCaseCard } from '../ExpertCaseCard';
import {getJwt} from '../../hooks/useJwt'
import {jwtPostFetcher} from '../../utils/authFetch'

export const useAcceptCase = (caseRequest,onSuccess) => useConfirmDialog(
  <ConfirmDialog
    title="Accept case"
    action={() => jwtPostFetcher(getJwt())("/api/expert/acceptCase/" + (caseRequest.requestId || caseRequest.id))}
    onSuccess={onSuccess}
    notify="Case accepted successfully."
    confirm={
      <Button highlight>Accept</Button>
    }
  >
    <ExpertCaseCard caseRequest={caseRequest} viewOnly />
    <p>By accepting this case you agree to provide a professional opinion.</p>
  </ConfirmDialog>
)
