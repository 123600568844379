import { Button, Icon, Link, Popup } from '@startlibs/components';
import { Field, WithForm } from '@startlibs/form';
import { isMobile } from '@startlibs/utils';
import { usePopupToggle } from '@startlibs/core';
import React from 'react'
import _ from 'lodash/fp'
import styled,{css} from 'styled-components'

import {QuantityInput} from './QuantityInput'

const PaginationButton = styled(Button) `
  box-shadow: none;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-weight: 600;
  min-width: 2rem;
  min-height: 2rem;
  user-select: none;
  ${props => props.highlight ? css`
      pointer-events: none;
    `:css`
      background: rgba(0,0,0,0.075);
      color: rgba(0,0,0,0.6);
      :hover {
        background: rgba(0,0,0,0.1);
      }
      :active {
        background: rgba(0,0,0,0.125);
      }
    `}
    ${props => props.disabledButton && css`
      pointer-events: none;
      background: rgba(0,0,0,0.05);
      color: rgba(0,0,0,0.2);
    `}
  & ~ & {
    margin-left: 0.5rem;
  }
  ${Icon} {
    font-size: 14px;
  }
`

export const Pagination = styled(({currentPage, totalPages, setPageParam, className}) => {

  const pages =
    totalPages <= 7
    ? _.uniq([1,2,3,4,5,6, 7])
    : currentPage < 4
    ? _.uniq([1,2,3,4,5,totalPages-1,totalPages])
    : currentPage > (totalPages - 4)
    ? _.uniq([1,2,totalPages-4,totalPages-3,totalPages-2,totalPages-1,totalPages])
    : _.uniq([1,2,Math.max(currentPage-1,1),currentPage,Math.min(currentPage+1,totalPages),totalPages-1,totalPages])



  return <div className={className}>
    <PaginationButton icon="arrow-left-line" onlyIcon disabledButton={currentPage === 1} onClick={setPageParam(currentPage-1)}/>
    {
      pages.slice(0,totalPages).map((page,i) =>
        (page === 2 && currentPage > 4 && totalPages > 7)
        ? <CollapsedPagination from={2} to={currentPage-1} setPageParam={setPageParam} totalPages={totalPages}/>
        : (page === (totalPages-1) && currentPage < (totalPages - 3) && totalPages > 7)
        ? <CollapsedPagination  from={currentPage+2} to={totalPages} setPageParam={setPageParam} totalPages={totalPages}/>
        : <SinglePage setPageParam={setPageParam} index={page} selected={currentPage === page} />
      )
    }
    <PaginationButton icon="arrow-right-line" onlyIcon disabledButton={currentPage >= totalPages} onClick={setPageParam(currentPage+1)}/>
  </div>
})`
  
`

const GoToPageInput = styled.div `
  display: flex;
  align-items: flex-end;
  input {
    width: 80px;
  }
  ${Button} {
    min-width: 0;
    margin-left: 0.5rem;
  }
`

const CollapsedPagination = ({from, to, setPageParam, totalPages}) => {
  const popup = usePopupToggle()

  const quantity = Math.abs(from-to)
  const showInput = quantity > 5

  return <PaginationButton onClick={popup.open} small>...
    {
      popup.isOpen &&
      <Popup onClick={e => e.stopPropagation()} arrow my="bottom center" at="top center">
        {
          showInput
          ? <WithForm alwaysSave action={({pageNumber})=> { if (pageNumber>=1 && pageNumber <= totalPages) { setPageParam(pageNumber)(); popup.close()}}}>{form=>
              <Field label="Go to page:" margin="none">
                <GoToPageInput>
                  <QuantityInput min={1} max={totalPages} path="pageNumber" placeholder="Ex: 10" type={isMobile() ? "number" : "text"}/>
                  <Button highlight type="submit" onClick={form.willSubmitForm}>Go</Button>
                </GoToPageInput>
              </Field>
            }</WithForm>
          : _.times((i)=> i+from,quantity).map( i =>
            <SinglePage index={i} setPageParam={setPageParam} />
          )
        }
      </Popup>
    }
  </PaginationButton>
}

const SinglePage = ({index,setPageParam, selected}) => <PaginationButton small highlight={selected} onClick={setPageParam(index)}>{index}</PaginationButton>