import React from 'react'
import {useToggle} from '@startlibs/core'

export const useIframeIsLoading = (test) => {

  const iframeIsLoading = useToggle()
  React.useEffect(() => {
    if (test) {
      iframeIsLoading.open()
    }
  },[test])
  return iframeIsLoading
}