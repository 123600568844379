import {using} from '@startlibs/utils'
import {jwtPostFetcher} from '../utils/authFetch'
import _ from 'lodash/fp'
import {getJwt} from '../hooks/useJwt'
import {DIGITAL, OFFLINE} from '../enums/PaymentMethod'
import {PRIVATE, MEDICARE, SELF} from '../enums/InsuranceType'
import {
  CASE_ARCHIVED, CASE_CLOSED,
  CASE_DRAFT, CASE_REJECTED, CASE_REVIEWED, PENDING_ASSIGNMENT,
  PENDING_REQUEST,
  REQUEST_REJECTED, UNDER_REVIEW, WAITING_ACCEPTANCE,
  WAITING_APPROVAL,
  WAITING_MEDICAL_RECORDS, WAITING_MORE_INFORMATION
} from '../enums/CaseState'
import {isStrEmpty} from '../utils'
import { getUploaderJwt } from '../hooks/useUploaderJwt'

export const isPaymentPending = payment => !payment.paid && !payment.revoked

export const isAlreadyAccepted = (values) => _.get('acceptanceInfo.externallyAccepted',values)||_.get('acceptanceInfo.accepted',values)
export const isAlreadyPaid = (payment) => payment.paid

export const isDigitalPaymentPending = (payment) => isPaymentPending(payment) && payment.paymentMethod === DIGITAL
export const isOfflinePaymentPending = (payment) => isPaymentPending(payment) && payment.paymentMethod === OFFLINE

const STATE_LIST = [CASE_ARCHIVED, REQUEST_REJECTED, PENDING_REQUEST, CASE_DRAFT, WAITING_MEDICAL_RECORDS, WAITING_APPROVAL, PENDING_ASSIGNMENT, CASE_REJECTED, WAITING_ACCEPTANCE, UNDER_REVIEW, WAITING_MORE_INFORMATION, CASE_REVIEWED, CASE_CLOSED]

export const isStateBefore = _.curry((state,of) => STATE_LIST.indexOf(state) < STATE_LIST.indexOf(of))
export const getAdminCaseRequestUrl = (caseRequest,path) => `/admin/request/${caseRequest.requestCode}/${path}`


export const fixMedicareInsideInsurances = _.update('patientInfo.insurances',(insurances) => insurances?.length > 0 ? insurances : [])

export const updateMedicalRecordsInfo = (postFetcher) => (medicalRecordsStore, setMedicalRecords, requestId) => ({
  add: (object,updater) => {

    return jwtPostFetcher(getJwt())('/api/medicalRecord?requestId='+requestId,_.unset('dicomStudy.instances',object),{method:"POST"})
      .then(() => {
        medicalRecordsStore.set(updater)
        setMedicalRecords(medicalRecordsStore.get())
      })
  },
  remove: (type, uid, updater) => {
    return jwtPostFetcher(getJwt())('/api/medicalRecord?requestId='+requestId,{deleteRequestType:type,mediaRecordID:uid},{method:"DELETE"})
      .then(() => {
        medicalRecordsStore.set(updater)
        setMedicalRecords(medicalRecordsStore.get())
      })
  }
})

export const persistMedicalRecord = (medicalRecordsUpdater,updateGroups) => {
  let lastUpdateGroups = Promise.resolve()
  let isUpdatingGroups = false
  let nextUpdateGroups = null

  const syncUpdateGroup = () => {
    if (isUpdatingGroups) {
      return lastUpdateGroups
    } else {
      const next = () => {
        isUpdatingGroups = true
        const currentGroups = nextUpdateGroups
        return updateGroups(nextUpdateGroups)
          .catch(e => {
            isUpdatingGroups = false
            return Promise.reject(e)
          })
          .then(() => {
            isUpdatingGroups = false
            if (currentGroups !== nextUpdateGroups) {
              syncUpdateGroup()
            }
          })

      }
      lastUpdateGroups = next()
      return lastUpdateGroups
    }
  }

  return ({dicomStudy, nonCompliant, nonDicomFile, groups}) => {
    if (groups) {
      nextUpdateGroups = groups
      return syncUpdateGroup()
    }
    if (dicomStudy) {
      return medicalRecordsUpdater.add({dicomStudy},_.update(
        'dicomStudies',
        (studies) => {
          const studyIndex = studies.findIndex(({studyUID}) => studyUID === dicomStudy.studyUID)
          if (studyIndex >= 0) {
            return _.set([studyIndex], _.omit(['instances', 'isNew'], dicomStudy), studies)
          } else {
            return studies.concat(_.omit(['instances', 'isNew'], dicomStudy))
          }
        }
      ))
    } else if (nonDicomFile) {
      return medicalRecordsUpdater.add({nonDicomFile},_.update(
        'nonDicomFiles',
        _.unionBy(_.property('uid'), [nonDicomFile])
      ))
    } else if (nonCompliant) {
      return medicalRecordsUpdater.add({nonCompliantDicom:nonCompliant},
        _.update(
          ['nonCompliantDicom'],
          _.unionBy(_.property('uid'), [nonCompliant])
        )
      )
    }
    return Promise.resolve()
  }
}


export const hasAnsweredAll = (list) => list?.length && !list?.find(({answer}) => isStrEmpty(answer))
export const hasAFieldsFilled = (caseInfo) => using(
  (caseInfo.additionalFields||[]).map(({title,content})=> ({label:title,value:content})).concat((caseInfo.questionsConsultant||[]).map(({question,answer})=> ({label:question,value:answer})))
)(fields =>
  fields?.length && fields.find(({label,value})=> !isStrEmpty(label) && !isStrEmpty(value))
)

export const startDownload = (launchURL) => {
  if (window.navigator && window.navigator.msSaveBlob) {
    window.location.href = launchURL
  } else {
    const link = document.createElement('a')
    link.href = launchURL
    link.style.visibility = "hidden"
    link.setAttribute("download", "")
    document.body.appendChild(link);
    link.click()
    link.remove()
  }
}

export const downloadFiles = (jwt, selectedRecords) => {
  // const downloaderURL = process.env.NODE_ENV === 'development' ? "https://downloader.vivacloud.io/records?recordId=" : window.DOWNLOADER_URL || "https://downloader.purview.net/v2/records?recordId=";
  const downloaderURL = process.env.NODE_ENV === 'development' ? "https://dev-downloader-us-east-2.purview.net/records?recordId=" : window.DOWNLOADER_URL || "https://downloader.purview.net/v2/records?recordId=";
  const downloadPromise =
  selectedRecords.length
    ? startDownload(downloaderURL+selectedRecords.map((item) => item).join(",")+'&jwt='+jwt)
    : Promise.resolve(false)
  return downloadPromise
 
}

export const viewFileSrc = (jwt, recordId ) => {
  const downloaderURL = process.env.NODE_ENV === 'development' ? "https://dev-downloader-us-east-2.purview.net/records?recordId=" : window.DOWNLOADER_URL || "https://downloader.purview.net/v2/records?recordId=";
  const viewURL = downloaderURL+recordId+'&jwt='+jwt+'&attachment=false'
  
  return viewURL
}


export const pdfIframeSrc = (record) => {

  // const downloaderUrl = process.env.NODE_ENV === 'development' ? "https://downloader.vivacloud.io/records?recordId=" : window.DOWNLOADER_URL || "https://downloader.purview.net/v2/records?recordId=";
  const downloaderUrl = process.env.NODE_ENV === 'development' ? "https://dev-downloader-us-east-2.purview.net/records?recordId=" : window.DOWNLOADER_URL || "https://downloader.purview.net/v2/records?recordId=";
  const pdfSrc = downloaderUrl+record+'&jwt='+getUploaderJwt()+'&attachment=false'

  return pdfSrc
}