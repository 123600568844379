import {Icon, Tooltip} from '@startlibs/components'
import { getColor, preventDefault } from '@startlibs/utils';
import React from 'react'
import styled, { css } from 'styled-components';

export const CaseCategoryTags = styled.div`
  display: flex;
  margin-top: .5rem;
  ${props => props.inSidebar && css`
    padding: 0 1.5rem;
    margin-top: 0.75rem;
  `}
`

export const CategoryTag = styled.div`
  border-radius: 1rem;
  height: 1.5rem;
  display: block;
  align-items: center;
  text-align: center;
  background: ${getColor('gray240')};
  padding: 0 .5rem;
  font-size: 12px;
  max-width: 100px;
  white-space: nowrap;
  min-width: 4rem;
  flex-shrink: 1;
  ~ & {
    margin-left: .5rem;
  }
  ~ div {
    margin-left: .5rem;
  }
  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    line-height: 1.5rem;
  }
  ${props => props.editTags && css`
    :hover {
      cursor: pointer;
      background: rgba(0,0,0,0.15);
    }
    min-width: 1.5rem;
    flex-shrink: 0;
  `}
  ${props => props.editWhenEmpty && css`
    :hover {
      background: rgba(0,0,0,0.15);
      cursor: pointer;
    }
    min-width: 100px;
    ${Icon} {
      vertical-align: -1px;
    }
  `}
  ${props => props.concat && css`
    min-width: 2rem;
  `}
  ${props => props.outline && css`
    background: transparent;
    border: 1px solid ${getColor('gray150')};
    color: ${getColor('gray120')};
    ${props => props.concat && css`
      line-height: 1.25rem;
    `}
    > div {
      line-height: 1.25rem;
    }
    ${props => (props.editTags || props.editWhenEmpty) && css`
      :hover {
        background: rgba(0,0,0,0.05);
      }
    `}
  `}
`

export const AdminCaseCardCategories = ({categories, editCategories, whenEmpty = null, quantityVisible = 2, inSidebar, outline, allowDivisions}) => {

  if (!categories.length) {
    return (inSidebar || allowDivisions) ? <>
    {allowDivisions && whenEmpty}
    <CaseCategoryTags inSidebar={inSidebar}>
      <CategoryTag outline={outline} editWhenEmpty onClick={preventDefault(editCategories)}><div>Edit divisions <Icon icon="edit"/></div></CategoryTag>
    </CaseCategoryTags>
    </>
    : whenEmpty
  }

  const moreCategories = categories.slice(quantityVisible).map(({name})=>name)

  return <CaseCategoryTags inSidebar={inSidebar}>
    {categories.slice(0,quantityVisible).map(({name}) => <CategoryTag outline={outline} ><Tooltip whenEllipsis={({target}) => target.offsetWidth < target.scrollWidth} content={name}>{name}</Tooltip></CategoryTag>)}
    {moreCategories.length > 0 && <Tooltip content={moreCategories.join(", ")}><CategoryTag concat outline={outline}>{moreCategories.length} more</CategoryTag></Tooltip>}
    <CategoryTag outline={outline} editTags onClick={preventDefault(editCategories)}><Icon icon="edit"/></CategoryTag>
  </CaseCategoryTags>
}
