import { Loading } from '@startlibs/components';
import {getColor, media} from '@startlibs/utils';
import React from 'react'
import { useNavigate } from 'react-router';
import styled, {css} from 'styled-components';
import { desaturate, lighten } from 'polished';

export const CaseDashboardCardStyle = styled.div`
  cursor: pointer;  
  border-radius: 8px;
  position: relative;
  flex-grow: 1;
  flex-basis: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80px;
  color: rgba(0,0,0,0.75);
  ${props => props.disabled ? `
    opacity: 0.8;
  `:`
    :hover:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0,0,0,0.03);
    }
    box-shadow: inset 0 -2px 0 0 rgb(0 0 0 / 15%);
  `}
  ${props => props.success ? css`
    background-color: ${props => desaturate(0.175, lighten(0.425, getColor("success")(props)))};
    `:`
    border: 1px solid rgba(0, 0, 0, 0.15);
  `}
  padding: 1rem;
  h3 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0.25rem;
    text-align: center;
    line-height: 120%;
  }
  .headerText {
    font-weight: 400;
    text-align: center;
    font-size: 12px;
    margin-bottom: 0.25rem;
  }
  .mainText {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    text-overflow: ellipsis;
    // white-space: nowrap;
    overflow: hidden;
  }
  .footerText {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }
  & ~ & {
    margin-left: 1rem;
  }
`

export const CaseDashboardCard = ({mainNumber, footerText, headerText, mainText, success = false, path, title="", disabled = false, isLoading = false}) => {
  const navigate = useNavigate()
  
  return (
    <CaseDashboardCardStyle disabled={disabled} success={success} 
      onClick={ () => {
        disabled ? null : navigate("../"+path)
      }} title={title}>
      {isLoading 
      ? <Loading absolute/>
      :
      <div>
        {(mainNumber || mainNumber >= 0) && <h3>{mainNumber}</h3>}
        {headerText && <div className="headerText">{headerText}</div>}
        {mainText && <div className="mainText">{mainText}</div>}
        {footerText && <div className="footerText">{footerText}</div>}
      </div>}
    </CaseDashboardCardStyle>
  )
}
