import {Tooltip, Icon} from '@startlibs/components'
import {darken} from 'polished'
import {getColor} from '@startlibs/utils'
import React from 'react'
import styled from 'styled-components'


const Box = styled.span`
  background-color: rgb(230,230,230);
  padding: 2px 2rem 4px 0.75rem;
  border-radius: 12px;
  font-size: 13px;
  position: relative;
  display: inline-block;
  cursor: default;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  vertical-align: -7px;
`
const RemoveIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 50%;
  line-height: 20px;
  transform: translateY(-50%);
  right: 2px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  color: ${getColor('gray120')};
  :hover {
    background-color: ${getColor('gray210')};
    color: ${props => darken(0.05, getColor('alert')(props))};
  }
`

export const AutocompleteBox = styled(({label, remove, className}) =>
  <div className={className}><Tooltip whenEllipsis content={label}>
    <Box className="fix-safari-alignment">
      {label}
      <RemoveIcon icon="x" onClick={remove}/>
    </Box>
  </Tooltip></div>)`
    max-width: 90%;
    display: inline-block;
    margin: 0 0.7rem 0 -0.25rem;
    > * {
      width: 100%;
    }
  `