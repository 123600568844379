import {TextInput} from '@startlibs/form'
import {Button} from '@startlibs/components'
import React from 'react'
import _ from 'lodash/fp'
import {ConfirmDialog, useConfirmDialogWithProps} from '../../../hooks/useConfirmDialog'
import {buildValidation} from '../../../utils/validation'
import {getJwt} from '../../../hooks/useJwt'
import {jwtPostFetcher} from '../../../utils/authFetch';

const getId = ({requestId, id}) => requestId || id

export const useEditCaseNumber = (
  onSuccess,
    {
      saveAction = (caseRequest, caseNumber) => jwtPostFetcher(getJwt())('/api/admin/legalInfo?requestId=' + getId(caseRequest), caseNumber, {method: "PUT"}),
      notify = "Case number updated successfully.",
    } = {}
) => {


  const action = (caseNumber) => {
      return saveAction(toggleWithCaseRequest.get(), caseNumber)
  }

  const toggleWithCaseRequest = useConfirmDialogWithProps((caseRequest) => <ConfirmDialog
      action={action}
      confirm={<Button highlight>{caseRequest?.legalInfo?.caseNumber?.length > 0  ? "Update" : "Save"}</Button>}
      values={{caseNumber: caseRequest.legalInfo.caseNumber}}
      onSuccess={onSuccess(caseRequest)}
      notify={notify}
      title= {caseRequest?.legalInfo?.caseNumber?.length > 0  ? "Edit case number" : "Add case number"}
    >{form => <>
      <TextInput
          label={caseRequest?.legalInfo?.caseNumber?.length > 0  ? "Edit the case number associated with this case" : "Add a case number to this case"}
          path="caseNumber"
          values={caseRequest.caseNumber}
          placeholder="Ex: EV-0001A/2021"
        />
      </>
    }</ConfirmDialog>
  )

  return toggleWithCaseRequest
}