
export function dicomDateToDate(strDate) {
  if (!strDate) { return new Date(NaN) }
  const tryIso = new Date(strDate)
  if (!isNaN(tryIso.getTime())) { return tryIso}
  const ints = strDate.replace(/(\d{4})(\d\d)(\d\d)/, "$1 $2 $3").split(" ");
  return new Date(
    parseInt(ints[0], 10),
    parseInt(ints[1], 10)-1,
    parseInt(ints[2], 10)
  );
}

const splitAt = (str,pos) => [str.slice(0,pos),str.slice(pos)]

export const formatCurrency = (price) => {
  const [left,right] = splitAt(("00"+Math.round(Math.abs(price*100))),-2)
  return left.replace(/^0+(\d)/,'$1').replace(/\d(?=(\d{3})+$)/g, '$&,') + '.' + right
}


export const isStrEmpty = (v) => !v || !(v+"").replace(/\s/g,"")



function futch(url, opts={}, onProgress) {
  return new Promise( (res, rej)=>{
    var xhr = new XMLHttpRequest();
    xhr.open(opts.method || 'get', url);
    for (var k in opts.headers||{})
      xhr.setRequestHeader(k, opts.headers[k]);
    xhr.onload = e => res(e.target.responseText);
    xhr.onerror = rej;
    if (xhr.upload && onProgress)
      xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
    xhr.send(opts.body);
  });
}