import {SimpleCheckbox} from '@startlibs/form'
import React from 'react'
import styled, { css } from 'styled-components';

const TermsCheckbox = styled(SimpleCheckbox)`
  ${props => props.checked && css`
    a.link {
      color: white;
    }
  `}
`

export const requiredTerms = (caseRequest) => !caseRequest?.acceptanceInfo?.acceptedTerms && ((v) => !v && "You must agree to the terms")

export const AcceptTermsCheckbox = ({caseRequest = {}}) => !caseRequest.acceptanceInfo?.acceptedTerms &&
  <TermsCheckbox
    path="acceptedTerms"
    label={<span onClick={e => e.stopPropagation()}>I confirm to have read and accept the <a className="link" target="_blank" href="https://www.purview.net/expert-opinion-portal-terms">License Terms</a> and the <a className="link" target="_blank" href="https://www.purview.net/expert-view-remote-second-opinion-services-agreement">Remote Second Opinion Services Agreement</a>.</span>}
    framedBoxes
    ultraHighlightSelected
    css="width: 100%; margin-bottom:2rem;"
  />
