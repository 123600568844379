import React from 'react'
import {wrapLazy} from '@startlibs/utils'
import {jwtGetFetcher} from "../../utils/authFetch";

const MINUTES = 60 * 1000
let refreshInterval = -1

export const stopRefresh = () => window.clearInterval(refreshInterval)

export const lazyAuth = wrapLazy(async initialJwt => {
  let jwt = null
  const setJwt = newJwt => {
    jwt = newJwt
  }
  const showError = e => {
    if (e[1].status === 401) {
      stopRefresh()
      alert('Authentication has expired, please open this page again')
    }
  }

  try {
    const response = await jwtGetFetcher(initialJwt)('/auth')
    setJwt(response.data.jwt)
    refreshInterval = setInterval(async () => {
      try {
        const refreshResponse = await jwtGetFetcher(jwt)('/auth/refresh')
        setJwt(refreshResponse.data.jwt)
      } catch (e) {
        showError(e)
      }
    }, 2 * MINUTES)
    return () => jwt
  } catch (e) {
    showError(e)
    return () => null
  }
})
