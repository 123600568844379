import React, {useEffect, useState} from 'react'
import _ from 'lodash/fp'
import {searchToParams} from '../utils/utils'
import {useConstant, useToggle, willUseSharedCallback} from '@startlibs/core'
import {addQueryString, callIfFunction} from '@startlibs/utils'

export const [useUpdateParams,callUpdateParams] = willUseSharedCallback()

export const useUpdateUrl = () => useConstant(
  _.throttle(200, (obj) => {
    const query = ('?'+addQueryString('', _.pickBy(_.identity,obj))).replace(/\?\?/, '?')
    if (query !== window.location.search) {
      if (!window.top.history.search && query === "?") {
        window.top.history.replaceState('', '', query)
      } else {
        window.top.history.pushState('', '', query)
      }
    }
  })
)

export const useQueryParams = (location, resetPageKeys) => {
  const loading = useToggle()
  const [params, rawSetParams] = useState(() => searchToParams(location.search))

  const updateParams = (params) => {
    rawSetParams(params)
    callUpdateParams(params)
  }

  useEffect(() => {
    updateParams(searchToParams(location.search))
  },[location])


  const debounceSetParams = useConstant(_.debounce(500, rawSetParams))

  const setParams = (updater, debounce) => {
    const verifyPage = (updater) => (state) => {
      const updatedState = callIfFunction(updater,state)
      if (_.some((key) => state[key] !== updatedState[key],resetPageKeys)
      ) {
        return _.set('page', 1, updatedState)
      } else if (state.maxResults !== updatedState.maxResults) {
        const firstRow = ((state.maxResults||10) * (updatedState.page-1)+1)
        return _.set('page',Math.max(Math.floor(firstRow/(updatedState.maxResults||10))+1,1),updatedState)
      } else {
        return updatedState
      }
    }
    if (debounce) {
      debounceSetParams(verifyPage(updater))
    } else {
      setTimeout(() => rawSetParams(verifyPage(updater)),0)
    }
    setTimeout(loading.open,0)
  }

  return [params, setParams, updateParams]
}