import {Radiobox} from '@startlibs/form';
import React from 'react';
import styled from 'styled-components';
import {ContactCard, SimpleContactCard} from './ContactCard';

export const ContactSelectInput = styled(({highlightSelected, caseRequest, relationKind, viewOnly, children,...props}) =>
  <Radiobox {...props}>{({disabled, checked, hasErrors, focused, tabIndex, framedBoxes}) =>
    <ContactCard
      checked={checked}
      focused={focused}
      selectable={!viewOnly}
      caseRequest={caseRequest}
      relationKind={relationKind}
      isRadioBox
    />
}</Radiobox>)`
    ${SimpleContactCard} {
      margin-bottom: 0.5rem;
    }
`