import React from 'react'
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import {Button} from '@startlibs/components'

export const useAlreadyRevoked = (doRevoke) => {

  return useConfirmDialog(<ConfirmDialog
    title="Paying for this case is no longer possible"
    closeButton={false}
    onSuccess={doRevoke}
    confirm={<Button>Close</Button>}
  >
    <p>The instution has revoked this payment request and is not possible to pay for this case right now.</p>
    <p>Please wait for instructions from the institution or contact them if needed.</p>
  </ConfirmDialog>)
}
