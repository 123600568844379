import React from 'react'
import {lazyProviderInfo} from './WithProvider'
import {useTitle} from '@startlibs/core'
import {WithLazyResource} from '../hooks/useSuspense'

export const ProviderTitle = () => {
  return <WithLazyResource fallback={<SetTitle/>} value={lazyProviderInfo}>{providerInfo => <SetTitle providerInfo={providerInfo}/>}</WithLazyResource>
}

const SetTitle = ({providerInfo}) => {
  useTitle(providerInfo ? providerInfo.name + " - Expert View" : "Expert View")
  return null
}