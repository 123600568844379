import React, {useEffect, useRef} from 'react'

export const useAutoScrollAndFocus = (check) => {


  const inputRef = useRef()

  useEffect(() => {
    if (check) {
      const beginTime = Date.now()
      setTimeout(() => inputRef.current.scrollIntoView({block: 'center', behavior: 'smooth'}), 50)
      const intersectionObserver = new IntersectionObserver((entries) => {
        let [entry] = entries;
        if (entry.isIntersecting) {
          if (beginTime + 100 < Date.now() ) {
            setTimeout(() => inputRef.current.focus(), 300)
          } else {
            inputRef.current.focus()
          }
          intersectionObserver.disconnect()
        }
      });
      intersectionObserver.observe(inputRef.current);
    }
  }, [])

  return inputRef

}