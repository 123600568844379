import React, {useEffect, useMemo, useRef} from 'react'
import _ from 'lodash/fp'
import {Fill} from '@startlibs/core'
import {Button, Popup, DefaultArrow} from '@startlibs/components'
import {TextInput, Errors, WithForm} from '@startlibs/form'
import styled from 'styled-components/macro'
import {Editor, Node, Path, Transforms} from "slate";
import {first} from "lodash";
import {PathUtils, unwrapLink, wrapLink} from "../slateUtils";
import {linkRegex, useEventListener} from "@startlibs/utils";
import {useDecorationToggle} from "../WithDecoration";
import {ReactEditor} from "slate-react";

const Arrow = styled(DefaultArrow)`
  top: -1rem;
  box-shadow: -1px -1px 2px -1px rgb(0 0 0 / 25%);
`

const preValidation = (formValues) => {
  const linkTextError = 'Text can not be empty'
  const linkUrlError = 'Please check the URL'
  let errors
  if (!formValues.text) {
    errors = {'text': [linkTextError]}
  }
  if (!formValues.url || formValues.url === ' ' || !linkRegex.test(formValues.url.trim())) {
    errors = {...errors, 'url': [linkUrlError]}
  }
  return errors
}

const addHttp = (s) => {
  s = s.replace(/^\s/, '')
  if (s.indexOf('http://') === 0 || s.indexOf('https://') === 0) {
    return s
  } else {
    return 'http://' + s
  }
}

const transform = _.update('url',addHttp)

export const LinkPopup = ({editor, isActive, values, range, closePopup}) => {
  const popupRef = useRef()
  const ignoredFirst = useRef()
  const decoration = useDecorationToggle()

  const valuesWithSelection = useMemo(() => {
    if (values) {
      return values
    }
    if (editor.selection) {
      const range = PathUtils.getFirstBlockRange(editor)
      Transforms.select(editor,range)
      const text = Editor.string(editor,range)
      return {text}
    }
    return {}
  },[])

  useEffect(() => {

    const el = popupRef.current
    const domRange = ReactEditor.toDOMRange(editor,editor.selection)
    const rect = domRange.getBoundingClientRect()
    el.style.opacity = '1'
    el.style.width = `340px`
    el.style.top = `${rect.bottom + 6 + window.pageYOffset}px`
    el.style.left = `${rect.left + window.pageXOffset - (el.offsetWidth / 2) + (rect.width / 2)}px`
    el.position = 'absolute'

    const [textInput,urlInput] = el.querySelectorAll("input[type=text]")
    if (!valuesWithSelection.url || valuesWithSelection.text) {
      urlInput.focus()
    } else {
      textInput.focus()
    }
  }, [])

  useEventListener('click',(e) => {
    if (e.target !== popupRef.current && !popupRef.current.contains(e.target) && ignoredFirst.current) {
      closePopup(false)
    }
    ignoredFirst.current = true
  })

  useEffect(() => {
    decoration.openWith([{
      ...(range || editor.selection),
      linkHighlight: true
    }])
    return () => decoration.close()
  },[])

  const confirm = ({text,url}) => {
    if (range) {
      Transforms.select(editor,range)
    }
    wrapLink(editor,url,text)
    closePopup()
  }
  const remove = ({text,url}) => {
    unwrapLink(editor)
    closePopup()
  }

  return <Fill name='Popup'>
    <Popup skipPortal ref={popupRef}>
      <Arrow/>
      <WithForm values={valuesWithSelection} action={confirm} preValidation={preValidation} transform={transform}>
        <TextInput path="text" label='Text to display' mandatory/>
        <TextInput path="url" label="Url" mandatory/>
        <Errors/>
        <Button onClick={isActive ? remove : closePopup}>Remove link</Button>
        <Button onClick={closePopup}>Cancel</Button>
        <Button type="submit" highlight>Apply</Button>
      </WithForm>
    </Popup>
  </Fill>
}