import React from 'react'
import {wrapLazy} from '@startlibs/utils'
import {jwtGetFetcher} from '../../utils/authFetch'


export const hasAcceptedAllTerms = (terms,caseRequest) => terms.length && !terms.find(({id}) => !caseRequest.acceptanceInfo?.agreements?.find(({serviceTermId}) => serviceTermId === id))

export const lazyTerms = wrapLazy((jwt) =>
  jwtGetFetcher(jwt)('/api/serviceTerms')
)

export const isReadOnlyShare = (caseRequest) => caseRequest?.requestCode?.indexOf("RO-")===0