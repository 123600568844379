import { Editor, Transforms, Path } from 'slate';
import { splitedTable } from '../selection';
import { createCell } from '../creator';

export function insertRight(table, editor) {
  const { selection } = editor;
  if (!selection || !table) return;

  const xIndex = table[1].length + 1;

  const { gridTable, getCol } = splitedTable(editor, table);

  const [startCell] = Editor.nodes(editor, {
    match: n => n.type === 'td',
  });

  const [insertPositionCol] = getCol(
    (c) => c?.cell?.key === startCell[0]?.key && c.isReal
  );

  const x = Number(insertPositionCol?.path[xIndex]) + (Number(insertPositionCol?.cell?.colspan) || Number(1)) - Number(1);
  const insertCols = new Map();
  let checkInsertable = true;

  gridTable?.forEach((row) => {
    const col = row[x];

    const [originCol] = getCol(
      (n) => n?.cell?.key === col?.cell?.key && n.isReal
    );

    const { cell, path } = originCol;

    if (
      !row[x + 1] ||
      (col.isReal && (!col.cell.colspan || col.cell.colspan === 1))
    ) {
      insertCols.set(cell.key || Math.random().toString(36).substring(2, 10), col);
    } else {
      if (Number(path[xIndex]) + (Number(cell.colspan) || Number(1)) - Number(1) === Number(x)) {
        insertCols.set(cell.key || Math.random().toString(36).substring(2, 10), originCol);
      } else {
        checkInsertable = false;
        return;
      }
    }
  });

  if (!checkInsertable) {
    return;
  }

  insertCols.forEach((col) => {
    const newCell = createCell({
      rowspan: col?.cell?.rowspan || 1,
    });

    Transforms.insertNodes(editor, newCell, {
      at: Path.next(col?.originPath),
      // at: col?.originPath,
    });
  });
}
