import {Button} from '@startlibs/components'
import React from 'react'
import {ConfirmDialog, useConfirmDialogWithProps} from '../../hooks/useConfirmDialog'
import {getJwt} from '../../hooks/useJwt'
import {jwtPostFetcher} from '../../utils/authFetch';
import {callIfFunction} from '@startlibs/utils'
import {CaseRequestCard} from '../CaseRequestCard'


const getId = ({requestId, id}) => requestId || id

export const useToggleAsPriority = (onSuccess, action = (caseRequest) => jwtPostFetcher(getJwt())('/api/admin/markAsPriority?requestId=' + getId(callIfFunction(caseRequest)), {priority: !caseRequest.markedAsPriorityEpochMilli}, {method: "PUT"})) =>
  useConfirmDialogWithProps((caseRequest) =>
    <ConfirmDialog
      action={() => action(caseRequest)}
      values={caseRequest}
      title={caseRequest.markedAsPriorityEpochMilli ? "Remove as priority" : "Mark case as priority"}
      notify={caseRequest.markedAsPriorityEpochMilli ? "Case priority removed." : "Case marked as priority."}
      onSuccess={onSuccess}
      confirm={<Button highlight>{caseRequest.markedAsPriorityEpochMilli ? "Remove" : "Mark"}</Button>}
    >
      <p>
        {caseRequest.markedAsPriorityEpochMilli ?
          "The case will no longer be pinned at the top of the case list."
          : "Priority cases are pinned at the top of the case list." }
      </p>
      <CaseRequestCard viewOnly caseRequest={caseRequest}/>
      {caseRequest.markedAsPriorityEpochMilli &&
        <p>Do you wish to proceed?</p>
      }
    </ConfirmDialog>
  )
