import {Button, Icon, Loading} from '@startlibs/components';
import {useToggle} from '@startlibs/core'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import _ from 'lodash/fp'
import {NotProvided} from '../../ExpertAssignmentStep';
import {ReportField} from './ReportFrontCover';
import {ReportTextButton} from './EditLayoutButton';
import {SectionContent} from './ReportAdditionalFields';
import {safeNewLineToDoubleBr} from '../../../../utils/utils'

export const ReportPrimaryConcern = ({caseRequest, form}) => {

  const cliniaclSummaryLoading = useToggle()

  const toggleSection = () => {
    form.setValue('reportLayout.clinicalSummary',v => !v)
    cliniaclSummaryLoading.wait(form.submitForm())
  }
  const isHidden = !caseRequest.reportData.reportLayout.clinicalSummary

  return <><ReportField css={isHidden && "opacity: 0.5"}>
      <div className="field-heading">
        <h4><FormattedMessage defaultMessage='Reason for seeking medical opinion:' description='Preview clinical summary label'/></h4>
        <div className="field-actions">
          {cliniaclSummaryLoading.isOpen && <Loading size={16} borderWidth={3} />}
          <ReportTextButton small onClick={toggleSection}>
            {isHidden ?
              <><Icon icon="view" /> Show in report</>
            : <><Icon icon="hide" /> Hide from report</>
            }
          </ReportTextButton>
          <Button.Link small to={`/admin/request/${caseRequest.requestCode}/patient`} state={{scrollToPrimaryConcern:true}}>Edit</Button.Link>
        </div>
      </div>
    </ReportField>
    <SectionContent
      setItemOrder={_.identity}
      disabled
    >
      <ReportField
        css={isHidden && "opacity:0.5;"}
        subItem
      >
        <div className="field-content fs-exclude">
          {caseRequest.caseInfo.clinicalSummary ?
            <div className="fs-exclude" dangerouslySetInnerHTML={{__html: safeNewLineToDoubleBr(caseRequest.caseInfo.clinicalSummary)}}/>
            : <NotProvided />
          }
        </div>
      </ReportField>
    </SectionContent>
  </>


}

