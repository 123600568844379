import { Button, Loading } from '@startlibs/components'
import { parseDate } from '@startlibs/utils'
import _ from 'lodash/fp'
import { useRefState, useToggle } from '@startlibs/core'
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { enums, Uploader } from 'uploader-frontend'
// import {Uploader} from 'uploader-frontend-legacy'
import { Uploader as UploaderLegacy } from 'uploader-frontend-legacy'
import { CASE_CLOSED, CASE_REVIEWED } from '../enums/CaseState'
import { Card, SectionHeading } from '../components/PageLayout'
import { EMPTY_MEDICAL_RECORDS, EMPTY_MEDICAL_RECORDS_LOCATION_FORM } from './MedicalRecords'
import { NotProvided } from '../admin/steps/ExpertAssignmentStep'
import { authPostFetcher, jwtGetFetcher, jwtPostFetcher } from '../utils/authFetch'
import { getJwt } from '../hooks/useJwt'
import { downloadFiles, persistMedicalRecord, updateMedicalRecordsInfo } from './utils'
import { calculateAge, isRichText, safeMultipleNewLineToBr } from '../utils/utils'
import { fullNameToName } from '../enums/ContactRelationKind'
import { getUploaderJwt, setUploaderJwt } from '../hooks/useUploaderJwt'
import { useNotification } from '../components/Notifications'
import { PageLoadingSuspense } from '../components/PageLoading'
import { callIfFunction } from '@startlibs/utils'
import { LoadingPage } from 'sharecomponents/src/components/PageLoading'
import { getStorageHost, setStorageHost } from '../hooks/useStorageHost'
import {using} from '@startlibs/utils'
import { EXPERT } from '../enums/UserRoles'
import {IfLocale} from '../hocs/IfLocale'
import {FormattedMessage} from 'react-intl'
import { PATIENT } from '../enums/ContactRelationType'
import { RichTextDisplay } from '../components/RichTextDisplay'


export const FieldsList = styled.div`
  margin: -0.5rem;
  ${props => props.twoColumns && `
    display: flex;
    flex-wrap: wrap;
    > * {
      flex-basis: 46%;
      flex-grow: 1;
      &.full-width {
        flex-basis: 100%;
      }
    }
    ${ReadOnlyField} {
      margin: 0.5rem 0.5rem 1.5rem 0.5rem;
    }
  `}
  .contact-relationship {
    text-transform: capitalize;
    :before {
      content: ' ';
    }
  }
  .address-concat {
    span ~ span:before {
      content: ', '
    }
  }
  .physician-contacts {
    span ~ span:before {
      content: ' | ';
    }
  }
`
const ReadOnlyQuestion = styled.div`
  font-size: 14px;
  font-weight: 600;
  label {
    display: block;
    margin-bottom: 0.25rem;
    font-size: 12px;
    color: rgba(0,0,0,0.4);
    font-weight: 400;
  }
  & ~ & {
    margin-top: 1rem;
  }
  .answer {
    font-weight: 400;
    margin-top: .25rem;
  }
`

export const ReadOnlyField = styled.div`
  font-size: 14px;
  margin-bottom: 1.5rem;
  label {
    display: block;
    font-weight: 600;
    margin-bottom: 0.25rem;
  }
`
export const FieldDetail = styled.span`
  color: rgba(0,0,0,0.4);
  font-size: 13px;
`

export const EmptyMedicalRecordsList = styled.div`
  text-align: center;
  color: rgba(0,0,0,0.4);
  padding: 1rem 0;
  font-size: 14px;
 `

export const SpacedDiv = styled.div`
  display: block;
  height: 460px;
`


export const QuestionsAndAdditionalOverview = ({ caseRequest,  role, withAnwsers = false}) => {

  const questions = <>
    <SectionHeading>
      <h3>Questions</h3>
    </SectionHeading>
    <Card>
      {caseRequest.caseInfo.questionsConsultant ? <>
          {caseRequest.caseInfo.questionsConsultant.length === 0 && <EmptyMedicalRecordsList>No questions provided.</EmptyMedicalRecordsList>}
          {(caseRequest.caseInfo.questionsConsultant || []).map(({ question, answer, id }, i) =>
            <ReadOnlyQuestion key={id}>
              <label>Question {i + 1}</label>
              {/* <div className='fs-exclude'>{question ? (question.charAt(0).toUpperCase() + question.substring(1)) : <NotProvided />}</div> */}
              <div className='fs-exclude'>{question ? <RichTextDisplay css="font-size: 14px; font-weight: 600;" content={(question.charAt(0).toUpperCase() + question.substring(1))} /> : <NotProvided />}</div>
              {withAnwsers ? <>
                {(caseRequest.state === CASE_REVIEWED || caseRequest.state === CASE_CLOSED) && !isRichText(answer) &&
                  <div className="answer fs-exclude">{
                    answer 
                    ? (answer.charAt(0).toUpperCase() + answer.substring(1)) 
                    : <NotProvided />}
                  </div>
                }
                {(caseRequest.state === CASE_REVIEWED || caseRequest.state === CASE_CLOSED) && isRichText(answer) &&
                  <div className="answer fs-exclude">{
                    answer 
                    ? <RichTextDisplay content={answer} className="answer overview fs-exclude" /> 
                    : <NotProvided />}
                  </div>
                }
              </> : <></>}
            </ReadOnlyQuestion>
          )} </>
        : <EmptyMedicalRecordsList>No questions provided.</EmptyMedicalRecordsList>
      }
    </Card>
  </>

  const additionalInformation = <>
    <SectionHeading>
      <h3>Additional Information</h3>
    </SectionHeading>
    <Card>
      {caseRequest.caseInfo.additionalFields ? <>
          {caseRequest.caseInfo.additionalFields.length === 0 && <EmptyMedicalRecordsList>No additional information provided.</EmptyMedicalRecordsList>}
          {(caseRequest.caseInfo.additionalFields || []).map(({ title, content, id }, i) =>
            <ReadOnlyQuestion key={id}>
              <label>Additional field {i + 1}</label>
              {/* <div className='fs-exclude'>{title ? (title.charAt(0).toUpperCase() + title.substring(1)) : <NotProvided />}</div> */}
              <div className='fs-exclude'>{title ? <RichTextDisplay css="font-size: 14px; font-weight: 600;" content={(title.charAt(0).toUpperCase() + title.substring(1))} /> : <NotProvided />}</div>
              {withAnwsers ? <>
                {(caseRequest.state === CASE_REVIEWED || caseRequest.state === CASE_CLOSED) && !isRichText(content) &&
                  <div className="answer fs-exclude">{
                    content 
                    ? (content.charAt(0).toUpperCase() + content.substring(1)) 
                    : <NotProvided />}
                  </div>
                }
                {(caseRequest.state === CASE_REVIEWED || caseRequest.state === CASE_CLOSED) && isRichText(content) &&
                  <div className="answer fs-exclude">{
                    content 
                    ? <RichTextDisplay content={content} className="answer overview fs-exclude" /> 
                    : <NotProvided />}
                  </div>
                }
                </> : <></>
              }
            </ReadOnlyQuestion>
          )} </>
        : <EmptyMedicalRecordsList>No additional information provided.</EmptyMedicalRecordsList>
      }
    </Card>
  </>


  return <>
    {questions} 
    <IfLocale not contains="LEGAL">
      {additionalInformation}
    </IfLocale>
  </>
}