import React from 'react'

export const DicomStudy = 'DicomStudy'
export const NonCompliantDicom = 'NonCompliantDicom'
export const Mirax = 'Mirax'
export const AperioSVS = 'AperioSVS'
export const HistechMRXS3D = 'HistechMRXS3D'
export const PhilipsiSyntax = 'PhilipsiSyntax'
export const HamamatsuNDPI = 'HamamatsuNDPI'
export const TIFF = 'TIFF'
export const TIF = 'TIF'
export const PathologyDicom = 'PathologyDicom'
export const CCDA = 'CCDA'
export const PDF = 'PDF'
export const Other = 'Other'
export const VentanaBIF = 'VentanaBIF'
export const Leica = 'Leica'
export const HamamatsuVMS = 'HamamatsuVMS'
export const HamamatsuVMU = 'HamamatsuVMU'

export const FormatToLabel ={
  PhilipsiSyntax: 'Philips iSyntax',
  HamamatsuNDPI: 'Hamamatsu NDPI',
  HamamatsuVMU: 'Hamamatsu VMU',
  HamamatsuVMS: 'Hamamatsu VMS',
  VentanaBIF: 'Ventana BIF',
  AperioSVS: 'Aperio SVS'
}