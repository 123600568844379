import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {UploaderHeaderComponent} from "../UploaderHeader";
import {DISK} from "../../enums/UploaderStepsManagement";
import {Button, Icon} from "@startlibs/components";
import {customTheme, getColor} from "@startlibs/utils";
import {lighten} from "polished";
import {css} from "styled-components";

styled

export const UploaderHeading = styled.div`
  background: white;
  position: sticky;
  z-index: 99;
  top: 5.5rem;
  background: white;
  margin: 0 -2px;
  padding: 0 2px 6px 2px;
  ${props => props.uploaderDisabled && css`
    padding-bottom: 0;
    ${UploaderHeaderComponent} {
      margin-bottom: 0;
    }
  `}
  :after {    
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 5px;
    background: white;
    border-bottom: 1px solid white;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0);
    transition: 0.25s ease;
  }
  ${props => props.isStickied && css`
    :after {
      border-bottom: 1px solid rgba(0,0,0,0.1);
      box-shadow: 0 2px 4px -1px rgba(0,0,0,0.1);
    }
  `}
  a.activity-log {
    position: absolute;
    bottom: 0.5rem;
    right: .5rem;
    font-size: 11px;
  }
  a.import-studies {
    position: absolute;
    bottom: .5rem;
    left: .5rem;
    font-size: 11px;
  }
  /* IE11 Adjustments */
  @media all and (-ms-high-contrast:none) {

    & { position: static; } /* IE10 */
     *::-ms-backdrop, & { position: static; } /* IE11 */

   }
  ${customTheme("UploaderHeading")};
`

export const FileInputBoxStyle = styled.div`
  height: 9rem;
  border-radius: 5px;
  border: 1px dashed ${getColor('gray180')};
  background: ${getColor('gray240')};
  position: relative;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: rgba(0,0,0,0.6);
  user-select: none;
  :hover {
  //  background: rgb(230,230,230);
  }
  ${props => (props.draggingOver || props.uploading) && css`
    background: ${props => lighten(0.54, props.theme.colors.main)};
    border-color: ${getColor('main')};
  `}
  ${props =>  props.mode === DISK && css`
    display: grid;
    justify-content: stretch;
    background: transparent;
    height: auto;
    border: 0px solid white;
    padding: 0px;
  `}
  ${props => props.uploading && css`
    border-style: solid;
  `}
  input[type="file"] {
    z-index: 100;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &::-webkit-file-upload-button {
      visibility: hidden;
    }
    &::file-upload-button {
      visibility: hidden;
    }
  }
  ${Button} {
    :after {
      content: none !important;
    }
  }
`

export const UploadStatusIcon = styled(Icon)`
  font-size: 28px;
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  margin: 2rem auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  ${props => props.failure && css`
    background-color: ${getColor('alert')};
  `}
  ${props => props.check && css`
    background-color: ${getColor('success')};
  `}
`

export const AddButton = styled(Button).attrs({icon:'plus-circle',color:'gray240'})`
  width: 100%;
  color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 1px ${getColor('gray210')};
`
