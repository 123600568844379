import React from 'react'
import styled, {css} from 'styled-components'
import {PHYSICIAN_OR_PROVIDER, SCAN_FACILITIES} from '../../enums/MedicalRecordLocationType'
import {Button, Icon} from '@startlibs/components'
import {getColor} from '@startlibs/utils'
import {createDateReformatter, dateReformatter} from '../../utils/utils'

export const ReleaseLocationCard = styled(({location,editContacts,className}) => {
  return <div className={className}>
    <ReleaseLocationContent location={location} editContacts={editContacts}/>
  </div>
})`
  padding: 1rem 1.25rem;
  background: rgba(41, 122, 163, 0.07);
  box-shadow: 0 0 0 1px rgba(41, 122, 163, 0.3);
  border-radius: 5px;
  margin-bottom: 1.5rem;
  position: relative;
`

const TYPE_LABEL = {
  [PHYSICIAN_OR_PROVIDER]:"Speciality",
  [SCAN_FACILITIES]:"Type of scan"
}

export const ReleaseLocationContent = ({location,editContacts}) => {
  return <div>
    <LocationName className='fs-exclude'>{location.name}</LocationName>
    {location.contactName &&
      <LocationDetail className='fs-exclude'><b>Contact name:</b> {location.contactName}
    </LocationDetail>}
    <LocationDetail className='fs-exclude'>
      {location.phoneNumber && <span><b>Phone/Fax:</b> {location.phoneNumber}</span>}
      {location.email && <span><b>Email:</b> {location.email}</span>}
      {editContacts && <a className="link" onClick={editContacts}>{location.phoneNumber || location.email ? "Edit contacts" : "Add contact information"}</a>}
    </LocationDetail>
    <LocationDetail>
      {TYPE_LABEL[location.locationType] && <span><b>{TYPE_LABEL[location.locationType]}:</b> {location.type}</span>}
      {location.city && <span><b>City:</b> {location.city}</span>}
    </LocationDetail>
  </div>
}

const LocationName = styled.div `
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0.25rem;
`

const LocationDetail = styled.div `
  margin-top: 2px;
  span + span, span + .link {
    margin-left: 1rem;
  }
`

export const ReleaseFormDetails = styled.div `
  color: rgba(0,0,0,0.5);
  border-top: 1px solid rgba(0,0,0,0.1);
  margin-top: 0.75rem;
  padding-top: 0.75rem;
  position:relative;
  min-height: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${Button} {
    margin-left: 1rem;
    flex-shrink: 0;
  }
  ${props => props.warning && css`
    color: ${getColor('alert')};
    ${Icon} {
      font-size: 18px;
      vertical-align: -3px;
      margin-right: 0.5rem;
    }
  `}
`

export const ReleaseDetails = ({release,suspendedButton,isExpired, isPatient}) => {
  const dateFormatter = createDateReformatter('MM-dd-yyyy',"MM/dd/yyyy")
  return  release.medicalRecordsReleaseLicense.signature
    ? <ReleaseFormDetails>
      <div>
        <div><a target="_blank" href={"/provider/medicalRelease/"+release.timedCode} className="DocumentLink"><Icon icon="report"/><span>Medical Records Release</span></a> <i>(Signed on {release.medicalRecordsReleaseLicense.date.split(" ")[0]})</i></div>
        <div><b>Authorized records between:</b> {dateFormatter(release.medicalRecordLocationItem.dateFrom)} and {dateFormatter(release.medicalRecordLocationItem.dateTo)} - <b>
          {isExpired ? "Expired in" : "Expires in"}:</b> {dateFormatter(release.medicalRecordLocationItem.dateExpiration)}
        </div>
      </div>
    </ReleaseFormDetails>
    : <ReleaseFormDetails css="margin-bottom:-0.25rem" warning>
      <div>
        <Icon icon="warning"/>
        Authorization not {isPatient && "yet"} signed
      </div>
      {suspendedButton}
    </ReleaseFormDetails>
}
