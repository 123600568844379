import {Button, Icon, Loading} from '@startlibs/components';
import {useToggle} from '@startlibs/core'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import _ from 'lodash/fp'
import {NotProvided} from '../../ExpertAssignmentStep';
import {ReportField} from './ReportFrontCover';
import {ReportTextButton} from './EditLayoutButton';
import {SectionContent} from './ReportAdditionalFields';
import {isRichText, safeNewLineToDoubleBr} from '../../../../utils/utils'
import {RichTextDisplay} from '../../../../components/RichTextDisplay'

export const ReportCaseSummary = ({caseRequest, form}) => {

  const caseSummaryLoading = useToggle()

  const toggleSection = () => {
    form.setValue('reportLayout.hideCaseSummary',v => !v)
    caseSummaryLoading.wait(form.submitForm())
  }
  const isHidden = caseRequest.reportData.reportLayout.hideCaseSummary

  return <><ReportField css={isHidden && "opacity: 0.5"}>
      <div className="field-heading">
        <h4><FormattedMessage defaultMessage='Clinical summary:' description='Preview case summary label'/></h4>
        <div className="field-actions">
          {caseSummaryLoading.isOpen && <Loading size={16} borderWidth={3} />}
          <ReportTextButton small onClick={toggleSection}>
            {isHidden ?
              <><Icon icon="view" /> Show in report</>
            : <><Icon icon="hide" /> Hide from report</>
            }
          </ReportTextButton>
          <Button.Link small to={`/admin/request/${caseRequest.requestCode}/clinical`}>Edit</Button.Link>
        </div>
      </div>
    </ReportField>
    <SectionContent
      setItemOrder={_.identity}
      disabled
    >
      <ReportField
        css={isHidden && "opacity:0.5;"}
        subItem
      >
        <div className="field-content fs-exclude">
          {caseRequest.caseInfo.caseSummary ?
            // <div  dangerouslySetInnerHTML={{__html: safeNewLineToDoubleBr(caseRequest.caseInfo.caseSummary)}}/>
            <RichTextDisplay className="field-content fs-exclude" content={caseRequest.caseInfo?.caseSummary} useSafeNewLine={!isRichText(caseRequest.caseInfo?.caseSummary)} />
            : <NotProvided />
          }
        </div>
      </ReportField>
    </SectionContent>
  </>


}

