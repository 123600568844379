import React from 'react'
import {createSelectableContext} from "../../hooks/useSignalUpdate";
import _ from "lodash/fp";

// export const UploadQueueContext = React.createContext()
const [UploadQueueProvider_,useUploadQueue_] = createSelectableContext()
export const UploadQueueProvider = ({children,signal}) => {
  const [useRegisterUpdates,getQueue] = signal
  useRegisterUpdates()
  return <UploadQueueProvider_ value={getQueue()}>{children}</UploadQueueProvider_>
}
export const useUploadQueueSelector = useUploadQueue_

export const useIsUploading = () =>
  useUploadQueueSelector(_.find('uploading.length'))