import {Button} from '@startlibs/components'
import React from 'react'
import {ConfirmDialog, useConfirmDialogWithProps} from '../../../hooks/useConfirmDialog'
import {InfoBox} from '../../../components/InfoBox'
import {ROLE_LABELS} from '../../../enums/UserRole';
import {getJwt} from '../../../hooks/useJwt'
import {jwtPostFetcher} from '../../../utils/authFetch'
import _ from 'lodash/fp'
import {responseFailure} from '../../../utils/validation'


const UserInfoBox = ({user:{firstName,lastName,role,email}}) => <InfoBox>
  <div css="font-size: 14px; color: rgba(0,0,0,0.6);">
    <strong>{firstName} {lastName} ({ROLE_LABELS[role]})</strong>
  </div>
  <div>
    <strong>Email:</strong> {email}
  </div>
</InfoBox>

const formatCategories = _.update('categories',_.map(_.get('id')))

export const useConfirmStaffDialogs = (onSuccess) => {

  // const saveCategories = (caseRequest,categories) => jwtPostFetcher(getJwt())('/api/admin/caseCategory?requestId='+getId(caseRequest),categories.map(_.get('id')),{method:"PUT"})

  const action = (categories) => {
    /*const newIds = categories.map(_.get('id'))
    if (!userInfo.categories.length || userInfo.categories.find(cat => newIds.indexOf(cat.id)>=0)) {
      return saveCategories(toggleWithCaseRequest.get(),categories)
    } else {
      confirmDialog.openWith(categories)
      return Promise.reject([])
    }*/
  }



  const toggleWithCaseRequest = useConfirmDialogWithProps(([type, staff]) => {
    switch(type) {

      case 'resend':
        return <ConfirmDialog
          action={() => jwtPostFetcher(getJwt())(`/api/admin/sendRegistrationEmail/${staff.id}`)}
          confirm={<Button highlight>Resend invitation email</Button>}
          values={staff}
          notify="Invitation email submitted."
          title="Confirm resending invitation"
        >{ form => <>
          <p>Please confirm resending this user's invitation for activating its account:</p>
          <UserInfoBox user={staff}/>
          <p>Are you sure you want to resend?</p>
        </>}</ConfirmDialog>


      case 'reset':
        return <ConfirmDialog
          action={action}
          confirm={<Button highlight>Send password reset email</Button>}
          notify="Password reset email sent."
          title="Confirm resetting password"
        >{ form => <>
          <p>Please confirm resetting this user's password:</p>
          <UserInfoBox user={staff}/>
          <p>An email will be sent to the user so it can set a new password.</p>
        </>}</ConfirmDialog>


      case 'suspend':
        return <ConfirmDialog
          action={() => jwtPostFetcher(getJwt())(`/api/admin/suspendUser/${staff.id}`)}
          confirm={<Button alert>Suspend user</Button>}
          onSuccess={onSuccess}
          notify="User suspended successfully."
          title="Confirm suspending user"
        >{ form => <>
          <p>Please confirm suspending the following user's account:</p>
          <UserInfoBox user={staff}/>
          <p>You can still reactivate this user later on. Are you sure you want to suspend?</p>
        </>}</ConfirmDialog>


      case 'activate':
        return <ConfirmDialog
          action={() => jwtPostFetcher(getJwt())(`/api/admin/activateUser/${staff.id}`)}
          confirm={<Button highlight>Activate user account</Button>}
          onSuccess={onSuccess}
          notify="User activated successfully."
          title="Confirm activating user"
        >{ form => <>
          <p>Please confirm activation of this currently suspended user:</p>
          <UserInfoBox user={staff}/>
          <p>Are you sure you want to activate this user?</p>
        </>}</ConfirmDialog>


      case 'delete':
        return <ConfirmDialog
          action={() => jwtPostFetcher(getJwt())(`/api/admin/user`,formatCategories(staff),{method: 'DELETE'})}
          confirm={<Button alert icon="delete">Delete user</Button>}
          onSuccess={onSuccess}
          onFailure={responseFailure((data, {status}) => (status === 401 && {'': ["It was not possible to delete this user"]}))}
          notify="User removed successfully."
          title="Confirm deleting user"
        >{ form => <>
          <p>Please confirm deleting the following user's account:</p>
          <UserInfoBox user={staff}/>
          <p>This action is irreversible. Are you sure you want to delete?</p>
        </>}</ConfirmDialog>

    }


  })

  // const confirmDialog = useConfirmMissingCategories(() => toggleWithCaseRequest.get(),(values) => {onSuccess(toggleWithCaseRequest.get())(values); toggleWithCaseRequest.close()})

  return toggleWithCaseRequest
}
