import React from 'react'
import {useToggle} from '@startlibs/core'
import {Button, Dialog} from '@startlibs/components'
import {Errors, WithForm} from '@startlibs/form'
import {useNotificationRef} from '../../../components/Notifications'
import {ExpertProfileCard} from '../components/ExpertProfileCard'
import {jwtGetFetcher} from '../../../utils/authFetch'
import {getJwt} from '../../../hooks/useJwt'
import {ConfirmDialog, useConfirmDialog} from '../../../hooks/useConfirmDialog'

export const useResetExpertPasswordDialog = (expert,onSuccess) => {

  const resetPassword = () => jwtGetFetcher(getJwt())(`/api/admin/${expert.id}/resetPassword`)

  const onFailure = (e,[resp,{status}],form) => {
    if (status === 404) {
      form.setError("","This account email has not yet been confirmed by its owner. Passwords can only be reset for accounts with confirmed email.")
    }
  }

  return useConfirmDialog(
    <ConfirmDialog
      action={resetPassword}
      onSuccess={onSuccess}
      onFailure={onFailure}
      confirm={<Button highlight>Reset password</Button>}
      title="Reset expert's password"
      notify="Password reset email sent"
    >
      <p>The following expert will be requested to set up a new password.</p>
      <ExpertProfileCard expert={expert}/>
      <p>Are you sure you want to proceed?</p>
    </ConfirmDialog>
  )

}
