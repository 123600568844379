import {Icon} from '@startlibs/components';
import {darken} from 'polished';
import {getColor} from '@startlibs/utils';
import React from 'react'
import styled from 'styled-components'

export const FormBox = styled.div`
  box-shadow: 0 0 0 1px ${getColor('gray210')};
  padding: 1.5rem 1.5rem 0.75rem;
  background: ${getColor('gray240')};
  border-radius: 6px;
  margin-bottom: 1rem;
  position: relative;
`

export const RemoveFormBox = styled(Icon).attrs({icon:'x'}) `
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  font-size: 18px;
  color: ${getColor('gray240')};
  background: ${getColor('gray120')};
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 50%;
  text-align: center;
  :hover {
    background: ${props => darken(0.05, getColor('gray120')(props))};
  }
  :active {
    background: ${props => darken(0.08, getColor('gray120')(props))};
  }
`

export const FormBoxTitle = styled.div `
  font-size: 14px;
  margin-bottom: 1.5rem;
  color: rgba(0,0,0,0.4);
`