import React from 'react'
import {PRIVATE, MEDICARE} from '../../enums/InsuranceType'
import _ from 'lodash/fp'
import {required} from '../../utils/validation'
import {OTHER, PATIENT, REF_PHYSICIAN} from '../../enums/ContactRelationType'

export const getCoveredAndCharge = (payment) => {
  const chargeValue = payment?.chargeValue || 0
  return [0,chargeValue]
}

const INSURANCE_PREFIX = 'patientInfo.insurances'
export const INSURANCE_REQUIRED_KEYS = ['insuranceCarrier', 'memberNumber', 'groupNumber']
export const ALL_INSURANCE_REQUIRED_KEYS = INSURANCE_REQUIRED_KEYS.concat("insurancedName")
export const MEDICARE_REQUIRED_KEYS = ['code']
export const OTHER_REQUIRED_KEYS = ['info']
const KEYS_FOR_TYPE = {
  [MEDICARE]:MEDICARE_REQUIRED_KEYS,
  [OTHER]: OTHER_REQUIRED_KEYS,
  [PRIVATE]: INSURANCE_REQUIRED_KEYS
}
const KEYS_FOR_TYPE_ALL = {
  ...KEYS_FOR_TYPE,
  [PRIVATE]: ALL_INSURANCE_REQUIRED_KEYS
}
export const checkInsurance = ({id,type,...rest}, i, forceValidation) => (forceValidation || Object.values(rest).find(_.identity))
    ? generalCheckInsurance(rest,type,i)
    : []
const generalCheckInsurance = (insurance, type, i) => {
  return KEYS_FOR_TYPE[type]
    .map(k => [INSURANCE_PREFIX + '[' + i + '].' + k, required(insurance[k])]).filter(([k, v]) => v)
}

export const removeExtraInsuranceFields = ({id,type,...rest}) =>
  ({id,type,..._.pick(KEYS_FOR_TYPE_ALL[type],rest)})
