import React from 'react'
import _ from 'lodash/fp'
import {SVS_DOCTYPE} from '../components/NonDicomBox'
import {FILE_TYPES} from './FileType'
import {callIfFunction} from '@startlibs/utils'
import {DicomStudy, NonCompliantDicom} from '../enums/RecordFormat'
import {Radiology} from '../enums/RecordClass'
import { updateActivityLogBy } from '../components/hooks/useActivityLog'
import { Deleted } from '../enums/FileState'

export const updateDicomStudy = (dicomInstance, updater) => studyList => {
  const studyIndex = studyList.findIndex(study => study.studyUID === dicomInstance.studyUID && study.format === DicomStudy)
  if (studyIndex >= 0 && studyList[studyIndex]) {
    return _.update([studyIndex], updater, studyList)
  }
  return studyList

}

export const updateDicomInstance = (dicomInstance, updater) => studyList => {
  const studyIndex = studyList.findIndex(study => study.studyUID === dicomInstance.studyUID && study.format === DicomStudy)
  if (studyIndex >= 0 && studyList[studyIndex] && studyList[studyIndex].instances) {
    const instanceIndex = studyList[studyIndex].instances.findIndex(instance => instance.instanceUID === dicomInstance.instanceUID)
    if (instanceIndex >= 0) {
      return _.update([studyIndex, 'instances', instanceIndex], updater, studyList)
    }
  }
  return studyList
}

export const removeDicomInstance = (dicomInstance) => studyList => {
  const studyIndex = studyList.findIndex(study => study.studyUID === dicomInstance.studyUID && study.format === DicomStudy)
  if (studyIndex >= 0 && studyList[studyIndex] && studyList[studyIndex].instances) {
    const study = studyList[studyIndex]
    if (study.isNew && (study.instances.length === 1 && studyList[studyIndex].instances[0].instanceUID === dicomInstance.instanceUID)) {
      return _.pullAt([studyIndex], studyList)
    } else {
      return _.update([studyIndex, 'instances'], (instances) => instances.filter(instance => instance.instanceUID !== dicomInstance.instanceUID), studyList)
    }
  }
  return studyList
}

export const removeDicomStudy = (dicomStudy) => studyList => {
  const studyIndex = studyList.findIndex(study => study.studyUID === dicomStudy.studyUID)
  if (studyIndex >= 0) {
    return _.pullAt([studyIndex], studyList)
  }
  return studyList
}

export const removeUnploadedInstances = (dicomStudy) => studyList => {
  const studyIndex = studyList.findIndex(study => study.studyUID === dicomStudy.studyUID)
  if (studyIndex >= 0) {
    const study = studyList[studyIndex]
    if (study.isNew && !study.instances.find(instance => !instance.sourceFile)) {
      return _.pullAt([studyIndex], studyList)
    } else {
      return _.update([studyIndex, 'instances'], _.filter(instance => !instance.sourceFile), studyList)
    }
  }
  return studyList
}


const PATIENT_TAGS = ['patientID', 'alternatePatientID', 'patientName', 'patientDoB', 'patientSex']
const STUDY_TAGS = PATIENT_TAGS.concat(['studyUID', 'studyDate', 'studyDescription','referringPhysician'])
// const SERIES_TAGS = STUDY_TAGS.concat(['seriesUID', 'seriesDate', 'seriesTime', 'seriesModality', 'seriesInstitutionName'])

export const addDicomInstance = (dicomInstance, sourceFile, byteArray, mode = '') => (studies) => {

  const key =  dicomInstance.key || getUUID()

  const createInstance = (studyKey) => ({
    ...dicomInstance,
    sourceFile,
    byteArray,
    fileName: dicomInstance.fileName || (sourceFile ? getFileNameAndExtension(sourceFile)[0] : "Missing filename"),
    key:studyKey
  })

  const createStudy = () => ({
    ..._.pick(STUDY_TAGS, dicomInstance),
    isNew: true,
    key,
    instances: [createInstance(key)],
    modalities: dicomInstance.seriesModality ? [dicomInstance.seriesModality] : [],
    seriesUIDs: dicomInstance.seriesUID ? [dicomInstance.seriesUID] : [],
    recordClass: Radiology,
    format: DicomStudy,
    mode: mode
  })


  const addToInstances = (studyKey) => (instances) => {
    const instanceIndex = instances.findIndex(instance => instance.instanceUID === dicomInstance.instanceUID)
    if (instanceIndex >= 0) {
      return instances
    } else {
      return [].concat(createInstance(studyKey),instances)
    }
  }

  const studyIndex = studies.findIndex(study => study.studyUID === dicomInstance.studyUID && study.format === DicomStudy)
  const study = studies[studyIndex]
  if (studyIndex >= 0) {
    return _.flow(
      _.update([studyIndex, 'instances'], addToInstances(study.key)),
      _.update([studyIndex, 'modalities'], _.union(dicomInstance.seriesModality ? [dicomInstance.seriesModality] : [])),
      _.update([studyIndex, 'seriesUIDs'], _.union(dicomInstance.seriesUID ? [dicomInstance.seriesUID] : []))
    )(studies)
  } else {
    return studies.concat(createStudy())
  }
}

export const removeNomCompliantInstance = (attachment) => (files) => {
  return _.pullAllBy(_.property(attachment.key ? 'key' : 'uid'), [attachment], files)
}

export const updateNonCompliantInstance = (attachment, updater) => attachments => {
  const attachmentIndex = attachments.findIndex((a) => (attachment.sourceFile && a.sourceFile === attachment.sourceFile) || (a.uid && a.uid === attachment.uid) || (a.key && a.key === attachment.key))
  // const attachmentIndex = attachments.findIndex((a) => (attachment.sourceFile && a.sourceFile === attachment.sourceFile && (a.key && a.key === attachment.key)) || (a.uid && a.uid === attachment.uid))
  if (attachmentIndex >= 0) {
    return _.update([attachmentIndex], updater, attachments)
  }
  return attachments
}

export const updateNonCompliantInstanceSource = (attachment, updater) => attachments => {
  // const attachmentIndex = attachments.findIndex((a) => (attachment.sourceFile && a.sourceFile === attachment.sourceFile) || (a.uid && a.uid === attachment.uid) || (a.key && a.key === attachment.key))
  const attachmentIndex = attachments.findIndex((a) => (attachment.sourceFile && a.sourceFile === attachment.sourceFile && (a.key && a.key === attachment.key)) || (a.uid && a.uid === attachment.uid))
  if (attachmentIndex >= 0) {
    return _.update([attachmentIndex], updater, attachments)
  }
  return attachments
}

export const updateMultiFileInstance = (attachment, updater) => records =>
  records.map((record) => attachment.recordKey === record.key
    ? _.update('instances', _.map((instance) => (instance.sourceFile === attachment.sourceFile) ? updater(instance) : instance),record)
    : record
  )

const NON_COMPLIANT_STUDY_FIELDS = ['patientId', 'patientName', 'patientDoB', "patientSex", "studyUID", "studyDate", "studyDescription"]
const NON_COMPLIANT_FILE_FIELDS = ['uid', 'fileName', 'fileExtension', 'fileSize', 'studyUID', 'instanceUID']

export const addNonCompliantInstance = (attachment, file) => (files) => {
  if (attachment.instanceUID && files.find(({instanceUID}) => instanceUID === attachment.instanceUID)) {
    return files
  }
  return [...files,getNonCompliantInstanceFile(attachment, file)]
}

export const getNonCompliantInstanceFile = (attachment,file) => {
  const [fileName,extension] = getFileNameAndExtension(file)
  return {
    ..._.pick(NON_COMPLIANT_FILE_FIELDS, attachment),
    key: attachment.key || getUUID(),
    nonCompliant: true,
    sourceFile: file,
    fileName: fileName,
    description:fileName,
    format: NonCompliantDicom,
    recordClass: Radiology,
    fileExtension: extension,
    fileSize: file.size,
    isNew:true
  }
}

export const removeUnploadedNonCompliant = (files) => _.filter(attachment => !attachment.sourceFile,files)


export const updateFile = (attachment, updater) => (attachments) => {
  const index = attachments.findIndex(attachmentItem => (attachmentItem.sourceFile && attachmentItem.sourceFile === attachment.sourceFile) || (attachment.uid !== undefined && attachment.uid === attachmentItem.uid)|| (attachment.id !== undefined && attachment.id === attachmentItem.id))
  return _.update([index], updater, attachments)
}

export const removeFile = (instance,getItemId) => (attachments) => {
  return attachments.filter(attachment => attachment.sourceFile !== instance.sourceFile || getItemId(attachment) !== getItemId(instance))
}

export const removeFiles = (medicalRecords,selectedRecords) => {
  
  const selectdRecordsObj = []
  _.map((item) => {
    selectdRecordsObj.push({'recordUID': item})
    updateActivityLogBy('recordUID',item,Deleted)
    const record  = medicalRecords.find((record) => record.recordUID === item)
    if((record?.multiFile == true) && (record?.recordClass === "Pathology")){
    }
  },selectedRecords)
  
  return  _.differenceBy(_.property('recordUID'), medicalRecords,  selectdRecordsObj )
 
}
 
export const removeRecord = (instance,getItemId) => (attachments) => {
  return attachments.filter(attachment => getItemId(attachment) !== getItemId(instance))
}

export const updateRecord = (instance,updater,getItemId) => (attachments) => {
  return attachments.map(attachment => getItemId(attachment) === getItemId(instance) ? callIfFunction(updater,attachment) : attachment)
}

let idcount = 0
export const getUUID = () => "temp-"+Date.now() + "" + (idcount++)

export const getFileNameAndExtension = (file) => {
  const fileName = file.name.replace(/(.*)\..+$/, "$1")
  const extension = file.name.indexOf(".") >= 0 ? file.name.replace(fileName + ".", "").slice(0,10) : ""
  return [fileName.slice(0,127),extension]
}

export const createAttachment = (file, mode) => {
  const [fileName,extension] = getFileNameAndExtension(file)
  const fileType = FILE_TYPES[extension]
  const addExtension = fileType === "zip" || !fileType
  return {
    description: fileName + ((addExtension && extension) ? "."+extension : ""),
    docType: extension === "svs" ? SVS_DOCTYPE : "",
    key: getUUID(),
    sourceFile: file,
    fileType: file.type,
    fileName: fileName,
    fileExtension: extension,
    fileSize: file.size,
    mode: mode ? mode : 'normal'
  }
}