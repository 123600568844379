import React from 'react'
import styled from 'styled-components'
import {Icon} from '@startlibs/components'
import {getColor} from '@startlibs/utils'

export const RemoveIcon = styled(Icon) `
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  color: ${getColor('gray150')};
  cursor: pointer;
`