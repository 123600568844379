import {useEffect} from 'react'
import {removeWindowScroll} from '@startlibs/components'
import {useRefState} from '@startlibs/core'

export const useRemoveWindowScroll = (test) => {
  const hasOpened = useRefState()

  useEffect(() => {
    if (hasOpened.get() || test) {
      removeWindowScroll(test)
    }
    hasOpened.set(true)
  },[test])// eslint-disable-line react-hooks/exhaustive-deps
}