import {Button} from '@startlibs/components';
import {FormValue, TextInput, ToggleCheckbox} from '@startlibs/form';
import React from 'react'
import {CaseRequestCard} from '../CaseRequestCard';
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import {ExpertProfileCard} from '../experts/components/ExpertProfileCard';
import {getJwt} from '../../hooks/useJwt'
import {jwtPostFetcher} from '../../utils/authFetch'
import {lazyProviderInfo} from '../../components/WithProvider'
import {responseFailure} from '../../utils/validation'
import {TransitionDiv} from '../../components/TransitionDiv'


export const useUnshareCoReviewDialog = (expertShare, caseRequest, onSuccess) => {

  const {expert} = expertShare
  const providerInfo = lazyProviderInfo.read()
  const formRef = React.useRef()

  return useConfirmDialog(
    <ConfirmDialog
      formRef={formRef}
      title="Remove expert from this case"
      confirm={<Button alert>Remove expert</Button>}
      action={(values) => jwtPostFetcher(getJwt())(`/api/expert/coreview/${expertShare.id}`, values, {method: 'DELETE'})}
      notify="Expert removed successfully."
      values={{message: "", sendEmail: true}}
      onSuccess={onSuccess}
      onFailure={responseFailure((n,{status}) => status === 566 && "This case is not shared with an expert")}
    >
      <p>Please review case details below:</p>
      <CaseRequestCard caseRequest={caseRequest}/>
      <p>Remove this expert from the case:</p>
      <ExpertProfileCard expert={expert}/>
      <FormValue path="sendEmail">{sendEmail =>
        <TransitionDiv>{sendEmail &&
        <TextInput
          textarea
          autoResize
          label="Message"
          path="message"
          placeholder="Insert custom message here"
        />
        }</TransitionDiv>
      }</FormValue>
      <p>The expert will no longer be able to access this case.</p>
    </ConfirmDialog>, [providerInfo]
  )
}
