import React from 'react'
import _ from 'lodash/fp'
import {willUseSharedCallback, willUseSharedState} from '@startlibs/core'

export const [useActivityLog,useActivityLogRef,setActivityLog,getActivityLog] = willUseSharedState([])

let i = 1;
const getNextKey = () => i++


export const updateActivityLogFile = (file,state,recordInfo) => file && setActivityLog( logList => {
  const index = _.findIndex(['file',file],logList)
  if (index>=0) {
    return _.update(index,(item)=> ({...item,...recordInfo,state,updateDate:new Date()}),logList)
  } else {
    return logList.concat({...recordInfo,file,state,updateDate:new Date(),key:getNextKey()})
  }
})

export const updateActivityLogBy = (key,value,state) => setActivityLog(_.map((item => item[key] === value ? _.set('state',state,item) : item)))


export const [useRegisterOpenDetails,openDetails] = willUseSharedCallback()