import {useEffect} from 'react'


export const clearSession = () => {
  window.localStorage.removeItem('admin-jwt')
  window.localStorage.removeItem('expert-jwt')
  window.localStorage.removeItem('jwt')
  window.localStorage.removeItem('patDevJpgs')
  window.localStorage.removeItem('patDiscJpgs')
}
export const useClearSession = () => {
  useEffect(() => {
    clearSession()
  },[])
}