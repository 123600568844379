import {Button} from '@startlibs/components';
import {getColor, media} from '@startlibs/utils'
import styled, {css} from 'styled-components';
import {ReturnButton} from './Header';

export const BoxPageLayout = styled.div`
  height: 100%;
  color: ${getColor('gray90')};
  ${props => props.responsiveWorkaround && css`
    min-width: 640px;
  `}
`

export const BoxPageContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 7rem 1.5rem 40px;
  ${media.max(420)`
    padding: 7rem 1rem 6rem;
  `}
`

export const BoxPageWrapper = styled.div`
  background-color: white;
  border-radius: 6px;
  padding: 1rem 2rem 2rem;
  width: 100%;
  max-width: 90rem;
  ${props => props.fullWidth && css`
    max-width: 100%;
  `}
  margin: 0 auto 1rem;
  display: block;
  ${media.max(520)`
    padding: 1rem 1rem 1rem;
  `}
`

export const BoxWrapperHeading = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #F3F4F8;
  margin: 0 -2rem 1.5rem;
  padding: 0 2rem 1rem;
  h2 {
    font-size: 18px;
    line-height: 24px;
    flex-grow: 1;
    margin: .5rem 1rem .5rem 0;
  }
  .buttons-wrapper {
    flex-shrink: 0;
    display: flex;
    word-break: keep-all;
    white-space: nowrap;
    ${Button} ~ ${Button} {
      margin-left: .5rem;
    }
  }
  ${props => props.withReturn && css`
    flex-wrap: nowrap;
    ${ReturnButton} {
      position: static;
      text-decoration: none;
      color: inherit;
      margin-right: 1rem;
      min-width: 3rem;
    }
    .header-content {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
  `}
`

export const BoxWrapperFooter = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .right-block {
    flex-grow: 1;
    text-align: right;
  }
  ${media.max(420)`
    flex-direction: column-reverse;
    ${Button} {
      flex-basis: 100%;
      width: 100%;
      display: block;
      margin: .25rem;
    }
    .right-block, .left-block {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      ${Button} {
        flex-basis: 100%;
        width: 100%;
        margin: .25rem 0;
      }
    }
  `}
`
